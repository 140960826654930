<div #ParentDiv>
    <div class="titleBar">
        <div class="title">Truth Engine Tracking</div>
    </div>

    <div class="welcome">

        <br>

        <div class="chooseComp" *ngIf="multipleCompanies && !loading">
            <div *ngIf="!currComp" class="subTitle">Please Select a Company:</div>
            <div *ngIf="currComp" class="subTitle">Company Name:</div>
            <br>
            <div *ngIf="userCompanies && userCompanies.length">
              <dx-select-box [dataSource]="userCompanies" displayExpr="companyShortName" valueExpr="id" [value]="selectedCompanyId"
                searchMode="contains" [searchEnabled]="true" [searchTimeout]="400" [minSearchLength]="3"
                [width]="companySelectWidth" [showClearButton]="true" placeholder="Start typing company name..."
                [showDataBeforeSearch]="!isProduction || userCompanies[0].userTypeId !== userTypeEnum.SuperUser"
                (onValueChanged)="companySelected($event)" class="marginAuto">
              </dx-select-box>
            </div>
        </div>

        <div *ngIf="loading" style="margin-bottom: 50px">
            <js-loading-spinner></js-loading-spinner>
        </div>

        <br>

        <div *ngIf="!loading">
            <div *ngIf="!(innerWidth < 750)" class="user">
                <div class="userDetails">
                    <div style="text-align: left;">
                        <span *ngIf="currUser" class="username">{{currUser.firstName}} {{currUser.lastName}}</span>
                        <span *ngIf="!currUser" class="username">User</span>
                    </div>
                    {{currUserEmail}}
                </div>
                <div class="userButtons">
                    <button class="btn btn-outline-primary" (click)="signOut()">Sign out</button>
                </div>
            </div>
            <div *ngIf="innerWidth < 750" class="userSmall">
                <div class="userDetailsSmall">
                    <div *ngIf="currUser" class="username">{{currUser.firstName}} {{currUser.lastName}}</div>
                    <div *ngIf="!currUser" class="username">User</div>
                    {{currUserEmail}}
                </div>
                <div class="userButtonsSmall">
                    <button class="btn btn-outline-primary buttonSmall" (click)="signOut()">Sign out</button>
                </div>
            </div>
        </div>
        <br>
        <div class="links">
          <button *ngIf="admin" class="btn btn-sm btn-secondary linkBtn" (click)="launchAdminPortal()">
              Launch Admin Portal
          </button>
            <button class="btn btn-sm btn-secondary linkBtn" (click)="launchTruthEngine()">
                Launch Truth Engine
            </button>
            <button class="btn btn-sm btn-secondary linkBtn" (click)="launchEstConfig()">
                Launch Estimating Config App
            </button>
            <button class="btn btn-sm btn-secondary linkBtn" (click)="launchCosting()">
                Launch Costing App
            </button>
            <button class="btn btn-sm btn-secondary linkBtn" (click)="launchConfig()">
                Launch Config App
            </button>
            <!-- <a class="btn btn-sm btn-secondary linkBtn" href="mailto:gerard@truthengine.com">Support</a> -->
        </div>

        <div *ngIf='currentVersion' class='versionString'>version {{currentVersion}}</div>
    </div>

    <br>
    <button class="btn btn-sm ms-2" (click)="clearCache()">
      to clear memory click here
    </button>
</div>
