export enum ConfigurationEnum {
    LogoPosition = 1,
    ReportFooterImage = 2,
    SalesQuotePrintWholeAddendum = 3,
    WordingOfToBeSelected = 4,
    WordingOfToBeSelectedByConsultant = 5,
    DefaultConstructionAdminFee = 6,
    CustomerUpdateAllowed = 7,
    SalesQuoteCoverImage = 8,
    DefaultJobDepositValue = 9,
    AddendumHeadingWording = 10,
    ManualOrders = 11,
    ClientNotificationOfInfoChanges = 12,
    VariationPriceEstimatingEnabled = 13,
    ConstructionActivitiesUsed = 21,
    SalesQuoteSignedDateShown = 45,
    AccountingSystem = 46,
    DocumentManagementSystem = 47,
    ConstructiveIntegrationEnabled = 50,
    PackageSystemActive = 63,
    EmailUpdatesToClientsActive = 66,
    LandDatabase = 70,
    TruthEngineClientPortalActive = 75
}

export enum DocumentManagementEnum {
  GoogleDocs = 1,
  SharePoint = 2
}
