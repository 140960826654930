import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { GlobalService } from './global.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private globalService: GlobalService,
    private utilsService: UtilsService
  ) {
    this.jsonReplacer = this.jsonReplacer.bind(this);
  }

  getHttpOptions(): { headers: HttpHeaders; } {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id': environment.clientName,
        'client-session-id': require('uuid').v4(),
        'client-version': environment.fullVersion,
        'company-id': this.globalService.getCurrentCompanyId(),
        'user-id': this.globalService.currentUserId?.toString() ?? '0'
      })
    };
    return httpOptions;
  }

  getHttpFileOptions(): { headers: HttpHeaders; } {
    const httpOptions = {
      headers: new HttpHeaders({
        'client-id': environment.clientName,
        'client-session-id': require('uuid').v4(),
        'client-version': environment.fullVersion,
        'company-id': this.globalService.getCurrentCompanyId(),
        'user-id': this.globalService.currentUserId?.toString() ?? '0'
      })
    };
    return httpOptions;
  }

  getHttpFileOptionsNoUser(): { headers: HttpHeaders; } {
    const httpOptions = {
      headers: new HttpHeaders({
        'client-id': environment.clientName,
        'client-session-id': require('uuid').v4(),
        'client-version': environment.fullVersion
      })
    };
    return httpOptions;
  }

  getHttpFilePDFOptions(): { headers: HttpHeaders; } {
    const httpOptions = {
      headers: new HttpHeaders({
        responseType: 'arraybuffer',
        'client-id': environment.clientName,
        'client-session-id': require('uuid').v4(),
        'client-version': environment.fullVersion,
        'company-id': this.globalService.getCurrentCompanyId(),
        'user-id': this.globalService.currentUserId?.toString() ?? '0'
      })
    };
    return httpOptions;
  }

  jsonReplacer(key, value) {
    if (this[key] instanceof Date) {
      return this.utilsService.convertDateToString(this[key]);
    } else {
      // check if this[key] is a date
      const checkNum = Date.parse(this[key]);
      const isDateField = key.includes('date') || key.includes('Date');
      if (!Number.isNaN(checkNum) && isDateField) {
        const newDate = new Date(checkNum);
        const newDateString = this.utilsService.convertDateToString(newDate);
        return newDateString;
      }
    }
    return value;
  }
}
