<div class="page">

  <js-job-number *ngIf="!loading" (refreshJobNumber)='onJobNumberChanged($event)'></js-job-number>

  <div *ngIf="!loading && taskHeadersLoaded && !taskHeadersForJob.length">
    <div>No construction workflow set up for this job.</div>
    <div>Go to <b>Job-Data</b> to set up the Region and add the construction workflow required (under Work-Flows).</div>
  </div>

  <dx-data-grid *ngIf="!loading && taskHeadersLoaded && taskHeadersForJob.length && dataSourceSetup" id="callUpGrid"
    #callUpGrid [dataSource]="dataSource" [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    [allowColumnReordering]="false" [allowColumnResizing]="false" [columnAutoWidth]="true"
    [focusedRowEnabled]="focusedRowEnabled" [(focusedRowKey)]="firstNotCompletedTaskId"
    [autoNavigateToFocusedRow]="autoNavigateToFocusedRow" (onInitNewRow)="onInitNewRow($event)"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate', 'toolbarTemplate2')"
    (onRowPrepared)="onRowPrepared($event)" (onEditingStart)="onEditingStart($event)"
    (onEditorPreparing)="onEditorPreparing($event)" (onRowCollapsing)="onRowCollapsed($event)"
    (onRowExpanding)="onRowExpanded($event)" (onContentReady)="onContentReady()"
    (onCellPrepared)="onCellPrepared($event)" [height]="gridHeight"
    (onExporting)="gridService.onExporting($event, 'Call-Ups')" [selectedRowKeys]="selectedRowKeys"
    (onSaved)="onTasksSaved()">

    <dxo-row-dragging [allowReordering]="reorderingAllowed" [onReorder]="onReorder" [showDragIcons]="true">
    </dxo-row-dragging>
    <dxo-scrolling mode="standard" rowRenderingMode="virtual" columnRenderingMode="virtual"></dxo-scrolling>
    <dxo-sorting mode='none'></dxo-sorting>
    <dxo-export [enabled]="windowWidth > 1100"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" width="110px"></dxo-search-panel>
    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="false" expandMode="rowClick"></dxo-grouping>
    <dxo-selection selectAllMode="allPages" showCheckBoxesMode="always" [mode]="selectionMode"></dxo-selection>

    <dxo-editing *ngIf="selectionMode === 'none'" [mode]="editMode" [allowAdding]="false"
      [allowUpdating]="selectedTaskHeaderId" [allowDeleting]="selectedTaskHeaderId" [useIcons]="true"
      refreshMode="full">
      <dxo-popup title="" [showTitle]="false" [width]="popupWidth" [height]="popupHeight" [fullScreen]="false">
        <dxi-toolbar-item *ngIf="!currentJobTask" toolbar="bottom" location="before" widget="dxButton"
          [options]="{ type: 'default', stylingMode: 'outlined', text: 'Add Multiple', onClick: addMultipleTasks }">
        </dxi-toolbar-item>
        <dxi-toolbar-item *ngIf="currentJobTask" toolbar="bottom" location="before" widget="dxButton"
          [options]="{ type: 'default', stylingMode: 'outlined', text: 'Attach Docs', onClick: attachDocs }">
        </dxi-toolbar-item>
        <dxi-toolbar-item *ngIf="currentJobTask && popupWidth > 560" toolbar="bottom" location="before"
          widget="dxButton"
          [options]="{ type: 'default', stylingMode: 'outlined', text: 'Extra POs', onClick: attachExtraPOs }">
        </dxi-toolbar-item>
        <dxi-toolbar-item *ngIf="currentJobTask && popupWidth > 460" toolbar="bottom" location="before"
          widget="dxButton"
          [options]="{ type: 'default', stylingMode: 'outlined', text: 'Accept', onClick: acceptCallUp }">
        </dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
          [options]="{ type: 'default', text: 'Save', onClick: saveClickHandler }">
        </dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
          [options]="{ type:'danger', text: 'Cancel', onClick: cancelClickHandler }">
        </dxi-toolbar-item>
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="taskMasterId" [colSpan]="4"></dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="16" [colSpan]="2">
          <dxi-item dataField="startDate" [colSpan]="7"></dxi-item>
          <dxi-item dataField="manualDays" [colSpan]="4"></dxi-item>
          <dxi-item dataField="dueDate" [colSpan]="5"></dxi-item>
          <dxi-item dataField="endDate" [colSpan]="7"></dxi-item>
          <dxi-item dataField="isCompleted" [colSpan]="4"></dxi-item>
          <dxi-item cssClass="email-button" [colSpan]="5">
            <div *dxTemplate>
              <span *ngIf="currentJobTask?.isAcceptedByVendor && currentJobTask?.dateAcceptedByVendor">
                Accepted: {{currentJobTask.dateAcceptedByVendor | date: 'd-MMM-yy'}}
              </span>
            </div>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="officeComment" editorType="dxTextArea" [colSpan]="4"
            [editorOptions]="{ minHeight: 70, maxHeight: popupHeight - 150 }">
          </dxi-item>
          <dxi-item dataField="vendorComment" editorType="dxTextArea" [colSpan]="4"
            [editorOptions]="{ minHeight: 95, maxHeight: popupHeight - 150}">
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="16" [colSpan]="2">
          <dxi-item dataField="vendorId" [colSpan]="15"> </dxi-item>
          <dxi-item cssClass="email-button" [colSpan]="1">
            <div *dxTemplate>
              <a *ngIf="vendorEmailString && vendorEmailString !== ''" [href]="vendorEmailString"
                class="material-icons">email</a>
            </div>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="userId" [colSpan]="4"> </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="16" [colSpan]="2">
          <dxi-item dataField="sendAddendum" [caption]='addendumCaption' [colSpan]="4"></dxi-item>
          <dxi-item dataField="doNotSendCallup" [colSpan]="4"></dxi-item>
          <dxi-item dataField="purchaseOrderId" [colSpan]="7"></dxi-item>
          <dxi-item cssClass="order-button" itemType="button" [buttonOptions]="orderbuttonOptions"> </dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="taskTypeId" dataType="number" caption="Load" [groupIndex]="0"
      [calculateGroupValue]="calculateLoadSortValue" groupCellTemplate="groupCellTemplate" [allowEditing]="false">
    </dxi-column>

    <dxi-column *ngIf="taskTitleWidth === vendorDescMinWidth" dataField="taskMasterId" dataType="number" caption="Title"
      [setCellValue]="setTaskMasterIdCellValue" editCellTemplate="editTaskMasterTemplate" [width]="taskTitleWidth"
      [allowEditing]="allowEditing">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="taskMasters" displayExpr="taskTitle" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column *ngIf="taskTitleWidth !== vendorDescMinWidth" dataField="taskMasterId" dataType="number" caption="Title"
      [setCellValue]="setTaskMasterIdCellValue" editCellTemplate="editTaskMasterTemplate" [allowEditing]="allowEditing">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="taskMasters" displayExpr="taskTitle" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="purchaseOrderId" caption="Order" dataType="number" [width]="75" [hidingPriority]="3"
      [setCellValue]="setPurchaseOrderIdCellValue" editCellTemplate="editPurchaseOrderTemplate">
      <dxo-lookup [dataSource]="purchaseOrders" displayExpr="poNumber" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [width]="vendorDescMinWidth"
      [setCellValue]="setVendorIdCellValue" [editorOptions]="{ showClearButton: true }"
      editCellTemplate="editVendorTemplate">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="userId" caption="Office User" dataType="number" [editorOptions]="{ showClearButton: true }"
      [visible]='false'>
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="calledDate" dataType="date" caption="Sent" [width]="65" alignment="center" format="d-MMM"
      [allowEditing]="false" [hidingPriority]="5">
    </dxi-column>

    <dxi-column dataField="startDate" caption="Delivery Start" dataType="date" [width]="75" alignment="center"
      format="d-MMM" [setCellValue]="setStartDateCellValue" [calculateDisplayValue]="calculateStartDateValue"
      [editorOptions]="{ showClearButton: inPopupMode, acceptCustomValue: false, calendarOptions: { showTodayButton: true }, pickerType: 'calendar' }">
    </dxi-column>

    <dxi-column *ngIf="!dueDateOnRight" dataField="dueDate" caption="Due" dataType="date" [width]="65"
      alignment="center" format="d-MMM" [setCellValue]="setDueDateCellValue" [hidingPriority]="1"
      [editorOptions]="{ showClearButton: true, acceptCustomValue: false, calendarOptions: { showTodayButton: true } }">
    </dxi-column>

    <dxi-column *ngIf="!dueDateOnRight" dataField="endDate" caption="Done" dataType="date" [width]="65"
      alignment="center" format="d-MMM" [hidingPriority]="2" [setCellValue]="setEndDateCellValue"
      [editorOptions]="{ showClearButton: true, acceptCustomValue: false, calendarOptions: { showTodayButton: true } }">
    </dxi-column>

    <dxi-column dataField="officeComment" dataType="string" caption="Office Comment" [width]="vendorDescMinWidth - 5"
      [hidingPriority]="4" cellTemplate="commentCellTemplate">
    </dxi-column>

    <dxi-column dataField="vendorComment" dataType="string" caption="Vendor Comment" [width]="vendorDescMinWidth"
      [hidingPriority]="6" cellTemplate="commentCellTemplate">
    </dxi-column>

    <dxi-column *ngIf="dueDateOnRight" dataField="dueDate" caption="Due" dataType="date" [width]="65" alignment="center"
      format="d-MMM" [setCellValue]="setDueDateCellValue" [hidingPriority]="1"
      [editorOptions]="{ showClearButton: true, acceptCustomValue: false, calendarOptions: { showTodayButton: true } }">
    </dxi-column>

    <dxi-column *ngIf="dueDateOnRight" dataField="endDate" caption="Done" dataType="date" [width]="65"
      alignment="center" format="d-MMM" [hidingPriority]="2" [setCellValue]="setEndDateCellValue"
      [editorOptions]="{ showClearButton: true, acceptCustomValue: false, calendarOptions: { showTodayButton: true } }">
    </dxi-column>

    <dxi-column dataField="isCompleted" caption="Done" dataType="boolean" [width]="50"
      [calculateCellValue]='calculateIsCompleted' [setCellValue]="setIsCompletedCellValue" [visible]='false'>
    </dxi-column>

    <dxi-column dataField="doNotSendCallup" caption="Don't Send" dataType="boolean" [width]="50" alignment="center"
      [visible]='false'>
    </dxi-column>

    <dxi-column dataField="statusId" dataType="number" [visible]='false'> </dxi-column>

    <dxi-column dataField="orderNumber" [visible]="false" sortOrder="asc"> </dxi-column>

    <dxi-column dataField="templateTaskHeaderId" [visible]="false"> </dxi-column>

    <dxi-column dataField="manualTaskTitle" [visible]='false'> </dxi-column>

    <dxi-column dataField="manualDays" caption="Days" dataType="number" [visible]='false'
      [editorOptions]="{ placeholder: manualDaysDefault }" [setCellValue]="setManualDaysCellValue">
    </dxi-column>

    <dxi-column dataField="prevStartDate" caption="Previous Delivery" dataType="date" format="d-MMM-yy"
      [allowEditing]="false" [visible]='false'>
    </dxi-column>

    <dxi-column dataField="sendAddendum" [caption]='addendumCaption' dataType="boolean" [width]="50" [visible]='false'>
    </dxi-column>

    <dxi-column dataField="isAcceptedByVendor" dataType="boolean" [visible]='false'>
    </dxi-column>

    <!-- for updating the completion forecast -->
    <dxi-column dataField="targetCompletionDate" [visible]="false"> </dxi-column>
    <dxi-column dataField="forecastCompletionDate" [visible]="false"> </dxi-column>

    <dxi-column *ngIf="selectionMode === 'none'" type="buttons" [width]="100">
      <dxi-button name="edit"></dxi-button>
      <dxi-button *ngIf="editMode === 'popup'" icon="trash" [onClick]="deleteTaskPopup"></dxi-button>
      <dxi-button *ngIf="editMode === 'popup'" icon="add" [onClick]="addTaskPopup"></dxi-button>
      <dxi-button [visible]="isCompleteIconVisible" icon="todo" [onClick]="completeTask"></dxi-button>
    </dxi-column>

    <!-- Master Jobs can have child jobs so can show them here -->
    <dxo-master-detail [enabled]="isMasterOrSubJob && selectionMode === 'none'"
      template="masterDetail"></dxo-master-detail>

    <div *dxTemplate="let masterDetailItem of 'masterDetail'">
      <js-child-call-ups [jobTask]=masterDetailItem.data [vendorDescMinWidth]="vendorDescMinWidth"
        [selectedTaskHeaderId]="selectedTaskHeaderId" [refreshFlag]="refreshChildJobs"></js-child-call-ups>
    </div>

    <!-- Templates -->

    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <dx-select-box id="selectWorkflow" [dataSource]="taskHeadersForJob" displayExpr="description" valueExpr="id"
          placeholder="Choose Template" [width]="250" [(value)]='selectedTaskHeaderId' [disabled]="editMode === 'batch'"
          (onValueChanged)='getCallUpData($event)'>
        </dx-select-box>

        <span *ngIf="selectedTaskHeaderId" class="ms-2 me-1">Show Done</span>

        <dx-check-box *ngIf="selectedTaskHeaderId" [(value)]="showCompleted" [width]="30"
          (onValueChanged)="showCompletedChanged($event)" [disabled]="editMode === 'batch'">
        </dx-check-box>

        <a *ngIf="selectedTaskHeaderId && isTablet && editMode === 'popup' && windowWidth > 500"
          class="btn btn-sm btn-outline-primary ms-1" [href]="emailString">Email</a>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate2'">
      <div>
        <dx-button *ngIf="editMode === 'popup'" text="Edit" type="default" (onClick)="changEditMode()">
        </dx-button>

        <dx-button *ngIf="editMode !== 'popup'" icon="close" type="default" (onClick)="changEditMode()">
        </dx-button>
      </div>
    </div>


    <!-- edit template so we can have a different data source -->

    <div *dxTemplate="let cellInfo of 'editTaskMasterTemplate'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="taskMasters" [(value)]="cellInfo.value"
        displayExpr="taskTitle" valueExpr="id" contentTemplate="taskMasterContentTemplate" [readOnly]="!allowEditing">
        <div *dxTemplate="let e of 'taskMasterContentTemplate'">
          <dx-data-grid *ngIf="!refreshingTaskMasterData" [dataSource]="templateTaskMastersData"
            [remoteOperations]="false" height="358px" [showBorders]="true" [wordWrapEnabled]="true"
            [selectedRowKeys]="[cellInfo.value]" [hoverStateEnabled]="true"
            [scrolling]="{ mode: 'virtual', preloadEnabled: true }" [autoNavigateToFocusedRow]="true"
            (onToolbarPreparing)="onTaskMasterToolbarPreparing($event)"
            (onSelectionChanged)="onTaskMasterSelectionChanged(cellInfo, e.component, $event)">

            <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>

            <dxi-column dataField="id" [visible]='false'></dxi-column>

            <dxi-column dataField="taskTypeId" dataType="number" caption="Type" [width]="200">
              <dxo-lookup [dataSource]="taskTypes" displayExpr="description" valueExpr="id">
              </dxo-lookup>
            </dxi-column>

            <dxi-column dataField="taskTitle" dataType="string">
            </dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>


    <!-- select a purchase order -->
    <div *dxTemplate="let cellInfo of 'editPurchaseOrderTemplate'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptionsPOs" [dataSource]="purchaseOrders" [(value)]="cellInfo.value"
        displayExpr="poNumber" valueExpr="id" contentTemplate="purchaseOrderContentTemplate" [showClearButton]="true"
        (onOpened)="onPODropdownOpened(cellInfo)" (onValueChanged)="onPODropDownChanged(cellInfo, $event)">
        <div *dxTemplate="let e of 'purchaseOrderContentTemplate'">
          <dx-data-grid [dataSource]="purchaseOrdersFiltered" keyExpr="id" [remoteOperations]="false" height="358px"
            [showBorders]="true" [wordWrapEnabled]="true" [selectedRowKeys]="[cellInfo.value]"
            [hoverStateEnabled]="true" (onToolbarPreparing)="onToolbarPreparingOrders($event)"
            [scrolling]="{ mode: 'standard', preloadEnabled: true }" [autoNavigateToFocusedRow]="true"
            (onSelectionChanged)="onPurchaseOrderSelectionChanged(cellInfo, e.component, $event)"
            (onCellPrepared)="onPOListCellPrepared($event)">

            <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>

            <dxi-column dataField="id" [visible]='false'></dxi-column>
            <dxi-column dataField="poNumber" caption="PO#" [width]="90" sortOrder="asc"></dxi-column>

            <dxi-column dataField="costCentreId" dataType="number" caption="Cost Code"
              [calculateSortValue]="calculateCostCentreSortValue">
              <dxo-lookup [dataSource]="costCentres" displayExpr="description" valueExpr="id">
              </dxo-lookup>
            </dxi-column>

            <dxi-column dataField="vendorId" dataType="number" caption="Vendor"
              [calculateSortValue]="calculateVendorSortValue">
              <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
              </dxo-lookup>
            </dxi-column>

            <dxi-column dataField="remainingBudgetExGst" caption="Remaining Budget" dataType="number" [width]="90"
              format="#,##0"></dxi-column>

            <dxi-column dataField="hasInvoicesNotApproved" dataType="boolean" [visible]="false"></dxi-column>

            <dxi-column type="buttons" [width]="50">
              <dxi-button icon="download" [onClick]="openPO" hint='Download PO'>
              </dxi-button>
            </dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>


    <!-- select a vendor - but may need to filter by trade -->
    <div *dxTemplate="let cellInfo of 'editVendorTemplate'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptionsVendors" [dataSource]="vendors" [(value)]="cellInfo.value"
        displayExpr="vendorName" valueExpr="id" contentTemplate="vendorContentTemplate" [showClearButton]="true"
        (onOpened)="onVendorDropdownOpened(cellInfo)" (onValueChanged)="onVendorDropDownChanged(cellInfo, $event)">
        <div *dxTemplate="let e of 'vendorContentTemplate'">
          <dx-data-grid [dataSource]="filteredVendors" keyExpr="id" [remoteOperations]="false"
            [height]="vendorGridHeight" [showBorders]="true" [wordWrapEnabled]="true"
            [selectedRowKeys]="[cellInfo.value]" [hoverStateEnabled]="true"
            [scrolling]="{ mode: 'standard', preloadEnabled: true }"
            (onSelectionChanged)="onVendorSelectionChanged(cellInfo, e.component, $event)"
            (onToolbarPreparing)="onVendorListToolbarPreparing($event, cellInfo)">

            <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>
            <dxi-column dataField="vendorName" sortOrder="asc" [width]="300"></dxi-column>
            <dxi-column dataField="phoneNumber" [width]="110"></dxi-column>
            <dxi-column dataField="emailAddress" [calculateCellValue]="calculateEmailAddress"></dxi-column>

            <dxi-column *ngIf="windowWidth > 500" [width]="30" cellTemplate="emailCellTemplate"
              [calculateCellValue]="calculateEmailAddress">
            </dxi-column>

            <div *dxTemplate="let cellInfo of 'emailCellTemplate'">
              <a *ngIf="cellInfo.value && cellInfo.value !== ''" [href]="getVendorEmailString(cellInfo.value)"
                class="material-icons">email</a>
            </div>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>


    <div *dxTemplate="let cellInfo of 'commentCellTemplate'">
      <div id="vendorComment" class='mx-0 px-0 ellipsis' [style.maxHeight.px]="maxCommentHeight">{{cellInfo.value}}
      </div>
    </div>

    <div *dxTemplate="let cellInfo of 'attachmentCellTemplate'">
      <div *ngIf="cellInfo.value && cellInfo.value != ''">
        <span *ngIf="cellInfo.value[0] === 'A'" id="addendaAttached">{{addendumLetter}}</span>
        <i *ngIf="cellInfo.value[0] !== 'A' && cellInfo.value[0] !== 'D'" class="material-icons red">error_outline</i>
        <i *ngIf="(cellInfo.value[0] === 'A' && cellInfo.value[1] === 'D') || cellInfo.value[0] === 'D'"
          class="material-icons">attach_file</i>
      </div>
    </div>

    <div *dxTemplate="let data of 'groupCellTemplate'">
      {{ getGroupTitle(data) }}
    </div>
  </dx-data-grid>


  <!-- popups -->

  <dx-popup class="popup" [width]="220" [height]="230" [showTitle]="true" title="Delete" [dragEnabled]="false"
    [hideOnOutsideClick]="true" [(visible)]="deletePopupVisible">
    <div *dxTemplate="let data of 'content'">
      <dx-button *ngIf="!deleteInProgress" id="deleteButton" text="Delete" type="danger"
        (onClick)="deleteTasksGo(true)">
      </dx-button>
      <br><br>
      <dx-button *ngIf="!deleteInProgress" id="notApplicableButton" text="Mark Not Applicable" type="default"
        (onClick)="deleteTasksGo(false)">
      </dx-button>
      <br><br>
      <dx-button *ngIf="!deleteInProgress" id="cancelButton" text="Cancel" type="default" stylingMode='outlined'
        (onClick)="deleteTaskPopupClose()">
      </dx-button>
    </div>
  </dx-popup>

  <dx-popup class="popup" [width]="220" [height]="240" [showTitle]="true" title="Create" [dragEnabled]="false"
    [hideOnOutsideClick]="true" [(visible)]="generatePopupVisible" showCloseButton="true">
    <div *dxTemplate="let data of 'content'">
      <div>Create new or missing tasks</div>
      <br>
      <dx-button id="generateButton" text="Create Call-Ups" type="success" (onClick)="generateTasksGo()">
      </dx-button>
      <br>
      <br>
      <dx-button id="generateButton" text="Allocate PO Numbers" type="default" (onClick)="generatePONumbers()">
      </dx-button>
    </div>
  </dx-popup>

  <!-- Vendor Popup -->
  <dx-popup class="popup" [width]="vendorPopupWidth" [height]="vendorPopupHeight" [showTitle]="true"
    title="Add/Remove Vendors" [dragEnabled]="false" [hideOnOutsideClick]="true"
    [(visible)]="addRemoveVendorsPopupVisible" showCloseButton="true" (onHidden)="closeAddRemoveVendors">
    <div *dxTemplate="let data of 'content'">
      <div><b style="width: 60px; display: inline-block">Trade:</b>{{tradeDescription}}</div>
      <div><b style="width: 60px; display: inline-block">Region:</b>{{tradeRegionDescription}}</div>
      <dx-data-grid id="vendordataGrid" class="m-auto" [dataSource]="tradeVendorDataSource" [showBorders]="true"
        [height]="vendorPopupGridHeight" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
        (onExporting)="gridService.onExporting($event)" [allowColumnResizing]="true"
        (onInitNewRow)="onInitNewTradeVendor($event)">

        <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
          refreshMode="full">
        </dxo-editing>
        <dxo-scrolling mode="standard"></dxo-scrolling>
        <dxo-export [enabled]="true"></dxo-export>
        <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>

        <dxo-paging [enabled]="false"></dxo-paging>

        <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [setCellValue]="setTradeVendorCellValue">
          <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
          </dxo-lookup>
        </dxi-column>

        <dxi-column dataField="defaultEmail" [allowEditing]="false"> </dxi-column>

        <dxi-column dataField="contactEmail" caption="Overriding Email (Leave Blank for default)"> </dxi-column>

        <dxi-column dataField="tradeId" dataType="number" [visible]="false"> </dxi-column>
        <dxi-column dataField="tradeRegionId" dataType="number" [visible]="false"> </dxi-column>
      </dx-data-grid>
    </div>
  </dx-popup>

  <!-- loading spinner - DevExtreme -->
  <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)"
    [visible]="loadingVisible || loading || deleteInProgress || loadingDocs || loadingCallUps" [showIndicator]="true"
    [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
  </dx-load-panel>
</div>


<dx-popup class="popup" [width]="360" [height]="180" [showTitle]="true" title="Mark selected tasks N/A"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="markTasksNAPopupVisible" showCloseButton="true">
  <div *dxTemplate="let data of 'content'">
    <span class="me-1">Mark matching tasks from related jobs also</span>

    <dx-check-box [(value)]="updateOtherChildJobs" [width]="30">
    </dx-check-box>
    <br>
    <br>
    <dx-button id="generateButton" text="Go" type="default" (onClick)="markSelectedAsNAGo()">
    </dx-button>
  </div>
</dx-popup>

<dx-popup class="popup" [width]="360" [height]="180" [showTitle]="true" title="Push out start dates"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="extendTasksPopupVisible" showCloseButton="true">
  <div *dxTemplate="let data of 'content'">
    <span class="me-1">Change matching tasks from related jobs also</span>

    <dx-check-box [(value)]="updateOtherChildJobs" [width]="30">
    </dx-check-box>
    <br>
    <br>
    <dx-button id="generateButton" text="Go" type="default" (onClick)="extendStartDatesGo()">
    </dx-button>
  </div>
</dx-popup>
