import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  /* if index is past the end of array, will pad with undefined */
  moveArrayItemToNewIndex(arr: any[], old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  /* n(log n) binary search to place item into already sorted array based on given object property */
  placeItemIntoSortedPosition(array: any[], el: any, property: string) {
    if (el[property] < array[0][property]) {
      array.unshift(el);
    }
    if (el[property] > array[array.length - 1][property]) {
      array.push(el);
    }
    let m = 0;
    let n = array.length - 1;
    while (m <= n) {
      /* tslint:disable-next-line */
      const k = (n + m) >> 1;
      const cmp = (el[property] < array[k][property]) ? -1 : (el[property] > array[k][property]) ? 1 : 0;
      if (cmp > 0) {
        m = k + 1;
      } else if (cmp < 0) {
        n = k - 1;
      } else {
        return k;
      }
    }
    array.splice(m, 0, el);
  }

  /* USAGE: objArray.sort(dynamicSort("field_name"))
    REVERSE: objArray.sort(dynamicSort("-field_name")
    works with strings and numbers                   */
  dynamicSort(property: string) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    };
  }

  getErrorString(err): string {
    if (err && err.error && err.error.message) {
      return JSON.stringify(err.error.message);
    } else {
      return JSON.stringify(err);
    }
  }

  editCache(object, cache: any[]) {
    const match = cache.filter(t => t.id === object.id)[0];
    if (match) {
      const index = cache.indexOf(match);
      cache[index] = object;
    } else {
      console.log('couldnt edit cache!');
    }
  }

  // assumes INPUT id is string and object ids are numbers
  deleteFromCache(id: string, cache: any[]) {
    const match = cache.filter(t => t.id.toString() === id)[0];
    if (match) {
      const index = cache.indexOf(match);
      cache.splice(index, 1);
    } else {
      console.log('couldnt delete from cache!');
    }
  }

  convertDateToString(date: any): string {
    if (!date) {
      return '';
    }

    if (date instanceof Date) {
      // let dateString = this.convertDateToString(date, 'yyyy-MM-dd');
      // if (dateString.substr(0, 4) === '0000') {
      //   const dateNowString = this.convertDateToString(new Date(), 'yyyy-MM-dd');
      //   dateString = dateNowString.substr(0, 4) + dateString.substr(4, 10);
      // } else if (dateString.substr(0, 2) === '00') {
      //   const dateNowString = this.convertDateToString(new Date(), 'yyyy-MM-dd');
      //   dateString = dateNowString.substr(0, 2) + dateString.substr(2, 10);
      // }


      return date.getFullYear() + '-'
      + ('0' + (date.getMonth() + 1)).toString().slice(-2) + '-'
      + ('0' + date.getDate()).slice(-2);

      // return dateString;
    } else {
      if (date.length >= 10) {
        return date.substr(0, 10);
      } else {
        return date;
      }
    }
  }

  getCalendarDaysDifference(date1: any, date2: any) {
    if (!date1 || !date2 || date1 === '' || date2 === '' || date2 === null || date1 === null) {
      return null;
    }

    let newDate1 = new Date();
    if (!(date1 instanceof Date)) {
      newDate1 = new Date(+date1.substr(0, 4), +date1.substr(5, 2) - 1, +date1.substr(8, 2), 0, 0, 0, 0);
    } else {
      const date1String = this.convertDateToString(date1);
      newDate1 = new Date(+date1String.substr(0, 4), +date1String.substr(5, 2) - 1, +date1String.substr(8, 2), 0, 0, 0, 0);
    }

    let newDate2 = new Date();
    if (!(date2 instanceof Date)) {
      newDate2 = new Date(+date2.substr(0, 4), +date2.substr(5, 2) - 1, +date2.substr(8, 2), 0, 0, 0, 0);
    } else {
      const date2String = this.convertDateToString(date2);
      newDate2 = new Date(+date2String.substr(0, 4), +date2String.substr(5, 2) - 1, +date2String.substr(8, 2), 0, 0, 0, 0);
    }
    return Math.floor((newDate1.getTime() - newDate2.getTime()) / (1000 * 3600 * 24));
  }

  getDateFromDateOrString(date: any): Date {
    if (!date || date === '') {
      return null;
    }

    if (date instanceof Date) {
      return date;
    } else {
      return new Date(+date.substr(0, 4), +date.substr(5, 2) - 1, +date.substr(8, 2), 0, 0, 0, 0);
    }
  }
}
