import { JobDocumentRecordTypeEnum } from './../../dtos/job-document-record-type.enum';
import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Job } from '../../dtos/job';
import { JobWorkFlowService } from '../../services/felixApi/job-work-flow.service';
import { JobService } from '../../services/felixApi/job.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { TaskService } from '../../services/felixApi/task.service';
import { UserService } from '../../services/felixApi/user.service';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { NotificationService } from '../../services/notification.service';
import { TaskType } from '../../dtos/task-type';
import { TaskMaster } from '../../dtos/task-master';
import { Task } from '../../dtos/task';
import { Vendor } from '../../dtos/vendor';
import { CallUpDocsType } from '../../dtos/call-up-docs-type';
import { VendorSummariesComponent } from '../vendor-summaries/vendor-summaries.component';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'js-send-call-ups',
  templateUrl: './send-call-ups.component.html',
  styleUrls: ['./send-call-ups.component.scss']
})
export class SendCallUpsComponent implements OnInit, OnDestroy {
  @Input() jobId: number;
  @Input() selectedTaskHeaderId: number;

  @ViewChild('sendGrid') grid: DxDataGridComponent;

  loading = true;
  subscriptions: Subscription[] = [];
  sendGridHeight: number;
  addendumCaption: string;
  addendumLetter: string;
  selectedRows: number[] = [];
  jobs: Job[];
  taskTypes: TaskType[];
  taskMasters: TaskMaster[];
  vendors: Vendor[];
  callUpTasks: Task[];
  ccToSelf = true;
  anyMissingAttachments = false;
  missingAttachmentsPopupVisible = false;
  missingAttachments: CallUpDocsType[];
  markAsSentPopupVisible = false;
  hasError: boolean;
  unSentOnly = true;
  callUpData: CustomStore;
  myEmail: string;

  constructor(
    private notiService: NotificationService,
    private activeModal: NgbActiveModal,
    private globalService: GlobalService,
    protected jobWorkFlowService: JobWorkFlowService,
    private taskService: TaskService,
    protected gridService: GridService,
    private jobService: JobService,
    private maintenanceService: MaintenanceService,
    private userService: UserService,
    private authService: AuthService,
    private modalService: NgbModal) {
    this.calculateHasAttachments = this.calculateHasAttachments.bind(this);
    this.calculateSendPO = this.calculateSendPO.bind(this);
    this.missingAttachmentsPopup = this.missingAttachmentsPopup.bind(this);
    this.calculateSentFromQueueStatus = this.calculateSentFromQueueStatus.bind(this);
    this.setEmailValueToMe = this.setEmailValueToMe.bind(this);
  }


  ngOnInit(): void {
    this.setJobStringMaxWidth();
    this.subscribeToInnerHeightWidth();

    this.addendumCaption = 'Send ' + this.globalService.getAddendumName();
    this.addendumLetter = this.globalService.getAddendumLetter();
    this.jobs = this.jobService.jobs;
    this.taskTypes = this.maintenanceService.taskTypes;
    this.taskMasters = this.maintenanceService.taskMasters;
    this.vendors = this.userService.vendors;
    this.myEmail = this.authService.getCurrentUser().email

    this.getTasks();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  close() {
    this.activeModal.dismiss();
  }

  subscribeToInnerHeightWidth() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          setTimeout(() => {
            this.setJobStringMaxWidth();
          }, 10); // wait for iPhone and grid
        }
      )
    );
  }

  setJobStringMaxWidth() {
    this.sendGridHeight = window.innerHeight - 205;
  }

  getTasks() {
    this.subscriptions.push(
      this.taskService.getCallUpDataForMaster(this.jobId, this.selectedTaskHeaderId)
        .subscribe({
          next:
            (tasks) => {
              this.callUpTasks = tasks.filter(i => !i.doNotSendCallup);

              if (this.unSentOnly) {
                this.callUpTasks = this.callUpTasks.filter(i => !i.calledDate);
              }

              this.ccToSelf = this.maintenanceService.taskControl.ccToSiteManager; // default setting

              // add email address
              this.callUpTasks.forEach(task => {
                // do we have overriding email
                const vendor = this.vendors.find(i => i.id === task.vendorId);
                if (vendor.quoteRequestEmail && vendor.quoteRequestEmail.trim() !== '' && task.isQuoteRequest) {
                  task.emailAddress = vendor.quoteRequestEmail;
                } else {
                  const currentTradeId = this.maintenanceService.templateTasks.find(i => i.taskMasterId === task.taskMasterId)?.tradeId;

                  const tradeVendor = this.maintenanceService.tradeVendors
                    .find(i => i.tradeId === currentTradeId
                      && i.vendorId === task.vendorId
                      && i.tradeRegionId === this.jobService.currentJobExtra.tradeRegionId);

                  if (tradeVendor && tradeVendor.contactEmail && tradeVendor.contactEmail.trim() !== '') {
                    task.emailAddress = tradeVendor.contactEmail;
                  } else {
                    if (vendor) {
                      task.emailAddress = vendor.callUpEmail && vendor.callUpEmail.length ? vendor.callUpEmail : vendor.email;
                    } else {
                      task.emailAddress = 'Not Specified';
                    }
                  }
                }

                // check we have the auto-attachments we expect
                task.missingAttachments = [];
                const templateTask = this.maintenanceService.templateTasks.find(i => i.taskMasterId === task.taskMasterId);
                if (templateTask?.callUpDocsTypes?.length) {
                  // do we have them all
                  templateTask?.callUpDocsTypes.forEach(callUpDocsType => {
                    const foundJobDoc = this.jobService.jobDocumentsForMasterJob
                      .find(i => i.jobId === task.jobId && i.callUpDocsTypeId === callUpDocsType
                        && i.recordTypeId !== JobDocumentRecordTypeEnum.Heading);
                    if (!foundJobDoc) {
                      task.missingAttachments.push(callUpDocsType);
                    }
                  });
                }
              });

              this.callUpData = new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.callUpTasks,
                update: async (key, values) => {
                  const changedRec = this.callUpTasks.find(i => i.id === key);
                  changedRec.emailAddress = values.emailAddress;
                  return changedRec;
                }
              });

              this.loading = false;
            },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  onToolbarPreparing(e, templateName: string) {
    const toolbarItems = e.toolbarOptions.items;

    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          stylingMode: 'outlined',
          type: 'default',
          icon: 'refresh',
          onClick: this.getTasks.bind(this)
        }
      });

    toolbarItems.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: templateName
    });
  }

  calculateHasAttachments(data) {
    let result = [];

    if (data.missingAttachments?.length) {
      result = data.missingAttachments;
    } else {
      if (data.sendAddendum) {
        result = ['A'];
      }

      const foundJobTaskDocs = this.jobService.jobTaskDocumentsForMasterChildJobs.find(i => i.jobTaskId === data.id);
      if (foundJobTaskDocs) {
        result.push('D');
      } else {
        const templateTask = this.maintenanceService.templateTasks.find(i => i.taskMasterId === data.taskMasterId);
        if (templateTask?.callUpDocsTypes?.length) {
          // do we have any attachments
          templateTask?.callUpDocsTypes.forEach(callUpDocsType => {
            const foundJobDoc = this.jobService.jobDocumentsForMasterJob
              .find(i => i.jobId === data.jobId && i.callUpDocsTypeId === callUpDocsType
                && i.recordTypeId !== JobDocumentRecordTypeEnum.Heading);
            if (foundJobDoc) {
              result.push('D');
            }
          });
        }
      }
    }
    return result;
  }

  calculateSendPO(data) {
    let result = false;

    if (data.purchaseOrderId) {
      const templateTask = this.maintenanceService.templateTasks.find(i => i.taskMasterId === data.taskMasterId);
      if (!templateTask || templateTask.sendPOWithCallUp) {
        result = true;
      }
    }
    return result;
  }

  missingAttachmentsPopup(missingAttachments: number[]) {
    this.missingAttachmentsPopupVisible = true;
    this.missingAttachments = [];

    missingAttachments.forEach(missingAttachment => {
      this.missingAttachments.push(
        {
          id: missingAttachment,
          description: this.maintenanceService.callUpDocsTypes.find(i => i.id === missingAttachment).description,
          isActive: true,
          isGlobal: false
        }
      );
    });
  }

  sendCallUpsGo() {
    this.anyMissingAttachments = false;

    this.callUpTasks.forEach(callUpTask => {
      const isSelected = this.selectedRows.find(i => i === callUpTask.id);

      if (isSelected) {
        if (callUpTask.missingAttachments?.length) {
          this.anyMissingAttachments = true;
        }
      }
    });

    if (!this.anyMissingAttachments) {
      this.sendCallUpsGoWithMissing(false);
    } else {
      this.notiService.showWarning('Selected call-ups are missing attachments. Click the error symbol for more information.');
    }
  }

  sendCallUpsGoWithMissing(markAsSent: boolean) {
    this.loading = true;
    this.hasError = false;
    this.markAsSentPopupVisible = false;

    // find master job
    let masterJobId = this.jobs.find(i => i.id === this.jobId).masterJobId ?? this.jobId;

    let jobCount = this.jobs.filter(i => i.id === masterJobId || i.masterJobId === masterJobId).length;

    // console.log('job count = ' + jobCount);

    this.jobs.filter(i => i.id === masterJobId || i.masterJobId === masterJobId).forEach(job => {

      const rowsToSend: number[] = [];
      const emailAddresses: string[] = [];

      this.selectedRows.forEach(selectedRecord => {
        const task = this.callUpTasks.find(i => i.jobId === job.id && i.id === selectedRecord);

        if (task) {
          rowsToSend.push(selectedRecord);
          emailAddresses.push(task.emailAddress);
        }
      });

      if (rowsToSend.length) {
        // console.log('Emails: ' + JSON.stringify(emailAddresses));

        // console.log('Sending emails for job ' + job.jobNumber);
        // console.log('Call-ups selected ' + JSON.stringify(rowsToSend));

        this.subscriptions.push(
          this.taskService.sendCallUps(job.id, rowsToSend, this.ccToSelf, markAsSent, emailAddresses)
            .subscribe({
              next: (res) => {

                // console.log('send complete - ' + (res && res.errorMessages ? res.errorMessages : ''));

                if (markAsSent && res?.failureCount > 0) {
                  this.notiService.showError(res?.errorMessages);
                  this.hasError = true;
                }
                jobCount--;
                // console.log('job count = ' + jobCount);
                if (jobCount === 0) {
                  this.finishSend();
                }
              },
              error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
                this.hasError = true;
              }
            })
        );
      } else {
        jobCount--;
        // console.log('job count = ' + jobCount);
        if (jobCount === 0) {
          this.finishSend();
        }
      }
    });
  }

  finishSend() {
    if (this.hasError) {
      this.selectedRows = [];
      this.getTasks();
    } else {
      this.activeModal.close();
    }
  }

  vendorSummaries() {
    const modalRef = this.modalService.open(VendorSummariesComponent, { windowClass: 'modal-1400', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.jobId = this.jobId;
    modalRef.componentInstance.selectedTaskHeaderId = this.selectedTaskHeaderId;

    modalRef.result.then(() => {
    });
  }

  calculateSentFromQueueStatus(data: Task): string {
    if (data.sentFromQueueDate) {
      return 'Sent';
    } else if (data.calledDate) {
      return 'Queued';
    }
    return '';
  }

  showUnSentOnlyChanged(e) {
    this.unSentOnly = e.value;
    this.loading = true;
    this.getTasks();
  }

  setEmailCellValue(rowData, value) {
    if (value) {
      rowData.emailAddress = value;
    }
  }

  setEmailValue(valueChangedEventArg, cellInfo) {
    cellInfo.setValue(valueChangedEventArg.value);
  }

  setEmailValueToMe(cellInfo) {
    cellInfo.setValue(this.myEmail);
  }
}
