import { AuthService } from './../services/auth.service';
import { Component, OnInit, Input, ViewChild, OnDestroy, OnChanges } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { NotificationService } from '../services/notification.service';
import { GlobalService } from '../services/global.service';
import { GridService } from '../services/grid.service';
import { JobService } from '../services/felixApi/job.service';
import { Job } from '../dtos/job';
import { JobEmailService } from '../services/felixApi/job-email.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobEmailExpandedComponent } from './job-email-expanded/job-email-expanded.component';
import { User } from '../dtos/user';
import { JobEmailTypeEnum } from '../dtos/job-email';
import { JobNoteExpandedComponent } from './job-note-expanded/job-note-expanded.component';
import DateBox, { Properties } from "devextreme/ui/date_box";

@Component({
  selector: 'js-job-emails',
  templateUrl: './job-emails.component.html',
  styleUrls: ['./job-emails.component.scss']
})
export class JobEmailsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() jobNumber: string;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  loading = false;
  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  users: User[];
  jobs: Job[];
  jobId: number;
  gridHeight: number;
  loadEmailPopupVisible: boolean;
  fileValues: any[] = [];
  jobEmailTypes: { id: number; description: string; }[];
  loadAllJobs = false;
  bodyTextWidth: number;
  bodyTextMaxHeight = 70;
  showDeleteButton = false;
  selectedRowKeys: any[] = [];
  popupHeight: number;
  popupWidth: number;
  fileCount: number;
  dateFrom: Date;
  dateTo: Date;

  constructor(
    private jobEmailService: JobEmailService,
    private notiService: NotificationService,
    private globalService: GlobalService,
    private authService: AuthService,
    protected gridService: GridService,
    private jobService: JobService,
    private modalService: NgbModal
  ) {
    this.cancelClickHandler = this.cancelClickHandler.bind(this);
    this.saveClickHandler = this.saveClickHandler.bind(this);

    DateBox.defaultOptions<Properties>({
      device: [
        { deviceType: 'desktop' },
        { deviceType: 'tablet' },
        { deviceType: 'phone' }
      ],
      options: {
        pickerType: 'calendar'
      }
    });
  }

  ngOnInit(): void {
    this.jobEmailTypes = [
      { id: 1, description: 'Email' },
      { id: 2, description: 'File Note' }
    ];
    this.dateFrom = new Date();
    this.dateTo = new Date();

    // take 2 months off the date
    this.dateFrom.setMonth(this.dateFrom.getMonth() - 2);

    this.calculateWidthsHeights();
    this.subscribeToInnerWidth();
    this.getData(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnChanges(): void {
    if (this.jobNumber && this.jobNumber !== '') {
      this.getJob();
    } else {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.setUpDataSource();
      }, 100);
    }
  }

  subscribeToInnerWidth() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.calculateWidthsHeights();
        }
      )
    );
  }

  onEditorPreparing(e) {
    if (e.dataField === 'createUserId') {
      e.editorOptions.dropDownOptions = { minWidth: 220 };
    }
    if (e.dataField === 'jobId' || e.dataField === 'jobEmailTypeId') {
      e.editorOptions.dropDownOptions = { minWidth: 120 };
    }
    if (e.dataType === 'boolean') {
      e.editorOptions.dropDownOptions = { minWidth: 70 };
    }
  }

  calculateWidthsHeights() {
    this.gridHeight = window.innerHeight - 155;

    let jobWidth = 0;
    if (this.loadAllJobs) {
      jobWidth = 90;
    }

    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Tracking') === 'Admin') {
      this.showDeleteButton = true;
    }


    if (this.globalService.innerWidth < 550) {
      this.bodyTextWidth = this.globalService.innerWidth - 246;
      this.showDeleteButton = false;
    }
    else {
      this.bodyTextWidth = this.globalService.innerWidth - 1042 - jobWidth;

      if (this.globalService.innerWidth < 1525) {
        this.bodyTextWidth += 114;
      }

      if (this.globalService.innerWidth < 1410) {
        this.bodyTextWidth += 170;
      }

      if (this.globalService.innerWidth < 1240) {
        this.bodyTextWidth += 170;
      }

      if (this.globalService.innerWidth < 1170) {
        this.bodyTextWidth += 110;
      }

      if (this.globalService.innerWidth < 960) {
        this.bodyTextWidth += 200;
      }

      if (this.globalService.innerWidth < 600) {
        this.bodyTextWidth += 25;
      }

      if (!this.showDeleteButton) {
        this.bodyTextWidth += 80;
      }
    }

    this.bodyTextWidth = this.bodyTextWidth < 300 ? 300 : this.bodyTextWidth;
    this.popupHeight = this.globalService.innerHeight < 330 ? this.globalService.innerHeight : 330;
    this.popupWidth = this.globalService.innerWidth < 650 ? this.globalService.innerWidth : 650;
  }

  getData(useCache: boolean) {
    this.loading = true;
    this.dataSource = null;
    this.subscriptions.push(
      this.jobEmailService.getJobEmailsData(useCache)
        .subscribe({
          next: (users) => {
            this.users = users;
            this.jobs = this.jobService.jobs;

            if (this.jobNumber && this.jobNumber !== '') {
              this.getJob();
            }

            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  onDateFromChanged(e) {
    this.dateFrom = e.value;
    this.setUpDataSource();
  }

  onDateToChanged(e) {
    this.dateTo = e.value;
    this.setUpDataSource();
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.jobEmailService.getJobEmails(this.loadAllJobs ? null : this.jobId, this.loadAllJobs ? this.dateFrom : null, this.loadAllJobs ? this.dateTo : null).subscribe({
            next: (res) => {
              res.forEach(element => {
                element.date = element.dateSent ?? element.dateReceived;
              });
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        values['jobId'] = this.jobId;
        values['dateSent'] = values['date'];
        return new Promise((resolve, reject) =>
          this.jobEmailService.addJobEmail(values).subscribe({
            next: (res) => {
              res.date = values['date'];
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.jobEmailService.updateJobEmail(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.jobEmailService.deleteJobEmail(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  getJob() {
    this.jobId = this.jobService.currentJob.id;
    this.setUpDataSource();
  }


  onToolbarPreparing(e, toolbarTemplate) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          stylingMode: 'outlined',
          type: 'default',
          text: this.loadAllJobs ? 'This Job' : 'All Jobs',
          onClick: this.allJobs.bind(this)
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          stylingMode: 'outlined',
          type: 'default',
          text: 'Load Email',
          onClick: this.loadEmails.bind(this)
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        template: toolbarTemplate
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          stylingMode: 'outlined',
          type: 'default',
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      });
  }

  refresh() {
    this.getData(false);
  }

  loadEmails() {
    // scan emails from folder and process
    this.loadEmailPopupVisible = true;
  }

  allJobs() {
    this.loadAllJobs = !this.loadAllJobs;

    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.calculateWidthsHeights();
      this.setUpDataSource();
    }, 100);
  }

  uploadClick() {
    // load the file
    if (this.fileValues && this.fileValues.length) {
      this.fileCount = this.fileValues.length;
      let itemNumber = -1;

      this.fileValues.forEach(fileValue => {
        itemNumber++;
        setTimeout(() => {
          const formData: FormData = new FormData();
          formData.append('image', fileValue, fileValue.name);

          this.subscriptions.push(
            this.jobEmailService.uploadEmail(this.jobId, formData).subscribe({
              next: () => {
                this.fileCount--;
                if (this.fileCount === 0) {
                  this.fileValues = [];
                  this.loadEmailPopupVisible = false;
                  this.setUpDataSource();
                }
              }, error: (err) => {
                this.notiService.notify(err);
                this.fileCount--;
                if (this.fileCount === 0) {
                  this.fileValues = [];
                  this.loadEmailPopupVisible = false;
                  this.setUpDataSource();
                }
              }
            })
          );
        }, itemNumber * 3000);
      });
    }
  }

  onInitNewRow(e) {
    e.data.date = new Date;
    e.data.jobEmailTypeId = 2;
    e.data.jobId = this.jobId;
  }

  bodyTextMaxHeightChanged(e) {
    this.bodyTextMaxHeight = e.value;
  }

  onSelectionChanged(event) {
    if (event.selectedRowKeys.length > 0) {
      // show the full email details in a popup
      const modalRef = this.modalService.open(
        event.selectedRowsData[0]?.jobEmailTypeId === JobEmailTypeEnum.Email ? JobEmailExpandedComponent : JobNoteExpandedComponent,
        { windowClass: 'modal-1500', backdrop: 'static', keyboard: false });
      modalRef.componentInstance.jobEmail = event.selectedRowsData[0];

      modalRef.result.then(() => {
        this.selectedRowKeys = [];
      }, () => {
        this.selectedRowKeys = [];
      });
    }
  }

  cancelClickHandler() {
    this.grid.instance.cancelEditData();
  }

  saveClickHandler() {
    this.grid.instance.saveEditData();
  }

  calculateHasAttachments(data) {
    if (data && data.attachments && data.attachments.length) {
      return true;
    } else {
      return false;
    }
  }
}
