import { VariationStatusEnum } from './../dtos/variation-status.enum';
import { NotificationService } from './../services/notification.service';
import { TaskService } from './../services/felixApi/task.service';
import { UserService } from './../services/felixApi/user.service';
import { JobService } from './../services/felixApi/job.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { GlobalService } from '../services/global.service';
import { JobCashFlowService } from '../services/felixApi/jobCashFlow.service';
import { GridService } from '../services/grid.service';
import { Router } from '@angular/router';
import { RoleTypeEnum } from '../dtos/role-type.enum';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { JobSearchTypeEnum } from '../dtos/job-search-type.enum';
import { JobSearchModalComponent } from '../shared/job-search-modal/job-search-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MaintenanceService } from '../services/felixApi/maintenance.service';

@Component({
  selector: 'js-cash-flow-forecast',
  templateUrl: './cash-flow-forecast.component.html',
  styleUrls: ['./cash-flow-forecast.component.scss']
})
export class CashFlowForecastComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  loading = true;
  dataSource: CustomStore;
  expectedPercentCancellation: number;
  showCancelledActivePopup: boolean;
  cancelledJobNumber: string;
  jobNumber = '';
  jobId: number;

  constructor(
    private notiService: NotificationService,
    private jobCashFlowService: JobCashFlowService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private router: Router,
    private jobService: JobService,
    private taskService: TaskService,
    private maintenanceService: MaintenanceService,
    private modalService: NgbModal,
    private userService: UserService
  ) {
    this.calculateSiteAddress = this.calculateSiteAddress.bind(this);
    this.calculateSiteManager = this.calculateSiteManager.bind(this);
    this.calculateContractTotal = this.calculateContractTotal.bind(this);
    this.calculateContractTotalIncPostVOs = this.calculateContractTotalIncPostVOs.bind(this);
  }

  ngOnInit(): void {
    this.expectedPercentCancellation = this.maintenanceService.taskControl?.expectedPercentCancellation;
    this.getRoles();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getRoles() {
    this.subscriptions.push(
      this.taskService.getAllRolesWithJobs(true)
        .subscribe({
          next: () => {
            // show a check here if any jobs cancelled but have been marked active
            this.cancelledJobNumber = '';
            const cancelledJobs = this.jobService.jobExtras.filter(i => i.cancellationDate);

            cancelledJobs.forEach(cancelledJob => {
              const job = this.jobService.jobs.find(i => i.id === cancelledJob.jobId && i.isActive);
              if (job) {
                this.cancelledJobNumber = job.jobNumber;
              }
            });

            this.loading = false;
            if (this.cancelledJobNumber !== '') {
              this.showCancelledActivePopup = true;
            } else {
              this.setUpDataSource();
            }
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  continue() {
    this.showCancelledActivePopup = false;
    this.setUpDataSource();
  }

  refresh() {
    this.grid.instance.refresh();
  }

  setUpDataSource() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 300); // wait for grid

    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.jobCashFlowService.getCashFlowForecast(
            !this.jobId ? null : this.jobService.jobs.find(i => i.id === this.jobId)?.salesDate,
            this.expectedPercentCancellation,
            this.jobId).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }));
      }
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          width: 120,
          text: 'Job Setup',
          type: 'default',
          onClick: this.goJobSetup.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxTextBox',
        options: {
          width: 130,
          value: this.jobNumber,
          placeholder: 'Job Number',
          // disabled: true
          showClearButton: true,
          onValueChanged: this.onJobNumberChanged.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'search',
          onClick: this.jobSearch.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refresh.bind(this),
          matTooltip: 'Refresh'
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      });
  }

  jobSearch() {
    const modalRef = this.modalService.open(JobSearchModalComponent, { windowClass: 'modal-search' });
    modalRef.componentInstance.searchType = JobSearchTypeEnum.AllJobs;

    modalRef.result.then((jobNumber) => {
      if (jobNumber) {
        this.jobNumber = jobNumber;
        this.setJobNumber();
      }
    });
  }

  onJobNumberChanged(e) {
    this.jobNumber = e.value?.trim();
    this.setJobNumber();
  }

  setJobNumber() {
    this.jobId = null;
    if (this.jobNumber) {
      const job = this.jobService.jobs?.find(i => i.jobNumber === this.jobNumber);
      if (job) {
        this.jobId = job.id;
        this.setUpDataSource();
      }
    } else {
      this.setUpDataSource();
    }
  }

  goJobSetup() {
    this.router.navigateByUrl('/jobSetUp');
  }

  calculateSiteAddress(data) {
    if (data && data.jobNumber) {
      const job = this.jobService.jobs?.find(i => i.jobNumber === data.jobNumber);
      return this.globalService.getJobString(job);
    }
  }

  calculateSiteManager(data) {
    if (data && data.jobNumber) {
      const job = this.jobService.jobs?.find(i => i.jobNumber === data.jobNumber);
      const jobRole = this.taskService.allJobRoles?.find(i => i.jobId === job?.id && i.roleId === RoleTypeEnum.SiteManager);
      const user = this.userService.users?.find(i => i.id === jobRole?.userId);
      return user?.fullName;
    }
  }

  calculateContractTotal(data) {
    if (data && data.jobNumber) {
      const job = this.jobService.jobs?.find(i => i.jobNumber === data.jobNumber);
      return this.jobCashFlowService.jobCashFlows?.find(i => i.jobId === job?.id)?.contractPriceIncGST;
    } else {
      return null;
    }
  }

  calculateContractTotalIncPostVOs(data) {
    if (data && data.jobNumber) {
      const job = this.jobService.jobs?.find(i => i.jobNumber === data.jobNumber);
      let contractPriceIncGST = this.jobCashFlowService.jobCashFlows?.find(i => i.jobId === job?.id)?.contractPriceIncGST;

      if (!contractPriceIncGST) {
        return null;
      }

      this.jobService.jobVariations
        .filter(i => i.jobId === job.id && i.variationType < 10 && i.statusId >= VariationStatusEnum.Approved).forEach(varn => {
          contractPriceIncGST += varn.variationTotal;
        });

      return contractPriceIncGST;
    } else {
      return null;
    }
  }

  clearStatePersistance() {
    this.loading = true;
    localStorage.removeItem('cash-flow-forecast');
    setTimeout(() => {
      this.loading = false;
    }, 300); // wait for grid
  }
}
