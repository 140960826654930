import { formatDate } from 'devextreme/localization';
import { JobService } from './job.service';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { JobEmail } from '../../dtos/job-email';
import { JobEmailDetails } from '../../dtos/job-email-details';
import { JobEmailAttachmentData } from '../../dtos/job-email-attachment-data';
import { NotificationService } from '../notification.service';
import { HttpService } from '../http.service';
import { User } from '../../dtos/user';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class JobEmailService {
  latestJobEmails: JobEmail[] = [];
  latestJobEmailsCompany: number;

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private userService: UserService,
    private notiService: NotificationService,
    private jobService: JobService,
    private globalService: GlobalService) { }


  getJobEmails(jobId: number, dateFrom: Date, dateTo: Date): Observable<JobEmail[]> {
    let url = this.globalService.getApiUrl() + '/job-emails?jobId=' + jobId + '&activeJobsOnly=' + (jobId === null);
    if (dateFrom) {
      url += '&dateFrom=' + formatDate(dateFrom, 'yyyy-MM-dd');
    }
    if (dateTo) {
      url += '&dateTo=' + formatDate(dateTo, 'yyyy-MM-dd');
    }
    return this._http.get<JobEmail[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  latestJobEmailsMap: Map<number, JobEmail> = new Map<number, JobEmail>();


  getLatestJobEmails(useCache: boolean): Observable<JobEmail[]> {
    if (useCache && this.latestJobEmails && this.latestJobEmails.length > 0
      && this.latestJobEmailsCompany === this.globalService.getCurrentCompany().id) {
      return of(this.latestJobEmails);
    } else {
      return this._http.get<JobEmail[]>(this.globalService.getApiUrl() + '/job-emails/latest',
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.latestJobEmails = res;
            this.latestJobEmailsCompany = this.globalService.getCurrentCompany().id;
            this.latestJobEmailsMap.clear();
            res.forEach(email => {
              this.latestJobEmailsMap.set(email.jobId, email);
            });
          }),
          catchError(this.handleError));
    }
  }

  getJobEmailsData(useCache: boolean): Observable<User[]> {
    return forkJoin(
      [
        this.userService.getCurrCompUsers(useCache),
        this.jobService.getJobsByAddress(useCache)
      ]
    )
      .pipe(map(
        ([users]) => {
          return users;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  addJobEmail(dataRecord: any): Observable<JobEmail> {
    const url = this.globalService.getApiUrl() + '/job-emails';
    return this._http.post<JobEmail>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  uploadEmail(jobId: number, xlFile) {
    const options = this.httpService.getHttpFileOptions();
    return this._http.post(this.globalService.getApiUrl()
      + '/job-emails/load-email?jobId=' + jobId, xlFile, options)
      .pipe(
        catchError(this.handleError.bind(this)));
  }

  updateJobEmail(id: string, itm: any): Observable<JobEmail[]> {
    const url = this.globalService.getApiUrl() + '/job-emails/' + id;
    return this._http.patch<JobEmail[]>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteJobEmail(id: string) {
    const url = this.globalService.getApiUrl() + '/job-emails/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  getJobEmailDetailsData(jobEmailId: number) {
    return this._http.get<JobEmailDetails>(this.globalService.getApiUrl() + '/job-emails/email-details/' + jobEmailId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getJobEmailAttachmentData(jobEmailId: number, fileName: string) {
    return this._http.get<JobEmailAttachmentData>(this.globalService.getApiUrl() + '/job-emails/email-attachment/' + jobEmailId + "?fileName=" + encodeURIComponent(fileName),
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));

    if (JSON.stringify(err).includes(') does not match this email address')) {
      // we have logged in with another email and the user ID does not match - log out
      this.notiService.showWarning('Invalid login most likely due to multiple logins being used - please log out and back in again');
    }

    return observableThrowError(err);
  }
}
