import { TaskService } from './task.service';
import { UserService } from './user.service';
import { Vendor } from './../../dtos/vendor';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, forkJoin, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { MaintenanceService } from './maintenance.service';
import { JobService } from './job.service';
import { Invoice, InvoiceStatusTypeEnum } from '../../dtos/invoice';
import { HttpService } from '../http.service';
import { PurchaseOrder } from '../../dtos/purchase-order';
import { FileAttachment } from '../../dtos/file-attachment';
import { InvoiceBatch } from '../../dtos/invoice-batch';
import { BackChargeDocument } from '../../dtos/back-charge';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  globalGSTRate: number;
  invoicesForJob: Invoice[];
  allPurchaseOrders: PurchaseOrder[];
  purchaseOrdersForJob: PurchaseOrder[];
  invoicesForThisJob: Invoice[];
  invoiceBatches: InvoiceBatch[];

  constructor(
    private _http: HttpClient,
    private maintenanceService: MaintenanceService,
    private taskService: TaskService,
    private jobService: JobService,
    private userService: UserService,
    private globalService: GlobalService,
    private httpService: HttpService) { }

  getInvoicesDataForOnHold(useCache: boolean): Observable<Vendor[]> {
    return forkJoin(
      [
        this.userService.getVendorData(useCache, false),
        this.jobService.getJobsByAddressWithExtras(useCache),
        this.getCurrentGST(),
        this.taskService.getAllJobRoles(useCache, false),
        this.userService.getCurrCompUsers(useCache),
        this.maintenanceService.getPriceFileItemGroups(useCache)
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getCurrentGST(): Observable<number> {
    if (this.globalGSTRate) {
      return of(this.globalGSTRate);
    } else {
      return this._http.get<number>(this.globalService.getApiUrl()
        + '/gst/current', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.globalGSTRate = res;
          }),
          catchError(this.handleError));
    }
  }

  getInvoices(batchId: number, invoiceStatusId: number, vendorId: number,
    jobNumber: string, externalInvoiceId: string, purchaseOrderId: number,
    costCentreId: number): Observable<Invoice[]> {
    if (batchId === 0) {
      return of([]);
    } else {
      let url = this.globalService.getApiUrl() + '/invoices';
      let hasParam = false;

      if (batchId) {
        url += '?invoiceBatchId=' + batchId;
        hasParam = true;
      }

      if (invoiceStatusId) {
        url += hasParam ? '&' : '?';
        url += 'invoiceStatusId=' + invoiceStatusId;
        hasParam = true;
      }

      if (vendorId) {
        url += hasParam ? '&' : '?';
        url += 'vendorId=' + vendorId;
        hasParam = true;
      }

      if (jobNumber && jobNumber !== '') {
        url += hasParam ? '&' : '?';
        url += 'jobNumber=' + jobNumber;
        hasParam = true;
      }

      if (externalInvoiceId && externalInvoiceId !== '') {
        url += hasParam ? '&' : '?';
        url += 'externalInvoiceId=' + externalInvoiceId;
        hasParam = true;
      }

      if (purchaseOrderId) {
        url += hasParam ? '&' : '?';
        url += 'purchaseOrderId=' + purchaseOrderId;
        hasParam = true;
      }

      if (costCentreId) {
        url += hasParam ? '&' : '?';
        url += 'costCentreId=' + costCentreId;
        hasParam = true;
      }

      return this._http.get<Invoice[]>(url, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
    }
  }

  getOnHoldInvoices(showMyInvoicesOnly: boolean): Observable<Invoice[]> {
    let url = this.globalService.getApiUrl() + '/invoices/on-hold?forTracking=true&showMyInvoicesOnly=' + showMyInvoicesOnly;

    return this._http.get<Invoice[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getInvoicesOnHold(showMyInvoicesOnly: boolean): Observable<Invoice[]> {
    return forkJoin(
      [
        this.getOnHoldInvoices(showMyInvoicesOnly),
        this.getAllPurchaseOrdersForOnHolds()
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getAllPurchaseOrdersForOnHolds(): Observable<PurchaseOrder[]> {
    return this._http.get<PurchaseOrder[]>(this.globalService.getApiUrl() +
      '/purchase-orders/on-hold-invoices', this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.allPurchaseOrders = res;
        }),
        catchError(this.handleError));
  }

  getPurchaseOrdersForJob(jobId: number): Observable<PurchaseOrder[]> {
    return this._http.get<PurchaseOrder[]>(this.globalService.getApiUrl() +
      '/purchase-orders?jobId=' + jobId, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.purchaseOrdersForJob = res;
        }),
        catchError(this.handleError));
  }

  getInvoicesForJob(jobId: number): Observable<Invoice[]> {
    const url = this.globalService.getApiUrl() + '/invoices-for-job/' + jobId;

    return this._http.get<Invoice[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.invoicesForThisJob = res;
      }),
      catchError(this.handleError));
  }

  getInvoicesDataForBackCharges(useCache: boolean): Observable<Vendor[]> {
    return forkJoin(
      [
        this.userService.getVendorData(useCache, true),
        this.jobService.getJobsByAddress(useCache),
        this.getInvoiceBatches(),
        this.maintenanceService.getPriceFileItemGroups(useCache)
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getInvoiceBatches(): Observable<InvoiceBatch[]> {
    return this._http.get<InvoiceBatch[]>(this.globalService.getApiUrl() +
      '/invoice-batches', this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.invoiceBatches = res;
        }),
        catchError(this.handleError));
  }

  getOrdersAndInvoicesForJob(jobId: number): Observable<PurchaseOrder[]> {
    return forkJoin(
      [
        this.getPurchaseOrdersForJob(jobId),
        this.getInvoicesForJob(jobId)
      ]
    )
      .pipe(map(
        ([res]) => {
          return res;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }



  updateInvoice(id: string, itm: any): Observable<Invoice> {
    const url = this.globalService.getApiUrl() + '/invoices/' + id + '/authorise';
    return this._http.patch<Invoice>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  rejectInvoice(id: string, dataRecord: object) {
    const url = this.globalService.getApiUrl() + '/invoices/' + id + '/reject';
    return this._http.patch(url, JSON.stringify(dataRecord, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  backChargeInvoice(id: number, dataRecord: object): Observable<Invoice> {
    const url = this.globalService.getApiUrl() + '/invoices/' + id + '/back-charge';
    return this._http.post<Invoice>(url, JSON.stringify(dataRecord, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  createBackChargeCredit(dataRecord: object): Observable<Invoice> {
    const url = this.globalService.getApiUrl() + '/invoices/create-credit';
    return this._http.post<Invoice>(url, JSON.stringify(dataRecord, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

  getBackChargeDocuments(backChargeId: number): Observable<BackChargeDocument[]> {
    return this._http.get<BackChargeDocument[]>(this.globalService.getApiUrl() +
      '/back-charge-documents?backChargeId=' + backChargeId, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }


  getInvoiceAttachment(id: number): Observable<FileAttachment> {
    return this._http.get<FileAttachment>(this.globalService.getApiUrl() +
      '/invoice-attachments/' + id, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addBackChargeDocuments(backChargeId: number, dataRecord: object) {
    const url = this.globalService.getApiUrl() + '/back-charge-documents?backChargeId=' + backChargeId;
    return this._http.post(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }


  checkIfInvoicePaid(id: string): Observable<Invoice> {
    const url = this.globalService.getApiUrl() + '/invoices/' + id + '/payment-status';
    return this._http.post<Invoice>(url, '', this.httpService.getHttpOptions());
  }


  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
