<div class="page">
  <dx-data-grid *ngIf="!loadingTasks && dataSource" id="dataGridTasks" #dataGridTasks class="m-auto" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" (onInitNewRow)="onInitNewRow($event)" (onToolbarPreparing)="onToolbarPreparing($event)"
    (onInitialized)="onInitialized($event)" (onRowPrepared)="onRowPrepared($event)"
    (onCellPrepared)="onCellPrepared($event)" (onEditingStart)="onEditingStart($event)" (onSaved)="resetUsers()"
    [filterSyncEnabled]="true" (onEditCanceled)="resetUsers()" [ngStyle]="{'height': 'calc(' + gridHeight + 'px)'}"
    (onRowCollapsing)="onRowCollapsed($event)" (onRowExpanding)="onRowExpanded($event)"
    (onContentReady)="onContentReady()" (onExporting)="gridService.onExporting($event, 'JobTasks')"
    (onSaved)="onTasksSaved()" [syncLookupFilterValues]="false" [focusedRowEnabled]="true"
    [(focusedRowKey)]="focusedTaskId" [autoNavigateToFocusedRow]="true" (onEditorPreparing)="onEditorPreparing($event)">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" [width]="150"></dxo-search-panel>
    <dxo-sorting mode='multiple'></dxo-sorting>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

    <dxo-editing mode="popup" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="false" [useIcons]="true"
      refreshMode="full" [(changes)]="changes">
      <dxo-popup title="" [showTitle]="false" [width]="editPopupWidth" [height]="editPopupHeight" [fullScreen]="false">
        <dxi-toolbar-item toolbar="bottom" location="before" widget="dxButton"
          [options]="{ type: 'default', stylingMode: 'outlined', text: 'Send', onClick: sendTask }">
        </dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
          [options]="{ type: 'default', text: 'Save', onClick: saveClickHandler }">
        </dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
          [options]="{ type:'danger', text: 'Cancel', onClick: cancelClickHandler }">
        </dxi-toolbar-item>
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
          <dxi-item dataField="taskTypeId" [colSpan]="2"></dxi-item>
          <dxi-item dataField="taskMasterId" [colSpan]="2"></dxi-item>
          <dxi-item dataField="templateTaskHeaderId" [colSpan]="2"></dxi-item>
          <dxi-item dataField="startDate"></dxi-item>
          <dxi-item dataField="calledDate"></dxi-item>
          <dxi-item dataField="dueDate"></dxi-item>
          <dxi-item dataField="endDate"></dxi-item>
          <dxi-item dataField="officeComment" editorType="dxTextArea" [colSpan]="2" [editorOptions]="{ height: 100 }">
          </dxi-item>

          <dxi-item itemType="button" [colSpan]="2" [buttonOptions]="commentHistoryButtonOptions">
          </dxi-item>


          <dxi-item itemType="group" [colCount]="32" [colSpan]="2">
            <dxi-item dataField="jobVariationId" [colSpan]="22"> </dxi-item>
            <dxi-item *ngIf="jobVariationId && jobVariationStatusId" [colSpan]="7" cssClass="variation-button">
              <dx-select-box [dataSource]="variationStatus" displayExpr="status" valueExpr="id"
                [(value)]="jobVariationStatusId"></dx-select-box>
            </dxi-item>
            <dxi-item *ngIf="jobVariationId && jobVariationStatusId" editorType="dxButton" [colSpan]="3"
              [editorOptions]="variationButtonOptions">
            </dxi-item>
          </dxi-item>

          <dxi-item dataField="userId"></dxi-item>
          <dxi-item dataField="statusId"></dxi-item>
          <dxi-item dataField="modifiedUserId"></dxi-item>
          <dxi-item dataField="modifiedDate"></dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true">
      <dxo-search [enabled]="true"></dxo-search>
    </dxo-header-filter>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="keytaskgrid" [savingTimeout]="500">
    </dxo-state-storing>

    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="false" [contextMenuEnabled]="true" expandMode="rowClick"></dxo-grouping>

    <dxi-column dataField="orderNumber" dataType="string" [visible]='false' sortOrder="asc"> </dxi-column>

    <dxi-column dataField="taskTypeId" caption="Type" dataType="number" width="120"
      [setCellValue]="setTaskTypeCellValue" [groupIndex]="0" [calculateGroupValue]="calculateLoadSortValue"
      groupCellTemplate="groupCellTemplate">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="taskTypes" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="taskMasterId" dataType="number" caption="Title" minWidth="150"
      [setCellValue]="setTaskMasterIdCellValue" [calculateSortValue]="calculateTaskSortValue">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="getTaskList" displayExpr="taskTitle" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="templateTaskHeaderId" caption="Work Flow" dataType="number" [visible]='false'
      [editorOptions]="{ showClearButton: true }" [setCellValue]="setTemplateTaskHeaderIdCellValue">
      <dxo-lookup [dataSource]="allTaskHeaders" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="manualTaskTitle" dataType="string" [visible]='false'>
    </dxi-column>

    <dxi-column dataField="startDate" dataType="date" width="90" alignment="center" format="d-MMM-yy"
      [setCellValue]="setStartDateCellValue"
      [editorOptions]="{ showClearButton: true, calendarOptions: { showTodayButton: true } }">
    </dxi-column>

    <dxi-column dataField="originalDueDate" dataType="date" width="90" alignment="center" format="d-MMM-yy"
      [calculateCellValue]="calcOriginalDueDate" [visible]="false">
    </dxi-column>

    <dxi-column dataField="dueDate" dataType="date" width="90" alignment="center" format="d-MMM-yy"
      [editorOptions]="{ showClearButton: true, calendarOptions: { showTodayButton: true } }">
    </dxi-column>

    <dxi-column dataField="endDate" caption="Completed" dataType="date" width="90" alignment="center" format="d-MMM-yy"
      [setCellValue]="setEndDateCellValue"
      [editorOptions]="{ showClearButton: true, calendarOptions: { showTodayButton: true } }">
    </dxi-column>

    <dxi-column dataField="officeComment" dataType="string" caption="Comment" minWidth="150"
      cellTemplate="commentCellTemplate">
    </dxi-column>

    <dxi-column dataField="vendorComment" dataType="string" caption="Vendor Comment" minWidth="150"
      cellTemplate="commentCellTemplate" [visible]="false">
    </dxi-column>

    <dxi-column dataField="userId" caption="Assigned To" dataType="number" minWidth="90"
      [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="isCompleted" caption="Done" dataType="boolean" width="50" [visible]='false'
      [calculateCellValue]='calculateIsCompleted' [setCellValue]="setIsCompletedCellValue">
    </dxi-column>

    <dxi-column dataField="statusId" caption="Status" dataType="number" width="110" [setCellValue]="setStatusCellValue">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="taskStatusList" displayExpr="status" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="modifiedDate" caption="Modified" dataType="date" width="85" alignment="center"
      format="d-MMM-yy" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="modifiedUserId" caption="Modified By" dataType="number" [allowEditing]="false" [width]="150">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="calledDate" caption="Sent" dataType="date" width="85" alignment="center" format="d-MMM-yy"
      [visible]='false' [editorOptions]="{ showClearButton: true, calendarOptions: { showTodayButton: true } }"
      [setCellValue]="setSentCellValue">
    </dxi-column>

    <!-- <dxi-column dataField="sentFromQueueDate" dataType="date" width="85" alignment="center" format="d-MMM-yy"
      [visible]='false' [allowEditing]="false">
    </dxi-column> -->

    <dxi-column dataField="emailQueueStatus" dataType="string" width="85" alignment="center" [visible]='false'
      [allowEditing]="false" [calculateCellValue]="calculateSentFromQueueStatus">
    </dxi-column>

    <dxi-column dataField="doNotSendCallup" caption="Don't Send" dataType="boolean" width="50" alignment="center"
      [visible]='false'>
    </dxi-column>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [visible]='false'
      [calculateSortValue]="calculateVendorSortValue">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="jobVariationId" caption="Variation" dataType="number" width="105" alignment="center"
      [visible]='false' editCellTemplate="editVariationTemplate" [setCellValue]="setVariationIdCellValue">
      <dxo-lookup [dataSource]="jobVariations" displayExpr="displayedVariationNumber" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="jobVariationTitle" caption="Variation Title" dataType="string" width="200" alignment="left"
      [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateVariationTitle">
    </dxi-column>

    <dxi-column dataField="jobVariationStatusId" caption="Variation Status" dataType="string" [width]="130"
      alignment="left" [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateVariationStatus">
    </dxi-column>

    <dxi-column dataField="masterJobId" dataType="number" caption="Master Job" width="100" alignment="left"
      [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateMasterJob">
    </dxi-column>

    <dxi-column dataType="string" [caption]="salesRepWording" width="100" alignment="left" [allowEditing]="false"
      [visible]='false' [calculateCellValue]="calculateSalesRep">
    </dxi-column>

    <dxi-column dataField="isKeyTask" caption="Key Task" dataType='boolean' [width]='50'
      [calculateCellValue]="calculateKeyTaskValue" [visible]="false" [allowEditing]="false">
    </dxi-column>



    <div *dxTemplate="let data of 'groupCellTemplate'">
      {{ getGroupTitle(data) }}
    </div>

    <div *dxTemplate="let cellInfo of 'editVariationTemplate'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="jobVariations" [(value)]="cellInfo.value"
        displayExpr="title" valueExpr="id" contentTemplate="contentTemplate" [showClearButton]="true"
        (onOpened)="openVariationDropdown(cellInfo)" (onValueChanged)="onVariationChanged(cellInfo, $event)">
        <div *dxTemplate="let e of 'contentTemplate'">
          <dx-data-grid [dataSource]="variationsDataSource" [remoteOperations]="false" height="500px"
            [showBorders]="true" [focusedRowEnabled]="true" [focusedRowKey]="cellInfo.value"
            [selectedRowKeys]="[cellInfo.value]" [hoverStateEnabled]="true"
            [scrolling]="{ mode: 'standard', preloadEnabled: true }"
            (onSelectionChanged)="onSelectionChanged(cellInfo, e, $event)">

            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-search-panel [visible]="true" location="before" [width]="jobDropdownWidth"></dxo-search-panel>

            <dxi-column dataField="displayedVariationNumber" caption="Variation" [width]="100" alignment="center">
            </dxi-column>
            <dxi-column dataField="title"></dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>

    <div *dxTemplate="let cellInfo of 'commentCellTemplate'">
      <div *ngIf='showFullComments' id="vendorComment" class='mx-0 px-0'>{{cellInfo.value}}</div>
      <div *ngIf='!showFullComments' id="vendorCommentLimited" class='mx-0 px-0'>{{cellInfo.value}}</div>
    </div>
  </dx-data-grid>
</div>


<!-- loading spinner - DevExtreme -->
<!-- <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#employee' }"
  [(visible)]="loadingTasks || updatingVariation" [showIndicator]="true" [showPane]="true" [shading]="true"
  [hideOnOutsideClick]="false">
</dx-load-panel> -->

<dx-popup class="popup" [width]="290" [height]="130" [showTitle]="true" title="Workflow?" [dragEnabled]="false"
  [hideOnOutsideClick]="false" [(visible)]="savePopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="button" text="Save with no workflow specified?" type="success" (onClick)="saveCheckDone()">
    </dx-button>
  </div>
</dx-popup>


<dx-popup class="popup" [width]="editPopupWidth" [height]="commentPopupHeight" [showTitle]="true"
  title="Comment History" [dragEnabled]="false" [hideOnOutsideClick]="false" [(visible)]="commentHistoryPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-data-grid id="dataGridComments" class="m-auto" [height]="commentGridHeight"
      [dataSource]="commentHistoryDataSource" [showBorders]="true" [rowAlternationEnabled]="true"
      [wordWrapEnabled]="true" [allowColumnResizing]="true" (onExporting)="gridService.onExporting($event)">

      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>

      <dxo-paging [enabled]="false"></dxo-paging>

      <dxi-column dataField="comment">
      </dxi-column>

      <dxi-column dataField="modifiedUserId" caption="Modified By" dataType="number" width="110" allowEditing="false"
        cellTemplate="noWrapTemplate" [allowEditing]="false">
        <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="modifiedDate" caption="Modified" dataType="date" width="85" alignment="center"
        format="d-MMM-yy" [allowEditing]="false">
      </dxi-column>


      <div *dxTemplate="let cell of 'noWrapTemplate'">
        <div style="white-space: nowrap; overflow: hidden;">{{cell.text}}</div>
      </div>
    </dx-data-grid>

    <button type="button" class="btn btn-sm btn-secondary mt-2" (click)="closeCommentHistory()">
      Close
    </button>
  </div>
</dx-popup>

<!-- Send Call-Up -->
<dx-popup class="popup" [width]="editPopupWidth" [height]="callUpPopupHeight" [showTitle]="true" title="Send Task"
  [dragEnabled]="false" [hideOnOutsideClick]="false" [(visible)]="sendPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="callUpPopupHeight - 65">

      <b>{{currentTaskTitle}}</b>
      <br><br>

      <div class="dx-field">
        <div class="dx-field-label">Send to:</div>
        <div class="dx-field-value">
          <dx-select-box [(ngModel)]="vendorId" [dataSource]="vendors" [searchEnabled]="true" valueExpr="id"
            displayExpr="vendorName" (onSelectionChanged)="onVendorSelectionChanged($event)">
          </dx-select-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Email Address:</div>
        <div class="dx-field-value">
          <dx-text-box [(value)]="callUpEmail"></dx-text-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Subject:</div>
        <div class="dx-field-value">
          <dx-text-box [(value)]="callUpSubject"></dx-text-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">External Comment:</div>
        <div class="dx-field-value">
          <dx-text-area [height]="80" [(value)]="vendorComment"> </dx-text-area>
        </div>
      </div>

      <!-- add attachments -->
      <!-- <b>Attach Info documents</b> -->

      <js-loading-spinner *ngIf="loadingDocs"></js-loading-spinner>

      <dx-tree-list *ngIf="!loadingDocs" id="jobDocumentsTree" [dataSource]="jobDocuments" keyExpr="keyId"
        [height]="callUpTreeHeight" parentIdExpr="parentKeyId" [showRowLines]="true" [showBorders]="true"
        [columnAutoWidth]="false" [(selectedRowKeys)]="selectedJobDocs"
        (onSelectionChanged)="attachmentSelectionChanged($event)">

        <dxo-search-panel [visible]="true" [width]="200"></dxo-search-panel>
        <dxo-selection mode="multiple" [recursive]="true" [allowSelectAll]="false"> </dxo-selection>

        <dxi-column dataField="description" [allowSorting]="false"></dxi-column>
        <dxi-column dataField="Auto-Attached" caption="Auto-Attached" [calculateCellValue]='calculateCallUpDocsType'
          [width]="110" cellTemplate="callUpDocsTypeCellTemplate" [allowSorting]="false"></dxi-column>

        <div *dxTemplate="let cellInfo of 'callUpDocsTypeCellTemplate'">
          <i *ngIf="cellInfo.value" class="material-icons">done</i>
        </div>
      </dx-tree-list>

      <div class="dx-field mt-3">
        <div class="dx-field-label">{{addendumCaption}}</div>
        <div>
          <dx-check-box [(value)]="sendAddendum"> </dx-check-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">cc email to yourself:</div>
        <div>
          <dx-check-box [(value)]="ccToSelf"> </dx-check-box>
        </div>
      </div>

      <br>
      <dx-button *ngIf="!loadingDocs" text="Send" type="default" (onClick)="sendCallUp()">
      </dx-button>

      <dx-button *ngIf="!loadingDocs" text="Clear Vendor Details" class="ms-2" type="outline"
        (onClick)="clearVendorDetails()">
      </dx-button>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup class="popup" [width]="190" [height]="130" [showTitle]="true" title="Reset" [dragEnabled]="false"
  [hideOnOutsideClick]="false" [(visible)]="resetPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="button" text="Reset Layout" type="success" (onClick)="clearStatePersistanceGo()" class="ms-3">
    </dx-button>
  </div>
</dx-popup>
