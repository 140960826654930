import { UserService } from './../../services/felixApi/user.service';
import { InvoiceService } from './../../services/felixApi/invoice.service';
import { Component, OnInit } from '@angular/core';

import { Company } from '../../dtos/company';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from '../../services/global.service';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { CompanyService } from '../../services/felixApi/company.service';
import { NotificationService } from '../../services/notification.service';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { Subscription } from 'rxjs';
import { OrderLimit } from '../../dtos/order-limit';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'js-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public isCollapsed = true;
  signedIn = false;
  currComp: Company;
  areaSelected = '';
  disabledMenu = false;
  phoneSize: boolean;
  userTypeEnum = UserTypeEnum;
  companyConfiguration: CompanyConfiguration[];
  innerWidth: number;
  isSuperUser: boolean;
  documentsConfigValue: number;
  documentsActive: boolean;
  subscriptions: Subscription[] = [];

  constructor(
    public globalService: GlobalService,
    public authService: AuthService,
    private compService: CompanyService,
    private notiService: NotificationService,
    private invoiceService: InvoiceService,
    private userService: UserService
  ) {
    this.subscribeToLoginChanges();
    this.subscribeToCompanyChanges();
    this.subscribeToAreaChanges();
    this.subscribeToMenuDisable();
    this.subscribeToInnerWidth();
  }

  ngOnInit() {
    this.innerWidth = this.globalService.innerWidth;
    if (this.globalService.currentUserId) {
      this.getCompanyConfigurations();
    }
  }

  menuPress(area) {
    this.globalService.setAreaSelected(area);
  }

  subscribeToAreaChanges() {
    this.globalService.areaChanged.subscribe(area => {
      this.areaSelected = area;
    });
  }

  subscribeToLoginChanges() {
    this.authService.isLoggedInChanged.subscribe(
      (isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.signedIn = false;
          this.disabledMenu = true;
        } else {
          this.signedIn = true;

          if (this.currComp) {
            this.getCompanyConfigurations();
          }
        }
      });
  }

  subscribeToCompanyChanges() {
    this.globalService.companySelected.subscribe(selectedComp => {
      this.currComp = selectedComp;
      // get permissions and configs
      this.getCompanyConfigurations();
    });
  }

  subscribeToMenuDisable() {
    // this.subscriptions.push(
    this.globalService.menuDisable.subscribe(
      disable => {
        if (disable) {
          this.disabledMenu = true;
        } else {
          this.disabledMenu = false;
        }
      }
    );
  }

  subscribeToInnerWidth() {
    this.globalService.innerHeightWidthChanged.subscribe(
      width => {
        this.innerWidth = this.globalService.innerWidth;
      }
    );
  }

  getCompanyConfigurations() {
    this.signedIn = true;
    this.compService.getCompanyConfigurations().subscribe({
      next: (companyConfiguration) => {
        this.companyConfiguration = companyConfiguration;
        // this.globalService.companyConfiguration = this.companyConfiguration;
        this.setConfigs();
      },
      error: (err) => {
        this.notiService.notify(err);
      }});
  }

  setConfigs() {
    this.isSuperUser = this.authService.isSuperUser();
    this.documentsConfigValue = this.globalService.getCompanyConfigValue(ConfigurationEnum.DocumentManagementSystem);

    if (this.documentsConfigValue) {
      if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Documents') !== 'none') {
        this.documentsActive = true;
      } else {
        this.documentsActive = false;
      }
    }

    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Tracking') === 'Admin') {
      this.globalService.canSeeMaintenance = true;
    } else {
      this.globalService.canSeeMaintenance = false;
    }

    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Forecast') !== 'none') {
      this.globalService.canSeeForecast = true;
    } else {
      this.globalService.canSeeForecast = false;
    }

    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('CallUps') !== 'none') {
      this.globalService.canSeeCallUps = true;
    } else {
      this.globalService.canSeeCallUps = false;
    }

    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Invoices') !== 'none'
      || this.authService.getSelectionsPermissions('InvoiceAuthorisation') !== 'none') {
      this.globalService.canSeeInvoices = true;
      this.loadDataForOnHolds();
    } else {
      this.globalService.canSeeInvoices = false;
    }

    // manual purchase orders permission
    this.globalService.canSeeManualPurchaseOrders = false;
    // if (this.globalService.getCompanyConfigValue(ConfigurationEnum.ManualOrders) === 1) {
    if (this.authService.isAdminOrSuper()
      || this.authService.getSelectionsPermissions('PurchaseOrders') === 'Write'
      || this.authService.getSelectionsPermissions('PurchaseOrders') === 'Admin') {
      this.globalService.canSeeManualPurchaseOrders = true;
    } else {
      // check if they have a limit
      this.subscriptions.push(
        this.userService.getMyUserOrderLimit()
          .subscribe({
            next: (orderLimit) => {
              if (orderLimit && (orderLimit.purchaseOrderLimit || orderLimit.costCentreLimit || orderLimit.jobLimit)) {
                this.globalService.canSeeManualPurchaseOrders = true;
              }
            },
            error: (err) => {
              this.notiService.notify(err);
            }})
      );
    }
    // }
  }

  loadDataForOnHolds() {
    this.subscriptions.push(
      this.invoiceService.getInvoicesDataForOnHold(true)
        .subscribe({
          next: () => {
            this.checkInvoicesOnHoldForUser();
          },
          error: (err) => {
            this.notiService.notify(err);
          }})
    );
  }

  checkInvoicesOnHoldForUser() {
    this.globalService.isHaveInvoicesToCheck = false;

    this.invoiceService.getInvoicesOnHold().subscribe({ next: (res) => {
      // filter where allocated directly you
      if (res.find(i => i.allocatedToUserId === this.authService.getCurrentUserId()) !== undefined) {
        this.globalService.isHaveInvoicesToCheck = true;
      }
    }, error: (err) => {
      this.notiService.notify(err);
    }});
  }

  dropdownItems = [
    {
      text: 'Core',
      onClick: () => this.launchCore(),
      icon: 'assets//tlogos/main.png'
    },
    {
      text: 'Costing',
      onClick: () => this.launchPurchaseOrders(),
      icon: 'assets//tlogos/costing.png'
    },
    {
      text: 'Estimating',
      onClick: () => this.launchEstConfig(),
      icon: 'assets//tlogos/estimating.png'
    },
    {
      text: 'Config',
      onClick: () => this.launchConfig(),
      icon: 'assets//tlogos/config.png'
    },
    {
      visible: this.authService.isAdminOrSuper,
      text: 'Admin',
      onClick: () => this.launchAdminPortal(),
      icon: 'assets//tlogos/admin.png'
    }
  ];

  launchAdminPortal() {
    window.open(environment.adminAppUrl);
  }

  launchConfig() {
    window.open(environment.configAppUrl);
  }

  launchEstConfig() {
    window.open(environment.estConfigAppUrl);
  }

  launchCore() {
    window.open(environment.addendaAppUrl);
  }

  launchPurchaseOrders() {
    window.open(environment.ordersAppUrl);
  }

}

