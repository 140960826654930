import { Router } from '@angular/router';
import { UtilsService } from './../services/utils.service';
import { TrackingColourEnum } from '../dtos/tracking-colour.enum';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { of, Subscription, timer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Job } from '../dtos/job';
import { JobField } from '../dtos/job-field';
import { StateStore } from '../shared/state-store/state-store';
import { StateStoreTypeEnum } from '../dtos/state-store-type-enum';
import { Task } from '../dtos/task';
import { TrackingFieldTypeEnum } from '../dtos/tracking-field-type.enum';
import { JobWorkFlowService } from '../services/felixApi/job-work-flow.service';
import { JobService } from '../services/felixApi/job.service';
import { JobCashFlowService } from '../services/felixApi/jobCashFlow.service';
import { MaintenanceService } from '../services/felixApi/maintenance.service';
import { TaskService } from '../services/felixApi/task.service';
import { TrackingFieldsService } from '../services/felixApi/tracking-fields.service';
import { UserService } from '../services/felixApi/user.service';
import { GlobalService } from '../services/global.service';
import { GridService } from '../services/grid.service';
import { NotificationService } from '../services/notification.service';
import { StateStoreComponent } from '../shared/state-store/state-store.component';
import { ColumnChooserComponent } from './column-chooser/column-chooser.component';
import { ReportGridService } from './report-grid.service';
import { DxColumn, DxGridState } from '../shared/state-store/dx-grid';
import { JobTaskForecast } from '../dtos/job-task-forecast';
import { VariationStatusEnum } from '../dtos/variation-status.enum';
import { TrackingFieldLookup } from '../dtos/tracking-field-lookup';
import { JobEmailService } from '../services/felixApi/job-email.service';

@Component({
  selector: 'js-report-grid',
  templateUrl: './report-grid.component.html',
  styleUrls: ['./report-grid.component.scss']
})
export class ReportGridComponent implements OnInit, OnDestroy {

  @ViewChild('allTaskGrid', { static: false }) dataGrid: DxDataGridComponent;

  loading = false;
  subscriptions: Subscription[] = [];
  jobData: CustomStore;
  jobs: Job[];
  taskStatus = [
    { id: 1, status: 'Not Started' },
    { id: 2, status: 'In Progress' },
    { id: 3, status: 'Hold' },
    { id: 4, status: 'Problem' },
    { id: 5, status: 'Waiting' },
    { id: 8, status: 'Cancelled' },
    { id: 9, status: 'Completed' },
    { id: 10, status: 'Not Applicable' }
  ];
  jobTypes = [
    { id: 1, description: 'Standard' },
    { id: 2, description: 'Sales Template' },
    { id: 3, description: 'Contract Template' },
    { id: 4, description: 'House Type Template' },
    { id: 5, description: 'Test Job' }
  ];
  taskStatusList: CustomStore;
  dataSource: CustomStore;
  reportGridData: any;
  jobField: JobField;
  stateStoreId: number; // the layout store id
  newLayoutDescription: string;
  currentState: DxGridState;
  filterValue: any;
  jobTask: Task;
  todayString: string;
  reportName = '';
  gridHeight: number;
  lastColSortDirectionAscending: boolean;
  forecastTasks: JobTaskForecast[];
  adhocSelection: string;
  lookupList: TrackingFieldLookup[];
  adhocItem: TrackingFieldLookup;
  dataFieldForEdit: any;
  inEditMode = false;
  loadingState: boolean;
  nextClaimAmountVisible: boolean;
  jobsToUpdate: number[] = [];
  minuteCountdown = 60;
  currentDate = new Date(); // cache for speed
  showInactive: boolean = false;
  jobsMap: Map<number, Job>;
  forecastTasksMap: Map<string, JobTaskForecast>;
  taskStatusMap: Map<number, { id: number; status: string; }>;
  jobsToUpdateMap: Map<number, boolean>;

  constructor(
    public reportGridService: ReportGridService,
    private taskService: TaskService,
    private notiService: NotificationService,
    private trackingFieldsService: TrackingFieldsService,
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private userService: UserService,
    private jobService: JobService,
    private jobCashFlowService: JobCashFlowService,
    private jobWorkFlowService: JobWorkFlowService,
    private jobEmailService: JobEmailService,
    private gridService: GridService,
    private utilsService: UtilsService,
    private router: Router
  ) {
    this.goToJobData = this.goToJobData.bind(this);
    this.onRowPrepared = this.onRowPrepared.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.setUpDataSource = this.setUpDataSource.bind(this);
    this.changEditMode = this.changEditMode.bind(this);
    this.onSaved = this.onSaved.bind(this);
    this.calculateCellValue = this.calculateCellValue.bind(this);
  }

  ngOnInit() {
    this.setGridMeasurements();
    this.subscribeToInnerHeight();

    const savedState = JSON.parse(localStorage.getItem('allTaskGrid'));
    this.getStateVariables(savedState);

    this.getData(false);
    this.setupDataRefresh();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToInnerHeight() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.pipe(debounceTime(200)).subscribe(
        () => {
          setTimeout(() => {
            this.setGridMeasurements();
          }, 500); // wait for iPhone
        }
      )
    );
  }

  setupDataRefresh(): void {
    // Refresh data every hour by counting down the minutes
    this.subscriptions.push(
      timer(0, 60000).subscribe(() => {
        if (!this.inEditMode) {
          if (this.minuteCountdown > 0 && !this.loading) {
            this.minuteCountdown--; // Decrement the countdown
          }

          if (this.minuteCountdown <= 0) {
            this.getData(false);
          }
        }
      })
    );
  }

  setGridMeasurements() {
    this.gridHeight = this.globalService.innerHeight - 80;
  }

  getData(useCache: boolean) {
    console.log('getData ' + new Date());
    this.loading = true;
    this.todayString = this.utilsService.convertDateToString(this.currentDate);

    if (!useCache) {
      this.minuteCountdown = 60; // Reset the countdown
    }

    this.subscriptions.push(
      this.taskService.getAllTasksGridData(useCache, false)
        .subscribe({
          next: (jobs) => {
            this.useData(jobs);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  useData(jobs: Job[]) {
    this.jobs = jobs;
    this.jobsMap = new Map<number, Job>();
    jobs.forEach(j => this.jobsMap.set(j.id, j));

    this.forecastTasks = this.taskService.forecastTasks;
    this.forecastTasksMap = new Map<string, JobTaskForecast>();

    this.forecastTasks.forEach(t => {
      const key = `${t.jobId}-${t.taskMasterId}`;
      this.forecastTasksMap.set(key, t);

      this.taskStatusMap = new Map<number, { id: number, status: string }>();
      this.taskStatus.forEach(ts => this.taskStatusMap.set(ts.id, ts));

      this.jobsToUpdateMap = new Map<number, boolean>();

      this.jobsToUpdate.forEach(jobId => {
        this.jobsToUpdateMap.set(jobId, true);
      });


    });







    this.jobData = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => jobs
    });

    this.taskStatusList = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.taskStatus
    });

    // columns dynamically generated based on data
    // tasks never cached currently so generate each time
    this.setupColumns();
    this.generateGridRows();
  }

  showInactiveChanged(e) {
    this.showInactive = e.value;
    this.setUpDataSource();

    const currentState = JSON.parse(localStorage.getItem('allTaskGrid')) || {};
    currentState['showInactive'] = this.showInactive;
    localStorage.setItem('allTaskGrid', JSON.stringify(currentState));
  }


  checkStoredCol(columns: DxColumn[], colField: string): [inGrid: boolean, visible: boolean] {
    if (!columns) {
      return [false, false];
    }
    const foundCol = columns.filter(c => c.dataField === colField);
    if (foundCol && foundCol.length === 1 && foundCol[0].visible) {
      return [true, true];
    } else if (foundCol && foundCol.length === 1) {
      return [true, false];
    } else {
      return [false, false];
    }
  }

  setupColumns() {
    console.log('setupColumns ' + new Date());
    let state: DxGridState;
    const stateStr = localStorage.getItem('allTaskGrid');
    if (stateStr) {
      state = JSON.parse(stateStr) as DxGridState;
    }
    const noState = !state || !state.columns || state.columns.length === 0;

    this.reportGridService.clear();




    // default cols //
    let res = this.checkStoredCol(state?.columns, 'jobNumber');
    this.addToColumns('jobNumber', 'Job Number', 'string', '', 'center', noState ? true : res[0], noState ? true : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.jobNumber;').bind(this)
    );


    res = this.checkStoredCol(state?.columns, 'contractName');
    this.addToColumns('contractName', 'Client', 'string', '', 'left', noState ? true : res[0], noState ? true : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.contractName;').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'siteAddress');
    this.addToColumns('siteAddress', 'Site Address', 'string', '', 'left', noState ? true : res[0], noState ? true : res[1], this.calculateSiteAddress);
    //////////////////

    res = this.checkStoredCol(state?.columns, 'masterJob');
    this.addToColumns('masterJob', 'Master Job', 'string', '', 'center', noState ? false : res[0], noState ? false : res[1], this.calculateMasterJob);
    res = this.checkStoredCol(state?.columns, 'isMasterJob');
    this.addToColumns('isMasterJob', 'Is Master Job', 'string', '', 'center', noState ? false : res[0], noState ? false : res[1], this.calculateIsMasterJob);
    res = this.checkStoredCol(state?.columns, 'jobType');
    this.addToColumns('jobType', 'Job Type', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1], this.calculateJobType);
    res = this.checkStoredCol(state?.columns, 'isActive');
    this.addToColumns('isActive', 'Active Job', 'string', '', 'center', noState ? false : res[0], noState ? false : res[1], null);
    res = this.checkStoredCol(state?.columns, 'lotNumber');
    this.addToColumns('lotNumber', 'Lot Number', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.jobAddress?.lotNumber;').bind(this));
    res = this.checkStoredCol(state?.columns, 'streetName1');
    this.addToColumns('streetName1', 'Street Name1', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.jobAddress?.streetName1;').bind(this));
    res = this.checkStoredCol(state?.columns, 'streetName2');
    this.addToColumns('streetName2', 'Street Name2', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.jobAddress?.streetName2;').bind(this));
    res = this.checkStoredCol(state?.columns, 'suburb');
    this.addToColumns('suburb', 'Suburb', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.jobAddress?.suburbTown;').bind(this));
    res = this.checkStoredCol(state?.columns, 'postCode');
    this.addToColumns('postCode', 'Postcode', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.jobAddress?.postCode;').bind(this));
    res = this.checkStoredCol(state?.columns, 'clientSuburb');
    this.addToColumns('clientSuburb', 'Client Suburb', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.contractAddress?.suburbTown;').bind(this));
    res = this.checkStoredCol(state?.columns, 'clientState');
    this.addToColumns('clientState', 'Client State', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.contractAddress?.state;').bind(this));
    res = this.checkStoredCol(state?.columns, 'clientPostCode');
    this.addToColumns('clientPostCode', 'Client Postcode', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.contractAddress?.postCode;').bind(this));
    res = this.checkStoredCol(state?.columns, 'clientAddress');
    this.addToColumns('clientAddress', 'Client Address', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.globalService.getClientAddress(this.jobsMap.get(param.jobId));').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'salesDate');
    this.addToColumns('salesDate', 'Sales Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobsMap.get(param.jobId)?.salesDate);').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'daysSinceSale');
    this.addToColumns('daysSinceSale', 'Days Since Sale', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getCalendarDaysDifference(this.currentDate, this.jobsMap.get(param.jobId)?.salesDate);').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'daysSinceContractSigned');
    this.addToColumns('daysSinceContractSigned', 'Days Since Contract Signed', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getCalendarDaysDifference(this.currentDate, this.jobsMap.get(param.jobId)?.contractSignedDate);').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'contractSignedDate');
    this.addToColumns('contractSignedDate', 'Contract Signed Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobsMap.get(param.jobId)?.contractSignedDate);').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'titleDueDate');
    this.addToColumns('titleDueDate', 'Title Due', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobsMap.get(param.jobId)?.titleDueDate);').bind(this),
      new Function('rowData', 'value', 'originalData', `
    const job = this.jobsMap.get(originalData.jobId);
    if (job) {
      job.titleDueDate = value;
      rowData['titleDueDate'] = value;
    }
  `).bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'contractPrice');
    this.addToColumns('contractPrice', 'Contract Price', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.contractPrice;').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'basePrice');
    this.addToColumns('basePrice', 'Base House Price', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.basePrice;').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'depositAmount');
    this.addToColumns('depositAmount', 'Deposit Amount', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.depositAmount;').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'depositPaid');
    this.addToColumns('depositPaid', 'Deposit Paid', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.depositPaid;').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'council');
    this.addToColumns('council', 'Council', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.council;').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'isOnHold');
    this.addToColumns('isOnHold', 'IsOnHold', 'boolean', '', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobService.jobExtrasMap.get(param.jobId)?.isOnHold ?? false;').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'onHoldReason');
    this.addToColumns('onHoldReason', 'Hold Reason', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobService.jobExtrasMap.get(param.jobId)?.onHoldReason ?? "";').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'holdDate');
    this.addToColumns('holdDate', 'Hold Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobService.jobExtrasMap.get(param.jobId)?.holdDate);').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'houseType');
    this.addToColumns('houseType', 'House Type', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobService.houseTypesMap.get(this.jobsMap.get(param.jobId)?.houseTypeId)?.description ?? "";').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'houseModifications');
    this.addToColumns('houseModifications', 'House Modifications', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.houseModificationDescription;').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'jobCreationDate');
    this.addToColumns('jobCreationDate', 'Job Created', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobsMap.get(param.jobId)?.createDate);').bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'salutation');
    this.addToColumns('salutation', 'Salutation', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      this.getSalutation.bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'clientLastName1');
    this.addToColumns('clientLastName1', 'Client Last Name 1', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.lastName1;').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'clientEmail');
    this.addToColumns('clientEmail', 'Client Email', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.clientEmail;').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'phoneNumber1');
    this.addToColumns('phoneNumber1', 'Phone Number1', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.phoneNumber1;').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'phoneNotes1');
    this.addToColumns('phoneNotes1', 'Phone Notes1', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.phoneNotes1;').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'phoneNumber2');
    this.addToColumns('phoneNumber2', 'Phone Number2', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.phoneNumber2;').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'phoneNotes2');
    this.addToColumns('phoneNotes2', 'Phone Notes2', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.phoneNotes2;').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'currentActivity');
    this.addToColumns('currentActivity', 'Current Activity', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      this.calculateCurrentActivity
    );
    res = this.checkStoredCol(state?.columns, 'currentActivityCode');
    this.addToColumns('currentActivityCode', 'Current Activity Code', 'string', '', 'center', noState ? false : res[0], noState ? false : res[1],
      this.calculateCurrentActivityCode
    );
    res = this.checkStoredCol(state?.columns, 'currentActivityStart');
    this.addToColumns('currentActivityStart', 'Current Activity Start', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobService.jobExtrasMap.get(param.jobId)?.currentActivityStartDate);').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'currentActivityDue');
    this.addToColumns('currentActivityDue', 'Current Activity Due', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobService.jobExtrasMap.get(param.jobId)?.currentActivityDueDate);').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'currentActivityEnd');
    this.addToColumns('currentActivityEnd', 'Current Activity Completed', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobService.jobExtrasMap.get(param.jobId)?.currentActivityEndDate);').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'completionDate');
    this.addToColumns('completionDate', 'Practical Completion Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobService.jobExtrasMap.get(param.jobId)?.completionDate);').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'siteStartDate');
    this.addToColumns('siteStartDate', 'Site Start Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobService.jobExtrasMap.get(param.jobId)?.siteStartDate);').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'handoverDate');
    this.addToColumns('handoverDate', 'Handover Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobService.jobExtrasMap.get(param.jobId)?.handoverDate);').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'cancellationDate');
    this.addToColumns('cancellationDate', 'Cancellation Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobService.jobExtrasMap.get(param.jobId)?.cancellationDate);').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'cancellationReason');
    this.addToColumns('cancellationReason', 'Cancellation Reason', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobService.jobExtrasMap.get(param.jobId)?.cancellationReason ?? "";').bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'targetCompletionDate');
    this.addToColumns('targetCompletionDate', 'Target Completion Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobService.jobExtrasMap.get(param.jobId)?.targetCompletionDate);').bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'agreedCompletionDate');
    this.addToColumns('agreedCompletionDate', 'Agreed Completion Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1],
      this.calculateAgreedCompletionDate.bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'forecastCompletionDate');
    this.addToColumns('forecastCompletionDate', 'Forecast Completion Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobService.jobExtrasMap.get(param.jobId)?.forecastCompletionDate);').bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'forecastSiteStartDate');
    this.addToColumns('forecastSiteStartDate', 'Forecast Site Start Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1],
      this.calculateForecastSiteStartDate.bind(this)
    );


    res = this.checkStoredCol(state?.columns, 'originalContractualCompletion');
    this.addToColumns('originalContractualCompletion', 'Original Contractual Completion Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobCashFlowService.jobCashFlowsMap.get(param.jobId)?.originalContractualCompletion);').bind(this)

    );
    res = this.checkStoredCol(state?.columns, 'currentContractualCompletionDate');
    this.addToColumns('currentContractualCompletionDate', 'Current Contractual Completion Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1],
      new Function('param', `return this.utilsService.getDateFromDateOrString(this.jobService.calculateContractualCompletion(this.jobCashFlowService.jobCashFlowsMap.get(param.jobId)?.originalContractualCompletion,param.jobId));`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'maintenanceCompleteDate');
    this.addToColumns('maintenanceCompleteDate', 'Maintenance Complete Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const jobExtra = this.jobService.jobExtrasMap.get(param.jobId);if (jobExtra && jobExtra.maintenanceCompleteDate) {return this.utilsService.getDateFromDateOrString(jobExtra.maintenanceCompleteDate);}`).bind(this)
    );


    res = this.checkStoredCol(state?.columns, 'targetStartDate');
    this.addToColumns('targetStartDate', 'Target Start Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobCashFlowService.jobCashFlowsMap.get(param.jobId)?.targetStartDate);').bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'fixedStartDate');
    this.addToColumns('fixedStartDate', 'Fixed Start Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobCashFlowService.jobCashFlowsMap.get(param.jobId)?.fixedStartDate);').bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'workflowTemplate');
    this.addToColumns('workflowTemplate', 'Construction Workflow', 'string', '', 'left',
      noState ? false : res[0], noState ? false : res[1],
      new Function('param', `
  const key = param.jobId.toString() + '-2';
  const constructionWorkflow = this.jobWorkFlowService.jobWorkFlowsByJobAndTypeMap.get(key);
  return constructionWorkflow?.templateTaskHeaderName ?? '';
`).bind(this));

    res = this.checkStoredCol(state?.columns, 'preconWorkflowTemplate');
    this.addToColumns('preconWorkflowTemplate', 'Pre-Construction Workflow', 'string', '', 'left',
      noState ? false : res[0], noState ? false : res[1],
      new Function('param', `
        const key = String(param.jobId) + '-1';
        const preconWorkflow = this.jobWorkFlowService.jobWorkFlowsByJobAndTypeMap.get(key);
        return preconWorkflow?.templateTaskHeaderName ?? '';
      `).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'salesWorkflowTemplate');
    this.addToColumns('salesWorkflowTemplate', 'Sales Workflow (First)', 'string', '', 'left',
      noState ? false : res[0], noState ? false : res[1],
      new Function('param', `
        const key = String(param.jobId) + '-4';
        const salesWorkflow = this.jobWorkFlowService.jobWorkFlowsByJobAndTypeMap.get(key);
        return salesWorkflow?.templateTaskHeaderName ?? '';
      `).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'otherWorkflowTemplate');
    this.addToColumns('otherWorkflowTemplate', 'Other Workflow (First)', 'string', '', 'left',
      noState ? false : res[0], noState ? false : res[1],
      new Function('param', `
        const key = String(param.jobId) + '-3';
        const otherWorkflow = this.jobWorkFlowService.jobWorkFlowsByJobAndTypeMap.get(key);
        return otherWorkflow?.templateTaskHeaderName ?? '';
      `).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'maintenanceWorkflowTemplate');
    this.addToColumns('maintenanceWorkflowTemplate', 'Maintenance Workflow (First)', 'string', '', 'left',
      noState ? false : res[0], noState ? false : res[1],
      new Function('param', `
        const key = String(param.jobId) + '-5';
        const maintenanceWorkflow = this.jobWorkFlowService.jobWorkFlowsByJobAndTypeMap.get(key);
        return maintenanceWorkflow?.templateTaskHeaderName ?? '';
      `).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'claimSchedule');
    this.addToColumns('claimSchedule', 'Claim Schedule', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `return this.jobCashFlowService.jobCashFlowsMap.get(param.jobId)?.claimScheduleName ?? '';`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'salesQuotePrice');
    this.addToColumns('salesQuotePrice', 'Sales Price', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobsMap.get(param.jobId)?.salesQuotePrice;').bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'salesQuoteSignedDate');
    this.addToColumns('salesQuoteSignedDate', 'Sales Quote Signed Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1],
      this.calculateSalesQuoteSignedDate.bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'depositBalanceDue');
    this.addToColumns('depositBalanceDue', 'Deposit Balance Due', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.utilsService.getDateFromDateOrString(this.jobsMap.get(param.jobId)?.depositBalanceDue);').bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'quoteDate');
    this.addToColumns('quoteDate', 'Sales Quote Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1],
      this.calculateQuoteDate.bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'division');
    this.addToColumns('division', 'Division', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const job = this.jobsMap.get(param.jobId);if (!job) return '';const division = this.maintenanceService.divisionsMap.get(job.divisionId);return division ? division.description : '';`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'contractQuoteDate');
    this.addToColumns('contractQuoteDate', 'Contract Quote Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1],
      this.calculateContractQuoteDate.bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'totalContractPrice');
    this.addToColumns('totalContractPrice', 'Total Contract Price', 'number', '#,###', 'right',
      noState ? false : res[0], noState ? false : res[1],
      this.calculateTotalContractPrice.bind(this)
    );


    res = this.checkStoredCol(state?.columns, 'estate');
    this.addToColumns('estate', 'Estate', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const job = this.jobsMap.get(param.jobId);return job?.estate || null;`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'warningNote');
    this.addToColumns('warningNote', 'Warning Note', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const job = this.jobsMap.get(param.jobId);return job?.warningNote || null;`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'latitude');
    this.addToColumns('latitude', 'Latitude', 'number', '###.0000##', 'right', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const job = this.jobsMap.get(param.jobId);const latitude = job?.jobAddress?.latitude;if (latitude != null) {return parseFloat(latitude);}return null;`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'longitude');
    this.addToColumns('longitude', 'Longitude', 'number', '###.0000##', 'right', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const job = this.jobsMap.get(param.jobId);const longitude = job?.jobAddress?.longitude;if (longitude != null) {return parseFloat(longitude);}return null;`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'landPrice');
    this.addToColumns('landPrice', 'Land Price', 'number', '###.0000##', 'right', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const jobExtra = this.jobService.jobExtrasMap.get(param.jobId);const landPrice = jobExtra?.landPrice;if (landPrice != null) {return parseFloat(landPrice);}return null;`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'tradeRegion');
    this.addToColumns('tradeRegion', 'Region', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      this.getTradeRegionDescription.bind(this)
    );
    res = this.checkStoredCol(state?.columns, 'planNumber');
    this.addToColumns('planNumber', 'Plan Number', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const job = this.jobsMap.get(param.jobId);return job?.planNumber || null;`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'volume');
    this.addToColumns('volume', 'Volume', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const job = this.jobsMap.get(param.jobId);return job?.volume || null;`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'folio');
    this.addToColumns('folio', 'Folio', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const job = this.jobsMap.get(param.jobId);return job?.folio || null;`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'landTitleType');
    this.addToColumns('landTitleType', 'Type of Title', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `
        const job = this.jobsMap.get(param.jobId);
        if (job && job.landTitleTypeId) {
          const landTitleType = this.maintenanceService.landTitleTypesMap.get(job.landTitleTypeId)?.description;
          return landTitleType || null;
        }
        return null;
      `).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'landZoning');
    this.addToColumns('landZoning', 'Land Zoning', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `
        const job = this.jobsMap.get(param.jobId);
        if (job && job.landZoningId) {
          const landZoning = this.maintenanceService.landZonesMap.get(job.landZoningId)?.description;
          return landZoning || null;
        }
        return null;
      `).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'landType');
    this.addToColumns('landType', 'Land Type', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `
        const job = this.jobsMap.get(param.jobId);
        if (job && job.landTypeId) {
          const landType = this.maintenanceService.landTypesMap.get(job.landTypeId)?.description;
          return landType || null;
        }
        return null;
      `).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'sendToConstructive');
    this.addToColumns('sendToConstructive', 'Send to Constructive', 'boolean', '', 'center',
      noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const jobExtra = this.jobService.jobExtrasMap.get(param.jobId);return jobExtra?.sendToConstructive ?? false;`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'nextClaimStage');
    this.addToColumns('nextClaimStage', 'Next Claim Stage', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const nextClaim = this.jobService.nextClaimsMap.get(param.jobId);return nextClaim ? nextClaim.activityDescription : '';`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'nextClaimAmount');
    this.addToColumns('nextClaimAmount', 'Next Claim Amount', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1],
      new Function('rowData', `
        const nextClaim = this.jobService.nextClaimsMap.get(rowData.jobId);
        return nextClaim ? nextClaim.amount : null;
      `).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'nextClaimDate');
    this.addToColumns('nextClaimDate', 'Next Claim Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      this.calculateNextClaimDate.bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'latestJobEmailDate');
    this.addToColumns('latestJobEmailDate', 'Latest Email/Note Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const latestJobEmail = this.jobEmailService.latestJobEmailsMap.get(param.jobId);const dateValue = latestJobEmail ? latestJobEmail.createDate : undefined;return dateValue ? this.utilsService.getDateFromDateOrString(dateValue) : undefined;`).bind(this)
    );


    res = this.checkStoredCol(state?.columns, 'latestJobEmailSubject');
    this.addToColumns('latestJobEmailSubject', 'Latest Email/Note Subject', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', ` const latestJobEmail = this.jobEmailService.latestJobEmailsMap.get(param.jobId);return latestJobEmail ? latestJobEmail.subject : '';`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'latestJobEmailBody');
    this.addToColumns('latestJobEmailBody', 'Latest Email/Note Body', 'string', null, 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const latestJobEmail = this.jobEmailService.latestJobEmailsMap.get(param.jobId);const bodyText = latestJobEmail?.bodyAsText?.replace(/\\n\\n/g, '\\n') ?? '';return bodyText;`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'latestJobEmailSavedBy');
    this.addToColumns('latestJobEmailSavedBy', 'Latest Email/Note Saved By', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const latestJobEmail = this.jobEmailService.latestJobEmailsMap.get(param.jobId);const savedBy = latestJobEmail? this.userService.usersMap.get(latestJobEmail.createUserId)?.fullName ?? '': '';return savedBy;`).bind(this)
    );

    // calc days
    res = this.checkStoredCol(state?.columns, 'calendarDaystoCompletion');
    this.addToColumns('calendarDaystoCompletion', 'Calendar days to Practical Completion', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const jobExtra = this.jobService.jobExtrasMap.get(param.jobId);if (jobExtra?.forecastCompletionDate) {return this.utilsService.getCalendarDaysDifference(jobExtra.forecastCompletionDate, this.currentDate);}return null;`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'workingDaystoCompletion');
    this.addToColumns('workingDaystoCompletion', 'Working days to Practical Completion', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const jobExtra = this.jobService.jobExtrasMap.get(param.jobId);if (jobExtra?.forecastCompletionDate) {return this.maintenanceService.getCalendarDaysDifferenceExHolidays(jobExtra.forecastCompletionDate, this.currentDate);}return null;`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'calendarDaysSinceSiteStart');
    this.addToColumns('calendarDaysSinceSiteStart', 'Calendar days since Site Start', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1],
      this.calculateCalendarDaysSinceSiteStart.bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'workingDaysSinceSiteStart');
    this.addToColumns('workingDaysSinceSiteStart', 'Working days since Site Start', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1],
      this.calculateWorkingDaysSinceSiteStart.bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'contractsRequiredByDate');
    this.addToColumns('contractsRequiredByDate', 'Contracts Required By', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const job = this.jobsMap.get(param.jobId);const dateValue = job ? job.contractsRequiredByDate : undefined;return dateValue ? this.utilsService.getDateFromDateOrString(dateValue) : undefined;`).bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'calendarDaysToContractsRequired');
    this.addToColumns('calendarDaysToContractsRequired', 'Calendar days to contracts required', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1],
      new Function('param', `const job = this.jobsMap.get(param.jobId);if (job?.contractsRequiredByDate) {return this.utilsService.getCalendarDaysDifference(job.contractsRequiredByDate, this.currentDate);}return null;`).bind(this)
    );

    // bank details
    res = this.checkStoredCol(state?.columns, 'bankDescriptionPlusEmail');
    this.addToColumns('bankDescriptionPlusEmail', 'Bank Details', 'string', '', 'left',
      noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobService.jobBanksMap.get(param.jobId)?.bankDescriptionPlusEmail;').bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'loanReference');
    this.addToColumns('loanReference', 'Loan Reference', 'string', '', 'left',
      noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobService.jobBanksMap.get(param.jobId)?.loanReference;').bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'loanAmount');
    this.addToColumns('loanAmount', 'Loan Amount', 'number', '#,###', 'right',
      noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobService.jobBanksMap.get(param.jobId)?.loanAmount;').bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'clientLoginEmail');
    this.addToColumns('clientLoginEmail', 'Client Login Email', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobService.jobClientLoginsMap.get(param.jobId)?.email;').bind(this)
    );

    res = this.checkStoredCol(state?.columns, 'sendInfoUpdateEmail');
    this.addToColumns('sendInfoUpdateEmail', 'Send email to client re updates to portal', 'boolean', '', 'center', noState ? false : res[0], noState ? false : res[1],
      new Function('param', 'return this.jobService.jobClientLoginsMap.get(param.jobId)?.sendInfoUpdateEmail ?? false;').bind(this)
    );

    // roles
    this.taskService.companyRoles.forEach(companyRole => {
      if (companyRole.isActive) {
        const id = 'companyRole' + companyRole.id;
        res = this.checkStoredCol(state?.columns, id);
        this.addToColumns(id, companyRole.companyRoleDescription, 'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
          new Function(
            'param',
            `return this.taskService.allJobRolesMap.get(this.taskService.getKeyFromNumbers(param.jobId, ${companyRole.roleId}))?.user?.fullName;`
          ).bind(this));
      }
    });

    // tracking fields
    let dataType = 'string';
    let dataFormat = 'd-MMM-yy';
    let dataAlignment;

    this.trackingFieldsService.trackingFields.filter(i => i.isActive && i.trackingFieldGroupIsActive).forEach(field => {
      switch (field.trackingFieldTypeId) {
        case TrackingFieldTypeEnum.Number:
          dataType = 'number';
          dataFormat = 'fixedPoint';
          dataAlignment = 'center';
          break;
        case TrackingFieldTypeEnum.Date:
          dataType = 'date';
          dataFormat = 'd-MMM-yy';
          dataAlignment = 'center';
          break;
        case TrackingFieldTypeEnum.Calculated:
          dataType = 'date';
          dataFormat = 'd-MMM-yy';
          dataAlignment = 'center';
          break;
        case TrackingFieldTypeEnum.Time:
          dataType = 'datetime';
          dataFormat = 'shortTime';
          dataAlignment = 'center';
          break;
        case TrackingFieldTypeEnum.Boolean:
          dataType = 'string';
          dataFormat = '';
          dataAlignment = 'center';
          break;
        default:
          dataType = 'string';
          dataFormat = '';
          dataAlignment = 'left';
      }
      const id = 'field' + field.id;
      res = this.checkStoredCol(state?.columns, id);
      if (field.trackingFieldTypeId === TrackingFieldTypeEnum.Calculated) {
        this.addToColumns(id, field.fieldName, dataType, dataFormat, dataAlignment, noState ? false : res[0], noState ? false : res[1],
          new Function(
            'rowData',
            `return this.taskService.calculateField(rowData.jobId, ${field.id});`
          ).bind(this)
        );
      }
      else if (field.trackingFieldTypeId === TrackingFieldTypeEnum.Date) {
        this.addToColumns(id, field.fieldName, dataType, dataFormat, dataAlignment, noState ? false : res[0], noState ? false : res[1],
          new Function(
            'rowData',
            `
              const key = rowData.jobId + '-${field.id}';
              const jobField = this.trackingFieldsService.jobFieldsMap.get(key);
              if (jobField && jobField.textValue) {
                const parsedDate = this.utilsService.getDateFromDateOrString(jobField.textValue);
                return parsedDate || null;
              } else {
                return null;
              }
            `
          ).bind(this),

          new Function(
            'rowData',
            'value',
            'currentRowData',
            `
              const key = currentRowData.jobId + '-${field.id}';
              const jobField = this.trackingFieldsService.jobFieldsMap.get(key);
              if (jobField) {
                jobField.textValue = value;
              }
              rowData['${id}'] = value;
            `
          ).bind(this)

        );
      }
      else {
        this.addToColumns(id, field.fieldName, dataType, dataFormat, dataAlignment, noState ? false : res[0], noState ? false : res[1],
          new Function(
            'rowData',
            `
              const key = rowData.jobId + '-${field.id}';
              const jobField = this.trackingFieldsService.jobFieldsMap.get(key);
              if (jobField) {
                return jobField.textValue;
              } else {
                return null;
              }
            `
          ).bind(this),

          new Function(
            'rowData',
            'value',
            'currentRowData',
            `
              const key = currentRowData.jobId + '-${field.id}';
              const jobField = this.trackingFieldsService.jobFieldsMap.get(key);
              if (jobField) {
                jobField.textValue = value;
              }
              rowData['${id}'] = value;
            `
          ).bind(this)
        );
      }
    });


    // tasks
    this.maintenanceService.taskMasters.forEach(taskMaster => {
      const inId = 'taskIn' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, inId);
      this.addToColumns(inId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' In',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
        new Function('rowData', `
          const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(rowData.jobId, ${taskMaster.id}));
          if (jobTask && jobTask.startDate) {
            const date = new Date(jobTask.startDate);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return this.utilsService.getDateFromDateOrString(utcDate);
          }
          return null;
        `).bind(this)
      );
      const dueId = 'taskDue' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, dueId);
      this.addToColumns(dueId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Due',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
        new Function('rowData', `
          const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(rowData.jobId, ${taskMaster.id}));
          if (jobTask?.dueDate) {
            const date = new Date(jobTask.dueDate);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return this.utilsService.getDateFromDateOrString(utcDate);
          }
          return null;
        `).bind(this),
        new Function('rowData', 'value', 'currentRowData', `
          const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(currentRowData.jobId, ${taskMaster.id}));
          jobTask.dueDate = value;
          rowData['${dueId}'] = value;
        `).bind(this)
      );
      const outId = 'taskOut' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, outId);
      this.addToColumns(outId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Out',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
        new Function('rowData', `
          const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(rowData.jobId, ${taskMaster.id}));
          if (jobTask && jobTask.endDate) {
            const date = new Date(jobTask.endDate);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return this.utilsService.getDateFromDateOrString(utcDate);
          }
          return null;
        `).bind(this)
      );
      const sentId = 'taskSent' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, sentId);
      this.addToColumns(sentId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Sent',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
        new Function('rowData', `
          const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(rowData.jobId, ${taskMaster.id}));
          if (jobTask && jobTask.calledDate) {
            const date = new Date(jobTask.calledDate);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return this.utilsService.getDateFromDateOrString(utcDate);
          }
          return null;
        `).bind(this)
      );
      const cId = 'taskComment' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, cId);
      this.addToColumns(cId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Comment',
        'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
        new Function('rowData', `
          const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(rowData.jobId, ${taskMaster.id}));
          return jobTask ? jobTask.officeComment : null;
        `).bind(this)
        , new Function('rowData', 'value', 'currentRowData', `
          const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(currentRowData.jobId, ${taskMaster.id}));
          jobTask.officeComment = value;
          rowData['${cId}'] = value;
        `).bind(this),
      );

      const prevInId = 'taskPrevIn' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, prevInId);
      this.addToColumns(prevInId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Previous In',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
        new Function('rowData', `
          const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(rowData.jobId, ${taskMaster.id}));
          if (jobTask && jobTask.prevStartDate) {
            const date = new Date(jobTask.prevStartDate);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return this.utilsService.getDateFromDateOrString(utcDate);
          }
          return null;
        `).bind(this)
      );

      const aId = 'taskAssignee' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, aId);
      this.addToColumns(aId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Assignee',
        'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
        new Function('rowData', `
          const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(rowData.jobId, ${taskMaster.id}));
          if (jobTask && jobTask.userId) {
            const assignee = this.userService.usersMap.get(jobTask.userId);
            return assignee ? assignee.fullName : '';
          }
          return null;
        `).bind(this)
      );

      const vId = 'taskVendor' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, vId);
      this.addToColumns(vId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Vendor',
        'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
        new Function('rowData', `
          const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(rowData.jobId, ${taskMaster.id}));
          if (jobTask && jobTask.vendorId) {
            const vendor = this.userService.vendorsMap.get(jobTask.vendorId);
            return vendor ? vendor.vendorName : '';
          }
          return null;
        `).bind(this)
      );

      const statusId = 'taskStatus' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, statusId);
      this.addToColumns(statusId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Status',
        'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
        new Function('rowData', `
          const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(rowData.jobId, ${taskMaster.id}));
          if (jobTask) {
            const status = this.taskStatusMap.get(jobTask.statusId);
            return status ? status.status : null;
          }
          return null;
        `).bind(this)
      );


      const forecastInId = 'taskForecastIn' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, forecastInId);
      this.addToColumns(forecastInId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Forecast In',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
        new Function('rowData', `
          const forecastTask = this.forecastTasksMap.get(rowData.jobId + '-${taskMaster.id}');
          if (forecastTask && forecastTask.forecastStartDate) {
            const date = new Date(forecastTask.forecastStartDate);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return this.utilsService.getDateFromDateOrString(utcDate);
          }
          return null;
        `).bind(this)
      );

      const forecastOutId = 'taskForecastOut' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, forecastOutId);
      this.addToColumns(forecastOutId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Forecast Out',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
        new Function('rowData', `
          const forecastTask = this.forecastTasksMap.get(rowData.jobId + '-${taskMaster.id}');
          if (forecastTask && forecastTask.forecastCompletionDate) {
            const date = new Date(forecastTask.forecastCompletionDate);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return this.utilsService.getDateFromDateOrString(utcDate);
          }
          return null;
        `).bind(this)
      );

      const targetInId = 'taskTargetIn' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, targetInId);
      this.addToColumns(targetInId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Target In',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
        new Function('rowData', `
          const forecastTask = this.forecastTasksMap.get(rowData.jobId + '-${taskMaster.id}');
          if (forecastTask && forecastTask.targetStartDate) {
            const date = new Date(forecastTask.targetStartDate);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return this.utilsService.getDateFromDateOrString(utcDate);
          }
          return null;
        `).bind(this)
      );

      const targetOutId = 'taskTargetOut' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, targetOutId);
      this.addToColumns(targetOutId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Target Out',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1],
        new Function('rowData', `
          const forecastTask = this.forecastTasksMap.get(rowData.jobId + '-${taskMaster.id}');
          if (forecastTask && forecastTask.targetCompletionDate) {
            const date = new Date(forecastTask.targetCompletionDate);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return this.utilsService.getDateFromDateOrString(utcDate);
          }
          return null;
        `).bind(this)
      );

      const modifiedUserId = 'taskModifiedUser' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, modifiedUserId);
      this.addToColumns(modifiedUserId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Modified By',
        'string', '', 'left', noState ? false : res[0], noState ? false : res[1],
        new Function('rowData', `
          const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(rowData.jobId, ${taskMaster.id}));
          if (jobTask && jobTask.modifiedUserId) {
            const user = this.userService.usersMap.get(jobTask.modifiedUserId);
            return user ? user.fullName : '';
          }
          return null;
        `).bind(this)
      );
    });


  }

  generateGridRows() {
    console.log('generateGridRows ' + new Date());
    this.reportGridData = [];

    this.jobs.forEach(job => {
      const reportGridRow = {};
      reportGridRow['jobId'] = job.id;
      reportGridRow['isActive'] = job.isActive ? 'Yes' : 'No';
      this.reportGridData.push(reportGridRow);
    });

    this.setUpDataSource();
  }

  addToColumns(
    dataField: string,
    caption: string,
    dataType: string,
    format: string,
    alignment: 'left' | 'right' | 'center',
    inGrid: boolean,
    visible: boolean,
    calculateCellValue: any = null,
    setCellValue: any = null

  ) {
    const col = {
      dataField: dataField,
      caption: caption,
      dataType: dataType,
      format: format,
      alignment: alignment,
      visible
    };

    if (dataType === 'date') {
      col['sortingMethod'] = this.dateSort;
    }

    if (dataType === 'string') {
      col['cellTemplate'] = 'commentCellTemplate';
    }


    if (dataField.length >= 5 && dataField.substring(0, 5) === 'field') {
      col['allowEditing'] = true;
    } else if (dataField.length >= 7 && dataField.substring(0, 7) === 'taskDue') {
      col['allowEditing'] = true;
    } else if (dataField === 'titleDueDate') {
      col['allowEditing'] = true;
    } else if (dataField.length >= 11 && dataField.substring(0, 11) === 'taskComment') {
      col['allowEditing'] = true;
    } else {
      col['allowEditing'] = false;
    }

    if (dataField === 'jobNumber') {
      col['fixed'] = true;
    }



    if (calculateCellValue) {
      col['calculateCellValue'] = calculateCellValue;
    }
    if (setCellValue) {
      col['setCellValue'] = setCellValue;
    }

    // col['allowSearch'] = true;

    if (inGrid) {
      this.reportGridService.gridColumns.push(col);
    } else {
      this.reportGridService.extraColumns.push(col);
    }

  }


  setUpDataSource() {
    console.log('setUpDataSource ' + new Date());
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 300); // grid wait to force refresh

    this.loadingState = false;

    this.dataSource = new CustomStore({
      key: 'jobId',
      load: async () => {
        return new Promise((resolve, reject) =>
          of(this.reportGridData).subscribe({
            next: (res) => {
              const data = res.filter(i => i.isActive === 'Yes' || this.showInactive);
              console.log('returning data filtered ' + new Date());
              return resolve(data);
            },
            error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values: object) => {
        const fieldKeys = Object.keys(values);

        return new Promise((resolve, reject) => {
          fieldKeys.forEach(fieldKey => {
            if (fieldKey.substring(0, 7) === 'taskDue') {
              const fieldNumber = fieldKey.substring(7);

              const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(key, +fieldNumber));
              if (jobTask) {
                const recordToUpdate: any = {};
                recordToUpdate.dueDate = values[fieldKey];
                this.taskService.updateJobTask(jobTask.id.toString(), recordToUpdate).subscribe({
                  next: (res) => {
                    // for recalculations
                    const jobExists = this.jobsToUpdateMap.get(key);
                    if (!jobExists) {
                      this.jobsToUpdate.push(key);
                    }
                    return resolve(res);
                  }, error: (err) => {
                    return reject(this.globalService.returnError(err));
                  }
                });
              } else {
                const taskMaster = this.maintenanceService.taskMastersMap.get(+fieldNumber);
                return reject((taskMaster?.taskTitle ?? '') + ' Task does not exist. Create from Tasks tab if required');
              }
            } else if (fieldKey.substring(0, 11) === 'taskComment') {
              const fieldNumber = fieldKey.substring(11);

              const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(key, +fieldNumber));
              if (jobTask) {
                this.taskService.updateJobTask(jobTask.id.toString(), { officeComment: values[fieldKey] }).subscribe({
                  next: (res) => {
                    jobTask.officeComment = values[fieldKey];
                    return resolve(res);
                  }, error: (err) => {
                    return reject(this.globalService.returnError(err));
                  }
                });
              } else {
                const taskMaster = this.maintenanceService.taskMastersMap.get(+fieldNumber);
                return reject((taskMaster?.taskTitle ?? '') + ' Task does not exist. Create from Tasks tab if required');
              }
            } else if (fieldKey.substring(0, 5) === 'field') {
              const fieldNumber = fieldKey.substring(5);

              this.trackingFieldsService.updateJobField(key, fieldNumber, { textValue: values[fieldKey] }).subscribe({
                next: (res) => {
                  return resolve(res);
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
            } else if (fieldKey === 'titleDueDate') {
              const job = this.jobsMap.get(key);
              this.jobService.updateJob(job.jobNumber, { titleDueDate: values[fieldKey] }).subscribe({
                next: (res) => {
                  return resolve(res);
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
            }
          });
        });
      }
    });
  }

  onToolbarPreparing(e, templateName: string, toolbarTemplate2) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName
    });

    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxCheckBox',
        options: {
          text: 'Show Inactive Jobs',
          value: this.showInactive,
          rtlEnabled: true,
          onValueChanged: this.showInactiveChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        template: toolbarTemplate2
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'columnchooser',
          hint: 'Choose Columns',
          onClick: this.columnChooser.bind(this)
        }
      });
  }

  refresh() {
    this.getData(false);
  }

  saveState() {
    this.currentState = JSON.parse(localStorage.getItem('allTaskGrid'));

    this.currentState['showInactive'] = this.showInactive;


    if (this.filterValue) {
      this.currentState.filterValue = this.filterValue;
    }

    const modalRef = this.modalService.open(StateStoreComponent, { windowClass: 'modal-stateStore', scrollable: true });
    modalRef.componentInstance.stateStoreTypeId = StateStoreTypeEnum.AllTaskGrid;
    modalRef.componentInstance.stateString = JSON.stringify(this.currentState);

    modalRef.result.then((state: StateStore) => {
      this.loadingState = true;
      this.loading = true;

      if (state) {
        this.reportName = state.description;
        this.currentState = JSON.parse(state.stateString) as DxGridState;

        this.getStateVariables(this.currentState);

        try {
          localStorage.setItem('allTaskGrid', JSON.stringify(this.currentState));
        } catch (err) {
          this.notiService.showError('Error loading layout. Please try again');
          console.error(err);
          this.currentState = null;
          this.reportName = '';
          localStorage.removeItem('allTaskGrid');
        }
      } else {
        // load default
        this.currentState = null;
        this.reportName = '';
        localStorage.removeItem('allTaskGrid');
      }

      this.getData(true);
    });
  }

  onRowPrepared(e) {
    if (e.rowType === 'data') {
      // On Hold rows = pink
      const jobExtra = this.jobService.jobExtrasMap.get(e.data.jobId);
      if (jobExtra && (jobExtra.isOnHold || jobExtra.cancellationDate)) {
        if (jobExtra.cancellationDate) {
          e.rowElement.style.backgroundColor = 'rgb(250, 140, 160)';
        } else {
          e.rowElement.style.backgroundColor = 'pink';
        }
      } else {
        if (jobExtra && jobExtra.trackingColour && jobExtra.trackingColour !== '0') {
          e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
          if (jobExtra.trackingColour === TrackingColourEnum.Orange.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(253, 209, 127, 0.6)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Yellow.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(253, 253, 139, 0.6)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Teal.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(132, 247, 237, 0.6)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Blue.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(147, 198, 247, 0.4)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Magenta.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(208, 157, 250, 0.6)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Grey.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(0, 0, 0, 0.2)';
          }
        }
        // Overdue date cells = red
        let key = '';
        // for (key in e.columns) {
        e.columns.forEach(column => {
          // if (e.data.hasOwnProperty(key)) {
          if (column.dataField) {
            const dataField = column.dataField;
            if (dataField.substring(0, 7) === 'taskDue') {
              // do we have an end date
              const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(e.key, +dataField.substring(7)));
              if (jobTask?.dueDate) {
                if (!jobTask?.endDate) {
                  // we can check if the due date is in the past
                  const dueString = jobTask.dueDate.toString().substring(0, 10);
                  const cellsToChange = e.cells.filter(i => i.column.name === dataField);

                  if (dueString < this.todayString) {
                    cellsToChange.forEach(cell => {
                      if (cell.column.name.substring(0, 7) === 'taskOut') {
                        cell.cellElement?.classList?.add('redBackground');
                      } else {
                        cell.cellElement?.classList?.add('red-bold');
                      }
                    });
                  }
                }
              }
            }

            if (dataField.substring(0, 7) === 'taskOut') {
              // do we have an end date
              const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(e.key, +dataField.substring(7)));
              if (jobTask?.dueDate) {
                if (!jobTask?.endDate) {
                  // we can check if the due date is in the past
                  const dueString = jobTask.dueDate.toString().substring(0, 10);
                  const cellsToChange = e.cells.filter(i => i.column.name === dataField);

                  if (dueString >= this.todayString) {
                    cellsToChange.forEach(cell => {
                      cell.cellElement?.classList?.add('lightGreenBackground');
                    });
                  } else {
                    cellsToChange.forEach(cell => {
                      cell.cellElement?.classList?.add('redBackground');
                    });
                  }
                }
              }
            }

            if (dataField.substring(0, 14) === 'taskForecastIn') {
              const jobId = e.key;
              const taskMasterId = +dataField.substring(14);
              const key = `${jobId}-${taskMasterId}`;
              const jobTask = this.forecastTasksMap.get(key);
              const jobTaskIn = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(e.key, +dataField.substring(14)));

              if (!jobTaskIn?.startDate) {
                if (jobTask?.forecastStartDate) {
                  const forecastString = jobTask.forecastStartDate.toString().substring(0, 10);
                  const cellsToChange = e.cells.filter(i => i.column.name === dataField);

                  if (forecastString < this.todayString) {
                    cellsToChange.forEach(cell => {
                      cell.cellElement?.classList?.add('red-bold');

                    });
                  }
                }
              }
            }

            if (dataField.substring(0, 15) === 'taskForecastOut') {
              const jobId = e.key;
              const taskMasterId = +dataField.substring(15);
              const key = `${jobId}-${taskMasterId}`;

              const jobTask = this.forecastTasksMap.get(key);

              const jobTaskOut = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(e.key, +dataField.substring(15)));

              if (!jobTaskOut?.endDate) {
                if (jobTask.forecastCompletionDate) {
                  const forecastString = jobTask.forecastCompletionDate.toString().substring(0, 10);
                  const cellsToChange = e.cells.filter(i => i.column.name === dataField);

                  if (forecastString < this.todayString) {
                    cellsToChange.forEach(cell => {
                      cell.cellElement?.classList?.add('red-bold');

                    });
                  }
                }
              }
            }


            if (dataField.startsWith('taskIn') || dataField.startsWith('taskOut')) {
              const taskMasterId = parseInt(dataField.replace('taskIn', '').replace('taskOut', ''), 10);
              const jobId = e.key;
              const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(jobId, taskMasterId));

              if (jobTask) {
                const status = this.taskStatusMap.get(jobTask.statusId)?.status;
                const cellsToChange = e.cells.filter(i => i.column.name === dataField);
                if (status === 'Not Applicable') {
                  cellsToChange.forEach(cell => {
                    cell.cellElement?.classList?.add('greyBackground');
                  });
                }
              }
            }


            // Check the contractual completion date
            if (dataField.substring(0, 22) === 'forecastCompletionDate') {
              const jobId = e.data.jobId;
              const jobCashFlow = this.jobCashFlowService.jobCashFlowsMap.get(jobId);
              const jobExtra = this.jobService.jobExtrasMap.get(jobId);

              const currentContractualCompletionDate = this.jobService.calculateContractualCompletion(jobCashFlow?.originalContractualCompletion, jobId);
              const forecastCompletionDate = jobExtra?.forecastCompletionDate;

              if (currentContractualCompletionDate && forecastCompletionDate) {
                const notOverdue = this.utilsService.convertDateToString(currentContractualCompletionDate) >= this.utilsService.convertDateToString(forecastCompletionDate);
                const cellsToChange = e.cells.filter(cell => cell.column.dataField === dataField);

                cellsToChange.forEach(cell => {
                  if (notOverdue) {
                    cell.cellElement?.classList?.add('green');
                  } else {
                    cell.cellElement?.classList?.add('red-bold');
                  }
                });
              }
            }


            // Check the calculated date fields
            if (dataField.substring(0, 5) === 'field') {
              const fieldId = dataField.substring(5);

              const calcField = this.trackingFieldsService.trackingFieldsMap.get(+fieldId);
              if (calcField?.trackingCalculationFieldId) {
                const dateString = this.taskService.calculateField(e.key, fieldId);
                if (dateString) {
                  const fieldDate = this.utilsService.convertDateToString(new Date(dateString));
                  const cellsToChange = e.cells.filter(cell => cell.column.dataField === dataField);


                  cellsToChange.forEach(cell => {
                    if (fieldDate < this.todayString) {
                      cell.cellElement?.classList?.add('red-bold');
                    }
                  });
                }
              }
            }







          }
        });
      }
    }
  }

  dateSort = (one, two): number => {
    if (!one && !two) { return 0; }
    if (!one && two) {
      return this.lastColSortDirectionAscending ? 1 : -1;
    }
    if (one && !two) {
      return this.lastColSortDirectionAscending ? -1 : 1;
    }
    if (one < two) { return -1; }
    if (one > two) { return 1; }
    return 0;
  }

  gridOptionChanged(e) {
    if (e.value === 'asc') {
      this.lastColSortDirectionAscending = true;
    } else if (e.value === 'desc') {
      this.lastColSortDirectionAscending = false;
    }
  }

  onExporting(e) {
    this.gridService.onExporting(e, this.reportName && this.reportName !== '' ? this.reportName : 'report-grid');
  }

  columnChooser() {
    const currentState = JSON.parse(localStorage.getItem('allTaskGrid'));

    const modalRef = this.modalService.open(ColumnChooserComponent, { windowClass: 'modal-1400' });
    modalRef.result.then(() => {
      this.loading = true;
      this.setState(currentState);

      // refresh the grid
      setTimeout(() => {
        this.loading = false;
      }, 300); // grid wait to force refresh
    });
  }

  setState(currentState: any) {
    // check that all columns exist in the users view - remember that some may be admin tracking fields
    localStorage.removeItem('allTaskGrid');
    if (currentState) {
      const newColumns = [];
      let filterValue = currentState.filterValue;
      currentState.columns.forEach(element => {
        const gridColumn = this.reportGridService.gridColumnsMap.get(element.dataField);
        if (gridColumn !== undefined) {
          if (gridColumn?.visible) {
            element.visible = true;
          } else {
            element.visible = false;
          }
          newColumns.push(element);
        } else {
          // we may need to take out of the filter
          if (JSON.stringify(filterValue).includes(element.dataField)) {
            filterValue = null;
          }
        }
      });

      currentState.columns = newColumns;
      currentState.filterValue = filterValue;
      this.getStateVariables(currentState);
      localStorage.setItem('allTaskGrid', JSON.stringify(currentState));
    }
  }

  onContentReady() {
    // console.log('Grid ready ' + new Date().toLocaleTimeString());
  }

  goToJobData(e) {
    if (e.row.data.jobId) {
      this.jobService.setCurrentJob(this.jobsMap.get(e.row.data.jobId)?.jobNumber);
      localStorage.setItem('jobANNX-costcentreId', e.row.data.costCentreId);
      this.globalService.setAreaSelected('job-data');
      this.router.navigateByUrl('/job-data');
    }
  }

  onEditorPreparing(e: any) {
    if (e.parentType !== 'dataRow') {
      return;
    } else {
      this.dataFieldForEdit = e;

      if (e.dataField.length >= 7 && e.dataField.substring(0, 7) === 'taskDue') {
        // can edit only if task exists
        const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(e.row.data.jobId, +e.dataField.substring(7)));
        if (jobTask) {
          e.editorName = 'dxDateBox';
          e.editorOptions = {
            type: 'date',
            showClearButton: true,
            displayFormat: 'd-MMM-yy',
            onValueChanged: this.onDateValueChanged.bind(this),
            value: this.adhocSelection
          };
        } else {
          this.notiService.showInfo('Task does not exist. Create from Tasks tab if required');
          e.editorOptions.disabled = true;
        }
      } else if (e.dataField === 'titleDueDate') {
        e.editorName = 'dxDateBox';
        e.editorOptions = {
          type: 'date',
          showClearButton: true,
          displayFormat: 'd-MMM-yy',
          onValueChanged: this.onDateValueChanged.bind(this),
          value: this.adhocSelection
        };

      } else if (e.dataField.length >= 11 && e.dataField.substring(0, 11) === 'taskComment') {
        // can edit only if task exists
        const jobTask = this.taskService.jobTasksMap.get(this.taskService.createJobAndTaskMasterKey(e.row.data.jobId, +e.dataField.substring(11)));
        if (jobTask) {
          e.editorName = 'dxTextArea';
          e.editorOptions.autoResizeEnabled = true;
          let prevHeight = null;
          e.editorOptions.onInput = (args) => {
            const td = args.element.closest('td');
            if (td && prevHeight !== td.offsetHeight) {
              const overlay = e.element.querySelector('.dx-datagrid-focus-overlay');
              if (overlay != null) {
                overlay.style.height = (td.offsetHeight + 1) + 'px';
              }
              prevHeight = td.offsetHeight;
            }
          };
        } else {
          this.notiService.showInfo('Task does not exist. Create from Tasks tab if required');
          e.editorOptions.disabled = true;
        }
      } else if (e.dataField.length >= 5 && e.dataField.substring(0, 5) === 'field') {
        const fieldNumber = e.dataField.substring(5);
        const trackingField = this.trackingFieldsService.trackingFieldsMap.get(+fieldNumber);

        if (trackingField.trackingFieldTypeId === TrackingFieldTypeEnum.Text) {
          e.editorName = 'dxTextArea';
          e.editorOptions.autoResizeEnabled = true;
          let prevHeight = null;
          e.editorOptions.onInput = (args) => {
            const td = args.element.closest('td');
            if (td && prevHeight !== td.offsetHeight) {
              const overlay = e.element.querySelector('.dx-datagrid-focus-overlay');
              if (overlay != null) {
                overlay.style.height = (td.offsetHeight + 1) + 'px';
              }
              prevHeight = td.offsetHeight;
            }
          };
        } else if (trackingField.trackingFieldTypeId === TrackingFieldTypeEnum.Number) {
          e.editorName = 'dxNumberBox';
          e.editorOptions.showSpinButtons = true;
          e.editorOptions.showClearButton = true;
        } else if (trackingField.trackingFieldTypeId === TrackingFieldTypeEnum.Date) {
          e.editorName = 'dxDateBox';
          e.editorOptions = {
            type: 'date',
            showClearButton: true,
            displayFormat: 'd-MMM-yy',
            onValueChanged: this.onDateValueChanged.bind(this),
            value: this.adhocSelection
          };
        } else if (trackingField.trackingFieldTypeId === TrackingFieldTypeEnum.Lookup
          || trackingField.trackingFieldTypeId === TrackingFieldTypeEnum.Boolean) {

          if (trackingField.trackingFieldTypeId === TrackingFieldTypeEnum.Lookup) {
            this.lookupList = this.trackingFieldsService.allTrackingFieldLookups.filter(i => i.trackingFieldId === +fieldNumber);

            if (e.value && e.value.length) {
              const foundSelection = this.lookupList.find(i => i.description === e.value);
              if (!foundSelection) {
                // asdd it to the list
                this.addToList(e.value, trackingField.trackingFieldTypeId);
              }
            }
          } else if (trackingField.trackingFieldTypeId === TrackingFieldTypeEnum.Boolean) {
            this.lookupList = [];

            this.adhocItem = new TrackingFieldLookup(1, 'Yes', 1, 0);
            this.lookupList.push(this.adhocItem);
            this.adhocItem = new TrackingFieldLookup(2, 'No', 2, 0);
            this.lookupList.push(this.adhocItem);
            this.adhocItem = new TrackingFieldLookup(3, 'N/A', 3, 0);
            this.lookupList.push(this.adhocItem);

            if (e.value && e.value.length) {
              const foundSelection = this.lookupList.find(i => i.description === e.value);
              if (!foundSelection) {
                // asdd it to the list
                this.adhocItem = new TrackingFieldLookup(4, e.value, 4, 0);
                this.lookupList.push(this.adhocItem);
              }
            }
          }

          e.editorName = 'dxSelectBox';
          e.editorOptions = {
            dataSource: this.lookupList,
            searchEnabled: true,
            acceptCustomValue: true,
            valueExpr: 'description',
            displayExpr: 'description',
            showClearButton: true,
            onValueChanged: this.onLookupValueChanged.bind(this),
            onCustomItemCreating: this.addCustomItem.bind(this),
            value: this.adhocSelection
          };
        } else if (trackingField.trackingFieldTypeId === TrackingFieldTypeEnum.Calculated) {
          e.editorOptions.disabled = true;
        }
      }
    }
  }

  onDateValueChanged(e) {
    if (e.value instanceof Date) {
      this.adhocSelection = this.utilsService.convertDateToString(e.value);
    } else {
      this.adhocSelection = e.value;
    }
    this.dataFieldForEdit.setValue(this.adhocSelection);
  }

  onLookupValueChanged(e) {
    this.adhocSelection = e.value;
    this.dataFieldForEdit.setValue(this.adhocSelection);
  }

  addCustomItem(data, trackingFieldId: number) {
    if (!data.text) {
      data.customItem = null;
      return;
    }

    this.addToList(data.text, trackingFieldId);
    this.adhocSelection = data.text;
    this.dataFieldForEdit.setValue(this.adhocSelection);
    data.customItem = this.adhocItem;
  }

  addToList(adhocText: string, trackingFieldId: number) {
    const productIds = this.lookupList.map(function (item) {
      return item.id;
    });
    const incrementedId = Math.max.apply(null, productIds) + 1;

    this.adhocItem = new TrackingFieldLookup(incrementedId, adhocText, incrementedId, trackingFieldId);
    this.lookupList.push(this.adhocItem);
  }

  onEditingStart(e) {
    if (e.column.dataField.length >= 5 && e.column.dataField.substring(0, 5) === 'field') {
      this.adhocSelection = e.data.textValue; // set
    } else if (e.column.dataField.length >= 7 && e.column.dataField.substring(0, 7) === 'taskDue') {
      this.adhocSelection = e.data[e.column.dataField] && Date.parse(e.data[e.column.dataField]) ? e.data[e.column.dataField] : null
    } else if (e.column.dataField.length >= 11 && e.column.dataField.substring(0, 11) === 'taskComment') {
      this.adhocSelection = e.data.textValue;
    }
    else if (e.column.dataField === 'titleDueDate') {
      this.adhocSelection = e.data[e.column.dataField] && Date.parse(e.data[e.column.dataField]) ? e.data[e.column.dataField] : null;
    }
  }

  changEditMode() {
    if (this.dataGrid.instance.hasEditData() && this.inEditMode) {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      this.inEditMode = !this.inEditMode;

      if (this.inEditMode) {
        this.notiService.showInfo('Edit mode now on. Edit start and completion dates via tasks tab. Edit task comments, due dates and tracking field data');
      }
    }
  }

  onSaved() {
    // recalc the current activity and forecast
    if (this.jobsToUpdate.length) {
      this.jobsToUpdate.forEach(jobId => {
        this.subscriptions.push(
          this.taskService.calcCurrentActivity(jobId)
            .subscribe({
              next: () => {
              },
              error: (err) => {
                this.notiService.notify(err);
              }
            })
        );
      });
      this.jobsToUpdate = [];
    }
  }

  calculateCellValue(rowData) {
    const column = this as any;
    console.log(column);
    return column.defaultCalculateCellValue(rowData);
  }


  calculateSiteAddress = (rowData) => {
    return this.globalService.getJobString(this.jobsMap.get(rowData.jobId))
  }
  calculateMasterJob = (rowData) => {
    const job = this.jobsMap.get(rowData.jobId);
    if (job.masterJobId) {
      return this.jobsMap.get(job.masterJobId)?.jobNumber;
    }
    return '';
  }
  calculateIsMasterJob = (rowData) => {
    return this.jobsMap.get(rowData.jobId) ? 'Master' : '';
  }
  calculateJobType = (rowData) => {
    const job = this.jobsMap.get(rowData.jobId);
    return this.jobTypes.find(i => i.id === job.jobTypeId).description;
  }
  calculateCurrentActivity = (rowData) => {
    const jobExtra = this.jobService.jobExtrasMap.get(rowData.jobId);
    if (jobExtra && jobExtra.maintenanceCompleteDate) {
      return 'Maintenance Complete';
    } else if (jobExtra && jobExtra.currentActivityId) {
      const currentActivity = this.maintenanceService.activitiesMap.get(jobExtra.currentActivityId);
      return currentActivity ? currentActivity.description : '';
    } else {
      const job = this.jobsMap.get(rowData.jobId);
      if (job.salesDate) {
        return '';
      } else {
        return 'Pending Sale';
      }
    }
  }
  calculateCurrentActivityCode = (rowData) => {
    const jobExtra = this.jobService.jobExtrasMap.get(rowData.jobId);
    if (jobExtra && jobExtra.maintenanceCompleteDate) {
      return '';
    } else if (jobExtra && jobExtra.currentActivityId) {
      const currentActivity = this.maintenanceService.activitiesMap.get(jobExtra.currentActivityId);
      return currentActivity ? currentActivity.activityCode : '';
    } else {
      return '';
    }
  }

  calculateWorkingDaysSinceSiteStart(rowData: any): number | null {
    const jobId = rowData.jobId;
    const key = jobId.toString() + '-2';
    const constructionWorkflow = this.jobWorkFlowService.jobWorkFlowsByJobAndTypeMap.get(key);
    // const siteStartTasks = this.maintenanceService.allTemplateTasks.filter(i => i.isForecastStart);
    // const siteStartTaskForJob = siteStartTasks?.find(i => i.templateTaskHeaderId === constructionWorkflow?.templateTaskHeaderId);
    const siteStartTaskForJob = this.maintenanceService.allTemplateTasksMap.get(constructionWorkflow?.templateTaskHeaderId).find(i => i.isForecastStart);
    const forecastStartDate = this.forecastTasksMap.get(jobId + `-${siteStartTaskForJob?.taskMasterId}`)?.forecastStartDate;
    if (forecastStartDate) {
      return this.maintenanceService.getCalendarDaysDifferenceExHolidays(this.currentDate, forecastStartDate);
    }
    return null;
  }

  calculateCalendarDaysSinceSiteStart(rowData: any): number | null {
    const jobId = rowData.jobId;
    const key = jobId.toString() + '-2';
    const constructionWorkflow = this.jobWorkFlowService.jobWorkFlowsByJobAndTypeMap.get(key);
    const siteStartTasks = this.maintenanceService.allTemplateTasks.filter((i) => i.isForecastStart);
    const siteStartTaskForJob = siteStartTasks?.find(
      (i) => i.templateTaskHeaderId === constructionWorkflow?.templateTaskHeaderId
    );
    // const forecastsForJob = this.forecastTasks.filter((i) => i.jobId === jobId);
    // const forecastStartDate = forecastsForJob?.find(
    //   (i) => i.taskMasterId === siteStartTaskForJob?.taskMasterId
    // )?.forecastStartDate;
    const forecastStartDate = this.forecastTasksMap.get(jobId + `-${siteStartTaskForJob?.taskMasterId}`)?.forecastStartDate;
    if (forecastStartDate) {
      return this.utilsService.getCalendarDaysDifference(this.currentDate, forecastStartDate);
    }
    return null;
  }

  calculateNextClaimDate(rowData: any): Date | undefined {
    const jobId = rowData.jobId;
    const nextClaim = this.jobService.nextClaimsMap.get(jobId);
    if (!nextClaim) return undefined;

    const forecastsForJob = this.forecastTasks.filter((i) => i.jobId === jobId);
    const jobWorkFlows = this.jobWorkFlowService.allJobWorkflows.filter((i) => i.jobId === jobId);

    for (const jobWorkFlow of jobWorkFlows) {
      // const nextClaimTask = this.maintenanceService.allTemplateTasks.find(
      //   (i) =>
      //     i.templateTaskHeaderId === jobWorkFlow.templateTaskHeaderId &&
      //     i.activityToSetEndDateId === nextClaim.companyActivityId
      // );
      const nextClaimTask = this.maintenanceService.allTemplateTasksMap.get(jobWorkFlow.templateTaskHeaderId).find(i => i.activityToSetEndDateId === nextClaim.companyActivityId);
      if (nextClaimTask) {
        const forecastDate = forecastsForJob.find(
          (i) => i.taskMasterId === nextClaimTask.taskMasterId
        )?.forecastCompletionDate;
        if (forecastDate) {
          return this.utilsService.getDateFromDateOrString(forecastDate);
        }
      }
    }
    return undefined;
  }

  calculateContractQuoteDate(rowData: any): Date | null {
    const job = this.jobsMap.get(rowData.jobId);
    if (job && job.contractQuoteDate) {
      const date = new Date(job.contractQuoteDate);
      const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      return this.utilsService.getDateFromDateOrString(utcDate);
    }
    return null;
  }
  calculateTotalContractPrice(rowData: any): number | null {
    const job = this.jobsMap.get(rowData.jobId);
    if (job) {
      let totalContractPrice: number | null = null;

      // Parse and validate contractPrice
      let contractPrice = job.contractPrice;
      if (contractPrice != null) {
        if (typeof contractPrice === 'string') {
          contractPrice = parseFloat(contractPrice);
        }
        if (typeof contractPrice === 'number' && !isNaN(contractPrice)) {
          totalContractPrice = contractPrice;
        }
      }

      const approvedStatusId = VariationStatusEnum.Approved;

      const variations = this.jobService.jobVariations.filter(
        (i) =>
          i.jobId === job.id &&
          i.variationType < 10 &&
          i.statusId >= approvedStatusId
      );

      let hasValidVariation = false;

      variations.forEach((varn) => {
        let variationTotal = varn.variationTotal;
        if (variationTotal != null) {
          if (typeof variationTotal === 'string') {
            variationTotal = parseFloat(variationTotal);
          }
          if (typeof variationTotal === 'number' && !isNaN(variationTotal)) {
            if (totalContractPrice === null) {
              totalContractPrice = 0;
            }
            totalContractPrice += variationTotal;
            hasValidVariation = true;
          }
        }
      });

      // If contractPrice is null and there were no valid variations, return null
      if (totalContractPrice === null && !hasValidVariation) {
        return null;
      }

      return totalContractPrice;
    } else {
      return null;
    }
  }


  calculateForecastSiteStartDate(rowData: any): Date | null {
    const jobId = rowData.jobId;
    const jobWorkFlows = this.jobWorkFlowService.allJobWorkflows.filter((i) => i.jobId === jobId);
    const key = jobId.toString() + '-2';
    const constructionWorkflow = this.jobWorkFlowService.jobWorkFlowsByJobAndTypeMap.get(key);

    if (!constructionWorkflow) {
      return null;
    }

    const siteStartTasks = this.maintenanceService.allTemplateTasks.filter((i) => i.isForecastStart);
    const siteStartTaskForJob = siteStartTasks.find(
      (i) => i.templateTaskHeaderId === constructionWorkflow.templateTaskHeaderId
    );

    if (!siteStartTaskForJob) {
      return null;
    }

    const forecastsForJob = this.forecastTasks.filter((i) => i.jobId === jobId);
    const forecastTask = forecastsForJob.find((i) => i.taskMasterId === siteStartTaskForJob.taskMasterId);

    if (forecastTask && forecastTask.forecastStartDate) {
      return this.utilsService.getDateFromDateOrString(forecastTask.forecastStartDate);
    }

    return null;
  }

  calculateAgreedCompletionDate(rowData: any): Date | null {
    const jobCashFlow = this.jobCashFlowService.jobCashFlowsMap.get(rowData.jobId);

    if (jobCashFlow && jobCashFlow.agreedCompletionDate) {
      const agreedDate = new Date(jobCashFlow.agreedCompletionDate);

      const utcDate = new Date(
        Date.UTC(
          agreedDate.getFullYear(),
          agreedDate.getMonth(),
          agreedDate.getDate()
        )
      );

      return this.utilsService.getDateFromDateOrString(utcDate);
    }

    return null;
  }

  calculateSalesQuoteSignedDate(rowData: any): Date | null {
    const job = this.jobsMap.get(rowData.jobId);
    if (job && job.salesQuoteSignedDate) {
      const date = new Date(job.salesQuoteSignedDate);
      const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      return this.utilsService.getDateFromDateOrString(utcDate);
    }
    return null;
  }

  calculateQuoteDate(rowData: any): Date | null {
    const job = this.jobsMap.get(rowData.jobId);
    if (job && job.quoteDate) {
      const date = new Date(job.quoteDate);
      const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      return this.utilsService.getDateFromDateOrString(utcDate);
    }
    return null;
  }

  getTradeRegionDescription(rowData: any): string | null {
    const jobExtra = this.jobService.jobExtrasMap.get(rowData.jobId);
    const tradeRegionId = jobExtra?.tradeRegionId;
    if (tradeRegionId == null) return null;
    const region = this.maintenanceService.tradeRegions.find((r) => r.id === tradeRegionId);
    return region?.description || null;
  }

  getSalutation(rowData: any): string | null {
    const job = this.jobsMap.get(rowData.jobId);
    return job ? job.salutation : null;
  }

  getStateVariables(currentState: any) {
    if (currentState) {
      this.showInactive = currentState['showInactive'] ?? false;
    }
  }
}
