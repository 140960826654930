import { InvoicesOnHoldComponent } from './invoices-on-hold/invoices-on-hold.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { MsalGuard } from '@azure/msal-angular';
import { RefreshGuard } from './services/refresh-guard.service';
import { JobCashFlowComponent } from './job-cash-flow/job-cash-flow.component';
import { CashFlowForecastComponent } from './cash-flow-forecast/cash-flow-forecast.component';
import { MaintenanceComponent } from './maintenance/maintenance/maintenance.component';
import { TasksComponent } from './tasks/tasks.component';
import { JobFieldsComponent } from './job-fields/job-fields.component';
import { JobWorkFlowComponent } from './job-work-flow/job-work-flow.component';
import { CallUpsComponent } from './call-ups/call-ups.component';
import { JobDataComponent } from './job-data/job-data.component';
import { ReportGridComponent } from './report-grid/report-grid.component';
import { DocumentsComponent } from './documents/documents.component';
import { ManualPurchaseOrdersComponent } from './manual-purchase-orders/manual-purchase-orders.component';
import { BackChargesComponent } from './invoices-on-hold/back-charges/back-charges.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login/:comp', component: LoginComponent },
  { path: 'welcome', component: WelcomeComponent, canActivate: [RefreshGuard] },
  { path: 'welcome/:comp', component: WelcomeComponent, canActivate: [RefreshGuard] },
  { path: 'jobSetUp', component: JobCashFlowComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'cashFlow', component: CashFlowForecastComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'maintenance', component: MaintenanceComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'tasks', component: TasksComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'job-data', component: JobDataComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'job-fields', component: JobFieldsComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'job-work-flows', component: JobWorkFlowComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'report-grid', component: ReportGridComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'call-ups', component: CallUpsComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'invoices-on-hold', component: InvoicesOnHoldComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'documents', component: DocumentsComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'manual-purchase-orders', component: ManualPurchaseOrdersComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'back-charges', component: BackChargesComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'login', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
