import { UtilsService } from './../services/utils.service';
import { Component, OnInit, Input, ViewChild, OnDestroy, OnChanges } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription, debounceTime } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { NotificationService } from '../services/notification.service';
import { GlobalService } from '../services/global.service';
import { GridService } from '../services/grid.service';
import { JobService } from '../services/felixApi/job.service';
import { Job } from '../dtos/job';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateTaskHeader } from '../dtos/templateTaskHeader';
import { Router } from '@angular/router';
import { JobDocument } from '../dtos/job-document';
import { JobWorkFlow } from '../dtos/job-work-flow';
import { RoleTypeEnum } from '../dtos/role-type.enum';
import { Task, TaskChange } from '../dtos/task';
import { TaskStatusEnum, TaskStatusOptions } from '../dtos/task-status.enum';
import { TemplateTask } from '../dtos/templateTask';
import { User } from '../dtos/user';
import { UserTypeEnum } from '../dtos/user-type.enum';
import { Vendor } from '../dtos/vendor';
import { AuthService } from '../services/auth.service';
import { JobWorkFlowService } from '../services/felixApi/job-work-flow.service';
import { MaintenanceService } from '../services/felixApi/maintenance.service';
import { TaskService } from '../services/felixApi/task.service';
import { UserService } from '../services/felixApi/user.service';
import { Variation } from '../dtos/variation';
import DataSource from 'devextreme/data/data_source';
import { formatDate } from 'devextreme/localization';
import DateBox, { Properties } from "devextreme/ui/date_box";
import { VariationStatusOptions } from '../dtos/variation-status.enum';
import { TaskMaster } from '../dtos/task-master';

@Component({
  selector: 'js-job-key-tasks',
  templateUrl: './job-key-tasks.component.html',
  styleUrls: ['./job-key-tasks.component.scss']
})
export class JobKeyTasksComponent implements OnInit, OnChanges, OnDestroy {
  @Input() jobNumber: string;

  @ViewChild('dataGridTasks', { static: false }) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: any = {};
  commentHistoryDataSource: CustomStore;
  dropDownOptions: object;
  jobData: CustomStore;
  claimMasters: CustomStore;
  users: CustomStore;
  taskTypes: CustomStore;
  resetPopupVisible = false;
  commentHistoryPopupVisible = false;
  focusedTaskId: number;
  taskStatus = TaskStatusOptions;
  taskStatusList: CustomStore;
  jobs: Job[];
  todayString: string;

  commentHistoryButtonOptions: any = {
    text: 'Comment History',
    type: 'default',
    stylingMode: 'outlined',
    useSubmitBehavior: false,
    onClick: () => {
      this.showCommentHistory();
    }
  };
  variationButtonOptions: any = {
    icon: 'download',
    stylingMode: 'outlined',
    useSubmitBehavior: false,
    onClick: () => {
      this.openVariationSelected();
    }
  };
  jobTaskId: number;
  allJobTasks: Task[];
  shownJobTasks: Task[];
  taskHeaders: TemplateTaskHeader[] = [];
  allTaskHeaders: TemplateTaskHeader[] = [];
  jobWorkFlows: JobWorkFlow[] = [];
  gridHeight: number;
  loadingTasks = true;
  isPhoneSize: boolean;
  vendors: Vendor[];
  editPopupWidth: number;
  editPopupHeight: number;
  commentPopupHeight: number;
  commentGridHeight: number;
  jobDropdownWidth: number;
  jobDropdownWindowWidth: number;
  sendPopupVisible: boolean;
  currentTaskTitle: string;
  vendorId: number;
  vendorComment: string;
  sendAddendum: boolean;
  ccToSelf = true;
  loadingDocs: boolean;
  jobDocuments: JobDocument[];
  selectedJobDocs: number[] = [];
  callUpSubject: string;
  callUpPopupHeight: number;
  updatedData: any;
  callUpEmail: string;
  currentTemplateTask: TemplateTask;
  callUpTreeHeight: number;
  currentState: any;
  jobFilterValue: any;
  currentJobWorkflow: JobWorkFlow;
  addendumCaption: string;
  jobVariations: Variation[] = [];
  showFullComments = true;
  selectedTemplateTaskHeaderId: any;
  changes: TaskChange<Task>[] = [];
  savePopupVisible: boolean;
  variationsDataSource: CustomStore;
  salesRepWording = '';
  keyTasksOnly = true;
  openedGroups: string[] = [];
  collapsingExpanding: boolean;
  jobVariationId: number;
  jobVariationStatusId: number;
  updatingVariation: boolean;
  variationStatus = VariationStatusOptions;
  canReadAmountsPermission: boolean;
  currentTaskMaster: TaskMaster;

  constructor(
    private taskService: TaskService,
    private auth: AuthService,
    private maintenanceService: MaintenanceService,
    private notiService: NotificationService,
    private globalService: GlobalService,
    protected gridService: GridService,
    protected jobWorkFlowService: JobWorkFlowService,
    private userService: UserService,
    private jobService: JobService,
    private utilService: UtilsService,
    private router: Router
  ) {
    this.dropDownOptions = { width: 900 };
    this.onInitNewRow = this.onInitNewRow.bind(this);
    this.onEditingStart = this.onEditingStart.bind(this);
    this.getTaskList = this.getTaskList.bind(this);
    this.setTaskMasterIdCellValue = this.setTaskMasterIdCellValue.bind(this);
    this.setTaskTypeCellValue = this.setTaskTypeCellValue.bind(this);
    this.calculateJobSortValue = this.calculateJobSortValue.bind(this);
    this.calculateJobGroupValue = this.calculateJobGroupValue.bind(this);
    this.calculateTaskSortValue = this.calculateTaskSortValue.bind(this);
    this.setStartDateCellValue = this.setStartDateCellValue.bind(this);
    this.setTemplateTaskHeaderIdCellValue = this.setTemplateTaskHeaderIdCellValue.bind(this);
    this.cancelClickHandler = this.cancelClickHandler.bind(this);
    this.saveClickHandler = this.saveClickHandler.bind(this);
    this.sendTask = this.sendTask.bind(this);
    this.setVendorEmail = this.setVendorEmail.bind(this);
    this.onVendorSelectionChanged = this.onVendorSelectionChanged.bind(this);
    this.calculateCallUpDocsType = this.calculateCallUpDocsType.bind(this);
    this.calculateVariationTitle = this.calculateVariationTitle.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.setSentCellValue = this.setSentCellValue.bind(this);
    this.setEndDateCellValue = this.setEndDateCellValue.bind(this);
    this.calcOriginalDueDate = this.calcOriginalDueDate.bind(this);
    this.calculateMasterJob = this.calculateMasterJob.bind(this);
    this.calculateSentFromQueueStatus = this.calculateSentFromQueueStatus.bind(this);
    this.openVariationDropdown = this.openVariationDropdown.bind(this);
    this.calculateSalesRep = this.calculateSalesRep.bind(this);
    this.calculateKeyTaskValue = this.calculateKeyTaskValue.bind(this);
    this.calculateLoadSortValue = this.calculateLoadSortValue.bind(this);
    this.upSertJobTasks = this.upSertJobTasks.bind(this);
    this.onRowExpanded = this.onRowExpanded.bind(this);
    this.onRowCollapsed = this.onRowCollapsed.bind(this);
    this.onContentReady = this.onContentReady.bind(this);
    this.calculateVariationStatus = this.calculateVariationStatus.bind(this);
    this.setVariationIdCellValue = this.setVariationIdCellValue.bind(this);
    this.openVariation = this.openVariation.bind(this);
    this.openVariationSelected = this.openVariationSelected.bind(this);
    this.onTasksSaved = this.onTasksSaved.bind(this);

    DateBox.defaultOptions<Properties>({
      device: [
        { deviceType: 'desktop' },
        { deviceType: 'tablet' },
        { deviceType: 'phone' }
      ],
      options: {
        pickerType: 'calendar'
      }
    });
  }

  ngOnInit(): void {
    this.addendumCaption = 'Send ' + this.globalService.getAddendumName();
    this.setGridMeasurements(true);
    this.subscribeToInnerHeight();
    this.getData(true);
    this.getVendors(); // get in the background

    // this.variationStatus = [
    //   { id: 1, status: 'Open' },
    //   { id: 2, status: 'Estimating' },
    //   { id: 5, status: 'Pending Approval' },
    //   { id: 6, status: 'Cancelled' },
    //   { id: 7, status: 'Approved' },
    //   { id: 8, status: 'Actioned' },
    //   { id: 9, status: 'Invoiced' }
    // ];

    const permissionAmounts = this.auth.getSelectionsPermissions('CanReadAmounts');
    if (permissionAmounts === 'Read' || permissionAmounts === 'Write' || permissionAmounts === 'Admin' || this.auth.isAdminOrSuper()) {
      this.canReadAmountsPermission = true;
    }
  }

  ngOnChanges(): void {
    this.getData(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToInnerHeight() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.pipe(debounceTime(250)).subscribe(
        () => {
          setTimeout(() => {
            this.setGridMeasurements(false);
          }, 500); // wait for iPhone
        }
      )
    );
  }

  setGridMeasurements(init: boolean) {
    this.gridHeight = window.innerHeight - 155;

    this.editPopupWidth = this.globalService.innerWidth < 700 ? this.globalService.innerWidth : 700;
    this.editPopupHeight = this.globalService.innerHeight < 635 ? this.globalService.innerHeight : 635;
    this.commentPopupHeight = this.globalService.innerHeight < 640 ? this.globalService.innerHeight : 640;
    this.commentGridHeight = this.globalService.innerHeight < 640 ? this.globalService.innerHeight - 130 : 510;
    this.jobDropdownWidth = this.globalService.innerWidth < 800 ? this.globalService.innerWidth - 100 : 700;
    this.jobDropdownWindowWidth = this.jobDropdownWidth + 20;
    this.callUpPopupHeight = this.globalService.innerHeight < 885 ? this.globalService.innerHeight : 885;
    this.callUpTreeHeight = this.callUpPopupHeight < 800 ? 290 : this.callUpPopupHeight - 510;

    this.dropDownOptions = { width: this.jobDropdownWidth + 40 };
  }

  onEditorPreparing(e) {
    if (e.dataField === 'taskTypeId' || e.dataField === 'taskMasterId'
      || e.dataField === 'templateTaskHeaderId' || e.dataField === 'vendorId') {
      e.editorOptions.dropDownOptions = { minWidth: 350 };
    }
    if (e.dataField === 'userId' || e.dataField === 'modifiedUserId') {
      e.editorOptions.dropDownOptions = { minWidth: 220 };
    }
    if (e.dataField === 'jobId' || e.dataField === 'statusId'
      || e.dataField === 'purchaseOrderId' || e.dataField === 'jobVariationId') {
      e.editorOptions.dropDownOptions = { minWidth: 120 };
    }
    if (e.dataType === 'boolean') {
      e.editorOptions.dropDownOptions = { minWidth: 70 };
    }
  }

  getVendors() {
    this.subscriptions.push(
      this.userService.getVendors(true, true)
        .subscribe({
          next: (vendors) => {
            this.vendors = vendors;
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  getData(useCache: boolean) {
    this.loadingTasks = true;
    this.allJobTasks = [];
    this.shownJobTasks = [];
    this.dataSource = null;
    const todaysDate = new Date();

    this.todayString = todaysDate.getFullYear() + '-'
      + ('0' + (todaysDate.getMonth() + 1)).toString().slice(-2) + '-'
      + ('0' + todaysDate.getDate()).slice(-2);

    this.subscriptions.push(
      this.taskService.getJobTasksData(this.jobService.currentJob.id, false, true,
        true, true, true, useCache, false, true)
        .subscribe({
          next: (jobs) => {
            this.jobs = jobs;
            this.allTaskHeaders = this.maintenanceService.taskHeaders;
            this.jobVariations = this.jobService.jobVariations;

            this.jobData = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.jobs.filter(i => i.isActive)
            });

            this.allJobTasks = this.taskService.jobTasks;
            this.setShownTasks();

            this.salesRepWording = this.taskService.companyRoles?.find(i => i.roleId === RoleTypeEnum.SalesRep)?.companyRoleDescription;

            this.taskTypes = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.maintenanceService.taskTypes
            });

            this.resetUsers();

            this.taskStatusList = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.taskStatus
            });

            // this.loading = false;
            this.loadingTasks = false;
            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            // this.loading = false;
            this.loadingTasks = false;
          }
        })
    );
  }

  setShownTasks() {
    if (this.keyTasksOnly) {
      this.shownJobTasks = [];
      this.allJobTasks.forEach(jobTask => {
        const thisTask = this.maintenanceService.taskMasters.find(i => i.id === jobTask.taskMasterId);
        if (thisTask && thisTask.isKeyTask) {
          this.shownJobTasks.push(jobTask);
        }
      });
    } else {
      this.shownJobTasks = this.allJobTasks;
    }
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      loadMode: 'raw',
      load: () => this.shownJobTasks,
      insert: async (values) => {
        values.jobId = this.jobService.currentJob.id;
        return new Promise((resolve, reject) =>
          this.taskService.addJobTask(values).subscribe({
            next: (res) => {
              this.upSertJobTasks(res);
              return resolve(res[0]);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) => {
          // this.loadingTasks = true;
          this.taskService.updateJobTask(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              this.upSertJobTasks(res);
              // this.loadingTasks = false;
              return resolve(res[0]);
            }, error: (err) => {
              // this.loadingTasks = false;
              return reject(this.globalService.returnError(err));
            }
          });
        });
      }
    });
  }

  onInitNewRow(e) {
    e.data.startDate = new Date();
    e.data.statusId = TaskStatusEnum.InProgress;
    e.data.userId = this.auth.getCurrentUserId();
    this.selectedTemplateTaskHeaderId = null;
    this.jobTaskId = null;

    // only active users
    this.users = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.userService.users.filter(i => i.userTypeId !== UserTypeEnum.Associate
        && i.userTypeId !== UserTypeEnum.Client
        && i.isActive)
    });
  }

  onTasksSaved() {
    // recalc the current activity
    this.subscriptions.push(
      this.taskService.calcCurrentActivity(this.jobService.currentJob.id)
        .subscribe({
          next: (jobExtra) => {
            this.jobService.currentJobExtra = jobExtra;
            this.jobService.currentActivity = this.maintenanceService
              .activities?.find(i => i.id === jobExtra?.currentActivityId)?.description;
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  onSelectionChanged(cellInfo, e, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0]);
      e.component.close();
    }
  }

  onVariationChanged(cellInfo, event) {
    // for clear event
    if (!event.value) {
      cellInfo.setValue(event.value);
    }
  }

  setTaskTypeCellValue(newData, value) {
    if (value) {
      newData.taskTypeId = value;
      newData.taskMasterId = null;
      newData.templateTaskHeaderId = null;
      this.taskHeaders = [];
    }
  }

  setTaskMasterIdCellValue(rowData, value, currentData) {
    rowData.templateTaskHeaderId = null;
    if (value) {
      rowData.taskMasterId = value;
      const task = this.maintenanceService.taskMasters.find(i => i.id === value);
      rowData.manualTaskTitle = task ? task.taskTitle : '';
      rowData.officeComment = task?.defaultOfficeComment;
      rowData.vendorComment = task?.defaultVendorComment;

      this.taskHeaders = [];
      if (task) {
        // add the default comment
        if (!currentData.officeComment || currentData.officeComment.trim() === '') {
          rowData.officeComment = task.defaultOfficeComment;
        }

        this.getTaskHeaders(task.id);
      }

      if (!currentData.endDate) {
        // we can reset the due date
        this.currentTemplateTask = null;

        if (this.taskHeaders && this.taskHeaders.length === 1) {
          rowData.templateTaskHeaderId = this.taskHeaders[0].id;

          this.currentTemplateTask = this.maintenanceService.allTemplateTasks
            .find(i => i.templateTaskHeaderId === rowData.templateTaskHeaderId && i.taskMasterId === value);

          rowData.sendAddendum = this.currentTemplateTask?.includeAddendum;
          rowData.doNotSendCallup = this.currentTemplateTask?.doNotSendCallup;
          rowData.vendorId = this.currentTemplateTask?.vendorId;
        }

        let startDateString = '';
        if (currentData.startDate instanceof Date) {
          startDateString = this.utilService.convertDateToString(currentData.startDate);
        } else {
          startDateString = currentData.startDate.substr(0, 10);
        }

        if (task) {
          const newDueDate = this.calcDueDate(task.id, startDateString);
          if (newDueDate) {
            rowData.dueDate = newDueDate;
          }
        }
      }
    }
  }

  setTemplateTaskHeaderIdCellValue(rowData, value, currentData) {
    if (value) {
      rowData.templateTaskHeaderId = value;

      this.currentTemplateTask = this.maintenanceService.allTemplateTasks
        .find(i => i.templateTaskHeaderId === value && i.taskMasterId === currentData.taskMasterId);

      this.currentJobWorkflow = this.jobWorkFlows.find(i => i.templateTaskHeaderId === value);

      rowData.sendAddendum = this.currentTemplateTask?.includeAddendum;
      rowData.doNotSendCallup = this.currentTemplateTask?.doNotSendCallup;
      rowData.vendorId = this.currentTemplateTask?.vendorId;

      // we can reset the due date
      if (currentData.startDate && currentData.taskMasterId) {
        const startDateString = this.utilService.convertDateToString(currentData.startDate);
        const newDueDate = this.calcDueDate(currentData.taskMasterId, startDateString);
        if (newDueDate) {
          rowData.dueDate = newDueDate;
        }
      }
    } else {
      rowData.templateTaskHeaderId = null;
    }

    this.selectedTemplateTaskHeaderId = value;
  }

  getTaskHeaders(taskMasterId: number) {
    // set up the task headers applicable
    const templateTasks = this.maintenanceService.allTemplateTasks.filter(i => i.taskMasterId === taskMasterId);

    templateTasks.forEach(templateTask => {
      const taskHeader = this.allTaskHeaders.find(i => i.id === templateTask.templateTaskHeaderId);
      if (taskHeader) {
        this.taskHeaders.push(taskHeader);
      }
    });
  }

  setStartDateCellValue(newData, value, currentRowData) {
    if (value) {
      newData.startDate = this.utilService.convertDateToString(value);
      if (currentRowData.statusId === TaskStatusEnum.NotStarted) {
        newData.statusId = TaskStatusEnum.InProgress;
      }

      const startDateString = this.utilService.convertDateToString(value);
      const todaysDateString = formatDate(new Date(), 'yyyy-MM-dd');

      if (startDateString < todaysDateString) {
        this.notiService.showWarning('Warning - date in the past');
      } else if (value.getDay() === 6 || value.getDay() === 0) {
        this.notiService.showWarning('Warning - weekend date');
      } else if (this.maintenanceService.holidays.find(i => i.date.toString().substr(0, 10) === startDateString)) {
        this.notiService.showWarning('Warning - date is a holiday');
      }

      newData.startDate = startDateString;
      if (currentRowData.statusId === TaskStatusEnum.NotStarted || currentRowData.statusId === TaskStatusEnum.NotApplicable) {
        newData.statusId = TaskStatusEnum.InProgress;
      }

      if (!currentRowData.endDate) {
        // we can reset the due date
        const newDueDate = this.calcDueDate(currentRowData.taskMasterId, startDateString);
        if (newDueDate) {
          newData.dueDate = newDueDate;
        }
      }
    } else {
      newData.statusId = TaskStatusEnum.NotStarted;
      newData.startDate = null;
      newData.dueDate = null;
      newData.endDate = null;
      newData.isCompleted = false;
    }
  }

  calcDueDate(taskMasterId: number, startDateString: string): string {
    const taskMaster = this.maintenanceService.taskMasters.find(i => i.id === taskMasterId);
    if (taskMaster) {
      const startDate =
        new Date(+startDateString.substr(0, 4), +startDateString.substr(5, 2) - 1, +startDateString.substr(8, 2), 0, 0, 0, 0);

      let days = taskMaster.days;

      const templateDays = this.maintenanceService.templateDays
        .find(i => i.templateTaskHeaderId === this.currentTemplateTask?.templateTaskHeaderId
          && i.templateTaskId === this.currentTemplateTask?.id
          && i.templateDaysHeaderId === this.currentJobWorkflow?.templateDaysHeaderId);

      if (templateDays && !templateDays.isOnlyForForecast) {
        days = templateDays.days;
      }

      return this.utilService.convertDateToString(this.maintenanceService.addDaysExHolidays(startDate, days));
    }
    return null;
  }

  getJobWorkFlows(taskMasterId: number) {
    this.jobWorkFlows = this.jobWorkFlowService.allJobWorkflows.filter(i => i.jobId === this.jobService.currentJob.id);
    this.getTaskHeaders(taskMasterId);
  }

  refresh() {
    this.jobService.jobs = [];
    this.userService.users = [];
    this.jobService.jobVariations = [];
    this.getData(false);
  }

  collapseAll() {
    this.openedGroups = [];
    this.focusedTaskId = null;
    this.grid.instance.collapseAll();
  }

  // uncollapseAll() {
  //   this.openedGroups = [];
  //   this.grid.instance.collapseAll();
  //   this.grid.instance.expandAll();
  // }

  getTaskList(options) {
    return {
      store: options.isNewRow ? this.maintenanceService.taskMasters.filter(i => i.isActive) : this.maintenanceService.taskMasters,
      filter: options.data ? ['taskTypeId', '=', options.data.taskTypeId] : null
    };
  }

  setEndDateCellValue(newData, value, currentRowData) {
    // must use formatDate as we get ISO format and lose hours so date can be yesterday
    if (value) {
      newData.endDate = this.utilService.convertDateToString(value);
      newData.statusId = TaskStatusEnum.Completed;
      newData.isCompleted = true;
    } else {
      newData.statusId = TaskStatusEnum.InProgress;
      newData.isCompleted = false;
      newData.endDate = null;
    }
  }

  setStatusCellValue(newData, value, currentRowData) {
    if (value) {
      newData.statusId = value;

      if (value === TaskStatusEnum.Completed || value === TaskStatusEnum.NotApplicable) {
        newData.endDate = formatDate(new Date(), 'yyyy-MM-dd');
      } else {
        newData.endDate = null;
      }

      if (value === TaskStatusEnum.NotStarted) {
        newData.startDate = null;
        newData.dueDate = null;
      }
    }
  }

  setIsCompletedCellValue(newData, value, currentRowData) {
    if (value) {
      newData.statusId = TaskStatusEnum.Completed;
      newData.endDate = formatDate(new Date(), 'yyyy-MM-dd');
    } else {
      newData.statusId = TaskStatusEnum.InProgress;
      newData.endDate = null;
    }
  }

  setSentCellValue(newData, value) {
    if (value) {
      newData.calledDate = this.utilService.convertDateToString(value);
    } else {
      newData.calledDate = null;
    }
  }

  calculateIsCompleted(data) {
    if (data && data.endDate) {
      return true;
    } else {
      return false;
    }
  }

  calculateKeyTaskValue(data) {
    if (data && data.taskMasterId) {
      const taskMaster = this.maintenanceService.taskMasters.find(i => i.id === data.taskMasterId);
      return taskMaster.isKeyTask;
    }
    return false;
  }

  clearStatePersistance() {
    this.resetPopupVisible = true;
  }

  clearStatePersistanceGo() {
    this.resetPopupVisible = false;
    this.loadingTasks = true;
    this.focusedTaskId = null;
    localStorage.removeItem('keytaskgrid');
    setTimeout(() => {
      this.loadingTasks = false;
    }, 300); // wait for grid
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Key tasks only',
          rtlEnabled: true,
          value: this.keyTasksOnly,
          onValueChanged: this.showKeyTasksChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show full comment',
          rtlEnabled: true,
          value: true,
          onValueChanged: this.showFullComment.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'collapse',
          onClick: this.collapseAll.bind(this)
        }
      },
      // {
      //   location: 'after',
      //   locateInMenu: 'auto',
      //   widget: 'dxButton',
      //   options: {
      //     icon: 'expand',
      //     onClick: this.uncollapseAll.bind(this)  *** causes a grid bug on edit of a task
      //   }
      // },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      });
  }

  onInitialized(e) {
    e.component.getView('headerPanel').updateToolbarDimensions = function () { };
  }

  setEditedValue(valueChangedEventArg, cellInfo) {
    cellInfo.setValue(valueChangedEventArg.value);
  }

  calculateTaskSortValue(data) {
    let sortOrder = 0;
    const task = this.maintenanceService.taskMasters.find(i => i.id === data.taskMasterId);
    if (task) {
      sortOrder = task.orderNumber;
    }

    return sortOrder;
  }

  onRowPrepared(e) {
    if (e.rowType === 'data') {
      if (e.rowType === 'data' && (e.data.endDate || e.data.statusId === TaskStatusEnum.NotApplicable)) {
        // completed in green or orange
        if (e.data.statusId === TaskStatusEnum.NotApplicable) {
          e.rowElement.style.backgroundColor = 'rgb(230, 245, 220)';
        } else {
          e.rowElement.style.backgroundColor = 'rgb(200, 250, 200)';
        }
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
      }
    }
  }

  onCellPrepared(e) {
    if (e.rowType === 'data') {
      if (e.data.statusId === TaskStatusEnum.NotApplicable || e.data.statusId === TaskStatusEnum.Cancelled) {
        e.cellElement.style.textDecoration = 'line-through';
      } else if (e.column.dataField === 'originalDueDate' && !e.data.endDate) {
        if (e.data.originalDueDate < this.todayString) {
          e.cellElement.style.color = 'red';
          e.cellElement.style.fontWeight = 'bold';
        }
      } else if (e.column.dataField === 'dueDate' && !e.data.endDate) {
        if (e.data.dueDate < this.todayString) {
          e.cellElement.style.color = 'red';
          e.cellElement.style.fontWeight = 'bold';
        }
      }
    }
  }

  onEditingStart(e) {
    // get notes history
    this.selectedTemplateTaskHeaderId = e.data.templateTaskHeaderId;
    this.jobTaskId = e.data.id;
    this.jobVariationId = e.data?.jobVariationId;
    if (this.jobVariationId) {
      this.jobVariationStatusId = this.jobVariations.find(i => i.id === this.jobVariationId)?.statusId;
    }

    this.vendorId = e.data.vendorId; // for send
    this.vendorComment = e.data.vendorComment;
    this.sendAddendum = e.data.sendAddendum;
    this.currentTemplateTask = this.maintenanceService.allTemplateTasks
      .find(i => i.templateTaskHeaderId === e.data.templateTaskHeaderId && i.taskMasterId === e.data.taskMasterId);

    const job = this.jobs.find(i => i.id === this.jobService.currentJob.id);
    this.callUpSubject = 'Job# ' + job.jobNumber + ' - ' + this.globalService.getJobString(job);

    if (job.warningNote && job.warningNote.trim() !== '') {
      this.notiService.showWarning(job.warningNote);
    }

    // check if on hold
    const jobExtra = this.jobService.jobExtras?.find(i => i.jobId === this.jobService.currentJob.id);
    if (jobExtra.isOnHold) {
      this.notiService.showWarning('Job is ON HOLD');
    }
    if (jobExtra.cancellationDate) {
      this.notiService.showWarning('Job is Cancelled');
    }

    this.taskHeaders = [];
    this.currentTaskMaster = this.maintenanceService.taskMasters.find(i => i.id === e.data.taskMasterId);
    if (this.currentTaskMaster) {
      this.getJobWorkFlows(this.currentTaskMaster.id);
      this.currentJobWorkflow = this.jobWorkFlows.find(i => i.templateTaskHeaderId === e.data.templateTaskHeaderId);
    } else {
      this.currentJobWorkflow = null;
    }
    this.currentTaskTitle = this.currentTaskMaster?.taskTitle;

    // only active users
    this.users = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.userService.users.filter(i => i.userTypeId !== UserTypeEnum.Associate
        && i.userTypeId !== UserTypeEnum.Client
        && (i.isActive || i.id === e.data.userId))
    });
  }

  resetUsers() {
    // all active users or users with tasks
    const users: User[] = [];

    this.userService.users.filter(i => i.userTypeId !== UserTypeEnum.Associate
      && i.userTypeId !== UserTypeEnum.Client).forEach(user => {
        if (user.isActive || this.allJobTasks.find(i => i.userId === user.id)) {
          users.push(user);
        }
      });

    this.users = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => users
    });
  }

  showCommentHistory() {
    this.commentHistoryPopupVisible = true;

    this.commentHistoryDataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.taskService.getJobTaskComments(this.jobTaskId).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  closeCommentHistory() {
    this.commentHistoryPopupVisible = false;
  }

  upSertJobTasks(newItems: Task[]) {
    newItems.forEach(element => {
      let foundId = this.shownJobTasks.findIndex(i => i.id === element.id);
      if (foundId >= 0) {
        this.shownJobTasks.splice(foundId, 1, element);
      } else {
        this.shownJobTasks.push(element);
      }
    });
  }

  // deleteJobTask(id: number, refresh: boolean) {
  //   let foundId = this.allJobTasks.findIndex(i => i.id === id);
  //   if (foundId >= 0) {
  //     this.allJobTasks.splice(foundId, 1);
  //   }

  //   foundId = this.shownJobTasks.findIndex(i => i.id === id);
  //   if (foundId >= 0) {
  //     this.shownJobTasks.splice(foundId, 1);
  //   }

  //   if (refresh) {
  //     this.grid.instance.refresh();
  //   }
  // }

  // updateJobTasks(newItems: Task[]) {
  //   // delete and replace
  //   newItems.forEach(newItem => {
  //     let existingItem = this.allJobTasks.find(i => i.id === newItem.id);
  //     if (existingItem) {
  //       existingItem = { ...newItem }
  //     }
  //   });

  //   this.upSertJobTasks(newItems);
  // }

  cancelClickHandler() {
    this.grid.instance.cancelEditData();
  }

  saveClickHandler(e) {
    if (this.grid.instance.hasEditData() && this.changes[0].type === 'insert' && this.taskHeaders && this.taskHeaders.length
      && !this.changes[0].data.templateTaskHeaderId && this.changes[0].data.jobId && this.changes[0].data.taskMasterId) {
      this.savePopupVisible = true;
    } else {
      this.grid.instance.saveEditData();
    }
  }

  saveCheckDone() {
    this.savePopupVisible = false;
    this.saveData();
  }

  saveData() {
    if (this.jobVariationId) {
      // save changes to variation
      const thisVariation = this.jobVariations.find(i => i.id === this.jobVariationId);
      if (this.jobVariationStatusId !== thisVariation?.statusId) {
        this.updatingVariation = true;
        this.subscriptions.push(
          this.jobService.updateVariationStatus(this.jobVariationId, this.jobVariationStatusId)
            .subscribe({
              next: () => {
                thisVariation.statusId = this.jobVariationStatusId;
                this.jobVariationId = null;
                this.updatingVariation = false;
                this.grid.instance.saveEditData();
              },
              error: (err) => {
                this.updatingVariation = false;
                this.notiService.notify(err);
              }
            })
        );
      } else {
        this.grid.instance.saveEditData();
      }
    } else {
      this.grid.instance.saveEditData();
    }
  }

  sendTask() {
    // send call-up for this task
    if (this.grid.instance.hasEditData()) {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      if (!this.vendorComment || this.vendorComment.trim() === '') {
        this.vendorComment = 'Please be advised: ' + this.currentTaskTitle + ' has been assigned to you.';
      }
      this.sendPopupVisible = true;
      this.selectedJobDocs = [];
      this.setVendorEmail();

      this.loadingDocs = true;
      this.subscriptions.push(
        this.jobService.getJobDocuments(this.jobService.currentJob.id, false)
          .subscribe({
            next: () => {
              this.jobDocuments = this.jobService.jobDocuments;
              this.loadingDocs = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.jobDocuments = [];
              this.loadingDocs = false;
            }
          })
      );
    }
  }

  setVendorEmail() {
    this.callUpEmail = '';
    if (this.vendorId) {
      const vendor = this.vendors.find(i => i.id === this.vendorId);
      if (vendor) {
        if (vendor.quoteRequestEmail && vendor.quoteRequestEmail.trim() !== '' && this.currentTaskMaster?.isQuoteRequest) {
          this.callUpEmail = vendor.quoteRequestEmail;
        } else {
          this.callUpEmail = vendor.callUpEmail ? vendor.callUpEmail : vendor.email;
        }
      }
    }
  }

  attachmentSelectionChanged(e): void {
    // get all leaves as new list
    this.selectedJobDocs = e.component.getSelectedRowKeys('all').filter(this.globalService.onlyUnique);
  }

  onVendorSelectionChanged(e) {
    this.vendorId = e.selectedItem?.id;
    this.setVendorEmail();
  }

  sendCallUp() {
    // first save any data
    this.loadingDocs = true;
    this.updatedData = {};
    this.updatedData.vendorId = this.vendorId;
    this.updatedData.vendorComment = this.vendorComment;
    this.updatedData.sendAddendum = this.sendAddendum;

    this.subscriptions.push(
      this.taskService.updateJobTask(this.jobTaskId.toString(), this.updatedData)
        .subscribe({
          next: () => {
            this.saveAttachedDocs();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );
  }

  saveAttachedDocs() {
    this.subscriptions.push(
      this.jobService.addJobTaskDocuments(this.jobTaskId, { keyIds: this.selectedJobDocs })
        .subscribe({
          next: () => {
            this.sendCallUpGo();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );
  }

  clearVendorDetails() {
    // first save any data
    this.loadingDocs = true;
    this.updatedData = {};
    this.updatedData.vendorId = null;
    this.updatedData.vendorComment = '';

    this.subscriptions.push(
      this.taskService.updateJobTask(this.jobTaskId.toString(), this.updatedData)
        .subscribe({
          next: () => {
            this.loadingDocs = false;
            this.sendPopupVisible = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );
  }

  calculateCallUpDocsType(data) {
    let result = false;

    if (data.callUpDocsTypeId && this.currentTemplateTask?.callUpDocsTypes && this.currentTemplateTask?.callUpDocsTypes.length) {
      const foundType = this.currentTemplateTask?.callUpDocsTypes.find(i => i === data.callUpDocsTypeId);

      if (foundType) {
        result = true;
      }
    }
    return result;
  }

  sendCallUpGo() {
    // send the task call-up
    this.subscriptions.push(
      this.taskService.sendCallUpForOneTask(this.jobTaskId, this.callUpEmail, this.callUpSubject, this.ccToSelf, null)
        .subscribe({
          next: () => {
            this.loadingDocs = false;
            this.sendPopupVisible = false;
            this.notiService.showSuccess('Email sent.');
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );
  }

  // saveState() {
  //   this.currentState = JSON.parse(localStorage.getItem('keytaskgrid'));
  //   // if (this.filterValue) {
  //   //   this.currentState.filterValue = this.filterValue;
  //   // }

  //   this.currentState.showMyTasksOnly = this.showMyTasksOnly;
  //   this.currentState.showNotStarted = this.showNotStarted;
  //   this.currentState.showInProgress = this.showInProgress;
  //   this.currentState.showCompleted = this.showCompleted;
  //   this.currentState.showCancelled = this.showCancelled;

  //   const modalRef = this.modalService.open(StateStoreComponent, { windowClass: 'modal-stateStore', scrollable: true });
  //   modalRef.componentInstance.stateStoreTypeId = StateStoreTypeEnum.TaskGrid;
  //   modalRef.componentInstance.stateString = JSON.stringify(this.currentState);

  //   modalRef.result.then((state: StateStore) => {
  //     this.loading = true;
  //     if (state) {
  //       this.currentState = JSON.parse(state.stateString);

  //       this.showMyTasksOnly = this.currentState.showMyTasksOnly;
  //       this.showNotStarted = this.currentState.showNotStarted;
  //       this.showInProgress = this.currentState.showInProgress;
  //       this.showCompleted = this.currentState.showCompleted;
  //       this.showCancelled = this.currentState.showCancelled;

  //       localStorage.setItem('keytaskgrid', state.stateString);

  //     } else {
  //       // load default
  //       this.currentState = null;

  //       this.showMyTasksOnly = true;
  //       this.showNotStarted = false;
  //       this.showInProgress = true;
  //       this.showCompleted = false;
  //       this.showCancelled = false;

  //       localStorage.removeItem('keytaskgrid');
  //     }
  //     this.refresh();
  //   },
  //     () => {
  //     });
  // }

  calculateJobSortValue(data) {
    const job = this.jobs.find(i => i.id === data.jobId);
    if (job) {
      return job.jobNumber;
    }

    return '';
  }

  calculateJobGroupValue(data): string {
    const job = this.jobs.find(i => i.id === data.jobId);
    if (job) {
      return job.jobNumber;
    }

    return '';
  }

  calculateVariationTitle(data) {
    if (data && data.jobVariationId) {
      const jobVariation = this.jobVariations.find(i => i.id === data.jobVariationId);
      if (jobVariation) {
        return jobVariation.title;
      }
    }
    return '';
  }

  calculateVariationStatus(data) {
    if (data && data.jobVariationId) {
      const jobVariation = this.jobVariations.find(i => i.id === data.jobVariationId);
      if (jobVariation) {
        return this.variationStatus.find(i => i.id === jobVariation.statusId)?.status;
      }
    }
    return '';
  }

  calculateMasterJob(data) {
    if (data) {
      const thisJob = this.jobs.find(i => i.id === this.jobService.currentJob.id);
      if (thisJob && thisJob.masterJobId) {
        return this.jobs.find(i => i.id === thisJob.masterJobId)?.jobNumber;
      }
      return thisJob?.jobNumber;
    }
    return '';
  }

  calculateSalesRep(data) {
    if (data) {
      const jobRole = this.taskService.allJobRoles?.find(i => i.jobId === this.jobService.currentJob.id && i.roleId === RoleTypeEnum.SalesRep);
      if (jobRole) {
        return jobRole.user.fullName;
      }
    }
    return '';
  }

  showFullComment() {
    this.showFullComments = !this.showFullComments;
  }

  showKeyTasksChanged() {
    this.keyTasksOnly = !this.keyTasksOnly;
    this.setShownTasks();
    this.setUpDataSource();
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }

  calcOriginalDueDate(data) {
    if (data.startDate) {
      let startDateString = '';
      if (data.startDate instanceof Date) {
        startDateString = this.utilService.convertDateToString(data.startDate);
      } else {
        startDateString = data.startDate.substr(0, 10);
      }

      const task = this.maintenanceService.taskMasters.find(i => i.id === data.taskMasterId);
      if (task) {
        const newDueDate = this.calcDueDate(task.id, startDateString);
        if (newDueDate) {
          return new Date(newDueDate);
        }
      }
    }
    return null;
  }

  calculateSentFromQueueStatus(data: Task): string {
    if (data.sentFromQueueDate) {
      return 'Sent';
    } else if (data.calledDate) {
      return 'Queued';
    }
    return '';
  }

  openVariationDropdown(e) {
    this.variationsDataSource = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.jobVariations?.filter(i => i.jobId === this.jobService.currentJob.id)
    });
  }

  setVariationIdCellValue(rowData, value) {
    if (value) {
      rowData.jobVariationId = value.id;
    } else {
      rowData.jobVariationId = null;
    }
    this.jobVariationId = null;
    this.jobVariationStatusId = null;
    setTimeout(() => {
      this.jobVariationId = rowData.jobVariationId;
      if (this.jobVariationId) {
        this.jobVariationStatusId = this.jobVariations.find(i => i.id === this.jobVariationId)?.statusId;
      }
    }, 200);
  }

  calculateLoadSortValue(jobTask: Task) {
    let orderNumber = 0;
    let description = 'Ad-Hoc';
    if (jobTask.taskTypeId) {
      const taskType = this.maintenanceService.taskTypes.find(i => i.id === jobTask.taskTypeId);
      if (taskType) {
        orderNumber = taskType.orderNumber;
        description = taskType.description;
        jobTask.taskTypeOrderNumber = taskType.orderNumber;
      }
    }
    return ('00000' + orderNumber.toString()).slice(-6) + ';' + description;
  }

  getGroupTitle(cellInfo) {
    return cellInfo.data.key.split(';')[1];
  }

  onRowCollapsed(e) {
    const foundIndex = this.openedGroups.findIndex(i => i === e.key[0]);
    if (foundIndex >= 0) {
      this.openedGroups.splice(foundIndex, 1);
    }
    this.collapsingExpanding = true;
  }

  onRowExpanded(e) {
    const foundIndex = this.openedGroups.findIndex(i => i === e.key[0]);
    if (foundIndex < 0) {
      this.openedGroups.push(e.key[0]);
    }
    this.collapsingExpanding = true;
  }

  onContentReady() {
    // console.log('content ready');
    if (this.openedGroups.length) {
      this.openedGroups.forEach(openedGroup => {
        if (!this.grid.instance.isRowExpanded([openedGroup])) {
          this.grid.instance.expandRow([openedGroup]);
        }
      });
    }
    this.collapsingExpanding = false;
  }

  openVariation(e) {
    // open variation
    this.jobVariationId = e.row.data.id;
    this.openVariationSelected();
  }

  openVariationSelected() {
    const variation = this.jobVariations.find(i => i.id === this.jobVariationId);
    const variationNumber = variation?.variationNumber;
    const jobNumber = this.jobs.find(i => i.id === variation?.jobId)?.jobNumber;
    this.updatingVariation = true;

    this.subscriptions.push(
      this.jobService.downloadJobVariation(jobNumber, variationNumber, this.canReadAmountsPermission)
        .subscribe({
          next: (res) => {
            this.updatingVariation = false;
            if (res.attachment) {
              this.globalService.convertAndSave(res.attachment, jobNumber);
            } else {
              this.notiService.showInfo('Variation has no items');
            }
          },
          error: (err) => {
            this.notiService.notify(err);
            this.updatingVariation = false;
          }
        })
    );
  }
}
