<div class="page">
  <dx-data-grid *ngIf="dataSource" id="dataGridTasks" #dataGridTasks class="m-auto" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')"
    [filterSyncEnabled]="true" [ngStyle]="{'height': 'calc(' + gridHeight + 'px)'}"
    (onExporting)="gridService.onExporting($event, 'Task-Log')" [syncLookupFilterValues]="false"
    (onEditorPreparing)="onEditorPreparing($event)">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" [width]="150"></dxo-search-panel>
    <dxo-sorting mode='multiple'></dxo-sorting>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true">
      <dxo-search [enabled]="true"></dxo-search>
    </dxo-header-filter>
    <!-- <dxo-state-storing [enabled]="true" type="localStorage" storageKey="keytaskgrid" [savingTimeout]="500">
    </dxo-state-storing> -->

    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="false" [contextMenuEnabled]="true" expandMode="rowClick"></dxo-grouping>

    <dxi-column dataField="createDate" caption="Log Date" dataType="date" width="90" alignment="center"
      format="d-MMM-yy">
    </dxi-column>

    <dxi-column *ngIf="showAllJobs" dataField="jobId" caption="Job#" dataType="number" width="100">
      <dxo-lookup [dataSource]="jobs" displayExpr="jobNumber" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="taskTypeId" caption="Type" dataType="number" width="120"
      [calculateGroupValue]="calculateLoadSortValue">
      <dxo-lookup [dataSource]="taskTypes" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="taskMasterId" dataType="number" caption="Title" minWidth="150"
      [calculateSortValue]="calculateTaskSortValue">
      <dxo-lookup [dataSource]="taskMasters" displayExpr="taskTitle" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="templateTaskHeaderId" caption="Work Flow" dataType="number" [visible]='false'>
      <dxo-lookup [dataSource]="templateTaskHeaders" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="prevStartDate" dataType="date" width="110" alignment="center" format="d-MMM-yy">
    </dxi-column>

    <dxi-column dataField="startDate" caption="Start Changed To" dataType="date" width="110" alignment="center"
      format="d-MMM-yy">
    </dxi-column>

    <dxi-column dataField="dueDate" caption="Due Changed To" dataType="date" width="110" alignment="center"
      format="d-MMM-yy">
    </dxi-column>

    <dxi-column dataField="endDate" caption="Completed Changed To" dataType="date" width="120" alignment="center"
      format="d-MMM-yy">
    </dxi-column>

    <dxi-column dataField="officeComment" dataType="string" caption="Office Comment" minWidth="150"
      cellTemplate="commentCellTemplate">
    </dxi-column>

    <dxi-column dataField="vendorComment" dataType="string" caption="Vendor Comment" minWidth="150"
      cellTemplate="commentCellTemplate" [visible]="false">
    </dxi-column>

    <dxi-column dataField="userId" caption="Assigned To" dataType="number" minWidth="90">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="statusId" caption="Status" dataType="number" width="110">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="taskStatusOptions" displayExpr="status" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="modifiedUserId" caption="Modified By" dataType="number" [width]="150">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="calledDate" caption="Sent" dataType="date" width="85" alignment="center" format="d-MMM-yy"
      [visible]='false'>
    </dxi-column>

    <dxi-column dataField="sentFromQueueDate" dataType="date" width="85" alignment="center" format="d-MMM-yy"
      [visible]='false'>
    </dxi-column>

    <dxi-column dataField="doNotSendCallup" caption="Don't Send" dataType="boolean" width="50" alignment="center"
      [visible]='false'>
    </dxi-column>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [visible]='false'
      [calculateSortValue]="calculateVendorSortValue">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="purchaseOrderId" caption="PO#" dataType="number" width="90" alignment="center"
      [visible]='false'>
      <dxo-lookup [dataSource]="purchaseOrders" displayExpr="poNumber" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="jobVariationId" caption="Variation" dataType="number" width="105" alignment="center"
      [visible]='false'>
      <dxo-lookup [dataSource]="jobVariations" displayExpr="displayedVariationNumber" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="isKeyTask" caption="Key Task" dataType='boolean' [width]='50'
      [calculateCellValue]="calculateKeyTaskValue" [visible]="false">
    </dxi-column>

    <dxi-column dataField="jobTaskId" dataType='number' [width]='80' [visible]="false">
    </dxi-column>

    <div *dxTemplate="let cellInfo of 'commentCellTemplate'">
      <div *ngIf='showFullComments' id="vendorComment" class='mx-0 px-0'>{{cellInfo.value}}</div>
      <div *ngIf='!showFullComments' id="vendorCommentLimited" class='mx-0 px-0'>{{cellInfo.value}}</div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <div class="dateFromLabel">Date From</div>
        <dx-date-box id="dateFromValue" [value]='dateFrom' (onValueChanged)='dateFromChanged($event)' [width]="160"
          [showClearButton]="true">
        </dx-date-box>
      </div>
    </div>
  </dx-data-grid>
</div>


<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#employee' }" [visible]="loadingTasks"
  [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
