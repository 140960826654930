import { MaintenanceService } from './maintenance.service';
import { TaskService } from './task.service';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { JobCashFlow } from '../../dtos/jobCashFlow';
import { Job } from '../../dtos/job';
import { TemplateTask } from '../../dtos/templateTask';
import { ClaimMaster } from '../../dtos/claimMaster';
import { UserService } from './user.service';
import { JobService } from './job.service';
import { CashFlowForecast } from '../../dtos/cashFlowForecast';
import { HttpService } from '../http.service';
import { UtilsService } from '../utils.service';
import { SkippedCost } from '../../dtos/skippedCost';

@Injectable({
  providedIn: 'root'
})
export class JobCashFlowService {
  tasks: TemplateTask[];
  claimMasters: ClaimMaster[];
  jobCashFlows: JobCashFlow[];
  jobCashFlowsCompany: number;
  allTemplateTasks: TemplateTask[];
  allTemplateTasksCompany: number;
  jobCashFlowsIncludeForecast: boolean;
  claimMastersCompany: number;
  skippedCosts: SkippedCost[];

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService,
    private maintenanceService: MaintenanceService,
    private jobService: JobService,
    private utilService: UtilsService,
    private userService: UserService) { }

  getJobCashFlows(activeOnly: boolean, includeForecast: boolean, useCache: boolean): Observable<JobCashFlow[]> {
    if (useCache && this.jobCashFlowsCompany === this.globalService.getCurrentCompany().id
      && this.jobCashFlows && this.jobCashFlows.length
      && this.jobCashFlowsIncludeForecast === includeForecast) {
      return of(this.jobCashFlows);
    } else {
      return this._http.get<JobCashFlow[]>(this.globalService.getApiUrl() +
        '/job-cash-flows?activeOnly=' + activeOnly
        + '&includeForecast=' + includeForecast,
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.jobCashFlows = res;
            this.jobCashFlowsCompany = this.globalService.getCurrentCompany().id;
            this.jobCashFlowsIncludeForecast = includeForecast;
          }),
          catchError(this.handleError));
    }
  }

  addJobCashFlow(dataRecord: any): Observable<JobCashFlow> {
    const url = this.globalService.getApiUrl() + '/job-cash-flows';
    return this._http.post<JobCashFlow>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateJobCashFlow(id: string, itm: any): Observable<JobCashFlow> {
    const url = this.globalService.getApiUrl() + '/job-cash-flows/' + id;
    return this._http.patch<JobCashFlow>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteJobCashFlow(id: string) {
    const url = this.globalService.getApiUrl() + '/job-cash-flows/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  getCashFlowForecast(startingDate: Date = null, expectedPercentCancellation: number,
    jobId: number): Observable<CashFlowForecast[]> {
    if (!startingDate) {
      startingDate = new Date();
    }
    const startingDateString = this.utilService.convertDateToString(startingDate);

    // const startingDateString = startingDate.getFullYear() + '-'
    //   + ('0' + (startingDate.getMonth() + 1)).toString().slice(-2) + '-'
    //   + ('0' + startingDate.getDate()).slice(-2);

    let url = this.globalService.getApiUrl() + '/job-task-forecasts/cashflow?startingDateString=' + startingDateString;

    if (expectedPercentCancellation) {
      url += '&expectedPercentCancellation=' + expectedPercentCancellation;
    }
    if (jobId) {
      url += '&jobId=' + jobId;
    }

    return this._http.get<CashFlowForecast[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getTaskForecast(): Observable<CashFlowForecast[]> {
    const url = this.globalService.getApiUrl()
      + '/job-task-forecasts';

    return this._http.get<CashFlowForecast[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }


  getAllTasks(useCache: boolean): Observable<TemplateTask[]> {
    if (useCache && this.allTemplateTasksCompany === this.globalService.getCurrentCompany().id
      && this.allTemplateTasks && this.allTemplateTasks.length) {
      return of(this.allTemplateTasks);
    } else {
      return this._http.get<TemplateTask[]>(this.globalService.getApiUrl() +
        '/template-tasks?getDates=false', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.allTemplateTasks = res; this.allTemplateTasksCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }

  getClaimMasters(useCache: boolean): Observable<ClaimMaster[]> {
    if (useCache && this.claimMastersCompany === this.globalService.getCurrentCompany().id
      && this.claimMasters && this.claimMasters.length) {
      return of(this.claimMasters);
    } else {
      return this._http.get<ClaimMaster[]>(this.globalService.getApiUrl() +
        '/claim-masters', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.claimMasters = res;
            this.claimMastersCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }


  // getJobsWithSkippedTasks(): Observable<CashFlowForecast[]> {
  //   return this._http.get<CashFlowForecast[]>(this.globalService.getApiUrl() +
  //     '/job-task-forecasts/skipped-tasks', this.httpService.getHttpOptions()).pipe(
  //       tap(res => {
  //         this.skippedTasks = res;
  //       }),
  //       catchError(this.handleError));
  // }

  // Finds and purchase orders with no invoices where the linked job task is completed
  getSkippedCosts(): Observable<SkippedCost[]> {
    return this._http.get<SkippedCost[]>(this.globalService.getApiUrl() +
      '/job-task-forecasts/skipped-cost', this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.skippedCosts = res;
        }),
        catchError(this.handleError));
  }

  getJobCashFlowData(useCache: boolean): Observable<Job[]> {
    return forkJoin(
      [this.jobService.getJobsByAddress(useCache),
      this.getClaimMasters(useCache),
      this.getAllTasks(useCache),
      this.maintenanceService.getTaskControl(useCache),
      this.userService.getCurrCompUsers(useCache),
      this.getSkippedCosts()
        // this.getJobsWithSkippedTasks()
      ]
    )
      .pipe(map(
        ([jobs, claimMasters, tasks, users]) => {
          this.tasks = tasks;
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
