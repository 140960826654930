import { DocumentService } from './../../services/felixApi/document.service';
import { JobService } from './../../services/felixApi/job.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { JobDocument } from '../../dtos/job-document';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobDocumentRecordTypeEnum } from '../../dtos/job-document-record-type.enum';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'js-add-documents',
  templateUrl: './add-documents.component.html',
  styleUrls: ['./add-documents.component.scss']
})
export class AddDocumentsComponent implements OnInit, OnDestroy {
  @Input() onlySharePoint: boolean;

  subscriptions: Subscription[] = [];
  loadingDocs: boolean = false;
  popupWidth: number;
  popupHeight: number;
  jobDocuments: JobDocument[] = [];
  selectedFolder: string[] = [];
  fileValue: any[] = [];
  numberOfDocs: number;
  resultIds: string[] = [];

  constructor(
    private globalService: GlobalService,
    private jobService: JobService,
    private documentService: DocumentService,
    private activeModal: NgbActiveModal,
    private notiService: NotificationService
  ) { }

  ngOnInit() {
    this.jobDocuments = this.jobService.jobDocuments.filter(x => x.recordTypeId === JobDocumentRecordTypeEnum.Heading);

    if (this.onlySharePoint) {
      this.jobDocuments = this.jobDocuments.filter(x => x.isSharePoint);
    }

    this.popupHeight = this.globalService.innerHeight < 575 ? this.globalService.innerHeight : 575;
    this.popupWidth = this.globalService.innerWidth < 650 ? this.globalService.innerWidth : 650;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  save() {
    this.loadingDocs = true;
    this.numberOfDocs = this.fileValue.length;
    this.loadFile(0);
  }

  loadFile(fileId: number) {
    const formData: FormData = new FormData();
    formData.append('image', this.fileValue[fileId], this.fileValue[fileId].name);

    const selectedFolder = this.jobDocuments.find(x => x.keyId === this.selectedFolder[0]);

    this.subscriptions.push(
      this.documentService.uploadDocument(this.selectedFolder[0], formData, this.jobService.currentJob.jobNumber)
        .subscribe({
          next: (res) => {
            // add doc to jobDocuments
            const foundExistingDocIndex = this.jobService.jobDocuments?.findIndex(x =>
              x.jobId === this.jobService.currentJob.id
              && x.recordTypeId === JobDocumentRecordTypeEnum.Detail
              && x.parentKeyId === this.selectedFolder[0]
              && x.description === this.fileValue[fileId].name);

            if (foundExistingDocIndex >= 0) {
              this.jobService.jobDocuments.splice(foundExistingDocIndex, 1);
            }

            // res is int for jobDocAttachmentId or string for SharePoint/Google doc
            const doc = new JobDocument(isNaN(+res) ? 0 : +res,
              this.jobService.currentJob.id,
              JobDocumentRecordTypeEnum.Detail,
              this.selectedFolder[0],
              res,
              this.fileValue[fileId].name,
              isNaN(+res) ? res : '',
              selectedFolder.callUpDocsTypeId);

            this.jobService.jobDocuments.push(doc);


            this.resultIds.push(res);
            this.numberOfDocs--;
            if (this.numberOfDocs === 0) {
              this.activeModal.close(this.resultIds);
            } else {
              this.loadFile(fileId + 1);
            }
          }, error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );
  }

  close() {
    this.activeModal.dismiss();
  }

  getFileNames() {
    let result = '';
    this.fileValue.forEach(x => {
      result += x.name + '; ';
    });
    return result;
  }
}
