import { TaskStatusEnum } from './../../dtos/task-status.enum';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { TemplateTask } from '../../dtos/templateTask';
import { TaskDependency } from '../../dtos/task-dependency';
import { TemplateTaskHeader } from '../../dtos/templateTaskHeader';
import { TaskType } from '../../dtos/task-type';
import { CompanyActivity } from '../../dtos/company-activity';
import { TemplateDaysHeader } from '../../dtos/template-days-header';
import { TemplateDays } from '../../dtos/template-days';
import { TaskControl } from '../../dtos/task-control';
import { UserService } from './user.service';
import { TaskMaster } from '../../dtos/task-master';
import { CompanyService } from './company.service';
import { Holiday } from '../../dtos/holiday';
import { Trade } from '../../dtos/trade';
import { TradeRegion } from '../../dtos/trade-region';
import { TradeVendor } from '../../dtos/trade-vendor';
import { CompletionDates } from '../../dtos/completion-dates';
import { Task } from '../../dtos/task';
import { CallUpDocsType } from '../../dtos/call-up-docs-type';
import { TemplateTaskDocsType } from '../../dtos/template-task-docs-type';
import { TaskDependencyTypeEnum } from '../../dtos/task-dependency-type.enum';
import { Phase } from '../../dtos/phase';
import { PriceFileItem } from '../../dtos/price-file-item';
import { PriceFileItemTypeEnum } from '../../dtos/price-file-item-type.enum';
import { PurchaseOrder } from '../../dtos/purchase-order';
import { OrderResponse } from '../../dtos/order-response';
import { HttpService } from '../http.service';
import { Division } from '../../dtos/division';
import { UnitOfMeasure } from '../../dtos/unitOfMeasure';
import { Lookup } from '../../dtos/lookup';
import { TemplateTaskCostCentre } from '../../dtos/template-task-cost-centre';
import { TemplateTaskPurchaseOrder } from '../../dtos/template-task-purchase-order';
import { UtilsService } from '../utils.service';
import { formatDate } from 'devextreme/localization';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  taskDependencies: TaskDependency[];
  newStart: Date;
  newEnd: Date;
  activities: CompanyActivity[] = [];
  taskHeaders: TemplateTaskHeader[];
  templateDaysHeaders: TemplateDaysHeader[] = [];
  // allTaskHeaders: TemplateTask[];
  taskTypes: TaskType[];
  templateTasks: TemplateTask[];
  taskMasters: TaskMaster[];
  allTemplateTasks: TemplateTask[];
  trades: Trade[] = [];
  tradeRegions: TradeRegion[] = [];
  tradeVendors: TradeVendor[] = [];
  tradeVendorsCompany: number;
  tradeRegionsCompany: number;
  tradesCompany: number;
  taskMastersCompany: number;
  templateDaysHeadersCompany: number;
  taskTypesCompany: number;
  taskHeadersCompany: number;
  allTemplateTasksCompany: number;
  activitiesCompany: number;
  activitiesActiveOnly: boolean;
  taskDependenciesCompany: number;
  holidays: Holiday[] = [];
  holidaysCompany: number;
  templateDays: TemplateDays[] = [];
  templateDaysCompany: number;
  callUpDocsTypes: CallUpDocsType[];
  callUpDocsTypesCompany: number;
  cachCompanyPhases: string;
  phases: Phase[];
  priceFileItemGroups: PriceFileItem[];
  cachCompanyPriceFileItemGroups: string;
  purchaseOrders: PurchaseOrder[];
  costCentres: PriceFileItem[];
  allPurchaseOrdersForActiveJobs: PurchaseOrder[];
  allPurchaseOrdersForActiveJobsCompanyId: string;
  taskControl: TaskControl;
  divisions: Division[];
  divisionsCompany: string;
  unitOfMeasures: UnitOfMeasure[];
  cachCompanyUnitOfMeasures: string;
  allTemplateTasksGetDates: boolean;
  landZones: Lookup[];
  landTitleTypes: Lookup[];
  landTypes: Lookup[];
  taskControlCompany: number;
  templateTaskCostCentres: TemplateTaskCostCentre[] = [];
  templateTaskDocsTypes: TemplateTaskDocsType[] = [];
  templateTaskDocsTypesCompany: string;

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService,
    private companyService: CompanyService,
    private userService: UserService,
    private utilsService: UtilsService
  ) { }


  getAllTemplateTasks(getDates: boolean): Observable<TemplateTask[]> {
    if (this.allTemplateTasksCompany === this.globalService.getCurrentCompany().id
      && this.allTemplateTasks && this.allTemplateTasks.length
      && this.allTemplateTasksGetDates === getDates) {
      return of(this.allTemplateTasks);
    } else {
      const url = this.globalService.getApiUrl() + '/template-tasks?getDates=' + getDates;
      return this._http.get<TemplateTask[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.allTemplateTasks = res;
          this.allTemplateTasksCompany = this.globalService.getCurrentCompany().id;
          this.allTemplateTasksGetDates = getDates;
        }),
        catchError(this.handleError));
    }
  }

  getTemplateTasks(taskHeaderId: number, getDates: boolean): Observable<TemplateTask[]> {
    const url = this.globalService.getApiUrl() + '/template-task-headers/' + taskHeaderId + '/template-tasks?getDates=' + getDates;

    return this._http.get<TemplateTask[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.templateTasks = res;
      }),
      catchError(this.handleError));
  }

  getTaskDependencies(useCache: boolean): Observable<TaskDependency[]> {
    if (useCache && this.taskDependenciesCompany === this.globalService.getCurrentCompany().id
      && this.taskDependencies && this.taskDependencies.length) {
      return of(this.taskDependencies);
    } else {
      const url = this.globalService.getApiUrl() + '/task-dependencies';

      return this._http.get<TaskDependency[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.taskDependencies = res;
          this.taskDependenciesCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  getTemplateTaskData(taskHeaderId: number, getDates: boolean): Observable<TemplateTask[]> {
    return forkJoin(
      [this.getTemplateTasksWithCallUpDocs(taskHeaderId, getDates),
      this.getTaskDependencies(false),
      this.getTaskTypes(true)]
    )
      .pipe(map(
        ([templateTasks]) => {
          return templateTasks;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getTemplateTaskHeaders(useCache: boolean, activeOnly: boolean): Observable<TemplateTaskHeader[]> {
    if (useCache && this.taskHeadersCompany === this.globalService.getCurrentCompany().id
      && this.taskHeaders && this.taskHeaders.length) {
      return of(this.taskHeaders);
    } else {
      const url = this.globalService.getApiUrl() + '/template-task-headers/';
      return this._http.get<TemplateTaskHeader[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          if (activeOnly) {
            res = res.filter(i => i.isActive);
          }
          this.taskHeaders = res;
          this.taskHeadersCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  getTemplateTaskHeadersFromAnotherCompany(companyId: number): Observable<TemplateTaskHeader[]> {
    const url = this.globalService.getApiUrl() + '/template-task-headers/another-company?companyId=' + companyId;
    return this._http.get<TemplateTaskHeader[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  addTemplateTaskHeader(dataRecord: any): Observable<TemplateTaskHeader> {
    const url = this.globalService.getApiUrl() + '/template-task-headers/';
    return this._http.post<TemplateTaskHeader>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateTemplateTaskHeader(id: string, itm: any): Observable<TemplateTaskHeader> {
    const url = this.globalService.getApiUrl() + '/template-task-headers/' + id;
    return this._http.patch<TemplateTaskHeader>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteTemplateTaskHeader(id: string) {
    const url = this.globalService.getApiUrl() + '/template-task-headers/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }




  getTaskTypes(useCache: boolean): Observable<TaskType[]> {
    if (useCache && this.taskTypesCompany === this.globalService.getCurrentCompany().id
      && this.taskTypes && this.taskTypes.length) {
      return of(this.taskTypes);
    } else {
      const url = this.globalService.getApiUrl() + '/task-types/';
      return this._http.get<TaskType[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.taskTypes = res;
          this.taskTypesCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  addTaskType(dataRecord: any): Observable<TaskType> {
    const url = this.globalService.getApiUrl() + '/task-types/';
    return this._http.post<TaskType>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateTaskType(id: string, itm: any): Observable<TaskType> {
    const url = this.globalService.getApiUrl() + '/task-types/' + id;
    return this._http.patch<TaskType>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteTaskType(id: string) {
    const url = this.globalService.getApiUrl() + '/task-types/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  moveTaskType(id: string, orderNumber: any): Observable<TaskType> {
    const url = this.globalService.getApiUrl() + '/task-types/move/' + id + '?orderNumber=' + orderNumber;
    return this._http.patch<TaskType>(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }



  addTemplateTask(taskHeaderId: number, dataRecord: any): Observable<TemplateTask> {
    this.allTemplateTasks = [];
    const url = this.globalService.getApiUrl() + '/template-task-headers/' + taskHeaderId + '/template-tasks';
    return this._http.post<TemplateTask>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateTemplateTask(id: number, itm: any): Observable<TemplateTask> {
    this.allTemplateTasks = [];
    const url = this.globalService.getApiUrl() + '/template-tasks/' + id;
    return this._http.patch<TemplateTask>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteTemplateTask(id: number) {
    this.allTemplateTasks = [];
    const url = this.globalService.getApiUrl() + '/template-tasks/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  copyTemplateTasks(idFrom: number, idTo: number): Observable<number> {
    const url = this.globalService.getApiUrl() + '/template-task-headers/copy/' + idFrom + '/to/' + idTo;
    return this._http.post<number>(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  copyTemplateTasksFromAnotherCompany(companyIdFrom: number, templateTaskHeaderIdFrom: number,
    templateTaskHeaderIdTo: number): Observable<number> {
    const url = this.globalService.getApiUrl() + '/template-tasks/copy-from-another-company?companyIdFrom=' + companyIdFrom
      + '&templateTaskHeaderIdFrom=' + templateTaskHeaderIdFrom
      + '&templateTaskHeaderIdTo=' + templateTaskHeaderIdTo;
    return this._http.post<number>(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }



  addTaskDependency(dataRecord: any): Observable<TaskDependency> {
    const url = this.globalService.getApiUrl() + '/task-dependencies';
    return this._http.post<TaskDependency>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateTaskDependency(id: number, itm: any): Observable<TaskDependency> {
    const url = this.globalService.getApiUrl() + '/task-dependencies/' + id;
    return this._http.patch<TaskDependency>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteTaskDependency(taskPredecessorId: number, taskSuccessorId: number) {
    const url = this.globalService.getApiUrl() + '/task-dependencies/by-tasks'
      + '?taskPredecessorId=' + taskPredecessorId
      + '&taskSuccessorId=' + taskSuccessorId;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  getCompanyActivities(activeOnly: boolean, useCache: boolean) {
    if (useCache && this.activitiesActiveOnly === activeOnly
      && this.activitiesCompany === this.globalService.getCurrentCompany().id
      && this.activities && this.activities.length) {
      return of(this.activities);
    } else {
      const url = this.globalService.getApiUrl() + '/activities?activeOnly=' + activeOnly;
      return this._http.get<CompanyActivity[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.activities = res;
          this.activitiesActiveOnly = activeOnly;
          this.activitiesCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  getTaskData(): Observable<TaskType[]> {
    return forkJoin(
      [this.getTaskTypes(true),
      this.getCompanyActivities(true, true),
      this.getTaskMasters(true),
      // this.userService.getCurrCompUsers(true),
      this.getTaskControlData(true),
      this.companyService.getCompanyRoles(),
      this.getTaskData2()
      ]
    )
      .pipe(map(
        ([taskTypes]) => {
          return taskTypes;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getTaskData2(): Observable<Trade[]> {
    return forkJoin(
      [
        this.getTrades(),
        this.getTradeRegions(),
        this.userService.getVendors(true, true),
        this.getCallUpDocsTypes(true),
        this.getPhases(true),
        this.getPriceFileItemGroups(true)
      ]
    )
      .pipe(map(
        ([trades]) => {
          return trades;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }



  getPhases(useCache: boolean = true): Observable<Phase[]> {
    const url = this.globalService.getApiUrl() + '/phases';

    if (useCache && this.phases && this.phases.length && this.cachCompanyPhases === this.globalService.getCurrentCompanyId()) {
      return of(this.phases);
    } else {
      return this._http.get<Phase[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.phases = res; this.cachCompanyPhases = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getPriceFileItemGroups(useCache: boolean): Observable<PriceFileItem[]> {
    if (useCache && this.priceFileItemGroups && this.priceFileItemGroups.length
      && this.cachCompanyPriceFileItemGroups === this.globalService.getCurrentCompanyId()) {
      return of(this.priceFileItemGroups);
    } else {
      let url = this.globalService.getApiUrl() + '/price-file-items';

      url += '?priceFileItemTypeId=' + PriceFileItemTypeEnum.Group;

      url += '&includeCostCentreData=' + true;

      return this._http.get<PriceFileItem[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.priceFileItemGroups = res; this.cachCompanyPriceFileItemGroups = this.globalService.getCurrentCompanyId();
          this.costCentres = res.filter(i => !i.priceFileItemParentId);
        }),
        catchError(this.handleError));
    }
  }

  getPurchaseOrders(jobId: number): Observable<PurchaseOrder[]> {
    return this._http.get<PurchaseOrder[]>(this.globalService.getApiUrl() +
      '/purchase-orders?jobId=' + jobId, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.purchaseOrders = res;
        }),
        catchError(this.handleError));
  }

  getAllPurchaseOrders(): Observable<PurchaseOrder[]> {
    if (this.allPurchaseOrdersForActiveJobs && this.allPurchaseOrdersForActiveJobs.length
      && this.allPurchaseOrdersForActiveJobsCompanyId === this.globalService.getCurrentCompanyId()) {
      return of(this.allPurchaseOrdersForActiveJobs);
    } else {
      return this._http.get<PurchaseOrder[]>(this.globalService.getApiUrl() +
        '/purchase-orders/active-jobs', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.allPurchaseOrdersForActiveJobs = res;
            this.allPurchaseOrdersForActiveJobsCompanyId = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  getPurchaseOrdersAndCostCentresForJob(jobId: number): Observable<PurchaseOrder[]> {
    return forkJoin(
      [this.getPurchaseOrders(jobId),
      this.getPriceFileItemGroups(true)]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  sendPurchaseOrders(jobId: number, purchaseOrderDto: any): Observable<OrderResponse> {
    return this._http.post<OrderResponse>(this.globalService.getApiUrl() +
      '/purchase-orders/' + jobId + '/send-pdfs', JSON.stringify(purchaseOrderDto), this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getUnitOfMeasures(useCache: boolean = true): Observable<UnitOfMeasure[]> {
    const url = this.globalService.getApiUrl() + '/company-units';

    if (useCache && this.unitOfMeasures && this.unitOfMeasures.length
      && this.cachCompanyUnitOfMeasures === this.globalService.getCurrentCompanyId()) {
      return of(this.unitOfMeasures);
    } else {
      return this._http.get<UnitOfMeasure[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.unitOfMeasures = res; this.cachCompanyUnitOfMeasures = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }




  getTemplateDaysHeaders(useCache: boolean = true): Observable<TemplateDaysHeader[]> {
    if (useCache && this.templateDaysHeadersCompany === this.globalService.getCurrentCompany().id
      && this.templateDaysHeaders && this.templateDaysHeaders.length) {
      return of(this.templateDaysHeaders);
    } else {
      const url = this.globalService.getApiUrl() + '/template-days-headers/';
      return this._http.get<TemplateDaysHeader[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.templateDaysHeaders = res;
          this.templateDaysHeadersCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  addTemplateDaysHeader(dataRecord: any): Observable<TemplateDaysHeader> {
    const url = this.globalService.getApiUrl() + '/template-days-headers/';
    return this._http.post<TemplateDaysHeader>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateTemplateDaysHeader(id: string, itm: any): Observable<TemplateDaysHeader> {
    const url = this.globalService.getApiUrl() + '/template-days-headers/' + id;
    return this._http.patch<TemplateDaysHeader>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteTemplateDaysHeader(id: string) {
    const url = this.globalService.getApiUrl() + '/template-days-headers/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }




  getTemplateDays(useCache: boolean = false): Observable<TemplateDays[]> {
    if (useCache && this.templateDaysCompany === this.globalService.getCurrentCompany().id
      && this.templateDays && this.templateDays.length) {
      return of(this.templateDays);
    } else {
      const url = this.globalService.getApiUrl() + '/template-task-days/';
      return this._http.get<TemplateDays[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.templateDays = res;
          this.templateDaysCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  addTemplateDay(dataRecord: any): Observable<TemplateDays> {
    const url = this.globalService.getApiUrl() + '/template-task-days/';
    return this._http.post<TemplateDays>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateTemplateDay(id: string, itm: any): Observable<TemplateDays> {
    const url = this.globalService.getApiUrl() + '/template-task-days/' + id;
    return this._http.patch<TemplateDays>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteTemplateDay(id: string) {
    const url = this.globalService.getApiUrl() + '/template-task-days/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  getTemplateDaysData(): Observable<TemplateTask[]> {
    return forkJoin(
      [this.getAllTemplateTasks(false),
      this.getTaskDependencies(false),
      this.getTaskTypes(true)]
    )
      .pipe(map(
        ([templateTasks]) => {
          return templateTasks;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }




  getHolidays(useCache: boolean): Observable<Holiday[]> {
    if (useCache && this.holidaysCompany === this.globalService.getCurrentCompany().id
      && this.holidays && this.holidays.length) {
      return of(this.holidays);
    } else {
      const url = this.globalService.getApiUrl() + '/holidays/';
      return this._http.get<Holiday[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.holidays = res;
          this.holidaysCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  addHoliday(dataRecord: any): Observable<Holiday> {
    const url = this.globalService.getApiUrl() + '/holidays/';
    return this._http.post<Holiday>(url, JSON.stringify(dataRecord, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  updateHoliday(id: string, itm: any): Observable<Holiday> {
    const url = this.globalService.getApiUrl() + '/holidays/' + id;
    return this._http.patch<Holiday>(url, JSON.stringify(itm, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  deleteHoliday(id: string) {
    const url = this.globalService.getApiUrl() + '/holidays/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }




  getTaskControl(useCache): Observable<TaskControl> {
    if (useCache && this.taskControl && this.taskControlCompany === this.globalService.getCurrentCompany().id) {
      return of(this.taskControl);
    } else {
      const url = this.globalService.getApiUrl() + '/task-controls/';
      return this._http.get<TaskControl>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.taskControl = res;
          this.taskControlCompany = this.globalService.getCurrentCompany().id
        }),
        catchError(this.handleError));
    }
  }

  addTaskControl(dataRecord: any): Observable<TaskControl> {
    const url = this.globalService.getApiUrl() + '/task-controls/';
    return this._http.post<TaskControl>(url, JSON.stringify(dataRecord, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  updateTaskControl(id: number, itm: any): Observable<TaskControl> {
    const url = this.globalService.getApiUrl() + '/task-controls/' + id;
    return this._http.patch<TaskControl>(url, JSON.stringify(itm, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  getTaskControlData(useCache: boolean): Observable<TaskControl> {
    return forkJoin(
      [
        this.getTaskControl(false),
        this.userService.getCurrCompUsers(useCache)
      ]
    )
      .pipe(
        map(([taskControl]) => { return taskControl; }),
        catchError((err) => { return this.handleError(err); })
      );
  }

  getTaskMasters(useCache: boolean): Observable<TaskMaster[]> {
    if (useCache && this.taskMastersCompany === this.globalService.getCurrentCompany().id
      && this.taskMasters && this.taskMasters.length) {
      return of(this.taskMasters);
    } else {
      const url = this.globalService.getApiUrl() + '/task-masters/';

      return this._http.get<TaskMaster[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.taskMasters = res;
          this.taskMastersCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  addTaskMaster(dataRecord: any): Observable<TaskMaster> {
    const url = this.globalService.getApiUrl() + '/task-masters/';
    return this._http.post<TaskMaster>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.taskMasters.push(res);
        this.taskMasters.sort(this.globalService.sortBy('taskTypeId', this.globalService.sortBy('orderNumber', false)));
      }),
      catchError(this.handleError));
  }

  updateTaskMaster(id: string, itm: any): Observable<TaskMaster> {
    const url = this.globalService.getApiUrl() + '/task-masters/' + id;
    return this._http.patch<TaskMaster>(url, JSON.stringify(itm), this.httpService.getHttpOptions()).pipe(
      tap(res => {
        const foundId = this.taskMasters.findIndex(i => i.id === res.id);
        if (foundId >= 0) {
          this.taskMasters.splice(foundId, 1);
        }
        this.taskMasters.push(res);
        this.taskMasters.sort(this.globalService.sortBy('taskTypeId', this.globalService.sortBy('orderNumber', false)));
      }),
      catchError(this.handleError));
  }

  deleteTaskMaster(id: string) {
    const url = this.globalService.getApiUrl() + '/task-masters/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  uploadTaskMasterExcel(xlFile) {
    const options = this.httpService.getHttpFileOptions();
    return this._http.post(this.globalService.getApiUrl()
      + '/task-masters/load-excel/', xlFile, options)
      .pipe(
        catchError(this.handleError.bind(this)));
  }

  uploadTemplateTasksExcel(taskHeaderId: number, xlFile) {
    const options = this.httpService.getHttpFileOptions();
    return this._http.post(this.globalService.getApiUrl()
      + '/template-tasks/' + taskHeaderId + '/load-excel', xlFile, options)
      .pipe(
        catchError(this.handleError.bind(this)));
  }




  getTrades(useCache: boolean = true): Observable<Trade[]> {
    if (useCache && this.tradesCompany === this.globalService.getCurrentCompany().id
      && this.trades && this.trades.length) {
      return of(this.trades);
    } else {
      const url = this.globalService.getApiUrl() + '/trades/';
      return this._http.get<Trade[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.trades = res;
          this.tradesCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  addTrade(dataRecord: any): Observable<Trade> {
    const url = this.globalService.getApiUrl() + '/trades/';
    return this._http.post<Trade>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateTrade(id: string, itm: any): Observable<Trade> {
    const url = this.globalService.getApiUrl() + '/trades/' + id;
    return this._http.patch<Trade>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteTrade(id: string) {
    const url = this.globalService.getApiUrl() + '/trades/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }




  getTradeRegions(useCache: boolean = true): Observable<TradeRegion[]> {
    if (useCache && this.tradeRegionsCompany === this.globalService.getCurrentCompany().id
      && this.tradeRegions && this.tradeRegions.length) {
      return of(this.tradeRegions);
    } else {
      const url = this.globalService.getApiUrl() + '/trade-regions/';
      return this._http.get<TradeRegion[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.tradeRegions = res;
          this.tradeRegionsCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  addTradeRegion(dataRecord: any): Observable<TradeRegion> {
    const url = this.globalService.getApiUrl() + '/trade-regions/';
    return this._http.post<TradeRegion>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateTradeRegion(id: string, itm: any): Observable<TradeRegion> {
    const url = this.globalService.getApiUrl() + '/trade-regions/' + id;
    return this._http.patch<TradeRegion>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteTradeRegion(id: string) {
    const url = this.globalService.getApiUrl() + '/trade-regions/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  moveTradeRegion(id: string, orderNumber: number) {
    const url = this.globalService.getApiUrl() + '/trade-regions/move/' + id + '?orderNumber=' + orderNumber;
    return this._http.patch(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }


  getTradeVendors(useCache: boolean = true): Observable<TradeVendor[]> {
    if (useCache && this.tradeVendorsCompany === this.globalService.getCurrentCompany().id
      && this.tradeVendors && this.tradeVendors.length) {
      return of(this.tradeVendors);
    } else {
      const url = this.globalService.getApiUrl() + '/trade-vendors/';
      return this._http.get<TradeVendor[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.tradeVendors = res;
          this.tradeVendorsCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }


  getTradeVendorsForRegionAndTrade(tradeRegionId: number, tradeId: number): Observable<TradeVendor[]> {
    const url = this.globalService.getApiUrl() + '/trade-vendors?tradeId=' + tradeId + '&tradeRegionId=' + tradeRegionId;
    return this._http.get<TradeVendor[]>(url, this.httpService.getHttpOptions());
  }

  addTradeVendor(dataRecord: any): Observable<TradeVendor> {
    const url = this.globalService.getApiUrl() + '/trade-vendors/';
    return this._http.post<TradeVendor>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateTradeVendor(id: string, itm: any): Observable<TradeVendor> {
    const url = this.globalService.getApiUrl() + '/trade-vendors/' + id;
    return this._http.patch<TradeVendor>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteTradeVendor(id: string) {
    const url = this.globalService.getApiUrl() + '/trade-vendors/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  getTradeVendorsData(useCache: boolean = false): Observable<Trade[]> {
    return forkJoin(
      [
        this.getTrades(useCache),
        this.getTradeRegions(useCache),
        this.userService.getVendors(useCache, true),
      ]
    )
      .pipe(map(
        ([data]) => {
          return data;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }




  // calculate the days diff excluding weekends
  calcDays(end: Date, startDate: Date): number {
    if (startDate instanceof Date) {
      this.newStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    } else {
      // use JSON to get the date
      const estDate = JSON.stringify(startDate).substr(1, 10);
      this.newStart = new Date(+estDate.substr(0, 4), +estDate.substr(5, 2) - 1, +estDate.toString().substr(8, 2));
    }

    if (end instanceof Date) {
      this.newEnd = new Date(end.getFullYear(), end.getMonth(), end.getDate());
    } else {
      // use JSON to get the date
      const estDate = JSON.stringify(end).substr(1, 10);
      this.newEnd = new Date(+estDate.substr(0, 4), +estDate.substr(5, 2) - 1, +estDate.toString().substr(8, 2));
    }

    this.newStart.setHours(0, 0, 0, 0);
    this.newEnd.setHours(0, 0, 0, 0);
    let days = 0;

    while (this.newStart.getTime() < this.newEnd.getTime()) {
      this.newStart = new Date(this.newStart.getTime() + (60 * 60 * 24 * 1000));

      if (this.newStart.getDay() !== 0 && this.newStart.getDay() !== 6) {
        days++;
      }
    }

    return days;
  }

  // calc days excluding holidays
  addDaysExHolidays(startDate: Date, days): Date {
    if (!days || days === 0) {
      return startDate;
    }

    if (!startDate) {
      return null;
    }

    const newDate = new Date(startDate.valueOf());
    let dayCount = 0;

    // we may be going + or - days
    if (days > 0) {
      while (dayCount < days) {
        newDate.setDate(newDate.getDate() + 1);

        const dateString = newDate.getFullYear() + '-'
          + ('0' + (newDate.getMonth() + 1)).toString().slice(-2) + '-'
          + ('0' + newDate.getDate()).slice(-2);

        if (newDate.getDay() !== 6 && newDate.getDay() !== 0 && !this.holidays.find(i => i.date.toString().substr(0, 10) === dateString)) {
          dayCount++;
        }
      }
    } else {
      while (dayCount > days) {
        newDate.setDate(newDate.getDate() - 1);

        const dateString = newDate.getFullYear() + '-'
          + ('0' + (newDate.getMonth() + 1)).toString().slice(-2) + '-'
          + ('0' + newDate.getDate()).slice(-2);

        if (newDate.getDay() !== 6 && newDate.getDay() !== 0 && !this.holidays.find(i => i.date.toString().substr(0, 10) === dateString)) {
          dayCount--;
        }
      }
    }
    return newDate;
  }

  // calc days excluding holidays
  addDays(startDate: Date, days, isWorkingDays: boolean = false): Date {
    if (isWorkingDays) {
      return this.addDaysExHolidays(startDate, days);
    } else {
      if (!days || days === 0) {
        return startDate;
      }

      if (!startDate) {
        return null;
      }

      const newDate = new Date(startDate.valueOf());
      let dayCount = 0;

      if (days > 0) {
        while (dayCount < days) {
          newDate.setDate(newDate.getDate() + 1);

          dayCount++;
        }
      } else {
        while (dayCount > days) {
          newDate.setDate(newDate.getDate() - 1);

          dayCount--;
        }
      }

      return newDate;
    }
  }


  // calc target and forecast completion dates from workflow for construction
  calcCompletionDates(taskMasterId: number, startDate: Date, endDate: Date,
    templateDaysHeaderId: number, jobTasks: Task[]): CompletionDates {
    const completionDates = new CompletionDates;

    // clear out any previous dates
    this.templateTasks.forEach(templateTask => {
      templateTask.start = null;
      templateTask.end = null;
    });

    // get starting task
    const thisTemplateTask = this.templateTasks.find(i => i.taskMasterId === taskMasterId);

    let completionTask = this.templateTasks.find(i => i.isPracticalCompletionTask);

    if (thisTemplateTask?.isForecastStart) {
      // clear out any previous dates
      this.templateTasks.forEach(templateTask => {
        templateTask.start = null;
        templateTask.end = null;
      });

      this.updateTaskDates(thisTemplateTask?.id, startDate, endDate, templateDaysHeaderId, [], true);
      // completionTask = this.templateTasks.find(i => i.isPracticalCompletionTask);
      completionDates.targetCompletionDate = completionTask?.end;
    }

    this.updateTaskDates(thisTemplateTask?.id, startDate, endDate, templateDaysHeaderId, jobTasks, false);
    completionDates.forecastCompletionDate = completionTask?.end;

    return completionDates;
  }

  updateTaskDates(templateTaskId: number, startDate: Date, endDate: Date,
    templateDaysHeaderId: number, jobTasks: Task[], isSiteStartTask: boolean) {

    // get the current task following tasks
    const successorDependencies = this.taskDependencies.filter(i => i.taskPredecessorId === templateTaskId);

    successorDependencies.forEach(successorDependency => {
      const successorTask = this.templateTasks.find(i => i.id === successorDependency.taskSuccessorId);

      if (successorTask) {
        let startDateToUse = endDate;
        if (successorDependency.dependencyTypeId === TaskDependencyTypeEnum.StartToStart) {
          startDateToUse = startDate ? startDate : endDate;
        }

        // if we have a job task we use the due or end date if set
        const jobTask = jobTasks.find(i => i.taskMasterId === successorTask.taskMasterId
          && i.statusId !== TaskStatusEnum.Cancelled);

        // if not applicable we treat the days as zero and ignore the task start and end dates
        if (jobTask && jobTask.statusId === TaskStatusEnum.NotApplicable) {
          successorTask.start = null;
          successorTask.end = startDateToUse;
        } else if (!isSiteStartTask && jobTask && (jobTask.dueDate || jobTask.endDate)) {
          successorTask.start = jobTask.startDate;
          successorTask.end = jobTask.endDate ? jobTask.endDate : jobTask.dueDate;
        } else {
          successorTask.start = !isSiteStartTask && jobTask?.startDate ? jobTask.startDate : startDateToUse;
          const taskMaster = this.taskMasters.find(i => i.id === successorTask.taskMasterId);

          let days = taskMaster?.days ? taskMaster.days : 0;
          // do we have overriding days
          if (jobTask && jobTask.manualDays) {
            days = jobTask.manualDays;
          } else if (this.templateDays && this.templateDays.length) {
            const templateDays = this.templateDays
              .find(i => i.templateDaysHeaderId === templateDaysHeaderId && i.templateTaskId === successorTask.id);

            days = templateDays?.days ? templateDays.days : days;
          }

          if (successorTask?.leadDays) {
            successorTask.start = this.addDaysExHolidays(successorTask.start, successorTask.leadDays);
          }

          const tempEndDate = this.addDaysExHolidays(successorTask.start, days);

          // we may have multiple dependencies so we need the latest date
          if (!successorTask.end || successorTask.end < tempEndDate) {
            successorTask.end = tempEndDate;
          }
        }

        this.updateTaskDates(successorTask.id, successorTask.start, successorTask.end, templateDaysHeaderId, jobTasks, isSiteStartTask);
      }
    });
  }



  getCallUpDocsTypes(useCache: boolean): Observable<CallUpDocsType[]> {
    if (useCache && this.callUpDocsTypesCompany === this.globalService.getCurrentCompany().id
      && this.callUpDocsTypes && this.callUpDocsTypes.length) {
      return of(this.callUpDocsTypes);
    } else {
      const url = this.globalService.getApiUrl() + '/call-up-docs-types/';
      return this._http.get<CallUpDocsType[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.callUpDocsTypes = res;
          this.callUpDocsTypesCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  addCallUpDocsType(dataRecord: any): Observable<CallUpDocsType> {
    const url = this.globalService.getApiUrl() + '/call-up-docs-types/';
    return this._http.post<CallUpDocsType>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateCallUpDocsType(id: string, itm: any): Observable<CallUpDocsType> {
    const url = this.globalService.getApiUrl() + '/call-up-docs-types/' + id;
    return this._http.patch<CallUpDocsType>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteCallUpDocsType(id: string) {
    const url = this.globalService.getApiUrl() + '/call-up-docs-types/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }


  getTemplateTaskDocsTypes(useCache: boolean): Observable<TemplateTaskDocsType[]> {
    if (useCache && this.templateTaskDocsTypesCompany && this.templateTaskDocsTypesCompany.length
      && this.templateTaskDocsTypesCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.templateTaskDocsTypes);
    }

    const url = this.globalService.getApiUrl() + '/template-task-docs-types/';
    return this._http.get<TemplateTaskDocsType[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.templateTaskDocsTypes = res;
        this.templateTaskDocsTypesCompany = this.globalService.getCurrentCompanyId();
      }),
      catchError(this.handleError));
  }

  getTemplateTasksWithCallUpDocs(taskHeaderId: number, getDates: boolean): Observable<TemplateTask[]> {
    return forkJoin(
      [this.getTemplateTasks(taskHeaderId, getDates),
      this.getTemplateTaskDocsTypes(false)]
    )
      .pipe(map(
        ([templateTasks, templateTaskDocsTypes]) => {
          this.templateTasks.forEach(templateTask => {
            templateTask.callUpDocsTypes = [];
            templateTaskDocsTypes.filter(i => i.templateTaskId === templateTask.id).forEach(templateTaskDocsType => {
              templateTask.callUpDocsTypes.push(templateTaskDocsType.callUpDocsTypeId);
            });
          });
          return this.templateTasks;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getAllTemplateTasksWithCallUpDocs(): Observable<TemplateTask[]> {
    return forkJoin(
      [this.getAllTemplateTasks(false),
      this.getTemplateTaskDocsTypes(false)]
    )
      .pipe(map(
        ([templateTasks, templateTaskDocsTypes]) => {
          this.allTemplateTasks.forEach(templateTask => {
            templateTask.callUpDocsTypes = [];
            templateTaskDocsTypes.filter(i => i.templateTaskId === templateTask.id).forEach(templateTaskDocsType => {
              templateTask.callUpDocsTypes.push(templateTaskDocsType.callUpDocsTypeId);
            });
          });
          return this.allTemplateTasks;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  getDivisions() {
    if (this.divisions && this.divisions.length
      && this.divisionsCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.divisions);
    } else {
      const url = this.globalService.getApiUrl() + '/divisions/';
      return this._http.get<Division[]>(url, this.httpService.getHttpOptions())
        .pipe(
          tap(res => {
            this.divisions = res;
            this.divisionsCompany = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError)
        );
    }
  }


  getLandZones(): Observable<Lookup[]> {
    if (this.landZones && this.landZones.length) {
      return of(this.landZones);
    }
    return this._http.get<Lookup[]>(this.globalService.getApiUrl() + '/land-zones',
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.landZones = res;
        }),
        catchError(this.handleError));
  }

  getLandTitleTypes(): Observable<Lookup[]> {
    if (this.landTitleTypes && this.landTitleTypes.length) {
      return of(this.landTitleTypes);
    }
    return this._http.get<Lookup[]>(this.globalService.getApiUrl() + '/land-title-types',
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.landTitleTypes = res;
        }),
        catchError(this.handleError));
  }

  getLandTypes(): Observable<Lookup[]> {
    if (this.landTypes && this.landTypes.length) {
      return of(this.landTypes);
    }
    return this._http.get<Lookup[]>(this.globalService.getApiUrl() + '/land-types',
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.landTypes = res;
        }),
        catchError(this.handleError));
  }



  // template task cost centres for cash flow
  getTemplateTaskCostCentres(templateTaskHeaderId: number, templateTaskId: number): Observable<TemplateTaskCostCentre[]> {
    const url = this.globalService.getApiUrl() + '/template-task-cost-centres?templateTaskHeaderId='
      + templateTaskHeaderId + '&templateTaskId=' + templateTaskId;
    return this._http.get<TemplateTaskCostCentre[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  addTemplateTaskCostCentre(dataRecord: any): Observable<TemplateTaskCostCentre> {
    const url = this.globalService.getApiUrl() + '/template-task-cost-centres/';
    return this._http.post<TemplateTaskCostCentre>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateTemplateTaskCostCentre(id: string, itm: any): Observable<TemplateTaskCostCentre> {
    const url = this.globalService.getApiUrl() + '/template-task-cost-centres/' + id;
    return this._http.patch<TemplateTaskCostCentre>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteTemplateTaskCostCentre(id: string) {
    const url = this.globalService.getApiUrl() + '/template-task-cost-centres/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }



  // template task purchase orders - used for auto adding extra POs to a call-up
  getTemplateTaskPurchaseOrders(templateTaskHeaderId: number): Observable<TemplateTaskPurchaseOrder[]> {
    const url = this.globalService.getApiUrl() + '/template-task-purchase-orders?templateTaskHeaderId='
      + templateTaskHeaderId;
    return this._http.get<TemplateTaskPurchaseOrder[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  addTemplateTaskPurchaseOrder(dataRecord: any): Observable<TemplateTaskPurchaseOrder> {
    const url = this.globalService.getApiUrl() + '/template-task-purchase-orders/';
    return this._http.post<TemplateTaskPurchaseOrder>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateTemplateTaskPurchaseOrder(id: string, itm: any): Observable<TemplateTaskPurchaseOrder> {
    const url = this.globalService.getApiUrl() + '/template-task-purchase-orders/' + id;
    return this._http.patch<TemplateTaskPurchaseOrder>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteTemplateTaskPurchaseOrder(id: string) {
    const url = this.globalService.getApiUrl() + '/template-task-purchase-orders/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }




  getCalendarDaysDifferenceExHolidays(dateTo: Date, dateFrom: any) {
    let diffDays = this.utilsService.getCalendarDaysDifference(dateTo, dateFrom);

    if (!diffDays) {
      return null;
    }

    // exclude holidays
    let newDateFrom: Date;
    if (!(dateFrom instanceof Date)) {
      newDateFrom = new Date(+dateFrom.substr(0, 4), +dateFrom.substr(5, 2) - 1, +dateFrom.substr(8, 2), 0, 0, 0, 0);
    } else {
      const dateFromString = formatDate(dateFrom, 'yyyy-MM-dd');
      newDateFrom = new Date(+dateFromString.substr(0, 4), +dateFromString.substr(5, 2) - 1, +dateFromString.substr(8, 2), 0, 0, 0, 0);
    }

    // for each day we check if it is a holiday or a weekend
    let dayCount = 0;

    while (diffDays > 0) {
      newDateFrom.setDate(newDateFrom.getDate() + 1);

      const dateString = formatDate(newDateFrom, 'yyyy-MM-dd');

      if (newDateFrom.getDay() !== 6 && newDateFrom.getDay() !== 0 && !this.holidays.find(i => i.date.toString().substr(0, 10) === dateString)) {
        dayCount++;
      }
      diffDays--;
    }

    return dayCount;
  }



  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
