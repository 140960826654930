<div class="page">
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" id="dataGrid" class="m-auto maintenancegrid-height" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)" (onInitNewRow)="onInitNewRow($event)"
    (onEditorPreparing)="onEditorPreparing($event)" (onExporting)="gridService.onExporting($event, 'TaskSetUp')">

    <dxo-scrolling mode="standard" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
    <dxo-editing [mode]="editMode" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="reshape">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-row-dragging [allowReordering]="true" [onReorder]="onReorder"></dxo-row-dragging>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-column-chooser [enabled]="true" [search]="{enabled: true}" [height]="500"></dxo-column-chooser>

    <dxi-column dataField="taskTypeId" dataType="number" caption="Type" [width]='140' sortOrder="asc"
      [calculateSortValue]="calculateTaskTypeSortValue" [fixed]="true">
      <dxo-lookup [dataSource]="taskTypes" displayExpr="description" valueExpr="id"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="taskTitle" caption="Title" dataType="string" [width]="180" [fixed]="true">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="orderNumber" dataType='number' [visible]="false" sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="days" dataType='number' [width]='50' alignment="center">
    </dxi-column>

    <dxi-column dataField="isHiddenFromCallUps" caption="Hide From Call-Ups" dataType='boolean' [width]='70'>
    </dxi-column>

    <dxi-column dataField="defaultOfficeComment" dataType="string" caption="Default Comment"
      cellTemplate="commentCellTemplate" [minWidth]="100">
    </dxi-column>

    <dxi-column dataField="defaultVendorComment" dataType="string" cellTemplate="commentCellTemplate"
      [minWidth]="100"></dxi-column>

    <dxi-column dataField="isLockOrders" caption="Complete Locks POs" dataType='boolean' [width]='75'>
    </dxi-column>

    <dxi-column dataField="isContractSigning" caption="Contract Signing Task" dataType='boolean' [width]='70'>
    </dxi-column>

    <dxi-column *ngIf="isConstructiveEnabled && !isTruthEngineClientPortalActive" dataField="isSentToConstructive"
      caption="Send to Constructive" dataType='boolean' [width]='95'>
    </dxi-column>

    <dxi-column *ngIf="isTruthEngineClientPortalActive" dataField="isSentToConstructive" caption="Send to Client Portal"
      dataType='boolean' [width]='65'>
    </dxi-column>

    <dxi-column *ngIf="isTruthEngineClientPortalActive" dataField="clientDescriptionIn"
      caption="Client Description on Start" [width]="100">
    </dxi-column>

    <dxi-column *ngIf="isTruthEngineClientPortalActive" dataField="clientDescriptionOut"
      caption="Client Description on End" [width]="100">
    </dxi-column>

    <dxi-column *ngIf="emailUpdatesToClientsActive" dataField="isEmailClientOnStart" caption="Email Client on Start"
      dataType='boolean' [width]='75'>
    </dxi-column>

    <dxi-column *ngIf="emailUpdatesToClientsActive" dataField="isEmailClientOnEnd" caption="Email Client on Complete"
      dataType='boolean' [width]='75'>
    </dxi-column>

    <dxi-column *ngIf="emailUpdatesToClientsActive" dataField="clientDescription" caption="Client Update Description"
      [width]="90" cellTemplate="htmlTemplate" editCellTemplate="clientEmailHtmlTemplate">
    </dxi-column>

    <dxi-column dataField="emailToOnComplete" caption="Email Address on Complete" dataType="string"
      [minWidth]="100"></dxi-column>

    <dxi-column dataField="roleIds" caption="Email Roles on Complete" [width]="150" editCellTemplate="tagBoxEditor"
      [cellTemplate]="cellTemplate" [calculateFilterExpression]="calculateRoleFilterExpression">
      <dxo-lookup [dataSource]="companyRoles" valueExpr="roleId" displayExpr="companyRoleDescription">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="emailHtmlOnComplete" dataType="string" caption="Email Text" [width]="50"
      cellTemplate="htmlTemplate" editCellTemplate="editHtmlTemplate"></dxi-column>

    <div *dxTemplate="let data of 'htmlTemplate'" class="text-center">
      <dx-button id="htmlButton" #htmlButton icon="exportpdf"></dx-button>
    </div>

    <div *dxTemplate="let data of 'editHtmlTemplate'" class="text-center">
      <dx-button id="htmlButton" #htmlButton icon="exportpdf"
        (onClick)="editHtmlText(data, 'emailHtmlOnComplete')"></dx-button>
    </div>

    <div *dxTemplate="let data of 'clientEmailHtmlTemplate'" class="text-center">
      <dx-button id="htmlButton" #htmlButton icon="exportpdf"
        (onClick)="editHtmlText(data, 'clientDescription')"></dx-button>
    </div>

    <dxi-column dataField="isEmailAssigneeOnStart" caption="Email Assignee on Start Set" dataType='boolean'
      [width]='75'>
    </dxi-column>

    <dxi-column dataField="isQuoteRequest" caption="Quote Request" dataType='boolean' [width]='65'>
    </dxi-column>

    <dxi-column dataField="isTitlesTask" caption="Titles Task" dataType='boolean' [width]='50'>
    </dxi-column>

    <dxi-column *ngIf="isLandDatabaseActive" dataField="isShownInLandMaster" caption="Show in Land Database"
      dataType='boolean' [width]='75'>
    </dxi-column>

    <dxi-column dataField="isKeyTask" caption="Key Task" dataType='boolean' [width]='50'>
    </dxi-column>

    <dxi-column dataField="isActive" caption="Active" dataType='boolean' [width]='55'>
    </dxi-column>

    <dxi-column dataField="modifiedDate" caption="Modified" dataType="date" [width]="75" alignment="center"
      format="d-M-yy" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="id" caption="Task ID" dataType='number' [visible]="false" [width]="65"> </dxi-column>


    <div *dxTemplate="let cellInfo of 'commentCellTemplate'">
      <div id="commentClass" class='mx-0 px-0'>{{cellInfo.value}}</div>
    </div>

    <div *dxTemplate="let cellInfo of 'tagBoxEditor'">
      <dx-tag-box [dataSource]="companyRoles" [value]="cellInfo.value" valueExpr="roleId"
        displayExpr="companyRoleDescription" [showSelectionControls]="true" [maxDisplayedTags]="55"
        [showMultiTagOnly]="false" applyValueMode="useButtons" [searchEnabled]="true"
        (onValueChanged)="cellInfo.setValue($event.value)" (onSelectionChanged)="cellInfo.component.updateDimensions()">
      </dx-tag-box>
    </div>
  </dx-data-grid>

  <dx-popup class="popup" [width]="400" [height]="350" [showTitle]="true" title="Load from Excel" [dragEnabled]="false"
    [hideOnOutsideClick]="false" [(visible)]="popupVisible">
    <div *dxTemplate="let data of 'content'">
      <p>Loads Task Type, Task Title & Days only. If the task exists the days will be updated.</p>

      <div id="fileuploader-container">
        <dx-file-uploader selectButtonText="Select file" labelText="or drop file here" [multiple]="false" accept="*"
          [(value)]="fileValue" uploadMode="useForm" [allowedFileExtensions]="['.xls', '.xlsx']">
        </dx-file-uploader>
      </div>

      <dx-button id="button" text="Load" type="success" (onClick)="uploadClick()" class="mt-3 ms-4">
      </dx-button>
    </div>
  </dx-popup>

</div>
