import { UtilsService } from './../services/utils.service';
import { JobField } from './../dtos/job-field';
import { Component, OnInit, Input, ViewChild, OnDestroy, OnChanges } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { NotificationService } from '../services/notification.service';
import { GlobalService } from '../services/global.service';
import { GridService } from '../services/grid.service';
import { JobService } from '../services/felixApi/job.service';
import { Job } from '../dtos/job';
import { TrackingFieldsService } from '../services/felixApi/tracking-fields.service';
import { TrackingFieldTypeEnum } from '../dtos/tracking-field-type.enum';
import { TrackingFieldLookup } from '../dtos/tracking-field-lookup';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HoldJobComponent } from './hold-job/hold-job.component';
import { CancelJobComponent } from './cancel-job/cancel-job.component';
import { TrackingFieldGroup } from '../dtos/tracking-field-group';
import { JobRolesComponent } from '../shared/job-roles/job-roles.component';
import { ColourJobComponent } from './colour-job/colour-job.component';
import { TrackingColourEnum } from '../dtos/tracking-colour.enum';
import { ConfigurationEnum } from '../dtos/configuration-enum';
import { environment } from '../../environments/environment';
import { TrackingCalculationFieldEnum } from '../dtos/tracking-calculation-field.enum';
import { MaintenanceService } from '../services/felixApi/maintenance.service';
import { TaskService } from '../services/felixApi/task.service';
import { TaskStatusEnum } from '../dtos/task-status.enum';

@Component({
  selector: 'js-job-fields',
  templateUrl: './job-fields.component.html',
  styleUrls: ['./job-fields.component.scss']
})
export class JobFieldsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() jobNumber: string;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  loading = false;
  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  claimMasters: CustomStore;
  users: CustomStore;
  templateTaskList: CustomStore;
  taskTypes: CustomStore;
  resetPopupVisible = false;
  jobs: Job[];
  jobId: number;
  trackingFieldId: number;
  trackingFieldTypeEnum = TrackingFieldTypeEnum;
  trackingFieldTypeId: number;
  lookupList: TrackingFieldLookup[];
  adhocItem: TrackingFieldLookup;
  jobString: string;
  jobAddress: string;
  valueWidth: number;
  updatedData: any;
  tradeRegionId: number;
  gridHeight: number;
  trackingFieldGroups: TrackingFieldGroup[];
  adhocSelection: string;
  editMode: string;
  dataFieldForEdit: any;
  colourId: number;
  trackingColourEnum = TrackingColourEnum;
  constructiveIntegrationEnabled: boolean;
  modifiedByVisible: boolean;
  labelWidth: number;
  sendToConstructive: boolean;

  constructor(
    private trackingFieldsService: TrackingFieldsService,
    private taskService: TaskService,
    private notiService: NotificationService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private jobService: JobService,
    private modalService: NgbModal,
    private utilService: UtilsService,
    private maintenanceService: MaintenanceService
  ) {
    this.onEditingStart = this.onEditingStart.bind(this);
    this.calculateGroupSortValue = this.calculateGroupSortValue.bind(this);
    this.changEditMode = this.changEditMode.bind(this);
    this.changeColour = this.changeColour.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.onDateValueChanged = this.onDateValueChanged.bind(this);
    this.onLookupValueChanged = this.onLookupValueChanged.bind(this);
    this.calculateField = this.calculateField.bind(this);
  }

  ngOnInit(): void {
    this.editMode = 'row';
    this.subscribeToInnerWidth();
    this.colourId = this.jobService.currentJobExtra ? +this.jobService.currentJobExtra?.trackingColour : 0;
    this.sendToConstructive = this.jobService.currentJobExtra?.sendToConstructive ? true : false;

    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.ConstructiveIntegrationEnabled)) {
      this.constructiveIntegrationEnabled = true;
    }
  }

  ngOnChanges(): void {
    this.setJobStringMaxWidth();
    this.colourId = this.jobService.currentJobExtra ? +this.jobService.currentJobExtra?.trackingColour : 0;
    this.sendToConstructive = this.jobService.currentJobExtra?.sendToConstructive ? true : false;
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToInnerWidth() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setJobStringMaxWidth();
        }
      )
    );
  }

  setJobStringMaxWidth() {
    this.gridHeight = window.innerHeight - 155;
    this.valueWidth = window.innerWidth > 1200 ? 500 : window.innerWidth > 800 ? window.innerWidth * 4.5 / 12 : window.innerWidth * 0.45;
    this.labelWidth = window.innerWidth > 1200 ? 378 : window.innerWidth > 800 ? (window.innerWidth * 7.5 / 12) - 350
      : (window.innerWidth * 0.55) - 125;
    this.modifiedByVisible = window.innerWidth > 800;
  }

  getData() {
    this.loading = true;
    this.dataSource = null;
    
    this.subscriptions.push(
      this.trackingFieldsService.getJobFieldsData()
        .subscribe({
          next: (users) => {
            this.users = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => users
            });

            this.trackingFieldGroups = this.trackingFieldsService.trackingFieldGroups;

            // this.jobNumber = this.jobService.getCurrentJob();
            if (this.jobNumber && this.jobNumber !== '') {
              this.getJob();
            }

            // this.tradeRegions = this.maintenanceService.tradeRegions;
            this.tradeRegionId = this.jobService.currentJobExtra?.tradeRegionId;
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'trackingFieldId',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.taskService.getJobFieldsWithTasks(this.jobId).subscribe({
            next: (res) => {
              res.forEach(element => {
                if (element.trackingFieldTypeId === this.trackingFieldTypeEnum.Date && !this.dateParse(element.textValue)) {
                  element.textValue = null;
                }
              });
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.trackingFieldsService.updateJobField(this.jobId, encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onEditingStart(e) {
    this.trackingFieldId = e.data.trackingFieldId;
    this.trackingFieldTypeId = e.data.trackingFieldTypeId;
    this.adhocSelection = e.data.textValue; // set

    if (this.trackingFieldTypeId === this.trackingFieldTypeEnum.Lookup) {
      this.lookupList = this.trackingFieldsService.allTrackingFieldLookups.filter(i => i.trackingFieldId === this.trackingFieldId);

      if (e.data.textValue && e.data.textValue.length) {
        const foundSelection = this.lookupList.find(i => i.description === e.data.textValue);
        if (!foundSelection) {
          // asdd it to the list
          this.addToList(e.data.textValue);
        }
      }
    } else if (this.trackingFieldTypeId === this.trackingFieldTypeEnum.Boolean) {
      this.lookupList = [];

      this.adhocItem = new TrackingFieldLookup(1, 'Yes', 1, 0);
      this.lookupList.push(this.adhocItem);
      this.adhocItem = new TrackingFieldLookup(2, 'No', 2, 0);
      this.lookupList.push(this.adhocItem);
      this.adhocItem = new TrackingFieldLookup(3, 'N/A', 3, 0);
      this.lookupList.push(this.adhocItem);

      if (e.data.textValue && e.data.textValue.length) {
        const foundSelection = this.lookupList.find(i => i.description === e.data.textValue);
        if (!foundSelection) {
          // asdd it to the list
          this.adhocItem = new TrackingFieldLookup(4, e.data.textValue, 4, 0);
          this.lookupList.push(this.adhocItem);
        }
      }
    }
  }

  onEditorPreparing(e: any) {
    if (e.parentType !== 'dataRow') {
      return;
    } else {
      this.dataFieldForEdit = e;
      if (e.dataField === 'textValue' && e.row.data.trackingFieldTypeId === this.trackingFieldTypeEnum.Text) {
        e.editorName = 'dxTextArea';
        e.editorOptions.autoResizeEnabled = true;
        let prevHeight = null;
        e.editorOptions.onInput = (args) => {
          const td = args.element.closest('td');
          if (td && prevHeight !== td.offsetHeight) {
            const overlay = e.element.querySelector('.dx-datagrid-focus-overlay');
            if (overlay != null) {
              overlay.style.height = (td.offsetHeight + 1) + 'px';
            }
            prevHeight = td.offsetHeight;
          }
        };
      } else if (e.dataField === 'textValue' && e.row.data.trackingFieldTypeId === this.trackingFieldTypeEnum.Number) {
        e.editorName = 'dxNumberBox';
        e.editorOptions.showSpinButtons = true;
        e.editorOptions.showClearButton = true;
      } else if (e.dataField === 'textValue' && e.row.data.trackingFieldTypeId === this.trackingFieldTypeEnum.Date) {
        e.editorName = 'dxDateBox';
        e.editorOptions = {
          type: 'date',
          showClearButton: true,
          displayFormat: 'd-MMM-yy',
          onValueChanged: this.onDateValueChanged.bind(this),
          value: this.adhocSelection
        };
      } else if (e.dataField === 'textValue'
        && (e.row.data.trackingFieldTypeId === this.trackingFieldTypeEnum.Lookup
          || e.row.data.trackingFieldTypeId === this.trackingFieldTypeEnum.Boolean)) {
        e.editorName = 'dxSelectBox';
        e.editorOptions = {
          dataSource: this.lookupList,
          searchEnabled: true,
          acceptCustomValue: true,
          valueExpr: 'description',
          displayExpr: 'description',
          showClearButton: true,
          onValueChanged: this.onLookupValueChanged.bind(this),
          onCustomItemCreating: this.addCustomItem.bind(this),
          value: this.adhocSelection
        };
      } else if (e.dataField === 'textValue' && e.row.data.trackingFieldTypeId === this.trackingFieldTypeEnum.Calculated) {
        e.editorOptions.disabled = true;
      }
    }
  }

  onLookupValueChanged(e) {
    this.adhocSelection = e.value;
    this.dataFieldForEdit.setValue(this.adhocSelection);
  }

  onDateValueChanged(e) {
    if (e.value instanceof Date) {
      this.adhocSelection = this.utilService.convertDateToString(e.value);
    } else {
      this.adhocSelection = e.value;
    }
    this.dataFieldForEdit.setValue(this.adhocSelection);
  }

  getJob() {
    this.jobId = this.jobService.currentJob.id;
    this.setUpDataSource();
  }


  onToolbarPreparing(e, colourTemplate: string, constructiveTemplate: string) {
    if (this.modifiedByVisible) {
      if (this.constructiveIntegrationEnabled) {
        e.toolbarOptions.items.unshift(
          {
            location: 'after',
            locateInMenu: 'auto',
            template: constructiveTemplate
          });
      }

      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          template: colourTemplate
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            stylingMode: 'outlined',
            type: 'danger',
            text: 'Hold',
            onClick: this.holdJob.bind(this)
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            stylingMode: 'outlined',
            type: 'danger',
            text: 'Cancel',
            onClick: this.cancelJob.bind(this)
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            icon: 'user',
            onClick: this.showJobRoles.bind(this)
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            icon: 'home',
            onClick: this.launchTruthEngine.bind(this)
          }
        });
    }

    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: this.editMode === 'row' ? 'Batch Edit' : 'Exit',
          type: 'default',
          onClick: this.changEditMode.bind(this),
          matTooltip: 'Batch Edit'
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          width: 40,
          icon: 'collapse',
          onClick: this.collapseAll.bind(this),
          matTooltip: 'Collapse All Rows'
        }
      });
  }

  colourJob() {
    // set the tracking colour for the task grids
    if (this.jobId) {
      const modalRef = this.modalService.open(ColourJobComponent, { windowClass: 'modal-1000' });
      modalRef.result.then(() => {
        this.colourId = this.jobService.currentJobExtra ? +this.jobService.currentJobExtra?.trackingColour : 0;
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 200); // reset buttons
      });
    }
  }

  setSendToConstructive(e) {
    // set flag
    if (this.jobService.currentJobExtra) {
      // edit
      this.subscriptions.push(
        this.jobService.updateJobExtra(this.jobService.currentJobExtra.id, { sendToConstructive: e.value })
          .subscribe({
            next: () => {
              this.notiService.showSuccess('Flag updated');
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    } else {
      // add
      this.subscriptions.push(
        this.jobService.addJobExtra({ jobId: this.jobService.currentJob.id, sendToConstructive: true })
          .subscribe({
            next: () => {
              this.notiService.showSuccess('Flag updated');
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  holdJob() {
    // put job on/off hold
    if (this.jobId) {
      const modalRef = this.modalService.open(HoldJobComponent, { windowClass: 'modal-1000' });
      modalRef.componentInstance.jobExtra = this.jobService.currentJobExtra;
      modalRef.componentInstance.jobId = this.jobId;

      modalRef.result.then((jobExtra) => {
        this.jobService.currentJobExtra = jobExtra;
      });
    }
  }

  cancelJob() {
    // put job on/off hold
    if (this.jobId) {
      const modalRef = this.modalService.open(CancelJobComponent, { windowClass: 'modal-1000' });
      modalRef.componentInstance.jobExtra = this.jobService.currentJobExtra;
      modalRef.componentInstance.jobId = this.jobId;

      modalRef.result.then((jobExtra) => {
        this.jobService.currentJobExtra = jobExtra;
      });
    }
  }

  addCustomItem(data) {
    if (!data.text) {
      data.customItem = null;
      return;
    }

    this.addToList(data.text);
    this.adhocSelection = data.text;
    this.dataFieldForEdit.setValue(this.adhocSelection);
    data.customItem = this.adhocItem;
  }

  addToList(adhocText: string) {
    const productIds = this.lookupList.map(function (item) {
      return item.id;
    });
    const incrementedId = Math.max.apply(null, productIds) + 1;

    this.adhocItem = new TrackingFieldLookup(incrementedId, adhocText, incrementedId, this.trackingFieldId);
    this.lookupList.push(this.adhocItem);
  }

  calculateGroupSortValue(jobField: JobField) {
    let orderNumber = 0;
    let description = '';
    if (jobField.trackingFieldGroupId) {
      const trackingGroup = this.trackingFieldGroups.find(i => i.id === jobField.trackingFieldGroupId);
      if (trackingGroup) {
        orderNumber = trackingGroup.orderNumber;
        description = trackingGroup.description;
      }
    }
    return ('00000' + orderNumber.toString()).slice(-6) + ';' + description;
  }

  getGroupTitle(cellInfo) {
    return cellInfo.data.key.split(';')[1];
  }

  collapseAll() {
    this.grid.instance.collapseAll();
  }

  expandAll() {
    this.grid.instance.expandAll();
  }

  changEditMode() {
    if (this.grid.instance.hasEditData() && this.editMode === 'batch') {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      if (this.editMode === 'row') {
        this.editMode = 'batch';
      } else {
        this.editMode = 'row';
      }
    }
  }

  changeColour(e) {
    const rowIndex = this.grid.instance.getRowIndexByKey(e.row.data.trackingFieldId);
    this.grid.instance.cellValue(rowIndex, 'colourId', e.row.data.colourId ? 0 : 1);

    this.grid.instance.saveEditData();
  }

  onRowPrepared(e) {
    if (e.rowType === 'data' && (e.data.colourId)) {
      e.rowElement.style.backgroundColor = 'yellow';
      e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
    }
  }

  showJobRoles() {
    // use modal to set the users to the roles
    const modalRef = this.modalService.open(JobRolesComponent, { windowClass: 'modal-edit' });
    modalRef.componentInstance.jobNumber = this.jobNumber;

    modalRef.result.then(() => {
    }, () => {
    });
  }

  dateParse(e) {
    return Date.parse(e);
  }

  launchTruthEngine() {
    window.open(environment.addendaAppUrl + '?coy=' + this.globalService.getCurrentCompany().id + '&job=' + this.jobNumber);
  }

  calculateField(cellData) {
    const field = this.trackingFieldsService.trackingFields.find(i => i.id === cellData.trackingFieldId);

    if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.SalesDate) {
      return this.maintenanceService.addDays(this.jobService.currentJob.salesDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
    } else if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.ContractSigned) {
      return this.maintenanceService.addDays(this.jobService.currentJob.contractSignedDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
    } else if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.HandoverDate && this.jobService.currentJobExtra) {
      return this.maintenanceService.addDays(this.jobService.currentJobExtra.handoverDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
    } else if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.PracticalCompletion && this.jobService.currentJobExtra) {
      return this.maintenanceService.addDays(this.jobService.currentJobExtra.completionDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
    } else if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.SiteStart) {
      const siteStartTasks = this.maintenanceService.allTemplateTasks.filter(i => i.isForecastStart);
      let siteStartDate: Date = null;
      siteStartTasks.forEach(siteStartTask => {
        siteStartDate = this.taskService.jobTasks
          .find(i => i.jobId === this.jobId && i.taskMasterId === siteStartTask.taskMasterId && i.statusId != TaskStatusEnum.Cancelled)?.startDate;
      });
      if (siteStartDate) {
        return this.maintenanceService.addDays(siteStartDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
      }
    } else if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.TitleDue) {
      const titleDueDate = this.jobService.currentJob.titleDueDate;
      if (titleDueDate) {
        return this.maintenanceService.addDays(titleDueDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
      }
    } else {
      return '';
    }
  }

  dateOnly(cell): Date {
    if (cell.value) {
      const currentDate = new Date(cell.value).toDateString();

      if (currentDate === 'Mon Jan 01 0001') {
        return null
      }
      return new Date(cell.value);
    }
    else {
      var newDate = null;
      return newDate;
    }
  }
}
