import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import {
  BrowserCacheLocation, InteractionType,
  IPublicClientApplication, LogLevel, PublicClientApplication
} from '@azure/msal-browser';
import {
  MsalModule, MsalInterceptor,
  MsalInterceptorConfiguration, MsalGuardConfiguration,
  MSAL_INSTANCE, MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG, MsalService,
  MsalBroadcastService, MsalRedirectComponent, MsalGuard
} from '@azure/msal-angular';

import { environment } from '../environments/environment';

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { LoginModule } from './login/login.module';
import { TasksModule } from './tasks/tasks.module';

import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';

import { UserService } from './services/felixApi/user.service';
import { CompanyService } from './services/felixApi/company.service';
import { GlobalService } from './services/global.service';
import { AuthService } from './services/auth.service';
import { NotificationService } from './services/notification.service';
import { RefreshGuard } from './services/refresh-guard.service';
import { MenuComponent } from './shared/menu/menu.component';
import { JobCashFlowModule } from './job-cash-flow/job-cash-flow.module';
import { CashFlowForecastModule } from './cash-flow-forecast/cash-flow-forecast.module';
import { JobFieldsComponent } from './job-fields/job-fields.component';
import { JobService } from './services/felixApi/job.service';
import { HoldJobComponent } from './job-fields/hold-job/hold-job.component';
import { CancelJobComponent } from './job-fields/cancel-job/cancel-job.component';
import { JobWorkFlowComponent } from './job-work-flow/job-work-flow.component';
import { JobWorkFlowService } from './services/felixApi/job-work-flow.service';
import { CallUpsComponent } from './call-ups/call-ups.component';
import { JobDataComponent } from './job-data/job-data.component';
import { ReportGridModule } from './report-grid/report-grid.module';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentTemplatesComponent } from './documents/document-templates/document-templates.component';
import { JobGanttComponent } from './job-data/job-gantt/job-gantt.component';
import { InvoicesOnHoldComponent } from './invoices-on-hold/invoices-on-hold.component';
import { ColourJobComponent } from './job-fields/colour-job/colour-job.component';
import { ChildCallUpsComponent } from './call-ups/child-call-ups/child-call-ups.component';
import { SendCallUpsComponent } from './call-ups/send-call-ups/send-call-ups.component';
import { VendorSummariesComponent } from './call-ups/vendor-summaries/vendor-summaries.component';
import { PreviousInvoicesComponent } from './invoices-on-hold/previous-invoices/previous-invoices.component';
import { SecondaryPurchaseOrdersComponent } from './call-ups/secondary-purchase-orders/secondary-purchase-orders.component';
import { AcceptCallUpComponent } from './call-ups/accept-call-up/accept-call-up.component';
import { ManualPurchaseOrdersComponent } from './manual-purchase-orders/manual-purchase-orders.component';
import { MasterChildCallUpsComponent } from './call-ups/master-child-call-ups/master-child-call-ups.component';
import { ResetJobTaskComponent } from './call-ups/reset-job-task/reset-job-task.component';
import { JobEmailsComponent } from './job-emails/job-emails.component';
import { JobEmailExpandedComponent } from './job-emails/job-email-expanded/job-email-expanded.component';
import { JobKeyTasksComponent } from './job-key-tasks/job-key-tasks.component';
import { JobNoteExpandedComponent } from './job-emails/job-note-expanded/job-note-expanded.component';
import { AddMultipleTasksComponent } from './call-ups/add-multiple-tasks/add-multiple-tasks.component';
import { RejectInvoiceComponent } from './invoices-on-hold/reject-invoice/reject-invoice.component';
import { DxDropDownButtonModule } from 'devextreme-angular';

import config from 'devextreme/core/config';
import { licenseKey } from '../devextreme-license';
import { JobTaskLogComponent } from './job-task-log/job-task-log.component';
import { VariationWorkflowsComponent } from './maintenance/variation-workflows/variation-workflows.component';
import { BackChargeComponent } from './invoices-on-hold/back-charge/back-charge.component';
import { BackChargesComponent } from './invoices-on-hold/back-charges/back-charges.component';
import { ManualOrderComponent } from './invoices-on-hold/manual-order/manual-order.component';
import { BackChargeDocumentsComponent } from './invoices-on-hold/back-charge-documents/back-charge-documents.component';
config({ licenseKey });

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  if (logLevel === LogLevel.Error) {
    console.error(message);
    // how to log to a service?
  } else {
    // console.log(message);
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientID,
      authority: environment.authority,
      redirectUri: environment.applicationUrl + 'auth',
      postLogoutRedirectUri: environment.applicationUrl,
      knownAuthorities: [environment.authoryDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUrl, environment.consentScopes);
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.consentScopes],
    },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    MenuComponent,
    JobFieldsComponent,
    HoldJobComponent,
    CancelJobComponent,
    JobWorkFlowComponent,
    CallUpsComponent,
    JobDataComponent,
    DocumentsComponent,
    DocumentTemplatesComponent,
    JobGanttComponent,
    InvoicesOnHoldComponent,
    ColourJobComponent,
    ChildCallUpsComponent,
    SendCallUpsComponent,
    VendorSummariesComponent,
    PreviousInvoicesComponent,
    SecondaryPurchaseOrdersComponent,
    AcceptCallUpComponent,
    ManualPurchaseOrdersComponent,
    MasterChildCallUpsComponent,
    ResetJobTaskComponent,
    JobEmailsComponent,
    JobEmailExpandedComponent,
    JobNoteExpandedComponent,
    JobKeyTasksComponent,
    AddMultipleTasksComponent,
    RejectInvoiceComponent,
    JobTaskLogComponent,
    VariationWorkflowsComponent,
    BackChargeComponent,
    BackChargesComponent,
    ManualOrderComponent,
    BackChargeDocumentsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbCollapseModule,
    NgbModule,
    ToastrModule.forRoot(),

    AppRoutingModule,

    JobCashFlowModule,
    CashFlowForecastModule,
    MaintenanceModule,
    SharedModule,
    LoginModule,
    TasksModule,
    DxDropDownButtonModule,
    ReportGridModule,

    MsalModule

  ],
  providers: [
    UserService,
    CompanyService,
    GlobalService,
    AuthService,
    NotificationService,
    JobService,
    JobWorkFlowService,
    RefreshGuard,
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
