import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { User } from '../../dtos/user';
import { GlobalService } from '../global.service';
import { Vendor } from '../../dtos/vendor';
import { HttpService } from '../http.service';
import { environment } from '../../../environments/environment';
import { VendorPayable } from '../../dtos/vendor-payable';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { VendorGroup } from '../../dtos/vendor-group';
import { OrderLimit } from '../../dtos/order-limit';

@Injectable()
export class UserService {
  users: User[];
  cachCompanyUsers: string;
  vendors: Vendor[];
  vendorCompany: string;
  vendorPayables: VendorPayable[];
  vendorPayablesCompany: string;
  officeUsers: User[];
  vendorGroups: VendorGroup[];
  vendorGroupsCompany: string;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }



  usersMap: Map<number, User> = new Map<number, User>();

  getCurrCompUsers(useCache: boolean): Observable<User[]> {
    if (useCache && this.users && this.users.length
      && this.cachCompanyUsers === this.globalService.getCurrentCompanyId()) {
      return of(this.users);
    } else {
      const url = this.globalService.getApiUrl() + '/companies/users';

      return this.http.get<User[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.users = res; this.cachCompanyUsers = this.globalService.getCurrentCompanyId();

          this.usersMap.clear();
          res.forEach(u => this.usersMap.set(u.id, u));

          this.officeUsers = [];
          this.users.forEach(user => {
            if ((user.isActive) && (user.userTypeId === UserTypeEnum.Admin || user.userTypeId === UserTypeEnum.Office)) {
              this.officeUsers.push(user);
            }
          });
        }),
        catchError(this.handleError));
    }
  }

  getUser(id: number): Observable<User> {
    return this.http.get<User>(this.globalService.getApiUrl() + '/users/' + id + '?includeUserType=true',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getUserCompany(id: number): Observable<User> {
    return this.http.get<User>(this.globalService.getApiUrl() + '/companies/users/' + id,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getUserByEmail(email: string) {
    return this.http.get<User>(this.globalService.getApiUrl() + '/users/' + email, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }


  vendorsMap: Map<number, Vendor> = new Map<number, Vendor>();

  getVendors(useCache: boolean = true, activeOnly: boolean): Observable<Vendor[]> {
    if (useCache && this.vendors && this.vendors.length && this.vendorCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.vendors);
    } else {
      return this.http.get<Vendor[]>(environment.apiUrl +
        '/vendors?activeOnly=' + activeOnly, this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.vendors = res; this.vendorCompany = this.globalService.getCurrentCompanyId();
            this.vendorsMap.clear();
            res.forEach(v => this.vendorsMap.set(v.id, v));
          }),
          catchError(this.handleError));
    }
  }

  getVendorPayables(useCache: boolean = true): Observable<VendorPayable[]> {
    if (useCache && this.vendorPayables && this.vendorPayables.length
      && this.vendorPayablesCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.vendorPayables);
    } else {
      return this.http.get<VendorPayable[]>(this.globalService.getApiUrl() +
        '/vendor-payables', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.vendorPayables = res; this.vendorPayablesCompany = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }



  getVendorGroups(useCache: boolean): Observable<VendorGroup[]> {
    if (useCache && this.vendorGroups && this.vendorGroups.length
      && this.vendorGroupsCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.vendorGroups);
    } else {
      return this.http.get<VendorGroup[]>(this.globalService.getApiUrl() +
        '/vendor-groups', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.vendorGroups = res; this.vendorGroupsCompany = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  getVendorData(useCache: boolean = true, activeOnly: boolean): Observable<Vendor[]> {
    return forkJoin(
      [
        this.getVendors(useCache, activeOnly),
        this.getVendorGroups(useCache)
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getMyUserOrderLimit(): Observable<OrderLimit> {
    return this.http.get<OrderLimit>(this.globalService.getApiUrl() + '/user-order-limits/me', this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
