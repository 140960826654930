<div class="page">

  <!-- PC version` -->
  <dx-data-grid *ngIf="!loading && !isMobile" id="dataGridTasks" class="m-auto" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" (onInitNewRow)="onInitNewRow($event)"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate', 'toolbarTemplate2', 'toolbarTemplate3', 'toolbarTemplate4', 'toolbarTemplate5', 'toolbarTemplate6', 'toolbarStartDays')"
    (onInitialized)="onInitialized($event)" (onRowPrepared)="onRowPrepared($event)"
    (onCellPrepared)="onCellPrepared($event)" (onEditingStart)="onEditingStart($event)" (onSaved)="resetUsers()"
    (onEditCanceled)="onEditCancelled()" (onExporting)="gridService.onExporting($event, 'Tasks')"
    [filterSyncEnabled]="true" (onEditCanceled)="resetUsers()" [height]="gridHeight" (onSaved)="onTasksSaved()"
    [syncLookupFilterValues]="false" [(selectedRowKeys)]="selectedJobTaskIds"
    (onEditorPreparing)="onEditorPreparing($event)">

    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" [width]="150"></dxo-search-panel>
    <dxo-sorting mode='multiple'></dxo-sorting>
    <dxo-column-chooser [enabled]="true" [search]="{enabled: true}" [height]="500"></dxo-column-chooser>
    <dxo-selection *ngIf="cancelOldTasksModeOn" mode="multiple" showCheckBoxesMode="always" [allowSelectAll]="true">
    </dxo-selection>

    <dxo-editing mode="popup" [allowAdding]="!cancelOldTasksModeOn" [allowUpdating]="!cancelOldTasksModeOn"
      [allowDeleting]="!cancelOldTasksModeOn" [useIcons]="true" refreshMode="full" [(changes)]="changes">
      <dxo-popup title="" [showTitle]="false" [width]="editPopupWidth" [height]="editPopupHeight" [fullScreen]="false">
        <dxi-toolbar-item toolbar="bottom" location="before" widget="dxButton"
          [options]="{ type: 'default', stylingMode: 'outlined', text: 'Send', onClick: sendTask }">
        </dxi-toolbar-item>
        <dxi-toolbar-item *ngIf="currentJobTask" toolbar="bottom" location="before" widget="dxButton"
          [options]="{ type: 'default', stylingMode: 'outlined', text: 'Attach Docs', onClick: attachDocs }">
        </dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
          [options]="{ type: 'default', text: 'Save', onClick: saveClickHandler }">
        </dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
          [options]="{ type:'danger', text: 'Cancel', onClick: cancelClickHandler }">
        </dxi-toolbar-item>
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="jobId" [colSpan]="2"></dxi-item>
          <dxi-item dataField="contractName" [colSpan]="2"></dxi-item>
          <dxi-item dataField="siteAddress" [colSpan]="4"></dxi-item>
          <dxi-item dataField="taskTypeId" [colSpan]="4"></dxi-item>
          <dxi-item dataField="taskMasterId" [colSpan]="4"></dxi-item>
          <dxi-item dataField="templateTaskHeaderId" [colSpan]="4"></dxi-item>
          <dxi-item dataField="startDate" [colSpan]="2"></dxi-item>
          <dxi-item dataField="calledDate" [colSpan]="2"></dxi-item>
          <dxi-item dataField="dueDate" [colSpan]="2"></dxi-item>
          <dxi-item dataField="endDate" [colSpan]="2"></dxi-item>
          <dxi-item dataField="officeComment" editorType="dxTextArea" [colSpan]="4" [editorOptions]="{ height: 100 }">
          </dxi-item>

          <dxi-item itemType="button" [colSpan]="4" [buttonOptions]="commentHistoryButtonOptions">
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="32" [colSpan]="2">
          <dxi-item dataField="jobVariationId" [colSpan]="22"> </dxi-item>
          <dxi-item *ngIf="jobVariationId && jobVariationStatusId" [colSpan]="7" cssClass="variation-button">
            <dx-select-box [dataSource]="variationStatus" displayExpr="status" valueExpr="id"
              [(value)]="jobVariationStatusId"></dx-select-box>
          </dxi-item>
          <dxi-item *ngIf="jobVariationId && jobVariationStatusId" editorType="dxButton" [colSpan]="3"
            [editorOptions]="variationButtonOptions">
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="userId" [colSpan]="2"></dxi-item>
          <dxi-item dataField="statusId" [colSpan]="2"></dxi-item>
          <dxi-item dataField="modifiedUserId" [colSpan]="2"></dxi-item>
          <dxi-item dataField="modifiedDate" [colSpan]="2"></dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"> <dxo-search [enabled]="true"></dxo-search> </dxo-header-filter>

    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="false" [contextMenuEnabled]="true" expandMode="rowClick"></dxo-grouping>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="jobtaskgrid" [savingTimeout]="500">
    </dxo-state-storing>

    <dxi-column dataField="jobId" dataType="number" caption="Job" [width]="120" editCellTemplate="codeDropDown"
      headerCellTemplate="jobHeaderCellTemplate" [setCellValue]="setJobIdCellValue" [(filterValue)]="jobFilterValue"
      [calculateSortValue]="calculateJobSortValue" [calculateGroupValue]="calculateJobGroupValue"
      groupCellTemplate="jobGroupCellTemplate" [allowEditing]="!jobTaskId">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="jobData" displayExpr="jobNumber" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <div *dxTemplate="let data of 'jobGroupCellTemplate'" class="displayThis">
      <span>{{ calculateJobGroupDisplay(data) }}</span>
    </div>

    <dxi-column dataField="contractName" dataType="string" [calculateCellValue]='calculateContractName' minWidth="150"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="siteAddress" dataType="string" [calculateCellValue]='calculateSiteAddress' minWidth="150"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="taskTypeId" caption="Type" dataType="number" [width]="120"
      [setCellValue]="setTaskTypeCellValue">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="taskTypes" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="taskMasterId" dataType="number" caption="Title" minWidth="150"
      [setCellValue]="setTaskMasterIdCellValue" [calculateSortValue]="calculateTaskSortValue">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="getTaskList" displayExpr="taskTitle" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="templateTaskHeaderId" caption="Work Flow" dataType="number" [visible]='false'
      [editorOptions]="{ showClearButton: true }" [setCellValue]="setTemplateTaskHeaderIdCellValue">
      <dxo-lookup [dataSource]="allTaskHeaders" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="manualTaskTitle" dataType="string" [visible]='false'>
    </dxi-column>

    <dxi-column dataField="startDate" dataType="date" [width]="90" alignment="center" format="d-MMM-yy"
      [setCellValue]="setStartDateCellValue"
      [editorOptions]="{ showClearButton: true, calendarOptions: { showTodayButton: true } }">
    </dxi-column>

    <dxi-column dataField="originalDueDate" dataType="date" [width]="90" alignment="center" format="d-MMM-yy"
      [calculateCellValue]="calcOriginalDueDate" [visible]="false">
    </dxi-column>

    <dxi-column dataField="dueDate" dataType="date" [width]="90" alignment="center" format="d-MMM-yy"
      cellTemplate="dueDateCellTemplate" [setCellValue]="setDueDateCellValue"
      [editorOptions]="{ showClearButton: true, calendarOptions: { showTodayButton: true } }" sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="endDate" caption="Completed" dataType="date" [width]="90" alignment="center"
      format="d-MMM-yy" [setCellValue]="setEndDateCellValue"
      [editorOptions]="{ showClearButton: true, calendarOptions: { showTodayButton: true } }">
    </dxi-column>

    <dxi-column dataField="officeComment" dataType="string" caption="Comment" minWidth="150"
      cellTemplate="officeCommentCellTemplate">
    </dxi-column>

    <dxi-column dataField="vendorComment" dataType="string" caption="Vendor Comment" minWidth="150"
      cellTemplate="vendorCommentCellTemplate" [visible]="false">
    </dxi-column>

    <dxi-column dataField="userId" caption="Assigned To" dataType="number" minWidth="90"
      [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="isCompleted" caption="Done" dataType="boolean" [width]="50" [visible]='false'
      [calculateCellValue]='calculateIsCompleted' [setCellValue]="setIsCompletedCellValue">
    </dxi-column>

    <dxi-column dataField="statusId" caption="Status" dataType="number" [width]="110"
      [setCellValue]="setStatusCellValue">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="taskStatusList" displayExpr="status" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="modifiedUserId" caption="Modified By" dataType="number" [allowEditing]="false"
      [visible]='false'>
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="modifiedDate" caption="Modified" dataType="date" [width]="85" alignment="center"
      format="d-MMM-yy" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="calledDate" caption="Sent" dataType="date" [width]="85" alignment="center" format="d-MMM-yy"
      [visible]='false' [editorOptions]="{ showClearButton: true, calendarOptions: { showTodayButton: true } }"
      [setCellValue]="setSentCellValue">
    </dxi-column>

    <!-- <dxi-column dataField="sentFromQueueDate" dataType="date" [width]="85" alignment="center" format="d-MMM-yy"
      [visible]='false' [allowEditing]="false">
    </dxi-column> -->

    <dxi-column dataField="emailQueueStatus" dataType="string" [width]="85" alignment="center" [visible]='false'
      [allowEditing]="false" [calculateCellValue]="calculateSentFromQueueStatus">
    </dxi-column>

    <dxi-column dataField="doNotSendCallup" caption="Don't Send" dataType="boolean" [width]="50" alignment="center"
      [visible]='false' [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [visible]='false' [allowEditing]="false"
      [calculateSortValue]="calculateVendorSortValue" minWidth="90">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="tradeRegionId" caption="Region" dataType="string" [visible]='false' minWidth="80"
      [calculateCellValue]="calculateTradeRegion" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="houseTypeId" caption="House Type" dataType="string" [visible]='false' [width]="100"
      [calculateCellValue]="calculateHouseType" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="tradeId" caption="Trade" dataType="string" [visible]='false' minWidth="90"
      [calculateCellValue]="calculateTrade" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="jobVariationId" caption="Variation" dataType="number" [width]="105" alignment="center"
      [visible]='false' editCellTemplate="editVariationTemplate" [setCellValue]="setVariationIdCellValue">
      <dxo-lookup [dataSource]="jobVariations" displayExpr="displayedVariationNumber" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="jobVariationTitle" caption="Variation Title" dataType="string" [width]="200" alignment="left"
      [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateVariationTitle">
    </dxi-column>

    <dxi-column dataField="jobVariationStatusId" caption="Variation Status" dataType="string" [width]="130"
      alignment="left" [visible]='false' [calculateCellValue]="calculateVariationStatus">
    </dxi-column>

    <dxi-column dataField="assignedUserId" caption="Variation Estimator" dataType="number" alignment="left"
      [minWidth]="100" [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateVariationEstimator">
    </dxi-column>

    <dxi-column dataField="masterJobId" dataType="number" caption="Master Job" [width]="100" alignment="left"
      [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateMasterJob">
    </dxi-column>

    <dxi-column dataField="salesRepId" dataType="number" [caption]="salesRepWording" [width]="100" alignment="left"
      [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateSalesRep">
    </dxi-column>

    <dxi-column dataField="currentActivity" dataType="string" [width]="130" alignment="left" [visible]='false'
      [allowEditing]="false" [calculateCellValue]="calculateCurrentActivity">
    </dxi-column>

    <dxi-column dataField="isKeyTask" caption="Key Task" dataType='boolean' [width]='50'
      [calculateCellValue]="calculateKeyTaskValue" [visible]="false" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="isAcceptedByVendor" dataType="boolean" [visible]='false'>
    </dxi-column>

    <dxi-column *ngIf="liaisonIsActive" dataField="liaison" dataType="number" [caption]="liaisonWording" [width]="100"
      alignment="left" [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateLiaison">
    </dxi-column>

    <dxi-column *ngIf="prestartOfficerIsActive" dataField="prestartOfficer" dataType="number"
      [caption]="prestartOfficerWording" [width]="100" alignment="left" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculatePrestartOfficer">
    </dxi-column>

    <dxi-column *ngIf="siteManagerIsActive" dataField="siteManager" dataType="number" [caption]="siteManagerWording"
      [width]="100" alignment="left" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculateSiteManager">
    </dxi-column>

    <dxi-column *ngIf="constructionCoordinatorIsActive" dataField="constructionCoordinator" dataType="number"
      [caption]="constructionCoordinatorWording" [width]="100" alignment="left" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculateConstructionCoordinator">
    </dxi-column>

    <dxi-column *ngIf="areaConstructionManagerIsActive" dataField="areaConstructionManager" dataType="number"
      [caption]="areaConstructionManagerWording" [width]="100" alignment="left" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculateAreaConstructionManager">
    </dxi-column>

    <dxi-column *ngIf="designerIsActive" dataField="designer" dataType="number" [caption]="designerWording"
      [width]="100" alignment="left" [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateDesigner">
    </dxi-column>

    <dxi-column *ngIf="salesEstimatorIsActive" dataField="salesEstimator" dataType="number"
      [caption]="salesEstimatorWording" [width]="100" alignment="left" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculateSalesEstimator">
    </dxi-column>

    <dxi-column *ngIf="schedulerIsActive" dataField="scheduler" dataType="number" [caption]="schedulerWording"
      [width]="100" alignment="left" [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateScheduler">
    </dxi-column>

    <dxi-column *ngIf="draftspersonIsActive" dataField="draftsperson" dataType="number" [caption]="draftspersonWording"
      [width]="100" alignment="left" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculateDraftsperson">
    </dxi-column>

    <dxi-column *ngIf="preConstructionCoordinatorIsActive" dataField="preConstructionCoordinator" dataType="number"
      [caption]="preConstructionCoordinatorWording" [width]="100" alignment="left" [allowEditing]="false"
      [visible]='false' [calculateCellValue]="calculatePreConstructionCoordinator">
    </dxi-column>

    <dxi-column *ngIf="accountsIsActive" dataField="accounts" dataType="number" [caption]="accountsWording"
      [width]="100" alignment="left" [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateAccounts">
    </dxi-column>

    <dxi-column dataField="createDate" caption="Created" dataType="date" [width]="85" alignment="center"
      format="d-MMM-yy" [allowEditing]="false" [visible]='false'>
    </dxi-column>

    <dxi-column dataField="daysSinceCreateDate" caption="Since Created" dataType="number" [width]="85"
      alignment="center" format="#,##0" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculateDaysSinceCreated">
    </dxi-column>

    <dxi-column dataField="daysSinceStartDate" caption="Since Start" dataType="number" [width]="85" alignment="center"
      format="#,##0" [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateDaysSinceStart">
    </dxi-column>

    <!-- division and warning note -->
    <dxi-column dataField="division" caption="Division" dataType="string" [width]="105" alignment="left"
      [visible]='false' [allowEditing]="false" [calculateCellValue]="calculateDivision">
    </dxi-column>

    <dxi-column dataField="warningNote" caption="Job Warning" dataType="string" [width]="150" alignment="left"
      [visible]='false' [allowEditing]="false" [calculateCellValue]="calculateJobWarning">
    </dxi-column>

    <!-- Contracts Required -->
    <dxi-column dataField="contractsRequiredByDate" dataType="date" [width]="95" alignment="center" format="d-MMM-yy"
      [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateContractsRequiredByDate">
    </dxi-column>

    <dxi-column dataField="daysToContractsRequired" caption="Calendar days to contracts required"
      dataType="number" [width]="85" alignment="center" format="#,##0" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculateDaysToContractsRequired">
    </dxi-column>


    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <span class="ms-3 me-1">Show:</span>
        <span class="me-2">My Tasks Only</span>
        <dx-check-box [(value)]="showMyTasksOnly" [width]="30" (onValueChanged)="showMyTasksOnlyChanged($event)"
          class="me-0">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate2'">
      <div>
        <span class="me-2">Not Started</span>
        <dx-check-box [(value)]="showNotStarted" [width]="30" (onValueChanged)="showNotStartedChanged($event)"
          class="me-0">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate3'">
      <div>
        <span class="me-2">In Progress</span>
        <dx-check-box [(value)]="showInProgress" [width]="30" (onValueChanged)="showInProgressChanged($event)"
          class="me-0">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate4'">
      <div>
        <span class="me-2">Completed</span>
        <dx-check-box [(value)]="showCompleted" [width]="30" (onValueChanged)="showCompletedChanged($event)">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate5'">
      <div>
        <span class="me-2">Cancelled</span>
        <dx-check-box [(value)]="showCancelled" [width]="30" (onValueChanged)="showCancelledChanged($event)">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate6'">
      <div>
        <span class="me-2">Jobs on Hold</span>
        <dx-check-box [(value)]="showJobsOnHold" [width]="30" (onValueChanged)="showJobsOnHoldChanged($event)">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarStartDays'">
      <div class="px-2">
        <span>Tasks are not started if starting in</span>
        <dx-number-box id="futureDaysClass" [(value)]="startFutureDays" [width]="80"
          (onValueChanged)="startFutureDaysChanged($event)" [showClearButton]="true">
        </dx-number-box>
        <span>days or more</span>
      </div>
    </div>


    <div *dxTemplate="let cellInfo of 'codeDropDown'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="jobData" [(value)]="cellInfo.value"
        displayExpr="jobNumber" valueExpr="id" contentTemplate="contentTemplate">
        <div *dxTemplate="let e of 'contentTemplate'">
          <dx-data-grid [dataSource]="jobData" [remoteOperations]="false" height="500px" [showBorders]="true"
            [focusedRowEnabled]="true" [focusedRowKey]="cellInfo.value" [selectedRowKeys]="[cellInfo.value]"
            [hoverStateEnabled]="true" [scrolling]="{ mode: 'virtual', preloadEnabled: true }"
            (onSelectionChanged)="onSelectionChanged(cellInfo, e, $event)">

            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-search-panel [visible]="true" location="before" [width]="jobDropdownWidth"></dxo-search-panel>

            <dxi-column dataField="jobId" [visible]='false'></dxi-column>
            <dxi-column dataField="jobNumber" [width]="110"></dxi-column>
            <dxi-column dataField="contractName"></dxi-column>
            <dxi-column dataField="jobAddressString" caption="Site Address"></dxi-column>
            <dxi-column dataField="salesDate" [visible]='false'></dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>

    <div *dxTemplate="let cellInfo of 'editVariationTemplate'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="jobVariations" [(value)]="cellInfo.value"
        displayExpr="title" valueExpr="id" contentTemplate="contentTemplate" [showClearButton]="true"
        (onValueChanged)="onVariationChanged(cellInfo, $event)" (onOpened)="openVariationDropdown(cellInfo)">
        <div *dxTemplate="let e of 'contentTemplate'">
          <dx-data-grid [dataSource]="variationsDataSource" [remoteOperations]="false" height="450px"
            [showBorders]="true" [focusedRowEnabled]="true" [focusedRowKey]="cellInfo.value"
            [selectedRowKeys]="[cellInfo.value]" [hoverStateEnabled]="true"
            [scrolling]="{ mode: 'standard', preloadEnabled: true }"
            (onSelectionChanged)="onSelectionChanged(cellInfo, e, $event)">

            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-search-panel [visible]="true" location="before" [width]="jobDropdownWidth"></dxo-search-panel>

            <dxi-column dataField="displayedVariationNumber" caption="Variation" [width]="100" alignment="center">
            </dxi-column>

            <dxi-column dataField="title"></dxi-column>

            <dxi-column type="buttons" [width]="40">
              <dxi-button icon="download" [onClick]="openVariation" hint='Open variation'></dxi-button>
            </dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>

    <div *dxTemplate="let cellInfo of 'officeCommentCellTemplate'">
      <div *ngIf='showFullComments' id="vendorComment" class='mx-0 px-0'>
        <div (click)="editDueDateOrComment(cellInfo)">{{cellInfo.value}}</div>
      </div>
      <div *ngIf='!showFullComments' id="vendorCommentLimited" class='mx-0 px-0'>
        <div (click)="editDueDateOrComment(cellInfo)">{{cellInfo.value}}</div>
      </div>
    </div>

    <div *dxTemplate="let cellInfo of 'vendorCommentCellTemplate'">
      <div *ngIf='showFullComments' id="vendorComment" class='mx-0 px-0'>{{cellInfo.value}}</div>
      <div *ngIf='!showFullComments' id="vendorCommentLimited" class='mx-0 px-0'>{{cellInfo.value}}</div>
    </div>

    <div *dxTemplate="let cellInfo of 'dueDateCellTemplate'">
      <dx-button id="dueDateButton" (onClick)="editDueDateOrComment(cellInfo)">
        <span>{{cellInfo.value | date : "d-MMM-yy"}}</span></dx-button>
    </div>

    <div *dxTemplate="let cellInfo of 'jobHeaderCellTemplate'">
      Job
      <dx-button icon="search" (onClick)="jobSearch()"></dx-button>
    </div>

    <dxi-column *ngIf="!cancelOldTasksModeOn" type="buttons" [width]="120">
      <dxi-button name="edit"> </dxi-button>
      <dxi-button icon="check" [onClick]="checkTaskPopup"></dxi-button>
      <dxi-button icon="trash" [onClick]="deleteTaskPopup"></dxi-button>
      <dxi-button icon="import" [onClick]="goToJobData" hint='Go to Job-Data'></dxi-button>
    </dxi-column>


    <dxo-summary>
      <dxi-group-item column="jobId" summaryType="count" displayFormat="{0}">
      </dxi-group-item>
    </dxo-summary>
  </dx-data-grid>


  <!-- mobile version with auto column hiding -->
  <dx-data-grid *ngIf="!loading && isMobile" id="dataGridMobile" class="m-auto" [dataSource]="dataSource"
    [height]="gridHeight" [allowColumnReordering]="false" [allowColumnResizing]="false" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" (onInitNewRow)="onInitNewRow($event)"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate', 'toolbarTemplate2', 'toolbarTemplate3', 'toolbarTemplate4', 'toolbarTemplate5', 'toolbarTemplate6', 'toolbarStartDays')"
    (onInitialized)="onInitialized($event)" (onRowPrepared)="onRowPrepared($event)" (onEditCanceled)="onEditCancelled()"
    (onCellPrepared)="onCellPrepared($event)" (onEditingStart)="onEditingStart($event)" (onSaved)="resetUsers()"
    (onEditCanceled)="resetUsers()" (onSaved)="onTasksSaved()" [syncLookupFilterValues]="false"
    (onEditorPreparing)="onEditorPreparing($event)">

    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-search-panel [visible]="true" location="before" [width]="100"></dxo-search-panel>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

    <dxo-editing mode="popup" [allowAdding]="!isPhoneSize" [allowUpdating]="!isPhoneSize" [allowDeleting]="!isPhoneSize"
      [useIcons]="true" refreshMode="full" [(changes)]="changes">
      <dxo-popup title="" [showTitle]="false" [width]="editPopupWidth" [height]="editPopupHeight" [fullScreen]="false">
        <dxi-toolbar-item *ngIf="!isSmallScreen" toolbar="bottom" location="before" widget="dxButton"
          [options]="{ type: 'default', stylingMode: 'outlined', text: 'Send', onClick: sendTask }">
        </dxi-toolbar-item>
        <dxi-toolbar-item *ngIf="currentJobTask && !isSmallScreen" toolbar="bottom" location="before" widget="dxButton"
          [options]="{ type: 'default', stylingMode: 'outlined', text: 'Attach Docs', onClick: attachDocs }">
        </dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
          [options]="{ type: 'default', text: 'Save', onClick: saveClickHandler }">
        </dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
          [options]="{ type:'danger', text: 'Cancel', onClick: cancelClickHandler }">
        </dxi-toolbar-item>
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
          <dxi-item dataField="jobId"></dxi-item>
          <dxi-item dataField="contractName"></dxi-item>
          <dxi-item dataField="siteAddress" [colSpan]="2"></dxi-item>
          <dxi-item dataField="taskTypeId" [colSpan]="2"></dxi-item>
          <dxi-item dataField="taskMasterId" [colSpan]="2"></dxi-item>
          <dxi-item dataField="templateTaskHeaderId" [colSpan]="2"></dxi-item>
          <dxi-item dataField="startDate"></dxi-item>
          <dxi-item dataField="dueDate"></dxi-item>
          <dxi-item dataField="endDate"></dxi-item>
          <dxi-item dataField="isCompleted"></dxi-item>
          <dxi-item dataField="officeComment" editorType="dxTextArea" [colSpan]="2" [editorOptions]="{ height: 100 }">
          </dxi-item>

          <dxi-item itemType="button" [colSpan]="2" [buttonOptions]="commentHistoryButtonOptions">
          </dxi-item>

          <dxi-item dataField="userId"></dxi-item>
          <dxi-item dataField="statusId"></dxi-item>
          <dxi-item dataField="modifiedUserId"></dxi-item>
          <dxi-item dataField="modifiedDate"></dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"> <dxo-search [enabled]="true"></dxo-search> </dxo-header-filter>

    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="false" [contextMenuEnabled]="true" expandMode="rowClick"></dxo-grouping>
    <!-- <dxo-state-storing [enabled]="true" type="localStorage" storageKey="jobtaskgrid"></dxo-state-storing> -->
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="jobtaskgrid" [savingTimeout]="500">
    </dxo-state-storing>

    <dxi-column dataField="jobId" dataType="number" caption="Job" [width]="80" editCellTemplate="codeDropDown"
      [setCellValue]="setJobIdCellValue" [calculateSortValue]="calculateJobSortValue" [allowEditing]="!jobTaskId"
      [calculateGroupValue]="calculateJobGroupValue" groupCellTemplate="jobGroupCellTemplate">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="jobData" displayExpr="jobNumber" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <div *dxTemplate="let data of 'jobGroupCellTemplate'" class="displayThis">
      <span>{{ calculateJobGroupDisplay(data) }}</span>
    </div>

    <dxi-column dataField="contractName" dataType="string" [calculateCellValue]='calculateContractName' [width]="200"
      [allowEditing]="false" [hidingPriority]="5">
    </dxi-column>

    <dxi-column dataField="siteAddress" dataType="string" [calculateCellValue]='calculateSiteAddress' [width]="200"
      [allowEditing]="false" [hidingPriority]="7">
    </dxi-column>

    <dxi-column dataField="taskTypeId" caption="Type" dataType="number" [width]="120"
      [setCellValue]="setTaskTypeCellValue" [hidingPriority]="6">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="taskTypes" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="taskMasterId" dataType="number" caption="Title" [width]="150"
      [setCellValue]="setTaskMasterIdCellValue" [calculateSortValue]="calculateTaskSortValue">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="getTaskList" displayExpr="taskTitle" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="templateTaskHeaderId" caption="Work Flow" dataType="number" [visible]='false'
      [editorOptions]="{ showClearButton: true }" [setCellValue]="setTemplateTaskHeaderIdCellValue" minWidth="100">
      <dxo-lookup [dataSource]="allTaskHeaders" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="startDate" dataType="date" [width]="90" alignment="center" format="d-MMM-yy"
      [setCellValue]="setStartDateCellValue"
      [editorOptions]="{ showClearButton: true, calendarOptions: { showTodayButton: true } }" [hidingPriority]="8">
    </dxi-column>

    <dxi-column dataField="dueDate" dataType="date" [width]="90" alignment="center" format="d-MMM-yy"
      [editorOptions]="{ showClearButton: true, calendarOptions: { showTodayButton: true } }" sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="originalDueDate" dataType="date" [width]="90" alignment="center" format="d-MMM-yy"
      [calculateCellValue]="calcOriginalDueDate" [visible]="false">
    </dxi-column>

    <dxi-column dataField="endDate" caption="Completed" dataType="date" [width]="90" alignment="center"
      format="d-MMM-yy" [setCellValue]="setEndDateCellValue" [hidingPriority]="3"
      [editorOptions]="{ showClearButton: true, calendarOptions: { showTodayButton: true } }">
    </dxi-column>

    <dxi-column dataField="officeComment" dataType="string" caption="Comment" minWidth="150"
      cellTemplate="commentCellTemplate" [hidingPriority]="9">
    </dxi-column>

    <dxi-column dataField="vendorComment" dataType="string" caption="Vendor Comment" minWidth="150"
      cellTemplate="commentCellTemplate" [hidingPriority]="10" [visible]="false">
    </dxi-column>

    <dxi-column dataField="userId" caption="Assigned To" dataType="number" [width]="110"
      [editorOptions]="{ showClearButton: true }" [hidingPriority]="4">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="isCompleted" caption="Done" dataType="boolean" [width]="50" [visible]='false'
      [calculateCellValue]='calculateIsCompleted' [setCellValue]="setIsCompletedCellValue">
    </dxi-column>

    <dxi-column dataField="statusId" caption="Status" dataType="number" [width]="110"
      [setCellValue]="setStatusCellValue" [hidingPriority]="2">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="taskStatusList" displayExpr="status" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="modifiedUserId" caption="Modified By" dataType="number" [allowEditing]="false"
      [visible]='false' [width]="110">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="modifiedDate" caption="Modified" dataType="date" [width]="85" alignment="center"
      format="d-MMM-yy" [allowEditing]="false" [hidingPriority]="1">
    </dxi-column>

    <dxi-column dataField="calledDate" caption="Sent" dataType="date" [width]="85" alignment="center" format="d-MMM-yy"
      [visible]='false' [allowEditing]="false">
    </dxi-column>

    <!-- <dxi-column dataField="sentFromQueueDate" dataType="date" [width]="85" alignment="center" format="d-MMM-yy"
      [visible]='false' [allowEditing]="false">
    </dxi-column> -->

    <dxi-column dataField="emailQueueStatus" dataType="string" [width]="85" alignment="center" [visible]='false'
      [allowEditing]="false" [calculateCellValue]="calculateSentFromQueueStatus">
    </dxi-column>

    <dxi-column dataField="doNotSendCallup" caption="Don't Send" dataType="boolean" [width]="50" alignment="center"
      [visible]='false' [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [visible]='false' [allowEditing]="false"
      [calculateSortValue]="calculateVendorSortValue" [width]="200">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="tradeRegionId" caption="Region" dataType="string" [visible]='false' [width]="100"
      [calculateCellValue]="calculateTradeRegion" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="houseTypeId" caption="House Type" dataType="string" [visible]='false' [width]="100"
      [calculateCellValue]="calculateHouseType" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="tradeId" caption="Trade" dataType="string" [visible]='false' [width]="120"
      [calculateCellValue]="calculateTrade" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="jobVariationId" caption="Variation" dataType="number" [width]="105" alignment="center"
      [visible]='false'>
      <dxo-lookup [dataSource]="jobVariations" displayExpr="displayedVariationNumber" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="jobVariationTitle" caption="Variation Title" dataType="string" [width]="200" alignment="left"
      [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateVariationTitle">
    </dxi-column>

    <dxi-column dataField="jobVariationStatusId" caption="Variation Status" dataType="string" [width]="130"
      alignment="left" [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateVariationStatus">
    </dxi-column>

    <dxi-column dataField="assignedUserId" caption="Variation Estimator" dataType="number" alignment="left"
      [minWidth]="100" [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateVariationEstimator">
    </dxi-column>

    <dxi-column dataField="masterJobId" dataType="number" caption="Master Job" [width]="100" alignment="left"
      [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateMasterJob">
    </dxi-column>

    <dxi-column dataField="salesRepId" dataType="number" [caption]="salesRepWording" [width]="100" alignment="left"
      [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateSalesRep">
    </dxi-column>

    <dxi-column dataField="currentActivity" dataType="string" [width]="130" alignment="left" [visible]='false'
      [allowEditing]="false" [calculateCellValue]="calculateCurrentActivity">
    </dxi-column>

    <dxi-column dataField="isKeyTask" caption="Key Task" dataType='boolean' [width]='50'
      [calculateCellValue]="calculateKeyTaskValue" [visible]="false" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="isAcceptedByVendor" dataType="boolean" [visible]='false'>
    </dxi-column>

    <dxi-column *ngIf="liaisonIsActive" dataField="liaison" dataType="number" [caption]="liaisonWording" [width]="100"
      alignment="left" [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateLiaison">
    </dxi-column>

    <dxi-column *ngIf="prestartOfficerIsActive" dataField="prestartOfficer" dataType="number"
      [caption]="prestartOfficerWording" [width]="100" alignment="left" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculatePrestartOfficer">
    </dxi-column>

    <dxi-column *ngIf="siteManagerIsActive" dataField="siteManager" dataType="number" [caption]="siteManagerWording"
      [width]="100" alignment="left" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculateSiteManager">
    </dxi-column>

    <dxi-column *ngIf="constructionCoordinatorIsActive" dataField="constructionCoordinator" dataType="number"
      [caption]="constructionCoordinatorWording" [width]="100" alignment="left" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculateConstructionCoordinator">
    </dxi-column>

    <dxi-column *ngIf="areaConstructionManagerIsActive" dataField="areaConstructionManager" dataType="number"
      [caption]="areaConstructionManagerWording" [width]="100" alignment="left" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculateAreaConstructionManager">
    </dxi-column>

    <dxi-column *ngIf="designerIsActive" dataField="designer" dataType="number" [caption]="designerWording"
      [width]="100" alignment="left" [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateDesigner">
    </dxi-column>

    <dxi-column *ngIf="salesEstimatorIsActive" dataField="salesEstimator" dataType="number"
      [caption]="salesEstimatorWording" [width]="100" alignment="left" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculateSalesEstimator">
    </dxi-column>

    <dxi-column *ngIf="schedulerIsActive" dataField="scheduler" dataType="number" [caption]="schedulerWording"
      [width]="100" alignment="left" [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateScheduler">
    </dxi-column>

    <dxi-column *ngIf="draftspersonIsActive" dataField="draftsperson" dataType="number" [caption]="draftspersonWording"
      [width]="100" alignment="left" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculateDraftsperson">
    </dxi-column>

    <dxi-column *ngIf="preConstructionCoordinatorIsActive" dataField="preConstructionCoordinator" dataType="number"
      [caption]="preConstructionCoordinatorWording" [width]="100" alignment="left" [allowEditing]="false"
      [visible]='false' [calculateCellValue]="calculatePreConstructionCoordinator">
    </dxi-column>

    <dxi-column *ngIf="accountsIsActive" dataField="accounts" dataType="number" [caption]="accountsWording"
      [width]="100" alignment="left" [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateAccounts">
    </dxi-column>

    <dxi-column dataField="createDate" caption="Created" dataType="date" [width]="85" alignment="center"
      format="d-MMM-yy" [allowEditing]="false" [visible]='false'>
    </dxi-column>

    <dxi-column dataField="daysSinceCreateDate" caption="Since Created" dataType="number" [width]="85"
      alignment="center" format="#,###" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculateDaysSinceCreated">
    </dxi-column>

    <dxi-column dataField="daysSinceStartDate" caption="Since Start" dataType="number" [width]="85" alignment="center"
      format="#,##0" [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateDaysSinceStart">
    </dxi-column>

    <!-- division and warning note -->
    <dxi-column dataField="division" caption="Division" dataType="string" [width]="105" alignment="left"
      [visible]='false' [allowEditing]="false" [calculateCellValue]="calculateDivision">
    </dxi-column>

    <dxi-column dataField="warningNote" caption="Job Warning" dataType="string" [width]="150" alignment="left"
      [visible]='false' [allowEditing]="false" [calculateCellValue]="calculateJobWarning">
    </dxi-column>

    <!-- Contracts Required -->
    <dxi-column dataField="contractsRequiredByDate" dataType="date" [width]="95" alignment="center" format="d-MMM-yy"
      [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateContractsRequiredByDate">
    </dxi-column>

    <dxi-column dataField="daysToContractsRequired" caption="Calendar days to contracts required"
      dataType="number" [width]="85" alignment="center" format="#,##0" [allowEditing]="false" [visible]='false'
      [calculateCellValue]="calculateDaysToContractsRequired">
    </dxi-column>


    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <span class="me-1">Show:</span>
        <span class="me-2">My Tasks Only</span>
        <dx-check-box [(value)]="showMyTasksOnly" [width]="30" (onValueChanged)="showMyTasksOnlyChanged($event)"
          class="me-0">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate2'">
      <div>
        <span class="me-2">Not Started</span>
        <dx-check-box [(value)]="showNotStarted" [width]="30" (onValueChanged)="showNotStartedChanged($event)"
          class="me-0">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate3'">
      <div>
        <span class="me-2">In Progress</span>
        <dx-check-box [(value)]="showInProgress" [width]="30" (onValueChanged)="showInProgressChanged($event)"
          class="me-0">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate4'">
      <div>
        <span class="me-2">Completed</span>
        <dx-check-box [(value)]="showCompleted" [width]="30" (onValueChanged)="showCompletedChanged($event)">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate5'">
      <div>
        <span class="me-2">Cancelled</span>
        <dx-check-box [(value)]="showCancelled" [width]="30" (onValueChanged)="showCancelledChanged($event)">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate6'">
      <div>
        <span class="me-2">Jobs on Hold</span>
        <dx-check-box [(value)]="showJobsOnHold" [width]="30" (onValueChanged)="showJobsOnHoldChanged($event)">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarStartDays'">
      <div>
        <span>Tasks are not started if starting in</span>
        <dx-number-box id="futureDaysClass" [(value)]="startFutureDays" [width]="80"
          (onValueChanged)="startFutureDaysChanged($event)" [showClearButton]="true">
        </dx-number-box>
        <span>days or more</span>
      </div>
    </div>


    <div *dxTemplate="let cellInfo of 'codeDropDown'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="jobData" [(value)]="cellInfo.value"
        displayExpr="jobNumber" valueExpr="id" contentTemplate="contentTemplate">
        <div *dxTemplate="let e of 'contentTemplate'">
          <dx-data-grid [dataSource]="jobData" [remoteOperations]="false" height="500px" [showBorders]="true"
            [focusedRowEnabled]="true" [focusedRowKey]="cellInfo.value" [selectedRowKeys]="[cellInfo.value]"
            [hoverStateEnabled]="true" [scrolling]="{ mode: 'virtual', preloadEnabled: true }"
            (onSelectionChanged)="onSelectionChanged(cellInfo, e, $event)">

            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-search-panel [visible]="true" location="before" [width]="jobDropdownWidth"></dxo-search-panel>

            <dxi-column dataField="jobId" [visible]='false'></dxi-column>
            <dxi-column dataField="jobNumber" [width]="110"></dxi-column>
            <!-- <dxi-column dataField="contractName"></dxi-column> -->
            <dxi-column dataField="jobAddressString" caption="Site Address"></dxi-column>
            <dxi-column dataField="salesDate" [visible]='false'></dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>

    <div *dxTemplate="let cellInfo of 'commentCellTemplate'">
      <div id="vendorComment" class='mx-0 px-0'>{{cellInfo.value}}</div>
    </div>

    <dxi-column *ngIf="!isPhoneSize" type="buttons" [width]="iPadButtonsWidth">
      <dxi-button name="edit"> </dxi-button>
      <dxi-button icon="check" [onClick]="checkTaskPopup"></dxi-button>
      <dxi-button *ngIf="iPadShowDeleteButton" icon="trash" [onClick]="deleteTaskPopup"></dxi-button>
      <dxi-button *ngIf="iPadShowGoToJobDataButton" icon="import" [onClick]="goToJobData"></dxi-button>
    </dxi-column>

    <dxo-summary>
      <dxi-group-item column="jobId" summaryType="count" displayFormat="{0}">
      </dxi-group-item>
    </dxo-summary>
  </dx-data-grid>


  <dx-popup class="popup" [width]="190" [height]="130" [showTitle]="true" title="Reset" [dragEnabled]="false"
    [hideOnOutsideClick]="true" [(visible)]="resetPopupVisible" (onHidden)="inEditMode = false">
    <div *dxTemplate="let data of 'content'">
      <dx-button id="button" text="Reset Layout" type="success" (onClick)="clearStatePersistanceGo()" class="ms-3">
      </dx-button>
    </div>
  </dx-popup>


  <dx-popup class="popup" [width]="editPopupWidth" [height]="commentPopupHeight" [showTitle]="true"
    title="Comment History" [dragEnabled]="false" [hideOnOutsideClick]="false" [(visible)]="commentHistoryPopupVisible">
    <div *dxTemplate="let data of 'content'">
      <dx-data-grid id="dataGridComments" class="m-auto" [height]="commentGridHeight"
        [dataSource]="commentHistoryDataSource" [showBorders]="true" [rowAlternationEnabled]="true"
        [wordWrapEnabled]="true" [allowColumnResizing]="true" (onExporting)="gridService.onExporting($event)">

        <dxo-scrolling mode="standard"></dxo-scrolling>
        <dxo-export [enabled]="true"></dxo-export>
        <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>

        <dxo-paging [enabled]="false"></dxo-paging>

        <dxi-column dataField="comment">
        </dxi-column>

        <dxi-column dataField="modifiedUserId" caption="Modified By" dataType="number" [width]="110"
          allowEditing="false" cellTemplate="noWrapTemplate" [allowEditing]="false">
          <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
          </dxo-lookup>
        </dxi-column>

        <dxi-column dataField="modifiedDate" caption="Modified" dataType="date" [width]="85" alignment="center"
          format="d-MMM-yy" [allowEditing]="false">
        </dxi-column>


        <div *dxTemplate="let cell of 'noWrapTemplate'">
          <div style="white-space: nowrap; overflow: hidden;">{{cell.text}}</div>
        </div>
      </dx-data-grid>

      <button type="button" class="btn btn-sm btn-secondary mt-2" (click)="closeCommentHistory()">
        Close
      </button>
    </div>
  </dx-popup>

  <!-- Send Call-Up -->
  <dx-popup class="popup" [width]="editPopupWidth" [height]="callUpPopupHeight" [showTitle]="true" title="Send Task"
    [dragEnabled]="false" [hideOnOutsideClick]="false" [(visible)]="sendPopupVisible">
    <div *dxTemplate="let data of 'content'">
      <dx-scroll-view width="100%" height="callUpPopupHeight - 65">

        <b>{{currentTaskTitle}}</b>
        <br><br>

        <div class="dx-field">
          <div class="dx-field-label">Send to:</div>
          <div class="dx-field-value">
            <dx-select-box [(ngModel)]="vendorId" [dataSource]="vendors" [searchEnabled]="true" valueExpr="id"
              displayExpr="vendorName" (onSelectionChanged)="onVendorSelectionChanged($event)">
            </dx-select-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Email Address:</div>
          <div class="dx-field-value">
            <dx-text-box [(value)]="callUpEmail"></dx-text-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Subject:</div>
          <div class="dx-field-value">
            <dx-text-box [(value)]="callUpSubject"></dx-text-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">External Comment:</div>
          <div class="dx-field-value">
            <dx-text-area [height]="80" [(value)]="vendorComment"> </dx-text-area>
          </div>
        </div>

        <!-- add attachments -->
        <js-loading-spinner *ngIf="loadingDocs"></js-loading-spinner>

        <dx-tree-list *ngIf="!loadingDocs" id="jobDocumentsTree" [dataSource]="jobDocuments" keyExpr="keyId"
          [height]="callUpTreeHeight" parentIdExpr="parentKeyId" [showRowLines]="true" [showBorders]="true"
          [columnAutoWidth]="false" [(selectedRowKeys)]="selectedJobDocs"
          (onSelectionChanged)="attachmentSelectionChanged($event)">

          <dxo-search-panel [visible]="true" [width]="200"></dxo-search-panel>
          <dxo-selection mode="multiple" [recursive]="true" [allowSelectAll]="false"> </dxo-selection>

          <dxi-column dataField="description" [allowSorting]="false"></dxi-column>
          <dxi-column dataField="Auto-Attached" caption="Auto-Attached" [calculateCellValue]='calculateCallUpDocsType'
            [width]="110" cellTemplate="callUpDocsTypeCellTemplate" [allowSorting]="false"></dxi-column>

          <div *dxTemplate="let cellInfo of 'callUpDocsTypeCellTemplate'">
            <i *ngIf="cellInfo.value" class="material-icons">done</i>
          </div>
        </dx-tree-list>

        <div class="dx-field mt-3 mb-1">
          <div class="dx-field-label">{{addendumCaption}}</div>
          <div>
            <dx-check-box [(value)]="sendAddendum"> </dx-check-box>
          </div>
        </div>

        <div class="dx-field mb-1">
          <div class="dx-field-label">cc email to yourself:</div>
          <div>
            <dx-check-box [(value)]="ccToSelf"> </dx-check-box>
          </div>
        </div>

        <div *ngIf="purchaseOrders" class="dx-field mb-1">
          <div class="dx-field-label">Attach Order:</div>
          <div class="dx-field-value">
            <dx-select-box class="selectClass" [dataSource]="purchaseOrders" displayExpr="poNumber" valueExpr="id"
              placeholder="Not Selected" showClearButton="true" [searchEnabled]="true"
              [(value)]="selectedPurchaseOrderId">
              <div *dxTemplate="let data of 'item'">
                <b class="po-number">{{data.poNumber}}</b>
                <span class="ms-2">{{data.vendorName}}</span>
              </div>
            </dx-select-box>
          </div>
        </div>

        <!-- <br> -->
        <dx-button *ngIf="!loadingDocs" text="Send" type="default" (onClick)="sendCallUp()">
        </dx-button>

        <dx-button *ngIf="!loadingDocs" text="Clear Vendor Details" class="ms-2" type="outline"
          (onClick)="clearVendorDetails()">
        </dx-button>
      </dx-scroll-view>
    </div>
  </dx-popup>


  <dx-popup class="popup" [width]="290" [height]="130" [showTitle]="true" title="Workflow?" [dragEnabled]="false"
    [hideOnOutsideClick]="false" [(visible)]="savePopupVisible">
    <div *dxTemplate="let data of 'content'">
      <dx-button id="button" text="Save with no workflow specified?" type="success" (onClick)="saveCheckDone()">
      </dx-button>
    </div>
  </dx-popup>


  <!-- loading spinner - DevExtreme -->
  <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#employee' }"
    [visible]="loading || loadingTasks || deleteInProgress || updatingVariation" [showIndicator]="true"
    [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
  </dx-load-panel>

  <span *ngIf="!loading && !isMobile">{{minuteCountdown}} minute(s) before auto refresh</span>
</div>

<dx-popup class="popup" [width]="220" [height]="180" [showTitle]="true" title="Delete" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="deletePopupVisible" (onHidden)="inEditMode = false">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="deleteButton" text="Mark as Cancelled" type="danger" (onClick)="deleteTasksGo(true)">
    </dx-button>
    <br><br>
    <dx-button id="notApplicableButton" text="Mark Not Applicable" type="default" (onClick)="deleteTasksGo(false)">
    </dx-button>
  </div>
</dx-popup>

<dx-popup class="popup" [width]="220" [height]="130" [showTitle]="true" title="Complete" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="checkPopupVisible" [showCloseButton]="true" (onHidden)="inEditMode = false">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="deleteButton" text="Mark Completed" type="success" (onClick)="checkTasksGo()">
    </dx-button>
  </div>
</dx-popup>

<dx-popup class="popup" [width]="600" [height]="370" [showTitle]="true" [title]="currentTaskTitle" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="editDueDateOrCommentPopupVisible" [showCloseButton]="true"
  (onHidden)="inEditMode = false">
  <div *dxTemplate="let data of 'content'">
    <div>Due Date</div>
    <dx-date-box id="dueDateBox" [(value)]="dueDate" [displayFormat]="'d-MMM-yy'" [type]="'date'" [width]="150"
      [pickerType]="'calendar'" [calendarOptions]="{ showTodayButton: true }">
    </dx-date-box>
    <br>
    <div>Comment</div>
    <dx-text-area [(value)]="officeComment" [height]="130"> </dx-text-area>
    <br>
    <dx-button text="Update" type="success" (onClick)="updateDueDate()" [width]="100">
    </dx-button>
  </div>
</dx-popup>

<dx-popup class="popup" [width]="420" [height]="190" [showTitle]="true" title="Cancel Tasks" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="cancelTasksPopupVisible" [showCloseButton]="true">
  <div *dxTemplate="let data of 'content'">
    <span>Include tasks in progress</span>
    <dx-check-box [(value)]="includeStartedTasksForCancel" [width]="30" class="ms-2"> </dx-check-box>
    <br>
    <div>Note: Cancelling too many tasks at once may time-out.</div>
    <br>
    <dx-button id="deleteButton" [text]="cancelTasksButton" type="danger" (onClick)="cancelOldJobTasksRun()">
    </dx-button>
  </div>
</dx-popup>
