import { UserService } from './../../services/felixApi/user.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { TaskService } from '../../services/felixApi/task.service';
import { NotificationService } from '../../services/notification.service';
import { SendCancellation } from '../../dtos/send-cancellation';
import { Vendor } from '../../dtos/vendor';

@Component({
  selector: 'js-send-cancellation',
  templateUrl: './send-cancellation.component.html',
  styleUrls: ['./send-cancellation.component.scss']
})
export class SendCancellationComponent implements OnInit, OnDestroy {
  @Input() jobTaskId: number;
  @Input() vendorId: number;
  @Input() isQuoteRequest: boolean;

  subscriptions: Subscription[] = [];
  sendCancellationDto = new SendCancellation();
  loading = true;
  popupHeight: number;
  vendorName: string;
  vendor: Vendor;

  constructor(
    private activeModal: NgbActiveModal,
    private taskService: TaskService,
    private userService: UserService,
    private notiService: NotificationService) {
  }

  ngOnInit() {
    this.popupHeight = 230;
    this.sendCancellationDto.sendCancellationComment = 'Advice of new date will be given when required.';
    this.vendor = this.userService.vendors.find(i => i.id === this.vendorId);
    this.vendorName = this.vendor?.vendorName ?? '';
    // look up the email address
    this.getTask();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getTask() {
    this.subscriptions.push(
      this.taskService.getJobTaskQueue(this.jobTaskId)
        .subscribe({
          next: (taskQueues) => {
            if (taskQueues && taskQueues.length) {
              this.sendCancellationDto.sendCancellationToEmail = (taskQueues[0].emailAddresses ?? '');
            }

            // if the task was Marked-As-Sent, then the email address will be blank
            if (this.sendCancellationDto.sendCancellationToEmail === '') {
              if (this.vendor) {
                if (this.vendor.quoteRequestEmail && this.vendor.quoteRequestEmail.trim() !== '' && this.isQuoteRequest) {
                  this.sendCancellationDto.sendCancellationToEmail = this.vendor.quoteRequestEmail;
                } else {
                  this.sendCancellationDto.sendCancellationToEmail = this.vendor.callUpEmail && this.vendor.callUpEmail.length ? this.vendor.callUpEmail : this.vendor.email;
                }
              }
            }

            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  sendCancellationEmail() {
    this.loading = true;
    this.subscriptions.push(
      this.taskService.sendJobTaskCancellation(this.jobTaskId, this.sendCancellationDto)
        .subscribe({
          next: () => {
            this.loading = false;
            this.notiService.showInfo('Cancellation email sent. Save changes to update the task.');
            this.activeModal.close();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  close() {
    this.activeModal.dismiss();
  }

}
