<nav *ngIf="signedIn && currComp" class="navbar navbar-expand-md navbar-light navbarclass">

  <dx-drop-down-button #dropdownButton [items]="dropdownItems" [useSelectMode]="false" [dropDownOptions]="{ width: 140, height: 180}"
    icon="assets/menuBar.png" class="menuDropDown">

    <div *dxTemplate="let item of 'item'">
      <img [src]="item.icon" class="dropDownLogo">
      <span class="dropdownOptionText">{{ item.text }}</span>
    </div>
  </dx-drop-down-button>

  <div>
    <img class="navbar-brand logo" src="../../../assets/logoTransparent.png" [routerLink]="['/welcome']" />
    <div *ngIf="!documentsActive || innerWidth > 900" class="title">
      Tracking
    </div>
  </div>

  <span *ngIf="currComp && innerWidth > 767" class="cust-divider-vertical"></span>

  <button class="navbar-toggler d-lg-none" type="button" (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed" aria-controls="navbarsExampleDefault" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarsExampleDefault" [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0" style="margin-top: 0!important;">

      <a class="nav-item active nav-link tab" [routerLink]="['/tasks']" [class.disabled]="disabledMenu"
        [class.selected]="areaSelected === 'tasks'" (click)="isCollapsed = true; menuPress('tasks')">
        Tasks
      </a>

      <a *ngIf="globalService.canSeeCallUps" class="nav-item active nav-link tab" [routerLink]="['/call-ups']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'call-ups'"
        (click)="isCollapsed = true; menuPress('call-ups')">
        Call-Ups
      </a>

      <a *ngIf="globalService.canSeeInvoices" class="nav-item active nav-link tab" [routerLink]="['/invoices-on-hold']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'invoices-on-hold'"
        (click)="isCollapsed = true; menuPress('invoices-on-hold')">
        Invoices<i *ngIf="globalService.isHaveInvoicesToCheck" class="material-icons">priority_high</i>
      </a>

      <a *ngIf="globalService.canSeeManualPurchaseOrders" class="nav-item active nav-link tab"
        [routerLink]="['/manual-purchase-orders']" [class.disabled]="disabledMenu"
        [class.selected]="areaSelected === 'manual-purchase-orders'"
        (click)="isCollapsed = true; menuPress('manual-purchase-orders')">
        Orders
      </a>

      <a class="nav-item active nav-link tab" [routerLink]="['/job-data']" [class.disabled]="disabledMenu"
        [class.selected]="areaSelected === 'job-data'" (click)="isCollapsed = true; menuPress('job-data')">
        Job-Data
      </a>

      <!-- <a class="nav-item active nav-link tab" [routerLink]="['/job-work-flows']" [class.disabled]="disabledMenu"
        [class.selected]="areaSelected === 'job-work-flows'" (click)="isCollapsed = true; menuPress('job-work-flows')">
        Job-Work-Flows
      </a> -->

      <a *ngIf="innerWidth > 1000 || !isCollapsed" class="nav-item active nav-link tab" [routerLink]="['/report-grid']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'report-grid'"
        (click)="isCollapsed = true; menuPress('report-grid')">
        Report-Grid
      </a>

      <a *ngIf="globalService.canSeeForecast && (innerWidth > 1000 || !isCollapsed)" class="nav-item active nav-link tab"
        [routerLink]="['/jobSetUp']" [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'jobSetUp'"
        (click)="isCollapsed = true; menuPress('jobSetUp')">
        Forecast
      </a>

      <a *ngIf="documentsActive && (innerWidth > 750 || !isCollapsed)" class="nav-item active nav-link tab" [routerLink]="['/documents']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'documents'"
        (click)="isCollapsed = true; menuPress('documents')">
        Documents
      </a>

      <a *ngIf="globalService.canSeeMaintenance && ((!documentsActive && innerWidth > 1000) || (documentsActive && innerWidth > 1100) || !isCollapsed)"
        class="nav-item active nav-link tab" [routerLink]="['/maintenance']" [class.disabled]="disabledMenu"
        [class.selected]="areaSelected === 'maintenance'" (click)="isCollapsed = true; menuPress('maintenance')">
        Set-Up
      </a>

    </ul>
  </div>

  <div *ngIf="innerWidth > 767">
    <a class="currComp" [routerLink]="['/welcome']">{{currComp.companyShortName}}</a>
  </div>
</nav>
