<dx-popup class="popup" [width]="popupWidth" [height]="popupHeight" [showTitle]="true" title="Attachments"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [visible]="true" [shading]="false" (onHidden)="close()"
  [showCloseButton]="true">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="popupScroll">
      <js-loading-spinner *ngIf="loadingDocs"></js-loading-spinner>

      <dx-tree-list *ngIf="!loadingDocs" id="jobDocumentsTree" [dataSource]="jobDocuments" keyExpr="keyId"
        [height]="popupHeight - 133" parentIdExpr="parentKeyId" [showRowLines]="true" [showBorders]="true"
        [columnAutoWidth]="false" [(selectedRowKeys)]="selectedJobDocs"
        (onSelectionChanged)="attachmentSelectionChanged($event)">

        <dxo-search-panel [visible]="true" width="200"></dxo-search-panel>
        <dxo-selection mode="multiple" [recursive]="true" [allowSelectAll]="false"> </dxo-selection>

        <dxi-column dataField="description" [allowSorting]="false"></dxi-column>

        <dxi-column type="buttons" [hidingPriority]="2" [width]="40">
          <dxi-button [visible]="isDownloadVisible" icon="download" [onClick]="download"></dxi-button>
        </dxi-column>

        <div *dxTemplate="let cellInfo of 'callUpDocsTypeCellTemplate'">
          <i *ngIf="cellInfo.value" class="material-icons">done</i>
        </div>
      </dx-tree-list>

      <dx-button *ngIf="!loadingDocs" text="Save" class="mt-1" type="default" (onClick)="saveAttachedDocs()">
      </dx-button>

      <dx-button *ngIf="!loadingDocs" text="Upload" class="mt-1 floatRight" type="default" stylingMode="outlined"
        (onClick)="uploadDocuments()">
      </dx-button>
    </dx-scroll-view>
  </div>
</dx-popup>
