import { Component, OnInit, ViewChild, OnDestroy, Input, OnChanges } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { NotificationService } from '../services/notification.service';
import { GlobalService } from '../services/global.service';
import { GridService } from '../services/grid.service';
import { JobService } from '../services/felixApi/job.service';
import { Job } from '../dtos/job';
import { JobWorkFlowService } from '../services/felixApi/job-work-flow.service';
import { TemplateTaskHeader } from '../dtos/templateTaskHeader';
import { UserService } from '../services/felixApi/user.service';
import { User } from '../dtos/user';
import { MaintenanceService } from '../services/felixApi/maintenance.service';
import { TradeRegion } from '../dtos/trade-region';

@Component({
  selector: 'js-job-work-flow',
  templateUrl: './job-work-flow.component.html',
  styleUrls: ['./job-work-flow.component.scss']
})
export class JobWorkFlowComponent implements OnInit, OnChanges, OnDestroy {
  @Input() jobNumber: string;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  loading = false;
  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  claimMasters: CustomStore;
  jobs: Job[];
  jobId: number;
  innerWidth: number;
  taskHeaders: TemplateTaskHeader[];
  users: User[];
  gridHeight: number;
  updatedData: any;
  tradeRegionId: number;
  tradeRegions: TradeRegion[];
  modifiedByVisible: boolean;
  savingRegion: boolean;

  constructor(
    private notiService: NotificationService,
    private globalService: GlobalService,
    private maintenanceService: MaintenanceService,
    protected jobWorkFlowService: JobWorkFlowService,
    private userService: UserService,
    protected gridService: GridService,
    private jobService: JobService
  ) {
    this.onReorder = this.onReorder.bind(this);
    this.getTaskDaysList = this.getTaskDaysList.bind(this);
    this.getTaskHeaders = this.getTaskHeaders.bind(this);
    this.onInitNewRow = this.onInitNewRow.bind(this);
  }

  ngOnInit(): void {
    this.subscribeToInnerWidth();
  }

  ngOnChanges(): void {
    this.setJobStringMaxWidth();
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToInnerWidth() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setJobStringMaxWidth();
        }
      )
    );
  }

  setJobStringMaxWidth() {
    this.innerWidth = this.globalService.innerWidth;
    this.gridHeight = window.innerHeight - 155;
    this.modifiedByVisible = window.innerWidth > 700;
  }

  getData() {
    this.loading = true;
    this.dataSource = null;
    this.subscriptions.push(
      this.jobWorkFlowService.getJobWorkFlowData()
        .subscribe({
          next: (taskHeaders) => {
            this.taskHeaders = taskHeaders;

            this.users = this.userService.users;
            this.tradeRegions = this.maintenanceService.tradeRegions;
            this.tradeRegionId = this.jobService.currentJobExtra?.tradeRegionId;

            if (this.jobNumber && this.jobNumber !== '') {
              this.getJob();
            }
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  getTaskHeaders(e) {
    if (e.isNewRow) {
      return this.taskHeaders.filter(i => i.isActive);
    } else {
      return this.taskHeaders;
    }
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.jobWorkFlowService.getJobWorkFlows(this.jobId).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.jobWorkFlowService.addJobWorkFlow(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.jobWorkFlowService.updateJobWorkFlow(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.jobWorkFlowService.deleteJobWorkFlow(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }


  onToolbarPreparing(e, templateName) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName
    });
  }

  onLookupValueChanged(ea, e) {
    e.setValue(ea.value);
  }

  onLookupSelectionChanged(ea, e) {
    e.setValue(ea.value);
  }

  getJob() {
    this.jobId = this.jobService.currentJob.id;

    if (!this.tradeRegionId && this.tradeRegions && this.tradeRegions.length === 1) {
      this.tradeRegionId = this.tradeRegions[0].id;
      this.saveRegion();
    }

    this.setUpDataSource();
  }

  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();
    const newOrderIndex = visibleRows[e.toIndex].data.orderNumber;

    this.subscriptions.push(
      this.jobWorkFlowService.moveJobWorkFlow(this.jobId, e.itemData.id, newOrderIndex).subscribe(() => {
        e.component.refresh();
      }, () => {
        this.notiService.showInfo('Cannot drop here');
      })
    );
  }

  getTaskDaysList(options) {
    return {
      store: this.maintenanceService.templateDaysHeaders,
      filter: options.data ? ['templateTaskHeaderId', '=', options.data.templateTaskHeaderId] : null
    };
  }

  saveRegion() {
    if (!this.savingRegion) {
      this.savingRegion = true;
      if (this.jobService.currentJobExtra) {
        // edit
        this.updatedData = {};

        if (this.tradeRegionId !== this.jobService.currentJobExtra.tradeRegionId) {
          this.updatedData.tradeRegionId = this.tradeRegionId;

          this.subscriptions.push(
            this.jobService.updateJobExtra(this.jobService.currentJobExtra.id, this.updatedData)
              .subscribe(
                () => {
                  this.notiService.showSuccess('Region updated');
                  this.savingRegion = false;
                },
                err => {
                  this.notiService.notify(err);
                  this.savingRegion = false;
                })
          );
        } else {
          this.savingRegion = false;
        }
      } else {
        // add
        this.updatedData = {};
        this.updatedData.jobId = this.jobId;
        this.updatedData.tradeRegionId = this.tradeRegionId;

        this.subscriptions.push(
          this.jobService.addJobExtra(this.updatedData)
            .subscribe(
              () => {
                this.notiService.showSuccess('Region updated');
                this.savingRegion = false;
              },
              err => {
                this.notiService.notify(err);
                this.savingRegion = false;
              })
        );
      }
    }
  }

  onInitNewRow(e) {
    e.data.jobId = this.jobId;
  }
}
