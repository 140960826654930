import { MaintenanceService } from './maintenance.service';
import { JobService } from './job.service';
import { GlobalService } from './../global.service';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UserVendor } from '../../dtos/user-vendor';
import { HttpService } from '../http.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UserService } from './user.service';
import { Job } from '../../dtos/job';
import { VarianceCode } from '../../dtos/variance-code';
import { VarianceReason } from '../../dtos/variance-reason';
import { PriceFileItemVendorRate } from '../../dtos/price-file-item-vendor-rate';
import { formatDate } from 'devextreme/localization';
import { District } from '../../dtos/district';
import { OrderHeader } from '../../dtos/order-header';
import { PurchaseOrder } from '../../dtos/purchase-order';
import { Invoice } from '../../dtos/invoice';

@Injectable({
  providedIn: 'root'
})
export class ManualPurchaseOrdersService {
  varianceCodes: VarianceCode[];
  cachCompanyVarianceCodes: string;
  cachCompanyVarianceReasons: string;
  varianceReasons: VarianceReason[];
  priceFileItemVendorRates: PriceFileItemVendorRate[];
  priceFileItemsForVendor: PriceFileItemVendorRate[];
  cacheCompanyPriceFileItemVendorRates: string;
  districts: District[];
  cachCompanyDistricts: string;
  purchaseOrdersForJob: PurchaseOrder[];
  invoicesForThisJob: Invoice[];

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService,
    private jobService: JobService,
    private maintenanceService: MaintenanceService,
    private userService: UserService
  ) { }

  getUserVendors(): Observable<UserVendor[]> {
    return this._http.get<UserVendor[]>(this.globalService.getApiUrl() +
      '/uservendors', this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addUserVendor(vendorId: number) {
    const url = this.globalService.getApiUrl() + '/uservendors/?vendorId=' + vendorId;
    return this._http.post(url, '', this.httpService.getHttpOptions());
  }

  deleteUserVendor(vendorId: number) {
    const url = this.globalService.getApiUrl() + '/uservendors/?vendorId=' + vendorId;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }


  getExtraCodes(useCache: boolean): Observable<VarianceCode[]> {
    if (useCache && this.varianceCodes && this.varianceCodes.length
      && this.cachCompanyVarianceCodes === this.globalService.getCurrentCompanyId()) {
      return of(this.varianceCodes);
    } else {
      return this._http.get<VarianceCode[]>(this.globalService.getApiUrl() +
        '/variance-codes', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.varianceCodes = res; this.cachCompanyVarianceCodes = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  getExtraReasons(useCache: boolean): Observable<VarianceReason[]> {
    if (useCache && this.varianceReasons && this.varianceReasons.length
      && this.cachCompanyVarianceReasons === this.globalService.getCurrentCompanyId()) {
      return of(this.varianceReasons);
    } else {
      return this._http.get<VarianceReason[]>(this.globalService.getApiUrl() +
        '/variance-reasons', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.varianceReasons = res; this.cachCompanyVarianceReasons = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  getPurchaseOrderData(useCache: boolean): Observable<Job[]> {
    return forkJoin(
      [
        this.jobService.getJobsByAddress(useCache),
        this.getExtraCodes(useCache),
        this.getExtraReasons(useCache),
        this.userService.getVendors(useCache, true),
        this.maintenanceService.getPriceFileItemGroups(useCache),
        this.maintenanceService.getUnitOfMeasures(useCache)
      ]
    )
      .pipe(map(
        ([data]) => {
          return data;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getPriceFileItemsWithRates(useCache): Observable<PriceFileItemVendorRate[]> {
    return forkJoin(
      [
        this.getPriceFileItemVendorRates(useCache),
        this.getDistricts(useCache)]
    )
      .pipe(map(
        ([data]) => {
          return data;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getDistricts(useCache: boolean = true): Observable<District[]> {
    const url = this.globalService.getApiUrl() + '/districts';

    if (useCache && this.districts && this.districts.length && this.cachCompanyDistricts === this.globalService.getCurrentCompanyId()) {
      return of(this.districts);
    } else {
      return this._http.get<District[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.districts = res; this.cachCompanyDistricts = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getOrderHeader(jobId: number): Observable<OrderHeader> {
    return this._http.get<OrderHeader>(this.globalService.getApiUrl() +
      '/job/' + jobId + '/headers', this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getPriceFileItemVendorRates(useCache: boolean): Observable<PriceFileItemVendorRate[]> {
    if (useCache && this.priceFileItemVendorRates && this.priceFileItemVendorRates.length
      && this.cacheCompanyPriceFileItemVendorRates === this.globalService.getCurrentCompanyId()) {
      return of(this.priceFileItemVendorRates);
    } else {
      const url = this.globalService.getApiUrl() + '/price-file-item-vendor-rates';
      return this._http.get<PriceFileItemVendorRate[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.priceFileItemVendorRates = res; this.cacheCompanyPriceFileItemVendorRates = this.globalService.getCurrentCompanyId();

          this.priceFileItemVendorRates.forEach(item => {
            item.unitOfMeasure = this.maintenanceService.unitOfMeasures.find(i => i.id === item.unitOfMeasureId)?.description;

            const priceFileItem = this.maintenanceService.priceFileItemGroups.find(i => i.id === item.priceFileItemParentId);
            if (priceFileItem) {
              item.subGroupItemDesc =
                priceFileItem.priceFileCode ? priceFileItem.priceFileCode + ' - ' + priceFileItem.description : priceFileItem?.description;
              item.subGroupOrderNumber = priceFileItem.orderNumber;

              const costCentre = this.maintenanceService.priceFileItemGroups.find(i => i.id === priceFileItem.priceFileItemParentId);
              if (costCentre) {
                item.masterGroupCostCentre =
                  costCentre.priceFileCode ? costCentre.priceFileCode + ' - ' + costCentre.description : costCentre.description;
                item.groupOrderNumber = costCentre.orderNumber;

                // hide rate from tracking?
                if (costCentre.isHideCostsFromTracking) {
                  item.rate = null;
                }
              }
            }

            item.masterGroupCostCentre
              = 'I' + ('00000' + item.groupOrderNumber.toString()).slice(-6) + ';' + item.masterGroupCostCentre;
            item.subGroupItemDesc = ('00000' + item.subGroupOrderNumber.toString()).slice(-6) + ';' + item.subGroupItemDesc;
          });
        }),
        catchError(this.handleError));
    }
  }

  getItemsForDateAndDistrict(effectiveDate: Date, districtId: number): PriceFileItemVendorRate[] {
    let effectiveDateString = '';
    if (effectiveDate instanceof Date) {
      effectiveDateString = formatDate(effectiveDate, 'yyyy-MM-dd');
    } else {
      effectiveDateString = effectiveDate;
      effectiveDateString = effectiveDateString.substr(0, 10);
    }

    const district = this.districts.find(i => i.id === districtId);

    const priceFileItemsForDateAndDistrict: PriceFileItemVendorRate[] = [];

    this.priceFileItemsForVendor.forEach(item => {
      // we deep calc the rate for this recipe
      const itemWithRate = this.getItemWithRate(item.priceFileItemId, district, effectiveDateString);

      if (itemWithRate) {
        // check we don't have this already
        const existingRecord = priceFileItemsForDateAndDistrict.find(i => i.priceFileCode === itemWithRate.priceFileCode);

        if (!existingRecord) {
          priceFileItemsForDateAndDistrict.push(itemWithRate);
        }
      }
    });

    return priceFileItemsForDateAndDistrict;
  }

  getItemWithRate(priceFileItemId: number, district: District, effectiveDateString: string): PriceFileItemVendorRate {
    const itemRec = this.priceFileItemsForVendor.find(i => i.priceFileItemId === priceFileItemId
      && i.districtId === district.id
      && i.isActive
      && i.effectiveDate.toString().slice(0, 10) <= effectiveDateString);

    if (!itemRec && district.districtParentId) {
      // we go up to the district parent
      const parentDistrict = this.districts.find(i => i.id === district.districtParentId);
      return (this.getItemWithRate(priceFileItemId, parentDistrict, effectiveDateString));
    } else if (!itemRec || (itemRec.expiryDate && itemRec.expiryDate.toString().slice(0, 10) < effectiveDateString)) {
      return null;
    } else {
      return itemRec;
    }
  }

  createManualPurchaseOrder(data: any): Observable<PurchaseOrder> {
    const url = this.globalService.getApiUrl() + '/purchase-orders/generate-manual-po';
    return this._http.post<PurchaseOrder>(url, JSON.stringify(data, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }


  queuePurchaseOrders(jobId: number, purchaseOrderDto: any): Observable<any> {
    return this._http.post<any>(this.globalService.getApiUrl() +
      '/purchase-orders/' + jobId + '/queue-orders', JSON.stringify(purchaseOrderDto), this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }


  createZeroValuePurchaseOrder(jobId: number, costCentreId: number, vendorId: number, description: string,
    varianceCodeId: number, varianceReason: string, amount: number, constructionStageId: number,
    sendEmail: boolean = false, emailAddress: string = '', ccToSelf: boolean = false): Observable<PurchaseOrder> {
    let url = this.globalService.getApiUrl() + '/job/' + jobId
      + '/purchase-orders/generate-zero-po?vendorId=' + vendorId + '&costCentreId=' + costCentreId;

    if (constructionStageId) {
      url += '&constructionStageId=' + constructionStageId;
    }
    if (sendEmail) {
      url += '&sendEmail=' + sendEmail;
    }
    if (emailAddress) {
      url += '&emailAddress=' + emailAddress;
    }
    if (ccToSelf) {
      url += '&ccToSelf=' + ccToSelf;
    }

    return this._http.post<PurchaseOrder>(url,
      JSON.stringify({ description: description, varianceCodeId: varianceCodeId, varianceReason: varianceReason, amount: amount }),
      this.httpService.getHttpOptions()).pipe(catchError(this.handleError));
  }


  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return throwError(err);
  }

}
