import { LogService } from './../services/log.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserTypeEnum } from '../dtos/user-type.enum';
import { Company } from '../dtos/company';
import { User } from '../dtos/user';
import { AuthService } from '../services/auth.service';
import { GlobalService } from '../services/global.service';
import { UserService } from '../services/felixApi/user.service';
import { NotificationService } from '../services/notification.service';
import { CompanyService } from '../services/felixApi/company.service';
import { AuthApiService } from '../services/felixApi/auth-api.service';
import { environment } from '../../environments/environment';


@Component({
  selector: 'js-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  COMPONENT_NAME = 'welcome';
  userTypeEnum = UserTypeEnum;
  userCompanies: Company[];
  currComp: Company;
  currUser: User;
  currUserEmail: string;
  multipleCompanies = false;
  admin = false;
  loading = true;
  innerWidth: number;
  compForm: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  officeUser = false;
  currentVersion: string;
  companySelectWidth: number;
  selectedCompanyId: number;
  isProduction: boolean;

  constructor(
    private router: Router,
    private userService: UserService,
    private globalService: GlobalService,
    private notiService: NotificationService,
    private logService: LogService,
    private authService: AuthService,
    private authApi: AuthApiService,
    private compService: CompanyService) {

    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(() => {
        this.setWidths();
      })
    );
  }

  ngOnInit() {
    this.setWidths();
    this.innerWidth = this.globalService.innerWidth;
    this.globalService.setAreaSelected('');

    this.currentVersion = this.authService.currentVersion;

    this.getCompanies();

    this.currComp = this.globalService.getCurrentCompany();
    this.selectedCompanyId = this.currComp?.id;
    this.currUserEmail = this.authService.getUserEmail();
    this.currUser = this.authService.getCurrentUser();
    if ((this.currComp && this.currComp.userTypeId === this.userTypeEnum.Admin) || (this.currUser && this.currUser.isSuperUser)) {
      this.admin = true;
      this.officeUser = true;
    } else if (this.currComp && this.currComp.userTypeId === this.userTypeEnum.Office) {
      this.officeUser = true;
    }
  }

  setWidths() {
    this.innerWidth = this.globalService.innerWidth;
    if (this.innerWidth > 400) {
      this.companySelectWidth = 360;
    } else {
      this.companySelectWidth = this.innerWidth - 40;
    }

    this.isProduction = environment.production;
  }

  companySelected(e) {
    this.selectedCompanyId = e.value;
    if (this.selectedCompanyId) {
      this.currComp = this.userCompanies.find(i => i.id === this.selectedCompanyId);
      this.compSelected();
    }
  }


  getCompanies() {
    this.userCompanies = this.compService.getCurrentCompanies();
    if (this.userCompanies) {
      this.populateCompanies();
    } else {
      if (!this.authService.getUserEmail()) {
        this.signOut();
      } else {
        this.subscriptions.push(
          this.authApi.getAuthorisedCompanies().subscribe({
            next: (companies) => {
              this.userCompanies = companies;
              this.populateCompanies();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.currUser = null;
              setTimeout(() => {
                this.signOut();
              }, 3000);
            }
          })
        );
      }
    }
  }

  populateCompanies() {
    this.compService.setCurrentCompanies(this.userCompanies);
    this.loading = false;

    if (this.userCompanies.length === 1) {
      this.multipleCompanies = false;
      this.currComp = this.userCompanies[0];
      this.selectedCompanyId = this.currComp?.id;
      if (!this.authService.signedIn) {
        this.compSelected();
      }
    } else {
      this.multipleCompanies = true;
    }
  }

  compSelected() {
    this.loading = true;
    this.globalService.menuDisable.emit(true);
    this.globalService.setCurrentCompany(this.currComp);

    // get the user master - only have all user info once company is selected
    // only then do we fully sign in
    this.subscriptions.push(
      this.userService.getUser(this.currComp.userId).subscribe({
        next: (currUser) => {
          this.currUser = currUser;
          this.authService.setCurrentUser(this.currUser);
          this.authService.signIn();
          if (this.currComp.userTypeId === this.userTypeEnum.Admin) {
            this.admin = true;
          }

          this.globalService.menuDisable.emit(false);
          this.writeLoginLog();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.currUser = null;
          this.globalService.menuDisable.emit(false);
          setTimeout(() => {
            this.signOut();
          }, 3000);
        }
      })
    );
  }

  writeLoginLog() {
    this.subscriptions.push(
      this.logService.loginLog(this.authService.getUserEmail(), true, 'Company ' + this.currComp.id.toString()).subscribe({
        next: () => {
          this.getCompanyConfigurations();
        },
        error: (err) => {
          this.notiService.notify(err);
          // continue
          this.getCompanyConfigurations();
        }
      })
    );
  }

  getCompanyConfigurations() {
    this.subscriptions.push(
      this.compService.getCompanyConfigurations().subscribe({
        next: () => {
          this.setConfigs();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
          setTimeout(() => {
            this.signOut();
          }, 3000);
        }
      })
    );
  }

  setConfigs() {
    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Tracking') === 'Admin') {
      this.globalService.canSeeMaintenance = true;
    } else {
      this.globalService.canSeeMaintenance = false;
    }

    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('CallUps') !== 'none') {
      this.globalService.canSeeCallUps = true;
    } else {
      this.globalService.canSeeCallUps = false;
    }

    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Invoices') !== 'none'
      || this.authService.getSelectionsPermissions('InvoiceAuthorisation') !== 'none') {
      this.globalService.canSeeInvoices = true;
    } else {
      this.globalService.canSeeInvoices = false;
    }

    // manual purchase orders permission
    this.globalService.canSeeManualPurchaseOrders = false;
    if (this.authService.isAdminOrSuper()
      || this.authService.getSelectionsPermissions('PurchaseOrders') === 'Write'
      || this.authService.getSelectionsPermissions('PurchaseOrders') === 'Admin') {
      this.globalService.canSeeManualPurchaseOrders = true;
      this.setStartingArea();
    } else {
      // check if they have a limit
      this.subscriptions.push(
        this.userService.getMyUserOrderLimit()
          .subscribe({
            next: (orderLimit) => {
              if (orderLimit && (orderLimit.purchaseOrderLimit || orderLimit.costCentreLimit || orderLimit.jobLimit)) {
                this.globalService.canSeeManualPurchaseOrders = true;
              }
              this.setStartingArea();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false
            }
          })
      );
    }
  }

  compareComps(comp1: Company, comp2: Company) {
    if (!comp1 || !comp2) {
      return false;
    } else {
      return comp1.id === comp2.id;
    }
  }

  signOut() {
    this.loading = true;
    this.authService.signOut();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  launchAdminPortal() {
    window.open(environment.adminAppUrl);
  }

  launchTruthEngine() {
    window.open(environment.addendaAppUrl);
  }

  launchEstConfig() {
    window.open(environment.estConfigAppUrl);
  }

  launchCosting() {
    window.open(environment.ordersAppUrl);
  }

  launchConfig() {
    window.open(environment.configAppUrl);
  }

  setStartingArea() {
    let area = localStorage.getItem('truthEngineTrackingArea');

    // check if changing companies
    if (area === 'call-ups' && !this.globalService.canSeeCallUps) {
      area = '';
    }

    if (area === 'manual-purchase-orders' && !this.globalService.canSeeManualPurchaseOrders) {
      area = '';
    }

    if (area === 'jobSetUp' && !this.globalService.canSeeForecast) {
      area = '';
    }

    if (area === 'invoices-on-hold' && !this.globalService.canSeeInvoices) {
      area = '';
    }

    if (area === 'maintenance' && !this.globalService.canSeeForecast) {
      area = '';
    }

    if (area && area !== '') {
      this.globalService.setAreaSelected(area);
      this.router.navigateByUrl('/' + area);
    } else {
      this.globalService.setAreaSelected('tasks');
      this.router.navigateByUrl('/tasks');
    }
  }

  clearCache() {
    localStorage.clear();
    this.notiService.showInfo('Cache cleared. Logging out...');
    setTimeout(() => {
      this.signOut();
    }, 5000);
  }
}
