<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading && dataSource" id="dataGrid" class="m-auto" [height]="gridHeight" [dataSource]="dataSource"
    [allowColumnReordering]="false" [allowColumnResizing]="false" [showBorders]="true" [rowAlternationEnabled]="true"
    [hoverStateEnabled]="true" [wordWrapEnabled]="true" [selectedRowKeys]="selectedRowKeys"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')" (onInitNewRow)="onInitNewRow($event)"
    (onSelectionChanged)="onSelectionChanged($event)" (onExporting)="gridService.onExporting($event, 'Emails-Notes')"
    (onEditorPreparing)="onEditorPreparing($event)">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" [width]="200"></dxo-search-panel>
    <dxo-sorting mode='multiple'></dxo-sorting>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true">
      <dxo-search [enabled]="true"></dxo-search>
    </dxo-header-filter>
    <dxo-selection mode="single"></dxo-selection>

    <dxo-editing mode="popup" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="showDeleteButton"
      [useIcons]="true" refreshMode="repaint">
      <dxo-popup title="" [showTitle]="false" [width]="popupWidth" [height]="popupHeight">
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
          [options]="{ type: 'default', text: 'Save', onClick: saveClickHandler }">
        </dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
          [options]="{ type:'danger', text: 'Cancel', onClick: cancelClickHandler }">
        </dxi-toolbar-item>
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="subject" [colSpan]="4"></dxi-item>
          <dxi-item dataField="bodyAsText" editorType="dxTextArea" [colSpan]="4" [editorOptions]="{ height: 150 }">
          </dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxi-column dataField="date" dataType="date" [width]="110" format="d-MMM-yy" alignment="center"
      [allowEditing]="false" sortOrder="desc">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column *ngIf="loadAllJobs" dataField="jobId" caption="Job" dataType="number" [width]="90" sortOrder="desc"
      [allowEditing]="false">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="jobs" displayExpr="jobNumber" valueExpr="id"> </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="jobEmailTypeId" caption="Type" dataType="number" [width]="110" [hidingPriority]="4">
      <dxo-lookup [dataSource]="jobEmailTypes" displayExpr="description" valueExpr="id"> </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="subject" dataType="string" [width]="200" [hidingPriority]="5"> </dxi-column>

    <dxi-column dataField="bodyAsText" caption="Email/Note" dataType="string" [width]="bodyTextWidth"
      cellTemplate="fieldTextTemplate">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="fromEmail" dataType="string" [width]="170" [hidingPriority]="2">
    </dxi-column>

    <dxi-column dataField="toEmail" dataType="string" [width]="170" cellTemplate="fieldTextTemplate"
      [hidingPriority]="3">
    </dxi-column>

    <dxi-column dataField="createUserId" caption="Saved By" dataType="number" [width]="150" [allowEditing]="false"
      [hidingPriority]="1">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"> </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="hasAttachments" dataType="boolean" [width]="25" caption="" [allowEditing]="false"
      [calculateCellValue]="calculateHasAttachments" cellTemplate="attachmentCellTemplate" [hidingPriority]="6"
      [allowFiltering]="false" [allowHeaderFiltering]="true">
    </dxi-column>


    <!-- Templates -->
    <div *dxTemplate="let cellInfo of 'attachmentCellTemplate'">
      <div *ngIf="cellInfo.value">
        <i class="material-icons">attach_file</i>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate'">
      <span class="ms-1 me-1">Max Line Height</span>

      <dx-number-box id="setLineHeight" [width]="90" [(value)]='bodyTextMaxHeight' [showSpinButtons]="true" [step]="20"
        [min]="20">
      </dx-number-box>

      <span *ngIf="loadAllJobs">
        <span class="ms-1 me-1">Date from</span>
        <dx-date-box id="setLineHeight" [width]="130" [value]='dateFrom' (onValueChanged)="onDateFromChanged($event)">
        </dx-date-box>

        <span class="ms-1 me-1">Date to</span>
        <dx-date-box id="setLineHeight" [width]="130" [value]='dateTo' (onValueChanged)="onDateToChanged($event)">
        </dx-date-box>
      </span>
    </div>

    <div *dxTemplate="let cellInfo of 'fieldTextTemplate'">
      <div id="fieldText" class='mx-0 px-0' [style.maxHeight.px]="bodyTextMaxHeight">
        {{cellInfo.value}}</div>
    </div>

  </dx-data-grid>
</div>


<dx-popup class="popup" [width]="350" [height]="290" [showTitle]="true" [title]="'Load Email for Job ' + jobNumber" [dragEnabled]="true"
  [hideOnOutsideClick]="false" [(visible)]="loadEmailPopupVisible" [showCloseButton]="true">
  <div *dxTemplate="let data of 'content'">
    <div *ngIf="!fileCount">
      <div id="fileuploader-container-email">
        <dx-file-uploader selectButtonText="Select file(s)" labelText="or drop file(s) here" [multiple]="true"
          accept="*" [(value)]="fileValues" uploadMode="useForm" [allowedFileExtensions]="['.msg', '.eml']">
        </dx-file-uploader>
      </div>

      <dx-button *ngIf="fileValues.length" id="button" text="Load" type="success" (onClick)="uploadClick()"
        class="mt-2">
      </dx-button>
    </div>

    <div *ngIf="fileCount">emails remaining to load: {{fileCount}}</div>
  </div>
</dx-popup>
