<ng-container *ngIf="!loading">
  <ng-container *ngIf="childTasksLoaded && childTasks && childTasks.length > 0; else noRelatedTasks"> 

    <dx-data-grid *ngIf="!loading" #childJobsGrid id="childJobsGrid" [dataSource]="dataSource"
      [allowColumnReordering]="false" [allowColumnResizing]="false" [repaintChangesOnly]="true" [showBorders]="true"
      [rowAlternationEnabled]="true" [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)"
      (onRowPrepared)="onRowPrepared($event)" (onCellPrepared)="onCellPrepared($event)">

      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-selection [mode]="selectionMode" showCheckBoxesMode="always" [allowSelectAll]="true"> </dxo-selection>

      <dxo-editing mode="batch" refreshMode="full" [useIcons]="true" [allowDeleting]="false" [allowAdding]="false"
        [allowUpdating]="true">
      </dxo-editing>
      <dxo-filter-panel [visible]="false"></dxo-filter-panel>
      <dxo-filter-row [visible]="false"></dxo-filter-row>

      <dxi-column dataField="jobId" caption="Job" dataType="number" [allowEditing]="false" [width]="75">
        <dxo-lookup [dataSource]="jobs" displayExpr="jobNumber" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="jobAddress" caption="Address" [allowEditing]="false" [width]="vendorWidth"
        [calculateCellValue]="calculateJobAddress" [hidingPriority]="1">
      </dxi-column>

      <dxi-column dataField="poNumber" caption="Order" [allowEditing]="false" [width]="75" [hidingPriority]="4">
      </dxi-column>

      <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [width]="vendorWidth"
        [editorOptions]="{ showClearButton: true }">
        <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="calledDate" dataType="date" caption="Sent" [width]="65" alignment="center" format="d-MMM"
        [allowEditing]="false" [hidingPriority]="6">
      </dxi-column>

      <dxi-column dataField="startDate" caption="Delivery Start" dataType="date" [width]="75" alignment="center"
        format="d-MMM" [setCellValue]="setStartDateCellValue"
        [editorOptions]="{ showClearButton: true, acceptCustomValue: false, calendarOptions: { showTodayButton: true } }">
      </dxi-column>

      <dxi-column dataField="dueDate" caption="Due" dataType="date" [width]="65" alignment="center" format="d-MMM"
        [hidingPriority]="2"
        [editorOptions]="{ showClearButton: true, acceptCustomValue: false, calendarOptions: { showTodayButton: true } }">
      </dxi-column>

      <dxi-column dataField="endDate" caption="Done" dataType="date" [width]="65" alignment="center" format="d-MMM"
        [hidingPriority]="3"
        [editorOptions]="{ showClearButton: true, acceptCustomValue: false, calendarOptions: { showTodayButton: true } }">
      </dxi-column>

      <dxi-column dataField="officeComment" dataType="string" caption="Office Comment" [hidingPriority]="5"
        [width]="vendorWidth" cellTemplate="commentCellTemplate">
      </dxi-column>

      <dxi-column dataField="vendorComment" dataType="string" caption="Vendor Comment" [hidingPriority]="7"
        [width]="vendorWidth" cellTemplate="commentCellTemplate">
      </dxi-column>


      <!-- Templates -->
      <div *dxTemplate="let cellInfo of 'commentCellTemplate'">
        <div id="vendorComment" class='mx-0 px-0'>{{cellInfo.value}}</div>
      </div>

    </dx-data-grid>
  </ng-container>

  <ng-template #noRelatedTasks>
    <div>
      No related tasks
    </div>
  </ng-template>
</ng-container>