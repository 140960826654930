import { Injectable } from '@angular/core';
import { UtilsService } from '../services/utils.service';
import { ReportGridItem } from './report-grid-item.type';

@Injectable({
  providedIn: 'root'
})
export class ReportGridService {

  gridColumns: ReportGridItem[] = [];
  extraColumns: ReportGridItem[] = [];
  gridColumnsMap: Map<string, ReportGridItem> = new Map<string, ReportGridItem>();


  constructor(private utils: UtilsService) { }

  clear() {
    this.gridColumns = [];
    this.extraColumns = [];
    this.gridColumnsMap.clear();

  }

  setGridColumns(columns: ReportGridItem[]) {
    this.gridColumns = columns;
    this.gridColumnsMap.clear();
    columns.forEach(col => this.gridColumnsMap.set(col.dataField, col));
  }

  sortExtraCols() {
    this.extraColumns = this.extraColumns.sort(this.utils.dynamicSort('caption'));
  }

  setGridColumnsMap() {
    this.gridColumnsMap.clear();
    this.gridColumns.forEach(col => this.gridColumnsMap.set(col.dataField, col));
  }

}
