import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TemplateTaskHeader } from '../../dtos/templateTaskHeader';
import { JobWorkFlow } from '../../dtos/job-work-flow';
import { GlobalService } from '../global.service';
import { UserService } from './user.service';
import { MaintenanceService } from './maintenance.service';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class JobWorkFlowService {
  allJobWorkflows: JobWorkFlow[] = [];
  allJobWorkflowsCompany: number;
  currentJobWorkflows: JobWorkFlow[];

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private maintenanceService: MaintenanceService,
    private userService: UserService,
    private globalService: GlobalService
  ) { }

  // getJobWorkFlows(jobId: number): Observable<JobWorkFlow[]> {
  //   let url = this.globalService.getApiUrl() + '/job-work-flows/' + jobId;

  //   return this._http.get<JobWorkFlow[]>(url, this.httpService.getHttpOptions()).pipe(
  //     catchError(this.handleError));
  // }

  jobWorkFlowsByJobAndTypeMap: Map<string, JobWorkFlow> = new Map<string, JobWorkFlow>();

  getAllJobWorkFlows(useCache: boolean, includeInactiveJobs: boolean): Observable<JobWorkFlow[]> {
    if (useCache
      && this.allJobWorkflowsCompany === this.globalService.getCurrentCompany().id
      && this.allJobWorkflows && this.allJobWorkflows.length) {
      return of(this.allJobWorkflows);
    } else {
      let url = this.globalService.getApiUrl() + '/job-work-flows';
      if (includeInactiveJobs) {
        url += '?includeInactiveJobs=true';
      }
      return this._http.get<JobWorkFlow[]>(this.globalService.getApiUrl() +
        '/job-work-flows', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.allJobWorkflows = res;
            this.allJobWorkflowsCompany = this.globalService.getCurrentCompany().id;

            this.jobWorkFlowsByJobAndTypeMap.clear();
            res.forEach(jwf => {
              const key = `${jwf.jobId}-${jwf.trackingWorkFlowTypeId}`;
              if (!this.jobWorkFlowsByJobAndTypeMap.has(key)) {
                this.jobWorkFlowsByJobAndTypeMap.set(key, jwf);
              }
            });
          }),
          catchError(this.handleError));
    }
  }

  getJobWorkFlows(jobId: number): Observable<JobWorkFlow[]> {
    return this._http.get<JobWorkFlow[]>(this.globalService.getApiUrl() +
      '/job/' + jobId + '/work-flows', this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.currentJobWorkflows = res;
        }),
        catchError(this.handleError));
  }

  addJobWorkFlow(dataRecord: any): Observable<JobWorkFlow[]> {
    const url = this.globalService.getApiUrl() + '/job-work-flows';
    return this._http.post<JobWorkFlow[]>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateJobWorkFlow(id: string, itm: any): Observable<JobWorkFlow[]> {
    const dataToPass = JSON.stringify(itm);
    const url = this.globalService.getApiUrl() + '/job-work-flows/' + id;
    return this._http.patch<JobWorkFlow[]>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteJobWorkFlow(id: string) {
    const url = this.globalService.getApiUrl() + '/job-work-flows/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  moveJobWorkFlow(jobId: number, id: string, orderNumber: any): Observable<JobWorkFlow> {
    const url = this.globalService.getApiUrl() + '/job-work-flows/' + jobId + '/move/' + id + '?orderNumber=' + orderNumber;
    return this._http.patch<JobWorkFlow>(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }


  getJobWorkFlowData(): Observable<TemplateTaskHeader[]> {
    return forkJoin(
      [
        this.maintenanceService.getTemplateTaskHeaders(true, true),
        this.maintenanceService.getTemplateDaysHeaders(),
        this.maintenanceService.getTradeRegions(),
        this.userService.getCurrCompUsers(true)
      ]
    )
      .pipe(map(
        ([templateTaskHeaders]) => {
          return templateTaskHeaders;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  getJobWorkFlowDataForCallUps(jobId: number): Observable<JobWorkFlow[]> {
    return forkJoin(
      [
        this.getJobWorkFlows(jobId),
        this.maintenanceService.getPurchaseOrders(jobId)
        // this.jobService.getJobDocuments(jobId)
      ]
    )
      .pipe(map(
        ([data]) => {
          return data;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
