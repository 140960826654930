import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { GlobalService } from './global.service';
import { AuthService } from './auth.service';
import { TruthEngineSession } from '../dtos/session';
import { NotificationService } from './notification.service';
import { UserService } from './felixApi/user.service';
import { CompanyService } from './felixApi/company.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthApiService } from './felixApi/auth-api.service';
import { LogService } from './log.service';


@Injectable()
export class RefreshGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private authApi: AuthApiService,
    private globalService: GlobalService,
    private compService: CompanyService,
    private notiService: NotificationService,
    private deviceService: DeviceDetectorService,
    private userService: UserService,
    private logger: LogService) { }

  canActivate() {
    this.authService.currentVersion = environment.fullVersion;

    // check current version is correct
    this.checkCorrectVersion();

    if (this.authService.getUserEmail()) {
      return true;
    } else {
      const session = this.globalService.getSessionObject();
      const email = this.authService.getMsalUserEmail();
      if (session.currentCompanyId && email) {
        return this.restoreFromCache(session, email);
      } else {
        this.router.navigate(['']);
      }
    }
  }

  checkCorrectVersion() {
    this.authApi.getCurrentApplicationVersion().subscribe({
      next: (appVersion) => {
        if (!appVersion || appVersion.version.trim() !== environment.majorVersion) {
          this.authService.versionIncorrect = true;
          this.authService.correctVersion = appVersion.version.trim();
        } else {
          this.authService.versionIncorrect = false;
        }
      },
      error: (err) => {
        this.logger.log('refresh-guard', '', err, 'couldn\'t get app version', true);
      }
    });
  }

  restoreFromCache(session: TruthEngineSession, email: string): Observable<boolean> {
    this.authService.setUserEmail(email);
    return this.authApi.getAuthorisedCompanies()
      .pipe(mergeMap(
        comps => {
          const company = comps.filter(x => x.id === session.currentCompanyId);
          if (company.length !== 1) {
            return of(this.error());
          }
          this.globalService.setCurrentCompany(company[0]);
          return forkJoin(
            [this.userService.getUserCompany(company[0].userId),
            this.compService.getCompanyConfigurations()]
          )
            .pipe(map(
              ([user]) => {
                this.authService.setCurrentUser(user);
                this.globalService.setCurrentCompany(company[0]);
                this.authService.signIn();
                this.globalService.setAreaSelected(session.areaSelected);
                this.globalService.setInnerHeightWidth();

                // reread the configs
                this.setConfigs();
                return true;
              }, (err) => {
                return this.error();
              }
            ));
        }
      ));
  }

  setConfigs() {
    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Tracking') === 'Admin') {
      this.globalService.canSeeMaintenance = true;
    } else {
      this.globalService.canSeeMaintenance = false;
    }

    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('CallUps') !== 'none') {
      this.globalService.canSeeCallUps = true;
    } else {
      this.globalService.canSeeCallUps = false;
    }

    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Invoices') !== 'none'
      || this.authService.getSelectionsPermissions('InvoiceAuthorisation') !== 'none') {
      this.globalService.canSeeInvoices = true;
    } else {
      this.globalService.canSeeInvoices = false;
    }

    if (this.deviceService.isTablet() || this.deviceService.isMobile()) {
      this.globalService.isTablet = true;
      this.globalService.browser = this.deviceService.browser;
    } else {
      this.globalService.isTablet = false;
    }

    // manual purchase orders permission
    this.globalService.canSeeManualPurchaseOrders = false;
    if (this.authService.isAdminOrSuper()
      || this.authService.getSelectionsPermissions('PurchaseOrders') === 'Write'
      || this.authService.getSelectionsPermissions('PurchaseOrders') === 'Admin') {
      this.globalService.canSeeManualPurchaseOrders = true;
    } else {
      // check if they have a limit
      this.userService.getMyUserOrderLimit()
        .subscribe({
          next: (orderLimit) => {
            if (orderLimit && (orderLimit.purchaseOrderLimit || orderLimit.costCentreLimit || orderLimit.jobLimit)) {
              this.globalService.canSeeManualPurchaseOrders = true;
            }
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        });
    }
  }

  error() {
    this.router.navigate(['']);
    this.notiService.showError('There was an error fetching data from the session so you will have to login again');
    return false;
  }
}
