import { NotificationService } from './notification.service';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Company } from '../dtos/company';
import { CompanyConfiguration } from '../dtos/company-configuration';
import { TruthEngineSession, SessionName } from '../dtos/session';
import { Job } from '../dtos/job';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DESKTOP_SIZE, PHONE_SIZE, SML_PHONE_SIZE } from '../../config/variables';
import { AttachmentTypeEnum } from '../dtos/attachment-type.enum';
import { saveAs } from 'file-saver';
import { throwError as observableThrowError } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class GlobalService {

  public MAX_OPTION_IMAGE_SIZE_MB = 0.2; // Uploaded imgs compressed to this size

  private currentCompany: Company;
  public currentUserId: number;

  private areaSelected = '';
  areaChanged = new EventEmitter<string>();

  menuDisable = new EventEmitter<boolean>();

  innerWidth: number;
  innerHeight: number;
  phoneSize = false;
  smlPhoneSize = false;
  desktopSize = false;
  innerHeightWidthChanged = new EventEmitter();

  companySelected = new EventEmitter<Company>();

  refreshErrorEvent = new EventEmitter<boolean>();

  public companyConfiguration: CompanyConfiguration[];
  refreshErrorMessage: string;
  isTablet: boolean;
  browser: string;
  canSeeMaintenance: boolean;
  canSeeCallUps: boolean;
  isHaveInvoicesToCheck: boolean;
  canSeeInvoices: boolean;
  canSeeForecast: boolean;
  canSeeManualPurchaseOrders = false;

  constructor(private deviceService: DeviceDetectorService,
    private notiService: NotificationService) { }

  isHttps() {
    return window.location.protocol.startsWith('https') || window.location.hostname === 'localhost';
  }

  getApiUrl(): string {
    return environment.apiUrl;
  }

  getAppID() {
    return environment.appId;
  }

  setInnerHeightWidth() {
    if (this.deviceService.isTablet() || this.deviceService.isMobile()) {
      this.isTablet = true;
      this.browser = this.deviceService.browser;
    } else {
      this.isTablet = false;
    }

    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;

    if (innerWidth <= PHONE_SIZE) {
      this.phoneSize = true;
    } else {
      this.phoneSize = false;
    }
    if (innerWidth <= SML_PHONE_SIZE) {
      this.smlPhoneSize = true;
    } else {
      this.smlPhoneSize = false;
    }
    if (innerWidth >= DESKTOP_SIZE) {
      this.desktopSize = true;
    } else {
      this.desktopSize = false;
    }

    this.innerHeightWidthChanged.emit();
  }

  setAreaSelected(area) {
    this.areaSelected = area;
    this.setSessionAtt('areaSelected', area);
    if (area && area !== '') {
      localStorage.setItem('truthEngineTrackingArea', area);
    }
    this.areaChanged.emit(this.areaSelected);
  }

  setCurrentCompany(company: Company) {
    this.currentCompany = company;
    this.currentUserId = company.userId;
    this.setSessionAtt('currentCompanyId', company.id);
    this.companySelected.emit(this.currentCompany);
  }

  getCurrentCompany(): Company {
    return this.currentCompany;
  }

  getAddendumName(): string {
    return this.currentCompany && this.currentCompany.addendumName && this.currentCompany.addendumName !== ''
      ? this.currentCompany.addendumName : 'Addendum';
  }

  getAddendumLetter(): string {
    return this.currentCompany && this.currentCompany.addendumName && this.currentCompany.addendumName !== ''
      ? this.currentCompany.addendumName[0] : 'A';
  }

  clearCurrentCompany() {
    this.currentCompany = null;
  }

  refreshError(refreshErrorMessage: string) {
    this.refreshErrorMessage = refreshErrorMessage;
    this.refreshErrorEvent.emit(true);
  }

  isCompSet() {
    if (this.currentCompany) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentCompanyId(): string {
    if (this.currentCompany !== undefined && this.currentCompany.id !== undefined && this.currentCompany.id !== null) {
      return this.currentCompany.id.toString();
    }
    return '';
  }

  isSalesVariationsActive() {
    return this.currentCompany.salesVariationsActive;
  }

  getSessionObject(): TruthEngineSession {
    if (sessionStorage.getItem(SessionName)) {
      return JSON.parse(sessionStorage.getItem(SessionName));
    } else {
      const session = new TruthEngineSession();
      sessionStorage.setItem(SessionName, JSON.stringify(session));
      return JSON.parse(sessionStorage.getItem(SessionName));
    }
  }

  setSessionAtt(att: string, obj: any) {
    const session = this.getSessionObject();
    session[att] = obj;
    const sessionString = JSON.stringify(session);
    sessionStorage.setItem(SessionName, sessionString);
  }

  getCompanyConfigValue(id: number): number {
    let returnVal = null;
    if (this.companyConfiguration && this.companyConfiguration.length) {
      this.companyConfiguration.forEach(element => {
        if (element.configurationId === id) {
          returnVal = element.configurationValue;
        }
      });
    }
    return returnVal;
  }

  sortBy(name, minor, isAscending = true) {
    const reverseMutliplier = isAscending ? 1 : -1;
    return function (o, p) {
      let a, b;
      let result;
      a = o[name];
      b = p[name];
      if (a === b) {
        return typeof minor === 'function' ? minor(o, p) : 0;
      }
      if (typeof a === typeof b) {
        result = a < b ? -1 : 1;
      } else {
        result = typeof a < typeof b ? -1 : 1;
      }
      return result * reverseMutliplier;
    };
  }

  getJobString(job: Job): string {
    let jobstring = '';

    if (job && job.jobAddress) {
      if (job.jobAddress.lotNumber && job.jobAddress.lotNumber.trim() !== '') {
        jobstring = jobstring.concat('Lot ' + job.jobAddress.lotNumber.trim());
      }
      if (job.jobAddress.streetNumber && job.jobAddress.streetNumber.trim() !== '') {
        jobstring = jobstring.concat(' #' + job.jobAddress.streetNumber.trim());
      }
      if (job.jobAddress.streetName1 && job.jobAddress.streetName1.trim() !== '') {
        jobstring = jobstring.concat(' ' + job.jobAddress.streetName1.trim());
      }
      if (job.jobAddress.streetName2 && job.jobAddress.streetName2.trim() !== '') {
        jobstring = jobstring.concat(' ' + job.jobAddress.streetName2.trim());
      }
      if (job.jobAddress.suburbTown && job.jobAddress.suburbTown.trim() !== '') {
        jobstring = jobstring.concat(' ' + job.jobAddress.suburbTown.trim());
      }
    }
    return jobstring;
  }

  getClientAddress(job: Job): string {
    let jobstring = '';

    if (job && job.contractAddress) {
      if (job.contractAddress.lotNumber && job.contractAddress.lotNumber.trim() !== '') {
        jobstring = jobstring.concat('Lot ' + job.contractAddress.lotNumber.trim());
      }
      if (job.contractAddress.streetNumber && job.contractAddress.streetNumber.trim() !== '') {
        jobstring = jobstring.concat(' #' + job.contractAddress.streetNumber.trim());
      }
      if (job.contractAddress.streetName1 && job.contractAddress.streetName1.trim() !== '') {
        jobstring = jobstring.concat(' ' + job.contractAddress.streetName1.trim());
      }
      if (job.contractAddress.streetName2 && job.contractAddress.streetName2.trim() !== '') {
        jobstring = jobstring.concat(' ' + job.contractAddress.streetName2.trim());
      }
      if (job.contractAddress.suburbTown && job.contractAddress.suburbTown.trim() !== '') {
        jobstring = jobstring.concat(' ' + job.contractAddress.suburbTown.trim());
      }
    }
    return jobstring;
  }

  getDateString(dateToConvert: string): string {
    const monthNumber = +dateToConvert.substr(5, 2) - 1;
    const dayNumber = +dateToConvert.substr(8, 2);

    const months = ['Jan', 'Feb', 'March', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    const monthString = months[monthNumber];

    return dayNumber.toString() + '-' + monthString;
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  getBlobFromBase64(base64: any, attachmentTypeId: number, fileName: string) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    if (attachmentTypeId === AttachmentTypeEnum.XLS
      || attachmentTypeId === AttachmentTypeEnum.XLSM
      || attachmentTypeId === AttachmentTypeEnum.XLSX) {
      this.saveFile(new Blob([bytes], { type: 'application/vnd.ms-excel' }), fileName);
    } else if (attachmentTypeId === AttachmentTypeEnum.PDF) {
      this.saveFile(new Blob([bytes], { type: 'application/pdf' }), fileName);
    } else if (attachmentTypeId === AttachmentTypeEnum.DOCX) {
      this.saveFile(new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }), fileName);
    } else if (attachmentTypeId === AttachmentTypeEnum.MSG) {
      this.saveFile(new Blob([bytes], { type: 'application/vnd.ms-outlook' }), fileName);
    } else if (attachmentTypeId === AttachmentTypeEnum.EML) {
      this.saveFile(new Blob([bytes], { type: 'message/rfc822' }), fileName);
    } else {
      this.saveFile(new Blob([bytes], { type: 'image/svg+xml' }), fileName);
    }
  }

  convertAndSave(file: any, jobNumber) {
    const binary_string = window.atob(file);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: 'application/pdf' });

    // Check if the user is on a Mac
    const isMac = navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;

    // If the user is on a Mac, convert the file to a data URL before saving
    if (isMac) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrl = reader.result as string;
        const base64 = dataUrl.split(',')[1];
        const convertedBlob = this.base64ToArrayBuffer(base64, 'POs for Job ' + jobNumber);
        this.saveFile(convertedBlob, 'POs for Job ' + jobNumber);
      };
      reader.readAsDataURL(blob);
    } else {
      this.saveFile(blob, 'POs for Job ' + jobNumber);
    }
  }

  returnError(err: any): string {
    console.log(JSON.stringify(err));
    if (err && err.error && err.error.message) {
      return JSON.stringify(err.error.message);
    } else if (err && err.error && err.error.detail) {
      return JSON.stringify(err.error.detail);
    } else if (err && err.error && err.error.Description && err.error.Description.length) {
      return JSON.stringify(err.error.Description[0]);
    } else {
      return JSON.stringify(err);
    }
  }

  saveFile(blob: any, fileName: string) {
    if (this.isTablet) {
      this.notiService.showInfo('Ensure pop-ups are not blocked: see settings', null, { timeOut: 5000 });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    } else {
      saveAs(blob, fileName);
    }
  }

  getStorageItem(storageItemName): boolean {
    if (localStorage.getItem(storageItemName)) {
      const value = localStorage.getItem(storageItemName);
      if (value && value === 'true') {
        return true;
      }
    }
    return false;
  }

  setStorageItem(storageItemName: string, value: boolean) {
    if (value) {
      localStorage.setItem(storageItemName, 'true');
    } else {
      localStorage.setItem(storageItemName, 'false');
    }
  }

  base64ToArrayBuffer(base64, saveName: string): Blob {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    if (saveName.endsWith('.pdf') || !saveName.includes('.')) {
      return new Blob([bytes], { type: 'application/pdf' });
    } else if (saveName.toLowerCase().endsWith('.jpg')) {
      return new Blob([bytes], { type: 'image/jpg' });
    } else if (saveName.toLowerCase().endsWith('.png')) {
      return new Blob([bytes], { type: 'image/png' });
    } else if (saveName.toLowerCase().endsWith('.doc') || saveName.toLowerCase().endsWith('.docx')) {
      return new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    } else if (saveName.toLowerCase().endsWith('.xls') || saveName.toLowerCase().endsWith('.xlsx') || saveName.toLowerCase().endsWith('.xlsm')) {
      return new Blob([bytes], { type: 'application/vnd.ms-excel' });
    } else {
      return new Blob([bytes], { type: 'application/octet-stream' });
    }
  }

  jsonReplacer(key, value) {
    if (this[key] instanceof Date) {
      return this[key].toDateString();
    }
    return value;
  }

  handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
