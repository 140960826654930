import { SecondaryPurchaseOrdersComponent } from './secondary-purchase-orders/secondary-purchase-orders.component';
import { UtilsService } from './../services/utils.service';
import { TradeVendor } from './../dtos/trade-vendor';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { environment } from '../../environments/environment';
import { NotificationService } from '../services/notification.service';
import { GlobalService } from '../services/global.service';
import { GridService } from '../services/grid.service';
import { JobService } from '../services/felixApi/job.service';
import { JobWorkFlowService } from '../services/felixApi/job-work-flow.service';
import { TemplateTaskHeader } from '../dtos/templateTaskHeader';
import { TrackingWorkFlowTypeEnum } from '../dtos/tracking-work-flow-type.enum';
import { MaintenanceService } from '../services/felixApi/maintenance.service';
import { TaskService } from '../services/felixApi/task.service';
import { TaskMaster } from '../dtos/task-master';
import { Task } from '../dtos/task';
import { UserService } from '../services/felixApi/user.service';
import { User } from '../dtos/user';
import { TaskStatusEnum } from '../dtos/task-status.enum';
import { formatDate } from 'devextreme/localization';
import { TaskType } from '../dtos/task-type';
import { JobWorkFlow } from '../dtos/job-work-flow';
import { UserTypeEnum } from '../dtos/user-type.enum';
import { Vendor } from './../dtos/vendor';
import { PurchaseOrder } from '../dtos/purchase-order';
import { PriceFileItem } from '../dtos/price-file-item';
import { AuthService } from '../services/auth.service';
import { VendorPayable } from '../dtos/vendor-payable';
import { JobRolesComponent } from '../shared/job-roles/job-roles.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendCallUpsComponent } from './send-call-ups/send-call-ups.component';
import { AcceptCallUpComponent } from './accept-call-up/accept-call-up.component';
import { MasterChildCallUpsComponent } from './master-child-call-ups/master-child-call-ups.component';
import { ResetJobTaskComponent } from './reset-job-task/reset-job-task.component';
import { TaskDocumentsComponent } from '../shared/task-documents/task-documents.component';
import { SendCancellationComponent } from './send-cancellation/send-cancellation.component';
import DateBox, { Properties } from "devextreme/ui/date_box";
import { AddMultipleTasksComponent } from './add-multiple-tasks/add-multiple-tasks.component';

@Component({
  selector: 'js-call-ups',
  templateUrl: './call-ups.component.html',
  styleUrls: ['./call-ups.component.scss']
})
export class CallUpsComponent implements OnInit, OnDestroy {

  @ViewChild('callUpGrid') grid: DxDataGridComponent;

  loading = true;
  subscriptions: Subscription[] = [];
  claimMasters: CustomStore;
  jobId: number;
  jobNumber = '';
  taskHeaders: TemplateTaskHeader[];
  taskHeadersForJob: TemplateTaskHeader[] = [];
  selectedTaskHeaderId: number;
  taskMasters: TaskMaster[];
  templateTaskMasters: TaskMaster[];
  taskTypes: TaskType[];
  tasks: Task[];
  users: User[];
  vendors: Vendor[];
  generateTasksOnGet: boolean;
  taskHeadersLoaded: boolean;
  gridHeight: number;
  generatePopupVisible = false;
  showCompleted = true;
  allJobTasks: Task[];
  shownTasks: Task[];
  addRemoveVendorsPopupVisible: boolean;
  tradeRegionDescription: string;
  tradeVendorDataSource: CustomStore;
  currentTradeId: number;
  tradeDescription: string;
  filteredVendors: Vendor[];
  currentStartDate: string;
  deletePopupVisible = false;
  recordToDelete: Task;
  deleteInProgress: boolean;
  attachmentsPopupVisible = false;
  loadingDocs: boolean;
  currentJobTaskId: number;
  currentJobTask: Task;
  dropDownOptions: object;
  templateTaskMastersData: CustomStore;
  loadingCallUps: boolean;
  windowWidth: number;
  selectedRows: number[] = [];
  ccToSelf = true;
  loadingVisible: boolean;
  emailString: string;
  isTablet: boolean;
  popupHeight: number;
  popupWidth: number;
  dataSource: DataSource;
  currentVendorId: number;
  currentRowKey: number;
  editMode = 'popup';
  inPopupMode = true;
  jobWorkFlows: JobWorkFlow[];
  selectedTemplateDaysHeaderId: number;
  collapsedGroups: string[] = [];
  collapsingExpanding: boolean;
  reorderingAllowed: boolean;
  vendorDescMinWidth: number;
  editPopupWidth: number;
  editPopupHeight: number;
  vendorPopupWidth: number;
  vendorPopupHeight: number;
  vendorPopupGridHeight: number;
  anyMissingAttachments: boolean;
  dataSourceSetup: boolean;
  purchaseOrders: PurchaseOrder[];
  purchaseOrdersFiltered: PurchaseOrder[];
  poIds: number[];
  orderbuttonOptions: object;
  currentPurchaseOrderId: number;
  costCentres: PriceFileItem[];
  firstNotCompletedTaskId: number;
  currentTaskTypeId: number;
  vendorPayables: VendorPayable[];
  newOrderNumber: any;
  newTaskTypeId: any;
  addendumCaption: string;
  addendumLetter: string;
  taskTitleWidth: number;
  allowEditing: boolean;
  manualDaysDefault: number;
  focusedRowEnabled = true;
  autoNavigateToFocusedRow = true;
  currentTaskMaster: TaskMaster;
  dropDownOptionsPOs: { width: number; minHeight: number; };
  isMasterOrSubJob = false;
  refreshChildJobs = false;
  dropDownOptionsVendors: { width: number; minHeight: number; };
  vendorGridHeight: number;
  vendorEmailString: string;
  dueDateOnRight = true;
  showNotApplicableItems = true;
  cancelCallUpPopupVisible = false;
  selectionMode = 'none';
  selectedRowKeys: any[] = [];
  markTasksNAPopupVisible: boolean;
  updateOtherChildJobs: boolean;
  whenAddingShowAllTaskMasters = false;
  refreshingTaskMasterData = false;
  inMultiDeleteMode: boolean;
  inExtendStartDatesMode: boolean;
  extendStartsBy = 1; // default to 1 day
  extendTasksPopupVisible: boolean;
  extendTypes = ['Days', 'Weeks'];
  extendTypeSelected = 'Days';
  maxCommentHeight: number;
  settingSiteStart: boolean;

  constructor(
    private notiService: NotificationService,
    private globalService: GlobalService,
    private auth: AuthService,
    protected jobWorkFlowService: JobWorkFlowService,
    private taskService: TaskService,
    protected gridService: GridService,
    private jobService: JobService,
    private maintenanceService: MaintenanceService,
    private userService: UserService,
    private modalService: NgbModal,
    private utilService: UtilsService
  ) {
    this.onReorder = this.onReorder.bind(this);
    this.setTaskMasterIdCellValue = this.setTaskMasterIdCellValue.bind(this);
    this.setStartDateCellValue = this.setStartDateCellValue.bind(this);
    this.setManualDaysCellValue = this.setManualDaysCellValue.bind(this);
    this.setEndDateCellValue = this.setEndDateCellValue.bind(this);
    this.setDueDateCellValue = this.setDueDateCellValue.bind(this);
    this.calculateLoadSortValue = this.calculateLoadSortValue.bind(this);
    this.deleteTaskPopup = this.deleteTaskPopup.bind(this);
    this.deleteTaskPopupClose = this.deleteTaskPopupClose.bind(this);
    this.addTaskPopup = this.addTaskPopup.bind(this);
    this.deleteTasksGo = this.deleteTasksGo.bind(this);
    this.cancelClickHandler = this.cancelClickHandler.bind(this);
    this.saveClickHandler = this.saveClickHandler.bind(this);
    this.addRemoveVendors = this.addRemoveVendors.bind(this);
    this.getVendorsForTradeRegion = this.getVendorsForTradeRegion.bind(this);
    this.attachDocs = this.attachDocs.bind(this);
    this.attachExtraPOs = this.attachExtraPOs.bind(this);
    this.acceptCallUp = this.acceptCallUp.bind(this);
    this.setTradeVendorCellValue = this.setTradeVendorCellValue.bind(this);
    this.changEditMode = this.changEditMode.bind(this);
    this.onRowCollapsed = this.onRowCollapsed.bind(this);
    this.onRowExpanded = this.onRowExpanded.bind(this);
    this.onContentReady = this.onContentReady.bind(this);
    this.setIsCompletedCellValue = this.setIsCompletedCellValue.bind(this);
    this.onCellPrepared = this.onCellPrepared.bind(this);
    this.calculateCallUpDocsType = this.calculateCallUpDocsType.bind(this);
    this.downloadOrder = this.downloadOrder.bind(this);
    this.setPurchaseOrderIdCellValue = this.setPurchaseOrderIdCellValue.bind(this);
    this.onPODropdownOpened = this.onPODropdownOpened.bind(this);
    this.onVendorDropdownOpened = this.onVendorDropdownOpened.bind(this);
    this.setVendorIdCellValue = this.setVendorIdCellValue.bind(this);
    this.getFilteredVendors = this.getFilteredVendors.bind(this);
    this.onInitNewRow = this.onInitNewRow.bind(this);
    this.onInitNewTradeVendor = this.onInitNewTradeVendor.bind(this);
    this.calculateStartDateValue = this.calculateStartDateValue.bind(this);
    this.calculateCostCentreSortValue = this.calculateCostCentreSortValue.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.completeTask = this.completeTask.bind(this);
    this.isCompleteIconVisible = this.isCompleteIconVisible.bind(this);
    this.openPO = this.openPO.bind(this);
    this.calculateEmailAddress = this.calculateEmailAddress.bind(this);
    this.onVendorListToolbarPreparing = this.onVendorListToolbarPreparing.bind(this);
    this.getVendorEmailString = this.getVendorEmailString.bind(this);
    this.onVendorSelectionChanged = this.onVendorSelectionChanged.bind(this);
    this.upSertJobTasks = this.upSertJobTasks.bind(this);
    this.addMultipleTasks = this.addMultipleTasks.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.onEditingStart = this.onEditingStart.bind(this);

    DateBox.defaultOptions<Properties>({
      device: [
        { deviceType: 'desktop' },
        { deviceType: 'tablet' },
        { deviceType: 'phone' }
      ],
      options: {
        pickerType: 'calendar'
      }
    });

  }

  ngOnInit(): void {
    this.setJobStringMaxWidth();
    this.subscribeToInnerHeightWidth();
    this.setStartingFlags();

    this.isTablet = this.globalService.isTablet;
    this.addendumCaption = 'Send ' + this.globalService.getAddendumName();
    this.addendumLetter = this.globalService.getAddendumLetter();

    this.orderbuttonOptions = { icon: 'download', onClick: this.downloadOrder.bind(this) };
    this.getData(false);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setStartingFlags() {
    const area = localStorage.getItem('callUpsShowCompleted');
    if (area) {
      if (area === 'false') {
        this.showCompleted = false;
      }
    }

    const stateValue = localStorage.getItem('call-up-duedateonright');
    if (stateValue) {
      this.dueDateOnRight = stateValue === 'true' ? true : false;
    }

    const showNotApplicableState = localStorage.getItem('callUpsShowNotApplicableItems');
    if (showNotApplicableState) {
      this.showNotApplicableItems = showNotApplicableState === 'true' ? true : false;
    }
  }

  subscribeToInnerHeightWidth() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          setTimeout(() => {
            this.setJobStringMaxWidth();
          }, 100); // wait for iPhone and grid
        }
      )
    );
  }

  setJobStringMaxWidth() {
    this.gridHeight = this.globalService.innerHeight - 100; // for menu and job row
    this.windowWidth = this.globalService.innerWidth - 20; // for pop-up for send
    this.maxCommentHeight = window.innerHeight < 600 ? 200 : window.innerHeight - 400;

    this.popupHeight = this.globalService.innerHeight < 600 ? this.globalService.innerHeight : 600;
    this.popupWidth = this.globalService.innerWidth < 700 ? this.globalService.innerWidth : 700;

    this.reorderingAllowed = this.globalService.innerWidth < 1200 ? false : true;

    this.vendorDescMinWidth = this.globalService.innerWidth < 600 ? 130
      : this.globalService.innerWidth < 775 ? (this.globalService.innerWidth - 232) / 2
        : this.globalService.innerWidth < 1000 ? (this.globalService.innerWidth - 235) / 3
          : this.globalService.innerWidth < 1200 ? (this.globalService.innerWidth - 463) / 4
            : (this.globalService.innerWidth - 500) / 4;

    if (this.isMasterOrSubJob) {
      this.vendorDescMinWidth -= 3;
    }

    this.taskTitleWidth = this.globalService.innerWidth > 600 && this.selectionMode === 'none' ? this.vendorDescMinWidth : 0;

    this.vendorPopupWidth = this.globalService.innerWidth < 700 ? this.globalService.innerWidth : 700;
    this.vendorPopupHeight = this.globalService.innerHeight < 565 ? this.globalService.innerHeight : 565;
    this.vendorPopupGridHeight = this.vendorPopupHeight - 125;

    this.vendorGridHeight = this.globalService.innerHeight < 400 ? this.globalService.innerHeight - 50 : 350;

    this.dropDownOptions = {
      width: this.windowWidth < 500 ? this.windowWidth : 500,
      minHeight: this.globalService.innerHeight < 400 ? this.globalService.innerHeight : 400
    };
    this.dropDownOptionsPOs = {
      width: this.windowWidth < 650 ? this.globalService.innerWidth : 650,
      minHeight: this.globalService.innerHeight < 400 ? this.globalService.innerHeight : 400
    };
    this.dropDownOptionsVendors = {
      width: this.windowWidth < 700 ? this.windowWidth : 700,
      minHeight: this.globalService.innerHeight < 400 ? this.globalService.innerHeight : 400
    };
  }

  getData(refreshing: boolean) {
    this.subscriptions.push(
      this.taskService.getCallUpsData(!refreshing)
        .subscribe({
          next: () => {
            this.taskHeaders = this.maintenanceService.taskHeaders;
            this.taskMasters = this.maintenanceService.taskMasters;
            this.vendors = this.userService.vendors.filter(i => i.canAttachOrdersToCallUps);
            this.vendorPayables = this.userService.vendorPayables;
            this.costCentres = this.maintenanceService.costCentres;

            // only active users
            this.users = this.userService.users.filter(i => i.userTypeId !== UserTypeEnum.Associate
              && i.userTypeId !== UserTypeEnum.Client
              && i.isActive);

            this.loading = false;
            if (refreshing) {
              this.getJobWorkFlow();
            }
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  getTasks(generateTasks: boolean, calcFirstNotCompleted: boolean) {
    this.loadingVisible = true;
    this.allJobTasks = [];
    this.shownTasks = [];
    this.subscriptions.push(
      this.taskService.getCallUpTasks(this.jobId, this.selectedTaskHeaderId, generateTasks, false)
        .subscribe({
          next: (tasks) => {
            this.allJobTasks = tasks;
            this.loadingVisible = false;

            this.taskTypes = this.maintenanceService.taskTypes;

            this.templateTaskMasters = [];
            this.taskMasters.forEach(taskMaster => {
              const templateTask = this.maintenanceService.templateTasks.find(i => i.taskMasterId === taskMaster.id);
              if (templateTask) {
                this.templateTaskMasters.push(taskMaster);
              }
            });

            this.templateTaskMastersData = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.templateTaskMasters.filter(i => i.isActive)
            });

            const jobWorkFlow = this.jobWorkFlows.find(i => i.templateTaskHeaderId === this.selectedTaskHeaderId);
            this.selectedTemplateDaysHeaderId = jobWorkFlow.templateDaysHeaderId;

            this.setUpShownTasks(calcFirstNotCompleted);
            this.refreshChildJobs = !this.refreshChildJobs; // force refresh of master-detail
            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingVisible = false;
            this.setUpDataSource();
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      loadMode: 'raw',
      load: () => this.shownTasks,
      insert: async (values) => {
        return new Promise((resolve, reject) => {
          this.loadingVisible = true;
          this.taskService.addJobTask(values).subscribe({
            next: (res) => {
              this.insertJobTasks(res, false);
              this.loadingVisible = false;
              return resolve(res[0]);
            }, error: (err) => {
              this.loadingVisible = false;
              return reject(this.globalService.returnError(err));
            }
          });
        });
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) => {
          this.loadingVisible = true;
          // if we are setting the starting task then we need to update the forecast dates
          if (values.startDate !== undefined) {
            const task = this.allJobTasks.find(i => i.id === key);
            if (task) {
              this.settingSiteStart = this.maintenanceService.templateTasks.find(i => i.isForecastStart && i.taskMasterId === task.taskMasterId) !== undefined;
            }
          }
          this.taskService.updateJobTask(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              this.updateJobTasks(res);
              this.loadingVisible = false;
              return resolve(res);
            }, error: (err) => {
              this.loadingVisible = false;
              return reject(this.globalService.returnError(err));
            }
          });
        });
      }
    });

    this.dataSourceSetup = true;
  }

  insertJobTasks(newItems: Task[], refresh: boolean) {
    // we may have updated the order number and returned existing tasks so we need to delete them
    newItems.forEach(newItem => {
      this.deleteJobTask(newItem.id, false);

      const taskMaster = this.taskMasters.find(i => i.id === newItem.taskMasterId);
      if (!taskMaster?.isHiddenFromCallUps) {
        if (newItem.vendorComment) {
          newItem.vendorComment = newItem.vendorComment.trim();
        }
        if (newItem.officeComment) {
          newItem.officeComment = newItem.officeComment.trim();
        }

        if (newItem.statusId === TaskStatusEnum.NotApplicable) {
          newItem.startDate = null; // show Not Applicable with No Start/Delivery date
          newItem.dueDate = null;
        }

        this.allJobTasks.push(newItem);
        this.shownTasks.push(newItem);
      }
    });

    // recalculate forecast
    this.calculateForecastDates();

    if (refresh) {
      this.setUpShownTasks(false);
      this.refreshGrid();
    }
  }

  deleteJobTask(id: number, refresh: boolean) {
    let foundId = this.allJobTasks.findIndex(i => i.id === id);
    if (foundId >= 0) {
      this.allJobTasks.splice(foundId, 1);
    }

    foundId = this.shownTasks.findIndex(i => i.id === id);
    if (foundId >= 0) {
      this.shownTasks.splice(foundId, 1);
    }

    if (refresh) {
      this.setUpShownTasks(false);
      this.refreshGrid();
    } else {
      this.calculateForecastDates();
    }
  }

  onTasksSaved() {
    // recalc the current activity
    this.subscriptions.push(
      this.taskService.calcCurrentActivity(this.jobId)
        .subscribe({
          next: (jobExtra) => {
            this.jobService.currentJobExtra = jobExtra;
            this.jobService.currentActivity = this.maintenanceService
              .activities?.find(i => i.id === jobExtra?.currentActivityId)?.description;

            if (this.settingSiteStart) {
              // we need to update the forecast dates
              this.settingSiteStart = false;
              this.storeTargetDates(false);
            }
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  // getJobActivity() {
  //   this.subscriptions.push(
  //     this.jobService.getJobExtras(this.jobId)
  //       .subscribe({
  //         next: (jobExtra) => {
  //           this.jobService.currentJobExtra = jobExtra;
  //           this.jobService.currentActivity = this.maintenanceService
  //             .activities?.find(i => i.id === jobExtra?.currentActivityId)?.description;
  //         },
  //         error: (err) => {
  //           this.notiService.notify(err);
  //         }
  //       })
  //   );
  // }

  onJobNumberChanged(jobNo: string) {
    if (jobNo) {
      if (this.jobNumber !== jobNo) {
        this.jobNumber = jobNo;
        this.collapsedGroups = [];
        this.clearJob();
        this.getJob();
      }
    } else {
      this.clearJob();
    }
  }

  clearJob() {
    this.jobId = null;
    this.dataSource = null;
    this.jobWorkFlows = [];
    this.selectedTaskHeaderId = null;
  }

  getJob() {
    this.taskHeadersLoaded = false;
    this.jobId = this.jobService.currentJob.id;
    this.emailString = 'mailto:' + this.auth.getCurrentUser().email
      + '?subject=Job ' + this.jobNumber + ' - ' + this.globalService.getJobString(this.jobService.currentJob);

    this.taskHeadersForJob = [];
    this.allJobTasks = [];
    this.shownTasks = [];

    // for units we may have a master job
    const foundChildJob = this.jobService.jobs?.find(i => i.masterJobId === this.jobId || this.jobService.currentJob.masterJobId === i.id);
    if (foundChildJob !== undefined) {
      this.isMasterOrSubJob = true;
    } else {
      this.isMasterOrSubJob = false;
    }
    this.setJobStringMaxWidth();

    if (!this.jobService.currentJobExtra?.tradeRegionId) {
      // no region specified so we stop and display message
      this.taskHeadersLoaded = true;
    } else {
      this.getJobWorkFlow();
    }
  }

  getJobWorkFlow() {
    this.subscriptions.push(
      this.jobWorkFlowService.getJobWorkFlowDataForCallUps(this.jobId)
        .subscribe({
          next: (jobWorkFlows) => {
            this.jobWorkFlows = jobWorkFlows;
            this.purchaseOrders = this.maintenanceService.purchaseOrders.filter(i => !i.isCancelled && i.canAttachOrdersToCallUps);

            this.taskHeaders.forEach(taskHeader => {
              if (taskHeader.trackingWorkFlowTypeId === TrackingWorkFlowTypeEnum.Construction
                || taskHeader.trackingWorkFlowTypeId === TrackingWorkFlowTypeEnum.Other
                || taskHeader.trackingWorkFlowTypeId === TrackingWorkFlowTypeEnum.Maintenance) {
                const jobWorkFlow = jobWorkFlows.find(i => i.templateTaskHeaderId === taskHeader.id);

                if (jobWorkFlow) {
                  this.taskHeadersForJob.push(taskHeader);
                  if (taskHeader.trackingWorkFlowTypeId === TrackingWorkFlowTypeEnum.Construction) {
                    this.selectedTaskHeaderId = taskHeader.id;
                  }
                }
              }
            });

            if (this.taskHeadersForJob && this.taskHeadersForJob.length === 1) {
              this.selectedTaskHeaderId = this.taskHeadersForJob[0].id;
              this.getTasks(false, true);
            } else if (this.selectedTaskHeaderId) {
              this.getTasks(false, true); // we use the default
            } else {
              this.setUpDataSource();
            }
            this.taskHeadersLoaded = true;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.taskHeadersLoaded = true;
            this.setUpDataSource();
          }
        })
    );
  }

  onToolbarPreparing(e, templateName: string, toolbarTemplate2: string) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName
    });

    if (this.selectedTaskHeaderId) {
      if (this.selectionMode === 'none') {
        e.toolbarOptions.items.unshift(
          {
            location: 'after',
            locateInMenu: 'auto',
            template: toolbarTemplate2
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              stylingMode: 'outlined',
              type: 'default',
              text: 'Create',
              onClick: this.generateTasks.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              stylingMode: 'outlined',
              type: 'success',
              text: 'Send',
              onClick: this.sendCallUps.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              icon: 'collapse',
              onClick: this.collapseAll.bind(this),
              matTooltip: 'Collapse All Rows'
            }
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refresh.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              icon: 'user',
              onClick: this.showJobRoles.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              icon: 'home',
              onClick: this.launchTruthEngine.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'always',
            widget: 'dxButton',
            options: {
              text: 'Move dates',
              onClick: this.dueDateLeftRight.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'always',
            widget: 'dxButton',
            options: {
              text: 'Multi N/A mode',
              onClick: this.multiDeleteMode.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'always',
            widget: 'dxButton',
            options: {
              text: 'Push out start dates',
              onClick: this.extendStartDatesMode.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'always',
            widget: 'dxButton',
            options: {
              text: 'Store target dates',
              onClick: this.storeTargetDates.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'always',
            widget: 'dxCheckBox',
            options: {
              text: 'Show Not Applicable items',
              value: this.showNotApplicableItems,
              rtlEnabled: true,
              onValueChanged: this.showNotApplicableItemsChanged.bind(this)
            }
          });
      } else if (this.inMultiDeleteMode) {
        e.toolbarOptions.items.unshift(
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              stylingMode: 'outlined',
              icon: 'close',
              onClick: this.closeMultiDeleteMode.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              stylingMode: 'outlined',
              type: 'default',
              text: 'Mark selected as N/A',
              onClick: this.markSelectedAsNA.bind(this)
            }
          });
      } else {
        e.toolbarOptions.items.unshift(
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              stylingMode: 'outlined',
              icon: 'close',
              onClick: this.closeExtendStartDatesModeMode.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxNumberBox',
            options: {
              showSpinButtons: true,
              width: 100,
              value: this.extendStartsBy,
              onValueChanged: this.extendStartsByValueChanged.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxSelectBox',
            options: {
              items: this.extendTypes,
              width: 100,
              value: this.extendTypeSelected,
              onValueChanged: this.extendTypeValueChanged.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              stylingMode: 'outlined',
              type: 'default',
              text: 'Update',
              onClick: this.extendStartDates.bind(this)
            }
          });
      }
    }
  }

  onToolbarPreparingOrders(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          stylingMode: 'outlined',
          type: 'default',
          text: 'Show All',
          onClick: this.showAllOrdersChanged.bind(this)
        }
      });
  }

  onTaskMasterToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          stylingMode: 'outlined',
          type: 'default',
          text: this.whenAddingShowAllTaskMasters ? 'Template Only' : 'Show All',
          onClick: this.showAllTaskMasters.bind(this)
        }
      });
  }

  showAllOrdersChanged() {
    this.purchaseOrdersFiltered = this.purchaseOrders;
  }

  showAllTaskMasters() {
    this.refreshingTaskMasterData = true;

    setTimeout(() => {
      if (!this.whenAddingShowAllTaskMasters) {
        this.templateTaskMastersData = new CustomStore({
          key: 'id',
          loadMode: 'raw',
          load: () => this.taskMasters.filter(i => i.isActive)
        });
        this.whenAddingShowAllTaskMasters = true;
      } else {
        this.templateTaskMastersData = new CustomStore({
          key: 'id',
          loadMode: 'raw',
          load: () => this.templateTaskMasters.filter(i => i.isActive)
        });
        this.whenAddingShowAllTaskMasters = false;
      }
      this.refreshingTaskMasterData = false;
    }, 200);
  }

  collapseAll() {
    this.grid.instance.collapseAll();
  }

  generateTasks() {
    if (this.grid.instance.hasEditData() && this.editMode === 'batch') {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      this.generatePopupVisible = true;
    }
  }

  generateTasksGo() {
    this.generatePopupVisible = false;
    this.getTasks(true, false);
  }

  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();
    let newIndex = e.toIndex;
    if (e.itemData.taskTypeId !== visibleRows[e.toIndex].data.taskTypeId) {
      newIndex = e.toIndex + 1;
    }
    const newOrderIndex = visibleRows[newIndex].data.orderNumber; // + 1 for group is zero
    // we need the new index for the type

    this.loadingVisible = true;
    this.subscriptions.push(
      this.taskService.moveJobTask(e.itemData.id, newOrderIndex ?? 1, visibleRows[e.toIndex].data.taskTypeId).subscribe({
        next: (res) => {
          // fix the local data array
          res.forEach(updatedTask => {
            const task = this.allJobTasks.find(i => i.id === updatedTask.id);
            if (task) {
              task.orderNumber = updatedTask.orderNumber;
              task.taskTypeId = updatedTask.taskTypeId;
            }
          });
          this.setUpShownTasks(false);
          this.loadingVisible = false;
          this.refreshGrid();
        }, error: () => {
          this.notiService.showInfo('Cannot drop here');
          this.loadingVisible = false;
        }
      })
    );
  }

  getCallUpData(e) {
    if (this.grid?.instance.hasEditData() && this.editMode === 'batch') {
      if (this.selectedTaskHeaderId !== e?.selectedItem?.id) {
        this.notiService.showInfo('Please Save or Cancel the edited data');
      }
    } else {
      if (e && e.value) {
        this.selectedTaskHeaderId = e.value;
        this.getTasks(false, true);
      }
    }
  }

  setDueDateCellValue(newData, value, currentRowData) {
    // must use formatDate as we get ISO format and lose hours so date can be yesterday
    if (value) {
      const endDateString = this.utilService.convertDateToString(value);
      newData.dueDate = endDateString;
      let startDate = new Date(+endDateString.substr(0, 4), +endDateString.substr(5, 2) - 1, +endDateString.substr(8, 2), 0, 0, 0, 0);

      if (!currentRowData.startDate) {
        newData.startDate = endDateString;
      } else {
        if (currentRowData.startDate instanceof Date) {
          startDate = currentRowData.startDate;
        } else {
          startDate = new Date(+currentRowData.startDate.substr(0, 4),
            +currentRowData.startDate.substr(5, 2) - 1, +currentRowData.startDate.substr(8, 2), 0, 0, 0, 0);
        }
      }

      // calc the new forecast
      const taskMaster = this.taskMasters.find(i => i.id === currentRowData.taskMasterId);
      if (taskMaster) {
        const endDate =
          new Date(+endDateString.substr(0, 4), +endDateString.substr(5, 2) - 1, +endDateString.substr(8, 2), 0, 0, 0, 0);

        const completionDates = this.maintenanceService.calcCompletionDates(taskMaster.id, startDate, endDate,
          this.selectedTemplateDaysHeaderId, this.allJobTasks);

        if (completionDates?.targetCompletionDate) {
          if (completionDates.targetCompletionDate instanceof Date) {
            newData.targetCompletionDate = this.utilService.convertDateToString(completionDates.targetCompletionDate);
          } else {
            newData.targetCompletionDate = completionDates.targetCompletionDate;
          }
        }
        if (completionDates?.forecastCompletionDate) {
          if (completionDates.forecastCompletionDate instanceof Date) {
            newData.forecastCompletionDate = this.utilService.convertDateToString(completionDates.forecastCompletionDate);
          } else {
            newData.forecastCompletionDate = completionDates.forecastCompletionDate;
          }
        }
      }
    } else {
      newData.dueDate = null;
    }
  }

  setManualDaysCellValue(newData, value, currentRowData) {
    const startDateString = currentRowData.startDate;
    if (value) {
      newData.manualDays = value;
      this.resetDueDate(currentRowData, newData, startDateString, value);
    } else {
      newData.manualDays = null;
      // we go back to the default days
      this.resetDueDate(currentRowData, newData, startDateString, this.manualDaysDefault);
    }
  }

  setEndDateCellValue(newData, value, currentRowData) {
    // must use formatDate as we get ISO format and lose hours so date can be yesterday
    if (value) {
      const endDateString = this.utilService.convertDateToString(value);
      newData.endDate = endDateString;
      newData.statusId = TaskStatusEnum.Completed;
      newData.isCompleted = true;
      let startDate = new Date(+endDateString.substr(0, 4), +endDateString.substr(5, 2) - 1, +endDateString.substr(8, 2), 0, 0, 0, 0);

      if (!currentRowData.startDate) {
        newData.startDate = endDateString;
      } else {
        if (currentRowData.startDate instanceof Date) {
          startDate = currentRowData.startDate;
        } else {
          startDate = new Date(+currentRowData.startDate.substr(0, 4),
            +currentRowData.startDate.substr(5, 2) - 1, +currentRowData.startDate.substr(8, 2), 0, 0, 0, 0);
        }
      }

      if (!currentRowData.dueDate) {
        newData.dueDate = endDateString;
      }

      if (!currentRowData.calledDate && !currentRowData.doNotSendCallup) {
        this.notiService.showInfo('Call-Up not sent');
      }

      // calc the new forecast
      const taskMaster = this.taskMasters.find(i => i.id === currentRowData.taskMasterId);
      if (taskMaster) {
        const endDate =
          new Date(+endDateString.substr(0, 4), +endDateString.substr(5, 2) - 1, +endDateString.substr(8, 2), 0, 0, 0, 0);

        const completionDates = this.maintenanceService.calcCompletionDates(taskMaster.id, startDate, endDate,
          this.selectedTemplateDaysHeaderId, this.allJobTasks);

        if (completionDates?.targetCompletionDate) {
          if (completionDates.targetCompletionDate instanceof Date) {
            newData.targetCompletionDate = this.utilService.convertDateToString(completionDates.targetCompletionDate);
          } else {
            newData.targetCompletionDate = completionDates.targetCompletionDate;
          }
        }
        if (completionDates?.forecastCompletionDate) {
          if (completionDates.forecastCompletionDate instanceof Date) {
            newData.forecastCompletionDate = this.utilService.convertDateToString(completionDates.forecastCompletionDate);
          } else {
            newData.forecastCompletionDate = completionDates.forecastCompletionDate;
          }
        }
      }
    } else {
      if (currentRowData.statusId === TaskStatusEnum.NotApplicable) {
        newData.startDate = null;
        newData.dueDate = null;
      }

      newData.statusId = TaskStatusEnum.InProgress;
      newData.isCompleted = false;
      newData.endDate = null;
    }
  }

  setStartDateCellValue(newData, value, currentRowData) {
    // must use formatDate as we get ISO format and lose hours so date can be yesterday
    if (currentRowData.endDate) {
      this.notiService.showWarning('Cannot change delivery date unless completed date is blank');
    } else {
      if (value) {
        if (this.currentStartDate && currentRowData.calledDate) {
          newData.prevStartDate = this.currentStartDate;
          const prevComment = 'Rescheduled from ' + this.globalService.getDateString(this.currentStartDate) + '.';
          if (currentRowData.vendorComment) {
            newData.vendorComment = prevComment + '\n' + currentRowData.vendorComment;
          } else {
            newData.vendorComment = prevComment;
          }
        }

        const startDateString = this.utilService.convertDateToString(value);
        const todaysDateString = formatDate(new Date(), 'yyyy-MM-dd');

        if (startDateString < todaysDateString) {
          this.notiService.showWarning('Warning - date in the past');
        } else if (value.getDay() === 6 || value.getDay() === 0) {
          this.notiService.showWarning('Warning - weekend date');
        } else if (this.maintenanceService.holidays.find(i => i.date.toString().substr(0, 10) === startDateString)) {
          this.notiService.showWarning('Warning - date is a holiday');
        }

        newData.startDate = startDateString;
        if (currentRowData.statusId === TaskStatusEnum.NotStarted || currentRowData.statusId === TaskStatusEnum.NotApplicable) {
          newData.statusId = TaskStatusEnum.InProgress;
        }

        const templateTask = this.maintenanceService.templateTasks.find(i => i.taskMasterId === currentRowData.taskMasterId);

        if (!currentRowData.vendorId) {
          newData.vendorId = templateTask ? templateTask.vendorId : null;
        }
        if (!currentRowData.userId) {
          newData.userId = templateTask ? templateTask.userId : null;
        }

        this.resetDueDate(currentRowData, newData, startDateString, currentRowData.manualDays);
      } else {
        if (currentRowData.calledDate) {
          this.checkForSendCancellation(currentRowData.id, currentRowData.doNotSendCallup, currentRowData.vendorId);
        }
        newData.statusId = TaskStatusEnum.NotStarted;
        newData.startDate = null;
        newData.dueDate = null;
        newData.endDate = null;
        newData.isCompleted = false;
      }
      newData.calledDate = null;
    }
  }

  resetDueDate(currentRowData: any, newData: any, startDateString: string, manualDays: number) {
    if (!currentRowData.endDate && startDateString && startDateString.length) {
      // we can reset the due date
      const taskMaster = this.taskMasters.find(i => i.id === currentRowData.taskMasterId);
      if (taskMaster) {

        const startDate =
          new Date(+startDateString.substr(0, 4), +startDateString.substr(5, 2) - 1, +startDateString.substr(8, 2), 0, 0, 0, 0);

        let days = taskMaster.days;

        if (manualDays) {
          days = manualDays;
        } else if (this.maintenanceService.templateDays && this.maintenanceService.templateDays.length) {
          const templateTask = this.maintenanceService.templateTasks.find(i => i.taskMasterId === currentRowData.taskMasterId);

          const templateDays = this.maintenanceService.templateDays
            .find(i => i.templateDaysHeaderId === this.selectedTemplateDaysHeaderId && i.templateTaskId === templateTask?.id);

          if (templateDays && !templateDays.isOnlyForForecast) {
            days = templateDays.days ? templateDays.days : 0;
          }
        }

        newData.dueDate = this.utilService.convertDateToString(this.maintenanceService.addDaysExHolidays(startDate, days));

        // calc the new forecast
        const completionDates = this.maintenanceService.calcCompletionDates(taskMaster.id, startDate, newData.dueDate,
          this.selectedTemplateDaysHeaderId, this.allJobTasks);

        if (completionDates?.targetCompletionDate) {
          if (completionDates.targetCompletionDate instanceof Date) {
            newData.targetCompletionDate = this.utilService.convertDateToString(completionDates.targetCompletionDate);
          } else {
            newData.targetCompletionDate = completionDates.targetCompletionDate;
          }
        }
        if (completionDates?.forecastCompletionDate) {
          if (completionDates.forecastCompletionDate instanceof Date) {
            newData.forecastCompletionDate = this.utilService.convertDateToString(completionDates.forecastCompletionDate);
          } else {
            newData.forecastCompletionDate = completionDates.forecastCompletionDate;
          }
        }
      }
    }
  }

  calculateIsCompleted(data) {
    if (data && data.endDate) {
      return true;
    } else {
      return false;
    }
  }

  setIsCompletedCellValue(newData, value, currentRowData) {
    if (value) {
      newData.statusId = TaskStatusEnum.Completed;
      const endDateString = formatDate(new Date(), 'yyyy-MM-dd');
      newData.endDate = endDateString;
      let startDate = new Date(+endDateString.substr(0, 4), +endDateString.substr(5, 2) - 1, +endDateString.substr(8, 2), 0, 0, 0, 0);

      if (!currentRowData.startDate) {
        newData.startDate = endDateString;
      } else {
        if (currentRowData.startDate instanceof Date) {
          startDate = currentRowData.startDate;
        } else {
          startDate = new Date(+currentRowData.startDate.substr(0, 4),
            +currentRowData.startDate.substr(5, 2) - 1, +currentRowData.startDate.substr(8, 2), 0, 0, 0, 0);
        }
      }
      if (!currentRowData.dueDate) {
        newData.dueDate = endDateString;
      }

      if (!currentRowData.calledDate && !currentRowData.doNotSendCallup) {
        this.notiService.showInfo('Call-Up not sent');
      }

      // calc the new forecast
      const taskMaster = this.taskMasters.find(i => i.id === currentRowData.taskMasterId);
      if (taskMaster) {
        const endDate =
          new Date(+endDateString.substr(0, 4), +endDateString.substr(5, 2) - 1, +endDateString.substr(8, 2), 0, 0, 0, 0);

        const completionDates = this.maintenanceService.calcCompletionDates(taskMaster.id, startDate, endDate,
          this.selectedTemplateDaysHeaderId, this.allJobTasks);

        if (completionDates?.targetCompletionDate) {
          if (completionDates.targetCompletionDate instanceof Date) {
            newData.targetCompletionDate = this.utilService.convertDateToString(completionDates.targetCompletionDate);
          } else {
            newData.targetCompletionDate = completionDates.targetCompletionDate;
          }
        }
        if (completionDates?.forecastCompletionDate) {
          if (completionDates.forecastCompletionDate instanceof Date) {
            newData.forecastCompletionDate = this.utilService.convertDateToString(completionDates.forecastCompletionDate);
          } else {
            newData.forecastCompletionDate = completionDates.forecastCompletionDate;
          }
        }
      }
    } else {
      if (currentRowData.statusId === TaskStatusEnum.NotApplicable) {
        newData.startDate = null;
        newData.dueDate = null;
      }
      newData.statusId = TaskStatusEnum.InProgress;
      newData.endDate = null;
    }
  }

  showCompletedChanged(e) {
    if (this.grid.instance.hasEditData() && this.editMode === 'batch') {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      this.showCompleted = e.value;
      this.autoNavigateToFocusedRow = true;
      localStorage.setItem('callUpsShowCompleted', this.showCompleted ? 'true' : 'false');
      this.setUpShownTasks(false);
      this.refreshGrid();
    }
  }

  setUpShownTasks(calcFirstNotCompleted: boolean) {
    this.shownTasks = [];
    this.allJobTasks.forEach(task => {
      // if hide if not applivable?
      const templateTask = this.maintenanceService.templateTasks.find(i => i.taskMasterId === task.taskMasterId);

      if (!templateTask || task.statusId !== TaskStatusEnum.NotApplicable || (this.showNotApplicableItems && !templateTask?.isHideNotApplicable)) {
        const taskMaster = this.taskMasters.find(i => i.id === task.taskMasterId);

        if (!taskMaster || !taskMaster.isHiddenFromCallUps) {
          if (this.showCompleted || task.statusId < TaskStatusEnum.Completed) {
            if (task.statusId === TaskStatusEnum.NotApplicable) {
              task.startDate = null; // show Not Applicable with No Start/Delivery date
              task.dueDate = null;
            }
            task.taskTypeOrderNumber = this.maintenanceService.taskTypes.find(i => i.id === taskMaster.taskTypeId)?.orderNumber;
            this.shownTasks.push(task);
          }
        }
      }
    });

    this.shownTasks.sort(this.globalService.sortBy('taskTypeOrderNumber', this.globalService.sortBy('orderNumber', false)));

    if (calcFirstNotCompleted) {
      const currentTask = this.shownTasks.find(i => i.endDate === null);
      this.firstNotCompletedTaskId = currentTask?.id;
      this.currentTaskTypeId = currentTask?.taskTypeId;
    }

    this.calculateForecastDates();
  }

  setTaskMasterIdCellValue(rowData, value) {
    if (value) {
      rowData.taskMasterId = value.id;
      const task = this.taskMasters.find(i => i.id === value.id);
      rowData.manualTaskTitle = task ? task.taskTitle : '';
      rowData.taskTypeId = task ? task.taskTypeId : null;
      rowData.jobId = this.jobId;
      rowData.templateTaskHeaderId = this.selectedTaskHeaderId;
      rowData.officeComment = task?.defaultOfficeComment;

      if (task?.defaultVendorComment && task?.defaultVendorComment.trim() !== '') {
        let defComment = task.defaultVendorComment;
        defComment = defComment.replace('[Job Number]', this.jobNumber);
        rowData.vendorComment = defComment;
      } else {
        rowData.vendorComment = '';
      }

      const templateTask = this.maintenanceService.templateTasks.find(i => i.taskMasterId === value.id);
      rowData.sendAddendum = templateTask?.includeAddendum;
      rowData.doNotSendCallup = templateTask?.doNotSendCallup;
      rowData.userId = templateTask?.userId;
      rowData.vendorId = templateTask?.vendorId;
    }
  }

  setPurchaseOrderIdCellValue(rowData, value, originalData) {
    if (!value) {
      rowData.purchaseOrderId = null;
    } else {
      rowData.purchaseOrderId = value.id;
      this.currentPurchaseOrderId = value.id;
      if (!originalData.vendorId) {
        const vendor = this.vendors.find(i => i.id === value.vendorId);
        if (vendor?.canAcceptInvoices) {
          rowData.vendorId = value.vendorId;
        }
      }
    }
  }

  setVendorIdCellValue(rowData, value, originalData) {
    if (!value) {
      if (originalData.calledDate && originalData.vendorId) {
        this.checkForSendCancellation(originalData.id, originalData.doNotSendCallup, originalData.vendorId);
      }
      rowData.vendorId = null;
    } else {
      rowData.vendorId = value;
      if (value !== originalData.vendorId) {
        if (originalData.calledDate) {
          this.checkForSendCancellation(originalData.id, originalData.doNotSendCallup, originalData.vendorId);
        }

        const vendor = this.vendors.find(i => i.id === value);
        if (!vendor?.canAcceptInvoices) {
          this.notiService.showInfo('Note: Cannot accept invoices from this vendor');
        } else {
          if (originalData.purchaseOrderId) {
            const po = this.purchaseOrders.find(i => i.id === originalData.purchaseOrderId);
            if (po && po.vendorId !== value) {
              const poVendor = this.vendors.find(i => i.id === po.vendorId);
              if (poVendor) {
                if (!poVendor.canAnyVendorInvoice) {
                  const vendorPayable = this.vendorPayables.find(i => i.orderVendorId === po.vendorId && i.payVendorId === value);
                  if (!vendorPayable) {
                    this.notiService.showInfo('Note: Cannot accept invoices from this vendor for this order');
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  checkForSendCancellation(jobTaskId: number, doNotSendCallup: boolean, vendorId: number) {
    if (!doNotSendCallup && vendorId) {
      const modalRef = this.modalService.open(SendCancellationComponent);
      modalRef.componentInstance.jobTaskId = jobTaskId;
      modalRef.componentInstance.vendorId = vendorId;

      modalRef.result.then(() => {
      }, () => {
        this.notiService.showWarning('Please advise vendor of cancelled call-up.');
      });
    } else {
      this.notiService.showWarning('Please advise vendor of cancelled call-up.');
    }
  }

  onInitNewRow(e) {
    e.data.statusId = TaskStatusEnum.InProgress;
    this.currentRowKey = null;
    this.allowEditing = true;
    this.currentJobTask = null;
    e.data.jobId = this.jobId;
    e.data.templateTaskHeaderId = this.selectedTaskHeaderId;
    e.data.orderNumber = this.newOrderNumber;
    e.data.newTaskTypeId = this.newTaskTypeId;
    e.data.sendAddendum = false;
    e.data.doNotSendCallup = false;
  }

  onEditingStart(e) {
    this.currentRowKey = e.key;
    this.currentStartDate = e.data?.startDate;
    this.currentJobTask = e.data;
    this.currentJobTaskId = e.data?.id;
    this.currentPurchaseOrderId = e.data?.purchaseOrderId;
    this.allowEditing = false;
    this.currentTaskMaster = null;

    if (this.editMode === 'popup') {
      const currentTemplateTask = this.maintenanceService.templateTasks.find(i => i.templateTaskHeaderId === this.selectedTaskHeaderId
        && i.taskMasterId === e.data?.taskMasterId);
      this.currentTradeId = currentTemplateTask?.tradeId;

      this.currentTaskMaster = this.maintenanceService.taskMasters.find(i => i.id === e.data?.taskMasterId);
      this.manualDaysDefault = this.currentTaskMaster ? this.currentTaskMaster.days : null;

      this.vendorEmailString = '';
      if (e.data.vendorId) {
        const vendor = this.vendors.find(i => i.id === e.data.vendorId);
        if (vendor) {
          const emailAddress = vendor.callUpEmail && vendor.callUpEmail.trim() !== '' ? vendor.callUpEmail : vendor.email;
          if (emailAddress) {
            this.vendorEmailString = 'mailto:' + emailAddress
              + '?subject=Job ' + this.jobNumber + ' - ' + this.globalService.getJobString(this.jobService.currentJob);
          }
        }
      }
    }
  }

  getVendorsForTradeRegion(vendorId: number, currentTradeId: number) {
    // for the update to work we need to set the trade id so we can filter
    // first reset
    this.vendors.forEach(vendor => {
      vendor.tradeId = null;
    });

    if (currentTradeId) {
      if (vendorId) {
        this.currentVendorId = vendorId;
        const existingVendor = this.vendors.find(i => i.id === vendorId);
        if (existingVendor) {
          existingVendor.tradeId = currentTradeId;
        }
      } else {
        this.currentVendorId = null;
      }

      if (this.maintenanceService.tradeVendors && this.maintenanceService.tradeVendors.length) {
        const tradeVendors = this.maintenanceService.tradeVendors.filter(i => i.tradeId === currentTradeId
          && i.tradeRegionId === this.jobService.currentJobExtra.tradeRegionId);

        if (tradeVendors && tradeVendors.length) {
          tradeVendors.forEach(tradeVendor => {
            const vendor = this.vendors.find(i => i.id === tradeVendor.vendorId && i.id !== vendorId);
            if (vendor) {
              vendor.tradeId = currentTradeId;
            }
          });
        }
      }
    }
  }

  getFilteredVendors(vendorId: number, currentTradeId: number) {
    if (currentTradeId) {
      this.getVendorsForTradeRegion(vendorId, currentTradeId);
      this.filteredVendors = this.vendors.filter(i => i.tradeId === currentTradeId && i.isActive);
    } else {
      this.filteredVendors = this.vendors.filter(i => i.isActive);
    }
  }

  addRemoveVendors() {
    if (this.maintenanceService.tradeRegions && this.maintenanceService.tradeRegions.length
      && this.jobService.currentJobExtra && this.jobService.currentJobExtra.tradeRegionId) {
      this.addRemoveVendorsPopupVisible = true;

      const tradeRegion = this.maintenanceService.tradeRegions.find(i => i.id === this.jobService.currentJobExtra.tradeRegionId);
      if (tradeRegion) {
        this.tradeRegionDescription = tradeRegion.description;
      }
      const trade = this.maintenanceService.trades.find(i => i.id === this.currentTradeId);
      if (trade) {
        this.tradeDescription = trade.description;
      }

      this.tradeVendorDataSource = new CustomStore({
        key: 'id',
        load: async () => {
          return new Promise((resolve, reject) =>
            this.maintenanceService.getTradeVendorsForRegionAndTrade(this.jobService.currentJobExtra.tradeRegionId,
              this.currentTradeId).subscribe({
                next: (res) => {
                  // add default email addresses
                  res.forEach(tradeVendor => {
                    const vendor = this.vendors.find(i => i.id === tradeVendor.vendorId);
                    if (vendor) {
                      tradeVendor.defaultEmail = vendor.callUpEmail ? vendor.callUpEmail : vendor.email;
                    }
                  });
                  return resolve(res);
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              }));
        },
        insert: async (values) => {
          return new Promise((resolve, reject) =>
            this.maintenanceService.addTradeVendor(values).subscribe({
              next: (res) => {
                const vendor = this.vendors.find(i => i.id === res.vendorId);
                if (vendor) {
                  this.filteredVendors.push(vendor);
                  res.defaultEmail = vendor.callUpEmail ? vendor.callUpEmail : vendor.email;
                  this.maintenanceService.tradeVendors.push(res);
                }
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }));
        },
        update: async (key, values: TradeVendor) => {
          return new Promise((resolve, reject) =>
            this.maintenanceService.updateTradeVendor(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                const tradeVendor = this.maintenanceService.tradeVendors.find(i => i.id === key);
                if (tradeVendor) {
                  tradeVendor.contactEmail = values.contactEmail;
                }
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }));
        },
        remove: async (key) => {
          return new Promise((resolve, reject) =>
            this.maintenanceService.deleteTradeVendor(encodeURIComponent(key)).subscribe({
              next: () => {
                const tradeVendor = this.maintenanceService.tradeVendors.find(i => i.id === key);
                if (tradeVendor) {
                  const foundTradeVendorIndex = this.maintenanceService.tradeVendors.findIndex(i => i.id === key);
                  this.maintenanceService.tradeVendors.splice(foundTradeVendorIndex, 1);
                }

                // reset the vendor so the filter works
                const foundVendor = this.vendors.find(i => i.id === tradeVendor.vendorId);
                if (foundVendor) {
                  foundVendor.tradeId = null;
                  const foundVendorIndex = this.filteredVendors.findIndex(i => i.id === tradeVendor.vendorId);
                  this.filteredVendors.splice(foundVendorIndex, 1);
                }
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }));
        }
      });
    } else {
      this.notiService.showInfo('No trade regions set up or this job has not had a region specified. See Job-Data');
    }
  }

  closeAddRemoveVendors() {
    // need to reread trade vendors full list
    this.subscriptions.push(
      this.maintenanceService.getTradeVendors().subscribe({
        next: () => {
          this.addRemoveVendorsPopupVisible = false;
        }, error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  calculateLoadSortValue(jobTask: Task) {
    let orderNumber = 0;
    let description = 'Ad-Hoc';
    if (jobTask.taskTypeId) {
      const taskType = this.maintenanceService.taskTypes.find(i => i.id === jobTask.taskTypeId);
      jobTask.taskTypeOrderNumber = taskType.orderNumber;
      if (taskType) {
        orderNumber = taskType.orderNumber;
        description = taskType.description;
      }
    }
    return ('00000' + orderNumber.toString()).slice(-6) + ';' + description; // 'I' to get items after recipes
  }

  getGroupTitle(cellInfo) {
    return cellInfo.data.key.split(';')[1];
  }

  onRowPrepared(e) {
    if (e.rowType === 'data' && (e.data.endDate || e.data.statusId === TaskStatusEnum.NotApplicable)) {
      // completed in green or orange
      if (e.data.statusId === TaskStatusEnum.NotApplicable) {
        e.rowElement.style.backgroundColor = 'rgb(230, 245, 220)';
      } else {
        e.rowElement.style.backgroundColor = 'rgb(200, 250, 200)';
      }
      e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
    }
  }

  onCellPrepared(e) {
    if (e.rowType === 'data') {
      if (e.column.dataField === 'vendorId' && !e.displayValue && e.data.startDate && !e.data.doNotSendCallup && !e.data.endDate) {
        e.cellElement.style.backgroundColor = 'red';
      }

      if (e.column.dataField === 'purchaseOrderId' && !e.displayValue && e.data.startDate && !e.data.doNotSendCallup && !e.data.endDate) {
        const templateTask = this.maintenanceService.templateTasks.find(i => i.taskMasterId === e.data.taskMasterId);
        if (templateTask?.mustHavePoToSend) {
          e.cellElement.style.backgroundColor = 'red';
        }
      }

      if (e.rowType === 'data' && ((!this.isMasterOrSubJob && e.columnIndex >= 3 && e.columnIndex <= 10)
        || (this.isMasterOrSubJob && e.columnIndex >= 4 && e.columnIndex <= 11))
        && e.data.statusId === TaskStatusEnum.NotApplicable) {
        e.cellElement.style.textDecoration = 'line-through';
      }

      if (e.rowType === 'data'
        && (this.reorderingAllowed && (e.column.dataField === 'taskMasterId' || e.column.dataField === 'vendorId'))) {
        const templateTask = this.maintenanceService.templateTasks.find(i => i.taskMasterId === e.data.taskMasterId);
        if (templateTask?.isUseWorkFlow) {
          e.cellElement.style.fontWeight = 'bold';
        }
      }

      if (e.column.dataField === 'endDate') {
        e.cellElement.style.paddingLeft = '0';
        e.cellElement.style.paddingRight = '0';
      }

      if (e.column.dataField === 'startDate') {
        // no start so we set to grey
        if (!e.data.startDate) {
          const forecastStart = this.calculateForecastStart(e.data.taskMasterId);
          const templateTask = this.maintenanceService.templateTasks.find(i => i.taskMasterId === e.data.taskMasterId);
          if (forecastStart) {
            let dateToTest = new Date();
            if (templateTask?.callUpSendLeadDays) {
              dateToTest = this.maintenanceService.addDaysExHolidays(dateToTest, templateTask.callUpSendLeadDays);
            }

            if (this.utilService.convertDateToString(forecastStart) <= this.utilService.convertDateToString(dateToTest)) {
              e.cellElement.style.color = 'rgb(240, 0, 0, .5)';
            } else {
              e.cellElement.style.color = 'rgb(200, 175, 150, .7)';
            }
          }
        }
      }

      if (e.column.dataField === 'calledDate') {
        // no start so we set to grey
        if (e.data.isAcceptedByVendor) {
          e.cellElement.style.color = 'rgb(20, 240, 0)';
        }
      }
    }
  }

  deleteTaskPopup(e) {
    if (e.row.data.statusId === TaskStatusEnum.NotApplicable) {
      // reset the task(s)
      const modalRef = this.modalService.open(ResetJobTaskComponent);
      modalRef.componentInstance.jobTask = e.row.data;

      modalRef.result.then(() => {
        this.getTasks(false, false);
      });
    } else if (this.isMasterOrSubJob) {
      const modalRef = this.modalService.open(MasterChildCallUpsComponent, { windowClass: 'modal-1500', backdrop: 'static', keyboard: false });
      modalRef.componentInstance.jobTask = e.row.data;
      modalRef.componentInstance.vendorDescMinWidth = this.vendorDescMinWidth;
      modalRef.componentInstance.selectedTaskHeaderId = this.selectedTaskHeaderId;
      modalRef.componentInstance.title = 'Mark Not Applicable';

      modalRef.result.then(() => {
        this.getTasks(false, false);
      });
    } else {
      // we may want to just flag as cancelled
      this.deletePopupVisible = true;
      this.recordToDelete = e.row.data;
    }
  }

  addTaskPopup(e) {
    this.newOrderNumber = e.row.data.orderNumber + 1;
    this.newTaskTypeId = e.row.data.taskTypeId;
    this.firstNotCompletedTaskId = e.row.data.id;
    this.currentTaskTypeId = e.row.data.taskTypeId;
    this.grid.instance.addRow();
  }

  isCompleteIconVisible(e) {
    // **************** temp change for Coast to get up to speed ***********************deleteJobTask
    if (this.editMode === 'batch' && (e.row.data.startDate || this.globalService.getCurrentCompanyId() === '47') && !e.row.data.endDate) {
      return true;
    }
    return false;
  }

  completeTask(e) {
    this.grid.instance.cellValue(e.row.rowIndex, 'endDate', new Date());
    this.firstNotCompletedTaskId = e.row.data.id;
    this.currentTaskTypeId = e.row.data.taskTypeId;
  }

  deleteTaskPopupClose() {
    this.deletePopupVisible = false;
  }

  deleteTasksGo(deleteRecord: boolean) {
    this.deleteInProgress = true;
    this.deletePopupVisible = false;

    let updateRecord: object;

    if (deleteRecord) {
      updateRecord =
      {
        statusId: TaskStatusEnum.Cancelled,
        startDate: this.recordToDelete.startDate ? this.utilService.convertDateToString(this.recordToDelete.startDate) : formatDate(new Date(), 'yyyy-MM-dd')
      };
    } else {
      updateRecord =
      {
        statusId: TaskStatusEnum.NotApplicable,
        officeComment: 'NOT Applicable',
        startDate: this.recordToDelete.startDate ? this.utilService.convertDateToString(this.recordToDelete.startDate) : formatDate(new Date(), 'yyyy-MM-dd'),
        dueDate: this.recordToDelete.dueDate ? this.utilService.convertDateToString(this.recordToDelete.dueDate) : formatDate(new Date(), 'yyyy-MM-dd'),
        endDate: formatDate(new Date(), 'yyyy-MM-dd')
      };
    }

    this.subscriptions.push(
      this.taskService.updateJobTask(this.recordToDelete.id.toString(), updateRecord)
        .subscribe({
          next: (res) => {
            this.deleteInProgress = false;
            this.upSertJobTasks(res);
            this.onTasksSaved();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.deleteInProgress = false;
          }
        })
    );
  }

  cancelClickHandler() {
    this.grid.instance.cancelEditData();
  }

  saveClickHandler() {
    this.grid.instance.saveEditData();
  }

  attachDocs() {
    const modalRef = this.modalService.open(TaskDocumentsComponent,
      { backdrop: 'static', keyboard: false, backdropClass: 'backdropClass' });
    modalRef.componentInstance.jobTask = this.currentJobTask;
    modalRef.componentInstance.useCache = true;

    modalRef.result.then(() => {
    }, () => {
    });
  }

  refresh() {
    if (this.grid.instance.hasEditData() && this.editMode === 'batch') {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      this.jobService.jobs = [];
      this.userService.users = [];
      this.maintenanceService.taskMasters = [];
      this.maintenanceService.taskHeaders = [];
      this.userService.vendors = [];
      this.maintenanceService.tradeVendors = [];
      this.maintenanceService.trades = [];
      this.jobService.jobDocuments = [];
      this.purchaseOrders = [];
      this.maintenanceService.purchaseOrders = [];
      this.loading = true;
      this.editMode = 'popup';
      this.autoNavigateToFocusedRow = true;
      this.getData(true);
    }
  }

  onTaskMasterSelectionChanged(cellInfo, dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0]);
      dropDownBox.close();
    }
  }

  onPurchaseOrderSelectionChanged(cellInfo, dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0]);
      dropDownBox.close();
    }
  }

  onVendorSelectionChanged(cellInfo, dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0].id);

      this.vendorEmailString = '';
      if (event.selectedRowsData[0].id) {
        const vendor = this.vendors.find(i => i.id === event.selectedRowsData[0].id);
        if (vendor) {
          const emailAddress = vendor.callUpEmail && vendor.callUpEmail.trim() !== '' ? vendor.callUpEmail : vendor.email;
          if (emailAddress) {
            this.vendorEmailString = 'mailto:' + emailAddress
              + '?subject=Job ' + this.jobNumber + ' - ' + this.globalService.getJobString(this.jobService.currentJob);
          }
        }
      }

      dropDownBox.close();
    }
  }

  onPODropDownChanged(cellInfo, e) {
    if (!e.value) {
      cellInfo.setValue(null);
    }
  }

  onVendorDropDownChanged(cellInfo, e) {
    if (!e.value) {
      cellInfo.setValue(null);
      this.vendorEmailString = null;
    }
  }

  onPODropdownOpened(cellInfo) {
    const templateTask = this.maintenanceService.templateTasks.find(i => i.taskMasterId === cellInfo.data.taskMasterId);

    if (templateTask && templateTask.costCentreId) {
      this.purchaseOrdersFiltered = this.purchaseOrders.filter(i => i.costCentreId === templateTask.costCentreId);
    } else {
      this.purchaseOrdersFiltered = this.purchaseOrders;
    }
  }

  onVendorDropdownOpened(cellInfo) {
    const templateTask = this.maintenanceService.templateTasks.find(i => i.taskMasterId === cellInfo.data.taskMasterId);
    this.getFilteredVendors(cellInfo.data.vendorId, templateTask?.tradeId);
  }

  sendCallUps() {
    if (this.grid.instance.hasEditData() && this.editMode === 'batch') {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      const modalRef = this.modalService.open(SendCallUpsComponent, { windowClass: 'modal-1500', backdrop: 'static', keyboard: false });
      modalRef.componentInstance.jobId = this.jobId;
      modalRef.componentInstance.selectedTaskHeaderId = this.selectedTaskHeaderId;

      modalRef.result.then(() => {
        this.getTasks(false, false);
      });
    }
  }

  setTradeVendorCellValue(rowData, value) {
    if (value) {
      rowData.vendorId = value;
      const vendor = this.vendors.find(i => i.id === value);
      rowData.defaultEmail = vendor?.callUpEmail?.length ? vendor.callUpEmail : vendor?.email;
    }
  }

  changEditMode() {
    if (this.grid.instance.hasEditData() && this.editMode === 'batch') {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      if (this.editMode === 'popup') {
        this.focusedRowEnabled = false;
        this.editMode = 'batch';
        this.inPopupMode = false;
      } else {
        this.firstNotCompletedTaskId = null; // stops the screen from jumping if the current row is not in focus
        this.editMode = 'popup';
        this.inPopupMode = true;
      }
    }
  }

  onRowCollapsed(e) {
    const foundIndex = this.collapsedGroups.findIndex(i => i === e.key[0]);
    if (foundIndex < 0) {
      this.collapsedGroups.push(e.key[0]);
    }
    this.collapsingExpanding = true;
  }

  onRowExpanded(e) {
    const foundIndex = this.collapsedGroups.findIndex(i => i === e.key[0]);
    if (foundIndex >= 0) {
      this.collapsedGroups.splice(foundIndex, 1);
    }
    this.collapsingExpanding = true;
  }

  refreshGrid() {
    this.grid.instance.refresh();
  }

  onContentReady() {
    if (this.collapsedGroups.length && !this.collapsingExpanding) {
      this.collapsedGroups.forEach(collapsedGroup => {
        if (this.grid.instance.isRowExpanded([collapsedGroup])) {
          this.grid.instance.collapseRow([collapsedGroup]);
        }
      });
    }
    this.collapsingExpanding = false;
    this.autoNavigateToFocusedRow = false;
  }

  calculateCallUpDocsType(data) {
    let result = false;

    const currentTemplateTask = this.maintenanceService.templateTasks.find(i => i.templateTaskHeaderId === this.selectedTaskHeaderId
      && i.taskMasterId === this.currentTaskMaster?.id);

    if (data.callUpDocsTypeId && currentTemplateTask?.callUpDocsTypes && currentTemplateTask?.callUpDocsTypes.length) {
      const foundType = currentTemplateTask.callUpDocsTypes.find(i => i === data.callUpDocsTypeId);

      if (foundType) {
        result = true;
      }
    }
    return result;
  }

  calculateEmailAddress(data) {
    if (this.currentTradeId) {
      const tradeVendor = this.maintenanceService.tradeVendors
        .find(i => i.tradeId === this.currentTradeId
          && i.tradeRegionId === this.jobService.currentJobExtra.tradeRegionId
          && i.vendorId === data.id);
      if (tradeVendor && tradeVendor.contactEmail && tradeVendor.contactEmail !== '') {
        return tradeVendor.contactEmail;
      }
    }
    const emailAddress = data.callUpEmail && data.callUpEmail.trim() !== '' ? data.callUpEmail : data.email;
    return emailAddress;
  }

  getVendorEmailString(emailAddress) {
    if (emailAddress) {
      return 'mailto:' + emailAddress
        + '?subject=Job ' + this.jobNumber + ' - ' + this.globalService.getJobString(this.jobService.currentJob);
    } else {
      return null;
    }
  }

  generatePONumbers() {
    this.generatePopupVisible = false;
    this.loadingCallUps = true;
    this.subscriptions.push(
      this.taskService.updateJobTaskPONumbers(this.jobId, this.selectedTaskHeaderId)
        .subscribe({
          next: () => {
            this.loadingCallUps = false;
            this.getTasks(false, false); // in case the extra POs have been updatedd
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingCallUps = false;
          }
        })
    );
  }

  downloadOrder() {
    // download attachment
    if (this.currentPurchaseOrderId) {
      this.loadingVisible = true;
      this.poIds = [];
      this.poIds.push(this.currentPurchaseOrderId);

      const po = this.maintenanceService.purchaseOrders.find(i => i.id === this.currentPurchaseOrderId);
      const isHideCostsFromTracking = this.costCentres.find(i => i.id === po.costCentreId)?.isHideCostsFromTracking

      const dataRecord = {
        purchaseOrderIds: this.poIds,
        emailAddresses: [],
        ccToSelf: false,
        download: true,
        emailSubject: '',
        emailMessage: '',
        printPrices: !isHideCostsFromTracking
      };

      this.subscriptions.push(
        this.maintenanceService.sendPurchaseOrders(this.jobId, dataRecord)
          .subscribe({
            next: (orderResponse) => {
              this.globalService.convertAndSave(orderResponse.pdfOrders, this.jobNumber);
              this.loadingVisible = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loadingVisible = false;
            }
          })
      );
    }
  }

  openPO(e) {
    if (e.row.data.jobId && e.row.data.id) {
      this.currentPurchaseOrderId = e.row.data.id;
      this.downloadOrder();
    }
  }

  onInitNewTradeVendor(e) {
    e.data.tradeRegionId = this.jobService.currentJobExtra.tradeRegionId;
    e.data.tradeId = this.currentTradeId;
  }

  showJobRoles() {
    // use modal to set the users to the roles
    const modalRef = this.modalService.open(JobRolesComponent, { windowClass: 'modal-edit' });
    modalRef.componentInstance.jobNumber = this.jobNumber;

    modalRef.result.then(() => {
    }, () => {
    });
  }

  calculateStartDateValue(e) {
    if (e.startDate) {
      if (e.startDate instanceof Date) {
        return e.startDate;
      } else {
        return new Date(+e.startDate.substr(0, 4),
          +e.startDate.substr(5, 2) - 1, +e.startDate.substr(8, 2), 0, 0, 0, 0);
      }
    }

    return this.calculateForecastStart(e.taskMasterId);
  }

  calculateForecastStart(taskMasterId): Date {
    const thisTask = this.maintenanceService.templateTasks.find(i => i.taskMasterId === taskMasterId);

    if (!thisTask?.isShowForecastStart) {
      return null;
    }

    if (!thisTask?.start) {
      return null;
    }

    if (thisTask.start instanceof Date) {
      return thisTask?.start;
    } else {
      const tempDate = JSON.stringify(thisTask.start);
      const year = tempDate.substring(1, 5);
      const month = tempDate.substring(6, 8);
      const day = tempDate.substring(9, 11);
      const newStart = new Date(+year, +month - 1, +day, 0, 0, 0, 0);
      return newStart;
    }
  }

  calculateForecastDates() {
    // clear out any previous dates
    this.maintenanceService.templateTasks.forEach(templateTask => {
      templateTask.start = null;
      templateTask.end = null;
    });

    const startingTemplateTask = this.maintenanceService.templateTasks.find(i => i.isForecastStart);

    if (startingTemplateTask) {
      const jobTask = this.allJobTasks.find(i => i.taskMasterId === startingTemplateTask.taskMasterId);

      if (jobTask && ((jobTask.startDate && jobTask.dueDate) || jobTask.endDate)) {
        const startDate = jobTask.startDate ?? jobTask.endDate;
        const dueDate = jobTask.endDate ?? jobTask.dueDate ?? jobTask.startDate;
        this.maintenanceService.updateTaskDates(startingTemplateTask.id, startDate, dueDate, this.selectedTemplateDaysHeaderId, this.allJobTasks, false);
      }
    }
  }

  calculateCostCentreSortValue(data) {
    return this.costCentres.find(i => i.id === data?.costCentreId)?.orderNumber;
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data?.vendorId)?.vendorName;
  }

  attachExtraPOs() {
    // use modal to add secondary POs that are sent with the Call-Up
    const modalRef = this.modalService.open(SecondaryPurchaseOrdersComponent, { windowClass: 'modal-edit' });
    modalRef.componentInstance.jobId = this.jobId;
    modalRef.componentInstance.jobNumber = this.jobNumber;
    modalRef.componentInstance.taskId = this.currentJobTaskId;

    modalRef.result.then(() => { });
  }

  acceptCallUp() {
    // use modal to add secondary POs that are sent with the Call-Up
    const modalRef = this.modalService.open(AcceptCallUpComponent);
    modalRef.componentInstance.taskId = this.currentJobTaskId;

    modalRef.result.then(() => {
      const task = this.shownTasks.find(i => i.id === this.currentJobTaskId);
      if (task) {
        task.isAcceptedByVendor = true;
        task.dateAcceptedByVendor = new Date();
      }
    });
  }

  onVendorListToolbarPreparing(e, cellInfo: any) {
    const currentTemplateTask = this.maintenanceService.templateTasks.find(i => i.templateTaskHeaderId === this.selectedTaskHeaderId
      && i.taskMasterId === cellInfo.data?.taskMasterId);
    this.currentTradeId = currentTemplateTask?.tradeId;

    if (this.currentTradeId && this.popupWidth > 500) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            stylingMode: 'outlined',
            type: 'default',
            text: 'Add/Remove Vendors',
            onClick: this.addRemoveVendors.bind(this)
          }
        });
    }
  }

  onPOListCellPrepared(e) {
    if (e.rowType === 'data' && e.column.dataField === 'remainingBudgetExGst'
      && e.data.remainingBudgetExGst < 0) {
      e.cellElement.style.color = 'red';
    }
  }

  launchTruthEngine() {
    window.open(environment.addendaAppUrl + '?coy=' + this.globalService.getCurrentCompany().id + '&job=' + this.jobNumber);
  }

  dueDateLeftRight() {
    this.dueDateOnRight = !this.dueDateOnRight;
    localStorage.setItem('call-up-duedateonright', this.dueDateOnRight ? 'true' : 'false');
  }

  showNotApplicableItemsChanged() {
    if (this.grid.instance.hasEditData() && this.editMode === 'batch') {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      this.showNotApplicableItems = !this.showNotApplicableItems;
      localStorage.setItem('callUpsShowNotApplicableItems', this.showNotApplicableItems ? 'true' : 'false');
      this.setUpShownTasks(false);
      this.dataSourceSetup = false;
      setTimeout(() => {
        this.setUpDataSource();
        this.dataSourceSetup = true;
      }, 300);
    }
  }

  multiDeleteMode() {
    this.inMultiDeleteMode = true;
    this.setGridIntoSelectMode();
  }

  closeMultiDeleteMode() {
    this.inMultiDeleteMode = false;
    this.setGridIntoSelectMode();
  }

  setGridIntoSelectMode() {
    if (this.selectionMode === 'none') {
      this.selectionMode = 'multiple';
      this.selectedRowKeys = [];
      this.taskTitleWidth = 0;
    } else {
      this.selectionMode = 'none';
      this.taskTitleWidth = this.globalService.innerWidth > 600 ? this.vendorDescMinWidth : 0;
    }

    this.dataSourceSetup = false;
    setTimeout(() => {
      this.setUpDataSource();
      this.dataSourceSetup = true;
    }, 300);
  }

  markSelectedAsNA() {
    if (this.selectedRowKeys && this.selectedRowKeys.length) {
      this.updateOtherChildJobs = false;
      if (this.isMasterOrSubJob) {
        this.markTasksNAPopupVisible = true;
      } else {
        this.markSelectedAsNAGo();
      }
    } else {
      this.notiService.showInfo('Please select some rows');
    }
  }

  markSelectedAsNAGo() {
    this.markTasksNAPopupVisible = false;
    this.loadingVisible = true;
    this.subscriptions.push(
      this.taskService.markJobTasksNotApplicable(this.jobService.currentJob.id, this.selectedRowKeys, this.updateOtherChildJobs, false)
        .subscribe({
          next: () => {
            this.loadingVisible = false;
            this.selectionMode = 'none';
            this.inMultiDeleteMode = false;
            this.onTasksSaved();
            this.getTasks(false, false);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingVisible = false;
          }
        })
    );
  }

  extendStartDatesMode() {
    this.inExtendStartDatesMode = true;
    this.setGridIntoSelectMode();
  }

  closeExtendStartDatesModeMode() {
    this.inExtendStartDatesMode = false;
    this.setGridIntoSelectMode();
  }

  extendStartsByValueChanged(e) {
    this.extendStartsBy = e.value;
  }

  extendTypeValueChanged(e) {
    this.extendTypeSelected = e.value;
  }

  extendStartDates() {
    if (!this.extendStartsBy) {
      this.notiService.showInfo('Please set days');
    } else if (this.selectedRowKeys && this.selectedRowKeys.length) {
      this.updateOtherChildJobs = false;
      if (this.isMasterOrSubJob) {
        this.extendTasksPopupVisible = true;
      } else {
        this.extendStartDatesGo();
      }
    } else {
      this.notiService.showInfo('Please select some rows');
    }
  }

  extendStartDatesGo() {
    this.extendTasksPopupVisible = false;
    this.loadingVisible = true;

    let extendDays = this.extendStartsBy;
    if (this.extendTypeSelected === 'Weeks') {
      extendDays = this.extendStartsBy * 5;
    }

    this.subscriptions.push(
      this.taskService.extendTaskStartDates(this.jobService.currentJob.id, this.selectedRowKeys, this.updateOtherChildJobs, extendDays)
        .subscribe({
          next: () => {
            this.loadingVisible = false;
            this.selectionMode = 'none';
            this.inExtendStartDatesMode = false;
            this.onTasksSaved();
            this.getTasks(false, false);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingVisible = false;
          }
        })
    );
  }

  onEditorPreparing(e: any) {
    if (e.parentType !== 'dataRow') {
      return;
    } else {
      if (e.dataField === 'officeComment' || e.dataField === 'vendorComment') {
        e.editorName = 'dxTextArea';
        e.editorOptions.autoResizeEnabled = true;
        let prevHeight = null;
        e.editorOptions.onInput = (args) => {
          const td = args.element.closest('td');
          if (td && prevHeight !== td.offsetHeight) {
            const overlay = e.element.querySelector('.dx-datagrid-focus-overlay');
            if (overlay != null) {
              overlay.style.height = (td.offsetHeight + 1) + 'px';
            }
            prevHeight = td.offsetHeight;
          }
        };
      }
    }
  }

  updateJobTasks(newTasks: Task[]) {
    newTasks.forEach(newTask => {
      let foundTask = this.allJobTasks.find(i => i.id === newTask.id);
      if (foundTask) {
        if (newTask.statusId === TaskStatusEnum.Cancelled) {
          const foundId = this.allJobTasks.findIndex(i => i.id === newTask.id);
          if (foundId >= 0) {
            this.allJobTasks.splice(foundId, 1);
          }
        } else {
          foundTask = Object.assign(foundTask, newTask);

          if (foundTask.statusId === TaskStatusEnum.NotApplicable) {
            foundTask.startDate = null; // show Not Applicable with No Start/Delivery date
            foundTask.dueDate = null;
          }
        }
      }

      // if we are only showing the not-completed we splice
      if (newTask.statusId === TaskStatusEnum.Cancelled || (!this.showCompleted && newTask.endDate)) {
        const foundId = this.shownTasks.findIndex(i => i.id === newTask.id);
        if (foundId >= 0) {
          this.shownTasks.splice(foundId, 1);
        }
      }
    });

    this.calculateForecastDates();
  }

  upSertJobTasks(newItems: Task[]) {
    this.updateJobTasks(newItems);
    this.grid.instance.refresh();
  }

  addMultipleTasks() {
    this.grid.instance.cancelEditData();

    const modalRef = this.modalService.open(AddMultipleTasksComponent, { windowClass: 'modal-edit', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.jobId = this.jobId;
    modalRef.componentInstance.selectedTaskHeaderId = this.selectedTaskHeaderId;
    modalRef.componentInstance.nextOrderNumber = this.newOrderNumber;
    modalRef.componentInstance.newTaskTypeId = this.newTaskTypeId;

    modalRef.result.then(() => {
      this.getTasks(false, false);
    });
  }

  storeTargetDates(showLoadingSpinner: boolean = true) {
    this.loadingVisible = showLoadingSpinner;
    this.subscriptions.push(
      this.taskService.storeTargetDates(this.jobId)
        .subscribe({
          next: () => {
            if (showLoadingSpinner) {
              this.loadingVisible = false;
              this.notiService.showSuccess('Target Dates stored');
            }
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingVisible = false;
          }
        })
    );
  }
}
