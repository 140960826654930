import { AuthService } from './../services/auth.service';
import { TaskService } from './../services/felixApi/task.service';
import { Invoice, InvoiceStatusTypeEnum } from './../dtos/invoice';
import { UserService } from '../services/felixApi/user.service';
import { JobService } from '../services/felixApi/job.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Vendor } from '../dtos/vendor';
import { MaintenanceService } from '../services/felixApi/maintenance.service';
import { GlobalService } from '../services/global.service';
import { InvoiceService } from '../services/felixApi/invoice.service';
import { NotificationService } from '../services/notification.service';
import { Job } from '../dtos/job';
import { RoleTypeEnum } from '../dtos/role-type.enum';
import { User } from '../dtos/user';
import { PriceFileItem } from '../dtos/price-file-item';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { VendorGroup } from '../dtos/vendor-group';
import { PreviousInvoicesComponent } from './previous-invoices/previous-invoices.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridService } from '../services/grid.service';
import { RejectInvoiceComponent } from './reject-invoice/reject-invoice.component';
import { BackChargeComponent } from './back-charge/back-charge.component';
import { Router } from '@angular/router';

@Component({
  selector: 'js-invoices-on-hold',
  templateUrl: './invoices-on-hold.component.html',
  styleUrls: ['./invoices-on-hold.component.scss']
})
export class InvoicesOnHoldComponent implements OnInit, OnDestroy {

  @ViewChild('invoiceHoldGrid') invoiceHoldGrid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  vendors: Vendor[];
  loadingData: boolean;
  jobs: Job[];
  gridHeight: number;
  lastVendorId: number;
  attachmentsPopupVisible: boolean;
  currentInvoiceId: any;
  files: any[] = [];
  loadingFile: boolean;
  attachmentExists: any;
  attachmentsPopupHeight: number;
  users: User[];
  usersForEdit: User[];
  costCentres: PriceFileItem[];
  addExtraVisible = false; // add a simple extra for a PO
  purchaseOrderId: number;
  poNumber: string;
  jobNumber: string;
  showMyInvoicesOnly = true;
  showInvoicesNeedingApprovalOnly = false;
  invoiceAuthorisationAdmin: boolean;
  invoices: Invoice[];
  popupHeight: number;
  popupWidth: number;
  selectedVendorGroupId: number;
  vendorGroups: VendorGroup[];
  dropDownOptions: object;
  invoiceId: any;
  descWidth: number;
  showRemainingBudget = false;
  vendorWidth = 125;
  jobAddressWidth = 170;
  approvePopupVisible: boolean;
  rowToUpdate: any;
  showHistory = false;
  showAllHistory: boolean;
  loadingInvoices = false;
  selectedVendorId: number;
  historyInvoices: Invoice[];
  hasBackChargePermission: boolean;

  constructor(
    private globalService: GlobalService,
    private router: Router,
    private invoiceService: InvoiceService,
    private notiService: NotificationService,
    protected gridService: GridService,
    private authService: AuthService,
    private jobService: JobService,
    private userService: UserService,
    private taskService: TaskService,
    private modalService: NgbModal,
    private maintenanceService: MaintenanceService) {
    this.setVendorCellValue = this.setVendorCellValue.bind(this);
    this.setOrderNumberCellValue = this.setOrderNumberCellValue.bind(this);
    this.setTotalIncGSTCellValue = this.setTotalIncGSTCellValue.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
    this.calculateJobNumber = this.calculateJobNumber.bind(this);
    this.calculateSiteManager = this.calculateSiteManager.bind(this);
    this.calculateRemainingBudget = this.calculateRemainingBudget.bind(this);
    this.calculateCostCentreId = this.calculateCostCentreId.bind(this);
    this.extrasPopup = this.extrasPopup.bind(this);
    this.extrasClose = this.extrasClose.bind(this);
    this.openPO = this.openPO.bind(this);
    this.calcAllocatedTo = this.calcAllocatedTo.bind(this);
    this.onCellPrepared = this.onCellPrepared.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.calculateCostCentreSortValue = this.calculateCostCentreSortValue.bind(this);
    this.getUsersForEdit = this.getUsersForEdit.bind(this);
    this.cancelClickHandler = this.cancelClickHandler.bind(this);
    this.saveClickHandler = this.saveClickHandler.bind(this);
    this.showInvoiceHistory = this.showInvoiceHistory.bind(this);
    this.onEditingStart = this.onEditingStart.bind(this);
    this.approveInvoice = this.approveInvoice.bind(this);
    this.calculatePOTotal = this.calculatePOTotal.bind(this);
    this.rejectInvoice = this.rejectInvoice.bind(this);
    this.backChargeInvoice = this.backChargeInvoice.bind(this);
  }

  ngOnInit(): void {
    this.globalService.innerHeightWidthChanged.subscribe(
      () => {
        this.loadingInvoices = true;
        setTimeout(() => {
          this.loadingInvoices = false;
          this.setHeightWidths();
        }, 500);
      }
    );
    this.dropDownOptions = { minHeight: 400 };


    const showRemainingBudgetState = localStorage.getItem('invoicesShowRemainingBudget');
    if (showRemainingBudgetState) {
      this.showRemainingBudget = showRemainingBudgetState === 'true' ? true : false;
    }

    // do we have admin perms to see other users entries
    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('InvoiceAuthorisation') === 'Admin') {
      this.invoiceAuthorisationAdmin = true;
    }

    if (this.authService.isAdminOrSuper()
      || this.authService.getSelectionsPermissions('Invoices') === 'Write' || this.authService.getSelectionsPermissions('Invoices') === 'Admin'
      || this.authService.getSelectionsPermissions('InvoiceBackCharge') === 'Write' || this.authService.getSelectionsPermissions('InvoiceBackCharge') === 'Admin') {
      this.hasBackChargePermission = true;
    }

    this.setHeightWidths();
    this.loadData(true);
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 80;
    this.popupHeight = this.globalService.innerHeight < 605 ? this.globalService.innerHeight : 605;
    this.popupWidth = this.globalService.innerWidth < 600 ? this.globalService.innerWidth : 600;

    if (this.globalService.innerWidth < 770 && this.popupHeight < this.globalService.innerHeight) {
      this.popupHeight = this.globalService.innerHeight < 660 ? this.globalService.innerHeight : 660;
    }
    this.descWidth = this.globalService.innerWidth < 1200 ? 200 : null;
    this.vendorWidth = window.innerWidth < 900 ? null : 125;
    if (window.innerWidth < 1100) {
      this.jobAddressWidth = window.innerWidth < 900 ? 240 : window.innerWidth / 4;
      this.vendorWidth = window.innerWidth < 900 ? null : 145;
    } else {
      this.jobAddressWidth = this.showRemainingBudget ? 240 : 170;
      this.vendorWidth = 125;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadData(useCache) {
    this.subscriptions.push(
      this.invoiceService.getInvoicesDataForOnHold(useCache)
        .subscribe({
          next: () => {
            this.vendors = this.userService.vendors;
            this.jobs = this.jobService.jobs;
            this.costCentres = this.maintenanceService.costCentres;
            this.users = this.userService.officeUsers;
            this.loadingData = false;
            this.vendorGroups = [new VendorGroup(null, 'All Groups')];
            this.vendorGroups = this.vendorGroups.concat(this.userService.vendorGroups);
            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingData = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.invoiceService.getInvoicesOnHold().subscribe({
            next: (res) => {
              this.invoices = res;
              res.forEach(invoice => {
                invoice.vendorGroupId = this.vendors.find(i => i.id === invoice.vendorId)?.vendorGroupId;
                invoice.IsHideInvoiceFromSupervisor = this.costCentres.find(i => i.id === invoice.costCentreId)?.isHideInvoiceFromSupervisor;
                invoice.siteManagerUserId
                  = this.taskService.allJobRoles?.find(i => i.jobId === invoice.jobId && i.roleId === RoleTypeEnum.SiteManager)?.user.id;
              });

              //Hide invoices if flag set on Cost Centre and they are NOT allocated to a specific user.
              res = res.filter(i => (i.IsHideInvoiceFromSupervisor === false) || (i.IsHideInvoiceFromSupervisor === true && i.allocatedToUserId !== null));

              if (this.showMyInvoicesOnly) {
                // show if allocated to you or if you are the supervisor and it is not allocated to someone else
                res = res.filter(i => i.allocatedToUserId === this.authService.getCurrentUserId()
                  || (!i.allocatedToUserId && i.siteManagerUserId === this.authService.getCurrentUserId()));
              }

              if (this.showInvoicesNeedingApprovalOnly) {
                res = res.filter(i => !i.isWorkDone && i.allocatedToUserId);
              }

              this.checkInvoicesOnHoldForUser();

              const filteredInvoices = res.filter(i => !this.selectedVendorGroupId || i.vendorGroupId === this.selectedVendorGroupId);
              return resolve(filteredInvoices);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.invoiceService.updateInvoice(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onToolbarPreparing(e, templateName: string, templateName2: string, toolbarTemplate3: string) {
    const toolbarItems = e.toolbarOptions.items;

    if (this.invoiceAuthorisationAdmin) {
      toolbarItems.push({
        location: 'before',
        locateInMenu: 'auto',
        template: templateName
      });
    }

    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName2
    });

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'always',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'always',
        widget: 'dxCheckBox',
        options: {
          text: 'Swap Remaining Budget',
          value: this.showRemainingBudget,
          rtlEnabled: true,
          onValueChanged: this.showNotApplicableItemsChanged.bind(this)
        }
      }
    );

    if (this.hasBackChargePermission) {
      toolbarItems.unshift({
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Back-Charges',
          onClick: this.showBackChargesClicked.bind(this)
        }
      });
    }

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        template: toolbarTemplate3
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'History',
          onClick: this.showAllHistoryClicked.bind(this)
        }
      }
    );
  }

  showNotApplicableItemsChanged() {
    this.showRemainingBudget = !this.showRemainingBudget;
    localStorage.setItem('invoicesShowRemainingBudget', this.showRemainingBudget ? 'true' : 'false');
    this.setHeightWidths();
    this.clearStatePersistance();
  }

  setVendorCellValue(rowData, value) {
    rowData.vendorId = value;
    this.lastVendorId = value;
  }

  setTotalIncGSTCellValue(rowData, value) {
    rowData.totalIncGST = value;
    rowData.totalGST = Math.round((value / (1 + this.invoiceService.globalGSTRate)) * 100) / 100;
    rowData.totalExGST = value - rowData.totalGST;
  }

  async setOrderNumberCellValue(rowData, value, originalData) {
    rowData.orderNumber = value;

    const orderNumberArray = value.split('.');
    // let validOrder = false;
    rowData.purchaseOrderId = null;
    rowData.poNumber = null;
    rowData.jobId = null;

    if (orderNumberArray.length === 3) {
      const jobNumber = orderNumberArray[0];
      const job = this.jobs.find(i => i.jobNumber === jobNumber);
      if (job) {
        const orderNumber = orderNumberArray[1] + '.' + orderNumberArray[2];
        // look this up to see if we can find the matching PO
        const po = this.invoiceService.allPurchaseOrders.find(i => i.jobId === job.id && i.poNumber === orderNumber);
        if (po) {
          rowData.purchaseOrderId = po.id;
          rowData.jobId = job.id;
          rowData.poNumber = orderNumber;
        }
      }
    }
  }

  // calculateHasAttachments(data) {
  //   return data.hasBlob ? data.id : null;
  // }

  calculateJobNumber(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobNumber;
  }

  calculateSiteManager(data) {
    return this.taskService.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.SiteManager)?.user.fullName;
  }

  calcAllocatedTo(data) {
    if (data.allocatedToUserId) {
      return ' '
        + this.users.find(i => i.id === data.allocatedToUserId)?.fullName;
    } else {
      return 'Site Manager: '
        + this.taskService.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.SiteManager)?.user.fullName;
    }
  }

  calculatePOTotal(data) {
    if (data.purchaseOrderId) {
      const po = this.invoiceService.allPurchaseOrders.find(i => i.id === data.purchaseOrderId);
      return po?.orderTotal;
    } else {
      return null;
    }
  }

  calculateRemainingBudget(data) {
    if (data.purchaseOrderId) {
      const po = this.invoiceService.allPurchaseOrders.find(i => i.id === data.purchaseOrderId);
      let remainingAmount = 0;
      if (po) {
        remainingAmount = po.orderTotal - (po.approvedInvoicesTotal ? po.approvedInvoicesTotal : 0);
      }

      // if we have previous invoices we calculate in order
      const dataSource = this.invoiceHoldGrid.instance.getDataSource();
      // const invoices = dataSource.items();
      const sameInvoices = this.invoices.filter(i => i.purchaseOrderId === data.purchaseOrderId);

      let totalOfAllInvoices = 0;
      let finishCalc = false;

      sameInvoices.forEach(invoice => {
        if (!finishCalc) {
          if (invoice.id === data.id) {
            finishCalc = true;
          }
          totalOfAllInvoices += invoice.totalExGST;
        }
      });

      return remainingAmount - totalOfAllInvoices;
    } else {
      return null;
    }
  }

  calculateCostCentreId(data) {
    if (data.purchaseOrderId) {
      const po = this.invoiceService.allPurchaseOrders.find(i => i.id === data.purchaseOrderId);
      return po?.costCentreId;
    } else {
      return null;
    }
  }

  downloadAttachment(e) {
    // download attachment
    this.loadingFile = true;
    this.subscriptions.push(
      this.invoiceService.getInvoiceAttachment(e.row.data.id).subscribe({
        next: (res) => {
          if (res) {
            this.globalService.getBlobFromBase64(res.attachment, res.attachmentTypeId, res.attachmentName);
          } else {
            this.notiService.showInfo('No invoice attached');
          }
          this.loadingFile = false;
        }, error: (err) => {
          this.notiService.notify(err);
          this.loadingFile = false;
        }
      })
    );
  }

  approveInvoice(e: any) {
    if (!e.row.data.isWorkDone) {
      this.approvePopupVisible = true;
      this.rowToUpdate = e;
    } else {
      this.notiService.showInfo('Invoice already approved');
    }
  }

  approveInvoiceGo() {
    this.loadingFile = true;
    this.approvePopupVisible = false;
    this.subscriptions.push(
      this.invoiceService.updateInvoice(this.rowToUpdate.row.data.id, { isWorkDone: true }).subscribe({
        next: () => {
          this.rowToUpdate.row.data.isWorkDone = true;
          this.rowToUpdate.row.data.onHoldType = this.rowToUpdate.row.data.onHoldType ? this.rowToUpdate.row.data.onHoldType.replace('W', '') : '';
          this.loadingFile = false;
          this.invoiceHoldGrid.instance.refresh();
        }, error: (err) => {
          this.notiService.notify(err);
          this.loadingFile = false;
        }
      })
    );
  }

  extrasPopup(e) {
    this.purchaseOrderId = e.row.data.purchaseOrderId;
    this.poNumber = e.row.data.poNumber;
    this.addExtraVisible = true;
  }

  extrasClose(e: number) {
    this.addExtraVisible = false;

    if (e) {
      const po = this.invoiceService.allPurchaseOrders.find(i => i.id === this.purchaseOrderId);
      if (po) {
        po.orderTotal += e;
      }
      this.setUpDataSource();
    }
  }

  openPO(e) {
    if (e.row.data.jobId && e.row.data.purchaseOrderId) {
      const jobNumber = this.jobs.find(i => i.id === e.row.data.jobId)?.jobNumber;

      const po = this.invoiceService.allPurchaseOrders?.find(i => i.id === e.row.data.purchaseOrderId);

      const isHideCostsFromTracking = this.costCentres.find(i => i.id === po.costCentreId)?.isHideCostsFromTracking

      const dataRecord = {
        purchaseOrderIds: [e.row.data.purchaseOrderId],
        emailAddresses: [],
        ccToSelf: false,
        download: true,
        printPrices: !isHideCostsFromTracking
      };

      this.subscriptions.push(
        this.maintenanceService.sendPurchaseOrders(e.row.data.jobId, dataRecord)
          .subscribe({
            next: (orderResponse) => {
              this.globalService.convertAndSave(orderResponse.pdfOrders, jobNumber);
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  holdMessage(data) {
    // console.log(data);
  }

  showMyTasksOnlyChanged(e) {
    this.showMyInvoicesOnly = e.value ? true : false;
    this.setUpDataSource();
  }

  showInvoicesNeedingApprovalOnlyChanged(e) {
    this.showInvoicesNeedingApprovalOnly = e.value ? true : false;
    this.setUpDataSource();
  }

  onCellPrepared(e) {
    if (e.rowType === 'data') {
      if (e.column.dataField === 'totalExGST' || e.column.dataField === 'remainingBudget') {
        const remainingBudget = this.calculateRemainingBudget(e.data);
        if (remainingBudget < 0) {
          e.cellElement.classList.add('red');
        }
      }
    }
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }

  calculateCostCentreSortValue(data) {
    if (data.purchaseOrderId) {
      const po = this.invoiceService.allPurchaseOrders.find(i => i.id === data.purchaseOrderId);
      return this.costCentres.find(i => i.id === po?.costCentreId)?.orderNumber;
    } else {
      return null;
    }
  }

  clearStatePersistance() {
    this.loadingData = true;
    localStorage.removeItem('tracking-invoices-on-hold');
    setTimeout(() => {
      this.loadingData = false;
    }, 300); // wait
  }

  checkInvoicesOnHoldForUser() {
    this.globalService.isHaveInvoicesToCheck = false;

    // filter where allocated directly you
    if (this.invoices.find(i => i.allocatedToUserId === this.authService.getCurrentUserId()) !== undefined) {
      this.globalService.isHaveInvoicesToCheck = true;
    }
  }

  getUsersForEdit(cellInfo) {
    const job = this.jobs.find(i => i.id === cellInfo.data.jobId);

    this.usersForEdit = [];

    if (job) {
      const jobRole = this.taskService.allJobRoles?.find(i => i.jobId === job?.id
        && i.roleId === RoleTypeEnum.SchedulerConstructionEstimator);
      const user = this.userService.users?.find(i => i.id === jobRole?.userId);

      if (user) {
        this.usersForEdit.push(new User(user.firstName, user.lastName, user.id, jobRole.companyRoleDescription + ': ' + user.fullName));
        this.usersForEdit = this.usersForEdit.concat(this.users.filter(i => i.id !== user.id));
        return;
      }
    }

    this.usersForEdit = this.users;
  }

  onSelectionChanged(cellInfo, dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0].id);
      dropDownBox.close();
    }
  }

  onDropDownChanged(cellInfo, e) {
    if (!e.value) {
      cellInfo.setValue(null);
    }
  }

  refresh() {
    this.loadData(false);
  }

  onEditingStart(e) {
    this.purchaseOrderId = e.data?.purchaseOrderId;
    this.invoiceId = e.key;
    this.jobNumber = this.calculateJobNumber(e.data);
  }

  onEditorPreparing(e) {
    if (e.dataField === 'jobId' || e.dataField === 'costCentreId'
      || e.dataField === 'vendorId') {
      e.editorOptions.dropDownOptions = { minWidth: 350 };
    }
    if (e.dataField === 'allocatedToUserId') {
      e.editorOptions.dropDownOptions = { minWidth: 220 };
    }
  }

  cancelClickHandler() {
    this.invoiceHoldGrid.instance.cancelEditData();
  }

  saveClickHandler() {
    this.invoiceHoldGrid.instance.saveEditData();
  }

  showInvoiceHistory() {
    // what other invoices have been posted to this PO
    const modalRef = this.modalService.open(PreviousInvoicesComponent,
      { windowClass: 'modal-1200', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.purchaseOrderId = this.purchaseOrderId;
    modalRef.componentInstance.invoiceId = this.invoiceId;
    modalRef.componentInstance.jobNumber = this.jobNumber;

    const po = this.invoiceService.allPurchaseOrders.find(i => i.id === this.purchaseOrderId);
    modalRef.componentInstance.costCentreId = po?.costCentreId;

    modalRef.result.then(() => {
    });
  }

  showBackChargesClicked() {
    this.router.navigateByUrl('/back-charges');
    // const modalRef = this.modalService.open(BackChargesComponent,
    //   { windowClass: 'modal-1200', backdrop: 'static', keyboard: false });
    // modalRef.componentInstance.purchaseOrderId = this.purchaseOrderId;
    // modalRef.componentInstance.invoiceId = this.invoiceId;
    // modalRef.componentInstance.jobNumber = this.jobNumber;

    // const po = this.invoiceService.allPurchaseOrders.find(i => i.id === this.purchaseOrderId);
    // modalRef.componentInstance.costCentreId = po?.costCentreId;

    // modalRef.result.then(() => {
    // });
  }

  showAllHistoryClicked() {
    this.showAllHistory = true;
  }

  showOnHoldClicked() {
    this.showAllHistory = false;
    this.setUpDataSource();
  }

  onJobNumberChanged(jobNo: string) {
    if (jobNo) {
      this.jobNumber = jobNo;

      if (this.selectedVendorId) {
        this.loadInvoices();
      }
    } else {
      this.clearJob();
    }
  }

  clearJob() {
    this.jobNumber = null;
    this.dataSource = null;
  }

  loadInvoices() {
    this.loadingInvoices = true;
    this.subscriptions.push(
      this.invoiceService.getInvoices(null, null, this.selectedVendorId, this.jobNumber, null, null, null)
        .subscribe({
          next: (invoices) => {
            this.historyInvoices = [];
            // check if cost centre is hidden
            invoices?.filter(i => i.invoiceStatusId >= InvoiceStatusTypeEnum.Posted).forEach(invoice => {
              if (!(this.costCentres.find(i => i.id === invoice.costCentreId)?.isHideCostsFromTracking ?? false)) {
                this.historyInvoices.push(invoice);
              }
            });
            this.loadingInvoices = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingInvoices = false;
          }
        })
    );
  }

  onHistoryToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxSelectBox',
        options: {
          width: 300,
          placeholder: 'Select Vendor...',
          items: this.vendors,
          displayExpr: 'vendorName',
          valueExpr: 'id',
          showClearButton: true,
          searchEnabled: true,
          onValueChanged: this.onVendorSelected.bind(this),
          value: this.selectedVendorId
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Back',
          onClick: this.showOnHoldClicked.bind(this)
        }
      },);
  }

  onVendorSelected(ea) {
    this.selectedVendorId = ea.value;
    if (this.selectedVendorId) {
      this.loadInvoices();
    }
  }

  checkIfPaid(e) {
    // console.log(e);
    if (!e.row.data.paidDate && e.row.data.invoiceStatusId >= InvoiceStatusTypeEnum.Posted) {
      this.loadingFile = true;
      this.subscriptions.push(
        this.invoiceService.checkIfInvoicePaid(e.row.data.id)
          .subscribe({
            next: (res) => {
              if (!res.paidDate) {
                this.notiService.showInfo('Invoice ' + e.row.data.invoiceNumber + ' is not paid');
              }
              this.loadingFile = false;
              this.loadInvoices();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loadingFile = false;
            }
          })
      );
    }
  }

  rejectInvoice() {
    this.invoiceHoldGrid.instance.cancelEditData();
    const modalRef = this.modalService.open(RejectInvoiceComponent, { windowClass: 'modal-edit' });
    modalRef.componentInstance.invoice = this.invoices.find(i => i.id === this.invoiceId);
    modalRef.result.then(() => {
      this.setUpDataSource();
    }, () => { });
  }

  backChargeInvoice() {
    this.invoiceHoldGrid.instance.cancelEditData();
    const modalRef = this.modalService.open(BackChargeComponent, { windowClass: 'modal-edit' });
    modalRef.componentInstance.invoice = this.invoices.find(i => i.id === this.invoiceId);
    modalRef.result.then(() => {
      this.setUpDataSource();
    }, () => { });
  }
}
