import { Router } from '@angular/router';
import { UtilsService } from './../services/utils.service';
import { TrackingWorkFlowTypeEnum } from './../dtos/tracking-work-flow-type.enum';
import { TrackingColourEnum } from '../dtos/tracking-colour.enum';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { of, Subscription, timer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Job } from '../dtos/job';
import { JobField } from '../dtos/job-field';
import { StateStore } from '../shared/state-store/state-store';
import { StateStoreTypeEnum } from '../dtos/state-store-type-enum';
import { Task } from '../dtos/task';
import { TrackingFieldTypeEnum } from '../dtos/tracking-field-type.enum';
import { JobWorkFlowService } from '../services/felixApi/job-work-flow.service';
import { JobService } from '../services/felixApi/job.service';
import { JobCashFlowService } from '../services/felixApi/jobCashFlow.service';
import { MaintenanceService } from '../services/felixApi/maintenance.service';
import { TaskService } from '../services/felixApi/task.service';
import { TrackingFieldsService } from '../services/felixApi/tracking-fields.service';
import { UserService } from '../services/felixApi/user.service';
import { GlobalService } from '../services/global.service';
import { GridService } from '../services/grid.service';
import { NotificationService } from '../services/notification.service';
import { StateStoreComponent } from '../shared/state-store/state-store.component';
import { ColumnChooserComponent } from './column-chooser/column-chooser.component';
import { ReportGridService } from './report-grid.service';
import { DxColumn, DxGridState } from '../shared/state-store/dx-grid';
import { JobTaskForecast } from '../dtos/job-task-forecast';
import { VariationStatusEnum } from '../dtos/variation-status.enum';
import { TrackingCalculationFieldEnum } from '../dtos/tracking-calculation-field.enum';
import { TrackingFieldLookup } from '../dtos/tracking-field-lookup';
import { TaskStatusEnum } from '../dtos/task-status.enum';
import { JobEmailService } from '../services/felixApi/job-email.service';

@Component({
  selector: 'js-report-grid',
  templateUrl: './report-grid.component.html',
  styleUrls: ['./report-grid.component.scss']
})
export class ReportGridComponent implements OnInit, OnDestroy {

  @ViewChild('allTaskGrid', { static: false }) dataGrid: DxDataGridComponent;

  loading = false;
  subscriptions: Subscription[] = [];
  jobData: CustomStore;
  jobs: Job[];
  taskStatus = [
    { id: 1, status: 'Not Started' },
    { id: 2, status: 'In Progress' },
    { id: 3, status: 'Hold' },
    { id: 4, status: 'Problem' },
    { id: 5, status: 'Waiting' },
    { id: 8, status: 'Cancelled' },
    { id: 9, status: 'Completed' },
    { id: 10, status: 'Not Applicable' }
  ];
  jobTypes = [
    { id: 1, description: 'Standard' },
    { id: 2, description: 'Sales Template' },
    { id: 3, description: 'Contract Template' },
    { id: 4, description: 'House Type Template' },
    { id: 5, description: 'Test Job' }
  ];
  taskStatusList: CustomStore;
  dataSource: CustomStore;
  reportGridData: any;
  jobField: JobField;
  stateStoreId: number; // the layout store id
  newLayoutDescription: string;
  currentState: DxGridState;
  filterValue: any;
  jobTask: Task;
  todayString: string;
  reportName = '';
  gridHeight: number;
  lastColSortDirectionAscending: boolean;
  forecastTasks: JobTaskForecast[];
  adhocSelection: string;
  lookupList: TrackingFieldLookup[];
  adhocItem: TrackingFieldLookup;
  dataFieldForEdit: any;
  inEditMode = false;
  loadingState: boolean;
  nextClaimAmountVisible: boolean;
  jobsToUpdate: number[] = [];
  minuteCountdown = 60;

  constructor(
    public reportGridService: ReportGridService,
    private taskService: TaskService,
    private notiService: NotificationService,
    private trackingFieldsService: TrackingFieldsService,
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private userService: UserService,
    private jobService: JobService,
    private jobCashFlowService: JobCashFlowService,
    private jobWorkFlowService: JobWorkFlowService,
    private jobEmailService: JobEmailService,
    private gridService: GridService,
    private utilService: UtilsService,
    private router: Router
  ) {
    this.goToJobData = this.goToJobData.bind(this);
    this.onRowPrepared = this.onRowPrepared.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.setUpDataSource = this.setUpDataSource.bind(this);
    this.changEditMode = this.changEditMode.bind(this);
    this.onSaved = this.onSaved.bind(this);
  }

  ngOnInit() {
    this.setGridMeasurements();
    this.subscribeToInnerHeight();
    this.getData(false);
    this.setupDataRefresh();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToInnerHeight() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.pipe(debounceTime(200)).subscribe(
        () => {
          setTimeout(() => {
            this.setGridMeasurements();
          }, 500); // wait for iPhone
        }
      )
    );
  }

  setupDataRefresh(): void {
    // Refresh data every hour by counting down the minutes
    this.subscriptions.push(
      timer(0, 60000).subscribe(() => {
        if (!this.inEditMode) {
          if (this.minuteCountdown > 0 && !this.loading) {
            this.minuteCountdown--; // Decrement the countdown
          }

          if (this.minuteCountdown <= 0) {
            this.getData(false);
          }
        }
      })
    );
  }

  setGridMeasurements() {
    this.gridHeight = this.globalService.innerHeight - 80;
  }

  getData(useCache: boolean) {
    this.loading = true;
    this.todayString = this.utilService.convertDateToString(new Date());

    if (!useCache) {
      this.minuteCountdown = 60; // Reset the countdown
    }

    this.subscriptions.push(
      this.taskService.getAllTasksGridData(useCache, false)
        .subscribe({
          next: (jobs) => {
            this.useData(jobs);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  useData(jobs: Job[]) {
    this.jobs = jobs;
    this.forecastTasks = this.taskService.forecastTasks;

    this.jobData = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => jobs
    });

    this.taskStatusList = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.taskStatus
    });

    // columns dynamically generated based on data
    // tasks never cached currently so generate each time
    this.setupColumns();
    this.generateGridRows();
  }

  checkStoredCol(columns: DxColumn[], colField: string): [inGrid: boolean, visible: boolean] {
    if (!columns) {
      return [false, false];
    }
    const foundCol = columns.filter(c => c.dataField === colField);
    if (foundCol && foundCol.length === 1 && foundCol[0].visible) {
      return [true, true];
    } else if (foundCol && foundCol.length === 1) {
      return [true, false];
    } else {
      return [false, false];
    }
  }

  setupColumns() {
    let state: DxGridState;
    const stateStr = localStorage.getItem('allTaskGrid');
    if (stateStr) {
      state = JSON.parse(stateStr) as DxGridState;
    }
    const noState = !state || !state.columns || state.columns.length === 0;

    this.reportGridService.clear();

    // default cols //
    let res = this.checkStoredCol(state?.columns, 'jobNumber');
    this.addToColumns('jobNumber', 'Job Number', 'string', '', 'center', noState ? true : res[0], noState ? true : res[1]);
    res = this.checkStoredCol(state?.columns, 'contractName');
    this.addToColumns('contractName', 'Client', 'string', '', 'left', noState ? true : res[0], noState ? true : res[1]);
    res = this.checkStoredCol(state?.columns, 'siteAddress');
    this.addToColumns('siteAddress', 'Site Address', 'string', '', 'left', noState ? true : res[0], noState ? true : res[1]);
    //////////////////

    res = this.checkStoredCol(state?.columns, 'masterJob');
    this.addToColumns('masterJob', 'Master Job', 'string', '', 'center', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'isMasterJob');
    this.addToColumns('isMasterJob', 'Is Master Job', 'string', '', 'center', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'jobType');
    this.addToColumns('jobType', 'Job Type', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'isActive');
    this.addToColumns('isActive', 'Active Job', 'string', '', 'center', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'lotNumber');
    this.addToColumns('lotNumber', 'Lot Number', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'streetName1');
    this.addToColumns('streetName1', 'Street Name1', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'streetName2');
    this.addToColumns('streetName2', 'Street Name2', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'suburb');
    this.addToColumns('suburb', 'Suburb', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'postCode');
    this.addToColumns('postCode', 'Postcode', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'clientSuburb');
    this.addToColumns('clientSuburb', 'Client Suburb', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'clientPostCode');
    this.addToColumns('clientPostCode', 'Client Postcode', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'clientAddress');
    this.addToColumns('clientAddress', 'Client Address', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'salesDate');
    this.addToColumns('salesDate', 'Sales Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'daysSinceSale');
    this.addToColumns('daysSinceSale', 'Days Since Sale', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'daysSinceContractSigned');
    this.addToColumns('daysSinceContractSigned', 'Days Since Contract Signed', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'contractSignedDate');
    this.addToColumns('contractSignedDate', 'Contract Signed Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'titleDueDate');
    this.addToColumns('titleDueDate', 'Title Due', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'contractPrice');
    this.addToColumns('contractPrice', 'Contract Price', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'basePrice');
    this.addToColumns('basePrice', 'Base House Price', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'depositAmount');
    this.addToColumns('depositAmount', 'Deposit Amount', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'depositPaid');
    this.addToColumns('depositPaid', 'Deposit Paid', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'council');
    this.addToColumns('council', 'Council', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'isOnHold');
    this.addToColumns('isOnHold', 'IsOnHold', 'boolean', '', 'center', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'onHoldReason');
    this.addToColumns('onHoldReason', 'Hold Reason', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'holdDate');
    this.addToColumns('holdDate', 'Hold Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'houseType');
    this.addToColumns('houseType', 'House Type', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'houseModifications');
    this.addToColumns('houseModifications', 'House Modifications', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'jobCreationDate');
    this.addToColumns('jobCreationDate', 'Job Created', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'clientLastName1');
    this.addToColumns('clientLastName1', 'Client Last Name 1', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'clientEmail');
    this.addToColumns('clientEmail', 'Client Email', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'phoneNumber1');
    this.addToColumns('phoneNumber1', 'Phone Number1', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'phoneNotes1');
    this.addToColumns('phoneNotes1', 'Phone Notes1', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'phoneNumber2');
    this.addToColumns('phoneNumber2', 'Phone Number2', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'phoneNotes2');
    this.addToColumns('phoneNotes2', 'Phone Notes2', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'currentActivity');
    this.addToColumns('currentActivity', 'Current Activity', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'currentActivityCode');
    this.addToColumns('currentActivityCode', 'Current Activity Code', 'string', '', 'center',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'currentActivityStart');
    this.addToColumns('currentActivityStart', 'Current Activity Start', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'currentActivityDue');
    this.addToColumns('currentActivityDue', 'Current Activity Due', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'currentActivityEnd');
    this.addToColumns('currentActivityEnd', 'Current Activity Completed', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'completionDate');
    this.addToColumns('completionDate', 'Practical Completion Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);
      res = this.checkStoredCol(state?.columns, 'siteStartDate');
      this.addToColumns('siteStartDate', 'Site Start Date', 'date', 'd-MMM-yy', 'center',
        noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'handoverDate');
    this.addToColumns('handoverDate', 'Handover Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'cancellationDate');
    this.addToColumns('cancellationDate', 'Cancellation Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'cancellationReason');
    this.addToColumns('cancellationReason', 'Cancellation Reason', 'string', '', 'left',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'targetCompletionDate');
    this.addToColumns('targetCompletionDate', 'Target Completion Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);
      res = this.checkStoredCol(state?.columns, 'agreedCompletionDate');
      this.addToColumns('agreedCompletionDate', 'Agreed Completion Date', 'date', 'd-MMM-yy', 'center',
        noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'forecastCompletionDate');
    this.addToColumns('forecastCompletionDate', 'Forecast Completion Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'forecastSiteStartDate');
    this.addToColumns('forecastSiteStartDate', 'Forecast Site Start Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'originalContractualCompletion');
    this.addToColumns('originalContractualCompletion', 'Original Contractual Completion Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'currentContractualCompletionDate');
    this.addToColumns('currentContractualCompletionDate', 'Current Contractual Completion Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'maintenanceCompleteDate');
    this.addToColumns('maintenanceCompleteDate', 'Maintenance CompleteDate Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'targetStartDate');
    this.addToColumns('targetStartDate', 'Target Start Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'fixedStartDate');
    this.addToColumns('fixedStartDate', 'Fixed Start Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'workflowTemplate');
    this.addToColumns('workflowTemplate', 'Construction Workflow', 'string', '', 'left',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'preconWorkflowTemplate');
    this.addToColumns('preconWorkflowTemplate', 'Pre-Construction Workflow', 'string', '', 'left',
      noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'salesWorkflowTemplate');
    this.addToColumns('salesWorkflowTemplate', 'Sales Workflow (First)', 'string', '', 'left',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'otherWorkflowTemplate');
    this.addToColumns('otherWorkflowTemplate', 'Other Workflow (First)', 'string', '', 'left',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'maintenanceWorkflowTemplate');
    this.addToColumns('maintenanceWorkflowTemplate', 'Maintenance Workflow (First)', 'string', '', 'left',
      noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'claimSchedule');
    this.addToColumns('claimSchedule', 'Claim Schedule', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'salesQuotePrice');
    this.addToColumns('salesQuotePrice', 'Sales Price', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'salesQuoteSignedDate');
    this.addToColumns('salesQuoteSignedDate', 'Sales Quote Signed Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'depositBalanceDue');
    this.addToColumns('depositBalanceDue', 'Deposit Balance Due', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'quoteDate');
    this.addToColumns('quoteDate', 'Sales Quote Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'division');
    this.addToColumns('division', 'Division', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'contractQuoteDate');
    this.addToColumns('contractQuoteDate', 'Contract Quote Date', 'date', 'd-MMM-yy', 'center',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'totalContractPrice');
    this.addToColumns('totalContractPrice', 'Total Contract Price', 'number', '#,###', 'right',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'estate');
    this.addToColumns('estate', 'Estate', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'warningNote');
    this.addToColumns('warningNote', 'Warning Note', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'latitude');
    this.addToColumns('latitude', 'Latitude', 'number', '###.0000##', 'right', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'longitude');
    this.addToColumns('longitude', 'Longitude', 'number', '###.0000##', 'right', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'landPrice');
    this.addToColumns('landPrice', 'Land Price', 'number', '###.0000##', 'right', noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'tradeRegion');
    this.addToColumns('tradeRegion', 'Region', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'planNumber');
    this.addToColumns('planNumber', 'Plan Number', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'volume');
    this.addToColumns('volume', 'Volume', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'folio');
    this.addToColumns('folio', 'Folio', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'landTitleType');
    this.addToColumns('landTitleType', 'Type of Title', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'landZoning');
    this.addToColumns('landZoning', 'Land Zoning', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'landType');
    this.addToColumns('landType', 'Land Type', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);


    res = this.checkStoredCol(state?.columns, 'sendToConstructive');
    this.addToColumns('sendToConstructive', 'Send to Constructive', 'boolean', '', 'center',
      noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'nextClaimStage');
    this.addToColumns('nextClaimStage', 'Next Claim Stage', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'nextClaimAmount');
    this.addToColumns('nextClaimAmount', 'Next Claim Amount', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1]);
    this.nextClaimAmountVisible = noState ? false : res[0];

    res = this.checkStoredCol(state?.columns, 'nextClaimDate');
    this.addToColumns('nextClaimDate', 'Next Claim Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'latestJobEmailDate');
    this.addToColumns('latestJobEmailDate', 'Latest Email/Note Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'latestJobEmailSubject');
    this.addToColumns('latestJobEmailSubject', 'Latest Email/Note Subject', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'latestJobEmailBody');
    this.addToColumns('latestJobEmailBody', 'Latest Email/Note Body', 'string', null, 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'latestJobEmailSavedBy');
    this.addToColumns('latestJobEmailSavedBy', 'Latest Email/Note Saved By', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);

    // calc days
    res = this.checkStoredCol(state?.columns, 'calendarDaystoCompletion');
    this.addToColumns('calendarDaystoCompletion', 'Calendar days to Practical Completion', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'workingDaystoCompletion');
    this.addToColumns('workingDaystoCompletion', 'Working days to Practical Completion', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'calendarDaysSinceSiteStart');
    this.addToColumns('calendarDaysSinceSiteStart', 'Calendar days since Site Start', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'workingDaysSinceSiteStart');
    this.addToColumns('workingDaysSinceSiteStart', 'Working days since Site Start', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'contractsRequiredByDate');
    this.addToColumns('contractsRequiredByDate', 'Contracts Required By', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'calendarDaysToContractsRequired');
    this.addToColumns('calendarDaysToContractsRequired', 'Calendar days to contracts required', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1]);

    // bank details
    res = this.checkStoredCol(state?.columns, 'bankDescriptionPlusEmail');
    this.addToColumns('bankDescriptionPlusEmail', 'Bank Details', 'string', '', 'left',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'loanReference');
    this.addToColumns('loanReference', 'Loan Reference', 'string', '', 'left',
      noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'loanAmount');
    this.addToColumns('loanAmount', 'Loan Amount', 'number', '#,###', 'right',
      noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'clientLoginEmail');
    this.addToColumns('clientLoginEmail', 'Client Login Email', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'sendInfoUpdateEmail');
    this.addToColumns('sendInfoUpdateEmail', 'Send email to client re updates to portal', 'boolean', '', 'center', noState ? false : res[0], noState ? false : res[1]);

    // roles
    this.taskService.companyRoles.forEach(companyRole => {
      if (companyRole.isActive) {
        const id = 'companyRole' + companyRole.id;
        res = this.checkStoredCol(state?.columns, id);
        this.addToColumns(id, companyRole.companyRoleDescription, 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
      }
    });

    // tracking fields
    let dataType = 'string';
    let dataFormat = 'd-MMM-yy';
    let dataAlignment;

    this.trackingFieldsService.trackingFields.filter(i => i.isActive && i.trackingFieldGroupIsActive).forEach(field => {
      switch (field.trackingFieldTypeId) {
        case TrackingFieldTypeEnum.Number:
          dataType = 'number';
          dataFormat = 'fixedPoint';
          dataAlignment = 'center';
          break;
        case TrackingFieldTypeEnum.Date:
          dataType = 'date';
          dataFormat = 'd-MMM-yy';
          dataAlignment = 'center';
          break;
        case TrackingFieldTypeEnum.Calculated:
          dataType = 'date';
          dataFormat = 'd-MMM-yy';
          dataAlignment = 'center';
          break;
        case TrackingFieldTypeEnum.Time:
          dataType = 'datetime';
          dataFormat = 'shortTime';
          dataAlignment = 'center';
          break;
        case TrackingFieldTypeEnum.Boolean:
          dataType = 'string';
          dataFormat = '';
          dataAlignment = 'center';
          break;
        default:
          dataType = 'string';
          dataFormat = '';
          dataAlignment = 'left';
      }
      const id = 'field' + field.id;
      res = this.checkStoredCol(state?.columns, id);
      this.addToColumns(id, field.fieldName, dataType, dataFormat, dataAlignment, noState ? false : res[0], noState ? false : res[1]);
    });

    // tasks
    this.maintenanceService.taskMasters.forEach(taskMaster => {
      const inId = 'taskIn' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, inId);
      this.addToColumns(inId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' In',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);
      const dueId = 'taskDue' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, dueId);
      this.addToColumns(dueId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Due',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);
      const outId = 'taskOut' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, outId);
      this.addToColumns(outId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Out',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);
      const sentId = 'taskSent' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, sentId);
      this.addToColumns(sentId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Sent',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);
      const cId = 'taskComment' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, cId);
      this.addToColumns(cId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Comment',
        'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);

      const prevInId = 'taskPrevIn' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, prevInId);
      this.addToColumns(prevInId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Previous In',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);

      const aId = 'taskAssignee' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, aId);
      this.addToColumns(aId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Assignee',
        'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);

      const vId = 'taskVendor' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, vId);
      this.addToColumns(vId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Vendor',
        'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);

      const statusId = 'taskStatus' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, statusId);
      this.addToColumns(statusId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Status',
        'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);

      const forecastInId = 'taskForecastIn' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, forecastInId);
      this.addToColumns(forecastInId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Forecast In',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);

      const forecastOutId = 'taskForecastOut' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, forecastOutId);
      this.addToColumns(forecastOutId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Forecast Out',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);

      const targetInId = 'taskTargetIn' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, targetInId);
      this.addToColumns(targetInId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Target In',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);

      const targetOutId = 'taskTargetOut' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, targetOutId);
      this.addToColumns(targetOutId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Target Out',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);

      const modifiedUserId = 'taskModifiedUser' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, modifiedUserId);
      this.addToColumns(modifiedUserId,
        taskMaster.taskTypeDescription + ' - ' + taskMaster.taskTitle + ' Modified By',
        'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    });
  }

  generateGridRows() {
    this.reportGridData = [];
    const siteStartTasks = this.maintenanceService.allTemplateTasks.filter(i => i.isForecastStart);

    // check we need the forecasts

    this.jobs.forEach(job => {
      const reportGridRow = {};
      reportGridRow['jobId'] = job.id;
      reportGridRow['jobType'] = this.jobTypes.find(i => i.id === job.jobTypeId).description;

      const masterJob = this.jobs.find(i => i.id === job.masterJobId)?.jobNumber;
      reportGridRow['masterJob'] = masterJob ? masterJob : this.jobs.find(i => i.masterJobId === job.id) ? job.jobNumber : '';

      reportGridRow['isMasterJob'] = this.jobs.find(i => i.masterJobId === job.id) ? 'Master' : '';
      reportGridRow['jobNumber'] = job.jobNumber;
      reportGridRow['isActive'] = job.isActive ? 'Yes' : 'No';
      reportGridRow['contractName'] = job.contractName;
      reportGridRow['lotNumber'] = job.jobAddress?.lotNumber;
      reportGridRow['streetName1'] = job.jobAddress?.streetName1;
      reportGridRow['streetName2'] = job.jobAddress?.streetName2;
      reportGridRow['suburb'] = job.jobAddress?.suburbTown;
      reportGridRow['postCode'] = job.jobAddress?.postCode;
      reportGridRow['clientSuburb'] = job.contractAddress?.suburbTown;
      reportGridRow['clientPostCode'] = job.contractAddress?.postCode;
      reportGridRow['siteAddress'] = this.globalService.getJobString(job);
      reportGridRow['clientAddress'] = this.globalService.getClientAddress(job);
      reportGridRow['salesDate'] = job.salesDate;
      reportGridRow['contractSignedDate'] = job.contractSignedDate;
      reportGridRow['titleDueDate'] = job.titleDueDate;
      reportGridRow['depositAmount'] = job.depositAmount;
      reportGridRow['contractPrice'] = job.contractPrice;
      reportGridRow['basePrice'] = job.basePrice;
      reportGridRow['depositPaid'] = job.depositPaid;
      reportGridRow['council'] = job.council;
      reportGridRow['jobCreationDate'] = job.createDate;
      reportGridRow['clientEmail'] = job.clientEmail;
      reportGridRow['clientLastName1'] = job.lastName1;
      reportGridRow['phoneNumber1'] = job.phoneNumber1;
      reportGridRow['phoneNotes1'] = job.phoneNotes1;
      reportGridRow['phoneNumber2'] = job.phoneNumber2;
      reportGridRow['phoneNotes2'] = job.phoneNotes2;
      reportGridRow['salesQuotePrice'] = job.salesQuotePrice;
      reportGridRow['depositBalanceDue'] = job.depositBalanceDue;
      reportGridRow['salesQuoteSignedDate'] = job.salesQuoteSignedDate;
      reportGridRow['quoteDate'] = job.quoteDate;
      reportGridRow['contractQuoteDate'] = job.contractQuoteDate;
      reportGridRow['estate'] = job.estate;
      reportGridRow['warningNote'] = job.warningNote;
      reportGridRow['latitude'] = job.jobAddress?.latitude;
      reportGridRow['longitude'] = job.jobAddress?.longitude;
      reportGridRow['contractsRequiredByDate'] = job.contractsRequiredByDate;

      reportGridRow['planNumber'] = job.planNumber;
      reportGridRow['volume'] = job.volume;
      reportGridRow['folio'] = job.folio;
      reportGridRow['landTitleType'] = this.maintenanceService.landTitleTypes?.find(i => i.id === job.landTitleTypeId)?.description
      reportGridRow['landZoning'] = this.maintenanceService.landZones?.find(i => i.id === job.landZoningId)?.description
      reportGridRow['landType'] = this.maintenanceService.landTypes?.find(i => i.id === job.landTypeId)?.description

      reportGridRow['totalContractPrice'] = job.contractPrice;
      this.jobService.jobVariations
        .filter(i => i.jobId === job.id && i.variationType < 10 && i.statusId >= VariationStatusEnum.Approved).forEach(varn => {
          reportGridRow['totalContractPrice'] += varn.variationTotal;
        });

      const jobExtra = this.jobService.jobExtras.find(i => i.jobId === job.id);
      reportGridRow['isOnHold'] = jobExtra ? jobExtra.isOnHold : false;
      reportGridRow['onHoldReason'] = jobExtra ? jobExtra.onHoldReason : '';
      reportGridRow['holdDate'] = jobExtra ? jobExtra.holdDate : null;
      reportGridRow['landPrice'] = jobExtra?.landPrice;
      reportGridRow['tradeRegion'] = jobExtra?.tradeRegionId ? this.maintenanceService.tradeRegions.find(i => i.id === jobExtra.tradeRegionId)?.description : '';

      const houseType = this.jobService.houseTypes.find(i => i.id === job.houseTypeId);
      reportGridRow['houseType'] = houseType ? houseType.description : '';

      reportGridRow['houseModifications'] = job.houseModificationDescription;

      const division = this.maintenanceService.divisions.find(i => i.id === job.divisionId);
      reportGridRow['division'] = division ? division.description : '';

      if (jobExtra && jobExtra.maintenanceCompleteDate) {
        reportGridRow['currentActivity'] = 'Maintenance Complete';
        reportGridRow['currentActivityCode'] = '';
      } else if (jobExtra && jobExtra.currentActivityId) {
        const currentActivity = this.maintenanceService.activities.find(i => i.id === jobExtra.currentActivityId);
        reportGridRow['currentActivity'] = currentActivity ? currentActivity.description : '';
        reportGridRow['currentActivityCode'] = currentActivity ? currentActivity.activityCode : '';
      } else {
        if (job.salesDate) {
          reportGridRow['currentActivity'] = '';
        } else {
          reportGridRow['currentActivity'] = 'Pending Sale';
        }
        reportGridRow['currentActivityCode'] = '';
      }

      reportGridRow['currentActivityStart'] = jobExtra ? jobExtra.currentActivityStartDate : null;
      reportGridRow['currentActivityDue'] = jobExtra ? jobExtra.currentActivityDueDate : null;
      reportGridRow['currentActivityEnd'] = jobExtra ? jobExtra.currentActivityEndDate : null;
      reportGridRow['completionDate'] = jobExtra ? jobExtra.completionDate : null;
      reportGridRow['handoverDate'] = jobExtra ? jobExtra.handoverDate : null;
      reportGridRow['cancellationDate'] = jobExtra ? jobExtra.cancellationDate : null;
      reportGridRow['cancellationReason'] = jobExtra ? jobExtra.cancellationReason : '';
      reportGridRow['targetCompletionDate'] = jobExtra ? jobExtra.targetCompletionDate : null;
      reportGridRow['forecastCompletionDate'] = jobExtra ? jobExtra.forecastCompletionDate : null;
      reportGridRow['sendToConstructive'] = jobExtra ? jobExtra.sendToConstructive : false;
      reportGridRow['maintenanceCompleteDate'] = jobExtra ? jobExtra.maintenanceCompleteDate : null;
      reportGridRow['siteStartDate'] = jobExtra ? jobExtra.siteStartDate : null;

      // add the bank
      const jobBank = this.jobService.jobBanks.find(i => i.jobId === job.id);
      reportGridRow['bankDescriptionPlusEmail'] = jobBank ? jobBank.bankDescriptionPlusEmail : '';
      reportGridRow['loanReference'] = jobBank ? jobBank.loanReference : '';
      reportGridRow['loanAmount'] = jobBank ? jobBank.loanAmount : '';


      const jobClientEmail = this.jobService.jobClientLogins.find(i => i.jobId === job.id);
      reportGridRow['clientLoginEmail'] = jobClientEmail?.email ?? '';
      reportGridRow['sendInfoUpdateEmail'] = jobClientEmail?.sendInfoUpdateEmail ?? false;

      // add the roles
      this.taskService.companyRoles.forEach(companyRole => {
        const jobRole = this.taskService.allJobRoles.find(i => i.jobId === job.id && i.roleId === companyRole.roleId);
        reportGridRow['companyRole' + companyRole.id] = jobRole ? jobRole.user.fullName : '';
      });


      // fields from the cashflow table
      if (this.jobCashFlowService.jobCashFlows) {
        const jobCashFlow = this.jobCashFlowService.jobCashFlows.find(i => i.jobId === job.id);
        reportGridRow['targetStartDate'] = jobCashFlow ? jobCashFlow.targetStartDate : null;
        reportGridRow['claimSchedule'] = jobCashFlow ? jobCashFlow.claimScheduleName : '';
        reportGridRow['fixedStartDate'] = jobCashFlow ? jobCashFlow.fixedStartDate : null;
        reportGridRow['originalContractualCompletion'] = jobCashFlow ? jobCashFlow.originalContractualCompletion : null;
        reportGridRow['currentContractualCompletionDate'] = jobCashFlow ? this.jobService.calculateContractualCompletion(jobCashFlow.originalContractualCompletion, job.id) : null;
        reportGridRow['agreedCompletionDate'] = jobCashFlow ? jobCashFlow.agreedCompletionDate : null;
      } else {
        reportGridRow['targetStartDate'] = null;
        reportGridRow['claimSchedule'] = null;
        reportGridRow['fixedStartDate'] = null;
        reportGridRow['originalContractualCompletion'] = null;
        reportGridRow['currentContractualCompletionDate'] = null;
        reportGridRow['agreedCompletionDate'] = null;
      }

      // workflows
      const jobWorkFlows = this.jobWorkFlowService.allJobWorkflows.filter(i => i.jobId === job.id);
      const constructionWorkflow = jobWorkFlows.find(i => i.trackingWorkFlowTypeId === TrackingWorkFlowTypeEnum.Construction);
      reportGridRow['workflowTemplate'] = constructionWorkflow?.templateTaskHeaderName ?? '';
      reportGridRow['preconWorkflowTemplate'] = jobWorkFlows.find(i => i.trackingWorkFlowTypeId === TrackingWorkFlowTypeEnum.PreConstruction)?.templateTaskHeaderName ?? '';
      reportGridRow['salesWorkflowTemplate'] = jobWorkFlows.find(i => i.trackingWorkFlowTypeId === TrackingWorkFlowTypeEnum.Sales)?.templateTaskHeaderName ?? '';
      reportGridRow['otherWorkflowTemplate'] = jobWorkFlows.find(i => i.trackingWorkFlowTypeId === TrackingWorkFlowTypeEnum.Other)?.templateTaskHeaderName ?? '';
      reportGridRow['maintenanceWorkflowTemplate'] = jobWorkFlows.find(i => i.trackingWorkFlowTypeId === TrackingWorkFlowTypeEnum.Maintenance)?.templateTaskHeaderName ?? '';


      // tasks
      const forecastsForJob = this.forecastTasks.filter(i => i.jobId === job.id);
      const tasksForJob = this.taskService.jobTasks.filter(i => i.jobId === job.id);

      const nextClaim = this.jobService.nextClaims.find(i => i.jobId == job.id);
      reportGridRow['nextClaimStage'] = nextClaim?.activityDescription;
      reportGridRow['nextClaimAmount'] = nextClaim?.amount;

      jobWorkFlows.forEach(jobWorkFlow => {
        const nextClaimTask = this.maintenanceService.allTemplateTasks.find(i => i.templateTaskHeaderId === jobWorkFlow.templateTaskHeaderId && i.activityToSetEndDateId === nextClaim?.companyActivityId);
        if (nextClaimTask) {
          reportGridRow['nextClaimDate'] = forecastsForJob.find(i => i.taskMasterId === nextClaimTask?.taskMasterId)?.forecastCompletionDate;
        }
      });

      // calculated days
      reportGridRow['daysSinceSale'] = this.utilService.getCalendarDaysDifference(new Date(), job.salesDate);
      reportGridRow['daysSinceContractSigned'] = this.utilService.getCalendarDaysDifference(new Date(), job.contractSignedDate);

      const siteStartTaskForJob = siteStartTasks?.find(i => i.templateTaskHeaderId === constructionWorkflow?.templateTaskHeaderId);
      const forecastStartDate = forecastsForJob?.find(i => i.jobId === job.id && i.taskMasterId === siteStartTaskForJob?.taskMasterId)?.forecastStartDate;
      reportGridRow['forecastSiteStartDate'] = forecastStartDate;

      if (jobExtra?.forecastCompletionDate) {
        reportGridRow['calendarDaystoCompletion'] = this.utilService.getCalendarDaysDifference(jobExtra.forecastCompletionDate, new Date());
        reportGridRow['workingDaystoCompletion'] = this.maintenanceService.getCalendarDaysDifferenceExHolidays(jobExtra.forecastCompletionDate, new Date());
      } else {
        reportGridRow['calendarDaystoCompletion'] = null;
        reportGridRow['workingDaystoCompletion'] = null;
      }
      if (forecastStartDate) {
        reportGridRow['calendarDaysSinceSiteStart'] = this.utilService.getCalendarDaysDifference(new Date(), forecastStartDate);
        reportGridRow['workingDaysSinceSiteStart'] = this.maintenanceService.getCalendarDaysDifferenceExHolidays(new Date(), forecastStartDate);
      } else {
        reportGridRow['calendarDaysSinceSiteStart'] = null;
        reportGridRow['workingDaysSinceSiteStart'] = null;
      }

      reportGridRow['calendarDaysToContractsRequired'] = this.utilService.getCalendarDaysDifference(job.contractsRequiredByDate, new Date());


      // email
      const latestJobEmail = this.jobEmailService.latestJobEmails?.find(i => i.jobId === job.id);
      reportGridRow['latestJobEmailDate'] = latestJobEmail?.createDate;
      reportGridRow['latestJobEmailSubject'] = latestJobEmail?.subject ?? '';
      reportGridRow['latestJobEmailBody'] = latestJobEmail?.bodyAsText?.replace(/\n\n/g, '\n') ?? ''; //.replace(/\n/g, '\n') ?? '';
      reportGridRow['latestJobEmailSavedBy'] = this.userService.users.find(i => i.id === latestJobEmail?.createUserId)?.fullName ?? '';

      // general job data
      if (this.trackingFieldsService.trackingFields) {
        const jobFieldsForJob = this.trackingFieldsService.allJobFields.filter(i => i.jobId === job.id);

        this.trackingFieldsService.trackingFields.filter(i => i.isActive && i.trackingFieldGroupIsActive).forEach(field => {
          this.jobField = null; // reset
          this.jobField = jobFieldsForJob.find(i => i.trackingFieldId === field.id);

          // as we can change the field type to a date old data may not be a date so we check
          if (field.trackingFieldTypeId === TrackingFieldTypeEnum.Date) {
            reportGridRow['field' + field.id] = this.jobField && Date.parse(this.jobField.textValue) ? this.jobField.textValue : null;
          } else if (field.trackingFieldTypeId === TrackingFieldTypeEnum.Calculated) {
            if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.SalesDate) {
              reportGridRow['field' + field.id] = this.maintenanceService.addDays(job.salesDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
            } else if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.ContractSigned) {
              reportGridRow['field' + field.id] = this.maintenanceService.addDays(job.contractSignedDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
            } else if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.HandoverDate && jobExtra) {
              reportGridRow['field' + field.id] = this.maintenanceService.addDays(jobExtra.handoverDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
            } else if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.PracticalCompletion && jobExtra) {
              reportGridRow['field' + field.id] = this.maintenanceService.addDays(jobExtra.completionDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
            } else if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.SiteStart) {
              let siteStartDate: Date = null;
              siteStartTasks.forEach(siteStartTask => {
                siteStartDate = this.taskService.jobTasks
                  .find(i => i.jobId === job.id && i.taskMasterId === siteStartTask.taskMasterId && i.statusId != TaskStatusEnum.Cancelled)?.startDate;
              });
              reportGridRow['field' + field.id] = this.maintenanceService.addDays(siteStartDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
            } else if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.TitleDue) {
              if (job.titleDueDate) {
                reportGridRow['field' + field.id] = this.maintenanceService.addDays(job.titleDueDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
              }
            } else {
              reportGridRow['field' + field.id] = '';
            }
          } else {
            reportGridRow['field' + field.id] = this.jobField ? this.jobField.textValue : '';
          }
        });
      }


      this.maintenanceService.taskMasters.forEach(taskMaster => {
        this.jobTask = null; // reset
        this.jobTask = tasksForJob.find(i => i.taskMasterId === taskMaster.id);
        if (this.jobTask) {
          reportGridRow['taskIn' + taskMaster.id] = this.jobTask.startDate;
          reportGridRow['taskPrevIn' + taskMaster.id] = this.jobTask.prevStartDate;
          reportGridRow['taskDue' + taskMaster.id] = this.jobTask.dueDate;
          reportGridRow['taskOut' + taskMaster.id] = this.jobTask.endDate;
          reportGridRow['taskSent' + taskMaster.id] = this.jobTask.calledDate;
          reportGridRow['taskComment' + taskMaster.id] = this.jobTask.officeComment;
          reportGridRow['taskStatus' + taskMaster.id] = this.taskStatus.find(i => i.id === this.jobTask.statusId)?.status;

          if (this.jobTask.userId) {
            const assignee = this.userService.users.find(i => i.id === this.jobTask.userId);
            reportGridRow['taskAssignee' + taskMaster.id] = assignee ? assignee.fullName : '';
          } else {
            reportGridRow['taskAssignee' + taskMaster.id] = null;
          }

          if (this.jobTask.vendorId) {
            const vendor = this.userService.vendors.find(i => i.id === this.jobTask.vendorId);
            reportGridRow['taskVendor' + taskMaster.id] = vendor ? vendor.vendorName : '';
          } else {
            reportGridRow['taskVendor' + taskMaster.id] = null;
          }

          const taskModifiedUser = this.userService.users.find(i => i.id === this.jobTask.modifiedUserId);
          reportGridRow['taskModifiedUser' + taskMaster.id] = taskModifiedUser ? taskModifiedUser.fullName : '';
        } else {
          // still set to null to not be undefined so that sorting works
          reportGridRow['taskIn' + taskMaster.id] = null;
          reportGridRow['taskPrevIn' + taskMaster.id] = null;
          reportGridRow['taskDue' + taskMaster.id] = null;
          reportGridRow['taskOut' + taskMaster.id] = null;
          reportGridRow['taskSent' + taskMaster.id] = null;
          reportGridRow['taskComment' + taskMaster.id] = null;
          reportGridRow['taskAssignee' + taskMaster.id] = null;
          reportGridRow['taskVendor' + taskMaster.id] = null;
          reportGridRow['taskModifiedUser' + taskMaster.id] = null;
          reportGridRow['taskStatus' + taskMaster.id] = null;
        }

        const forecastTask = forecastsForJob.find(i => i.taskMasterId === taskMaster.id);
        if (forecastTask) {
          reportGridRow['taskForecastIn' + taskMaster.id] = forecastTask.forecastStartDate;
          reportGridRow['taskForecastOut' + taskMaster.id] = forecastTask.forecastCompletionDate;
          reportGridRow['taskTargetIn' + taskMaster.id] = forecastTask.targetStartDate;
          reportGridRow['taskTargetOut' + taskMaster.id] = forecastTask.targetCompletionDate;
        } else {
          reportGridRow['taskForecastIn' + taskMaster.id] = null;
          reportGridRow['taskForecastOut' + taskMaster.id] = null;
          reportGridRow['taskTargetIn' + taskMaster.id] = null;
          reportGridRow['taskTargetOut' + taskMaster.id] = null;
        }
      });

      this.reportGridData.push(reportGridRow);
    });

    this.setUpDataSource();
  }

  addToColumns(
    dataField: string,
    caption: string,
    dataType: string,
    format: string,
    alignment: 'left' | 'right' | 'center',
    inGrid: boolean,
    visible: boolean = false
  ) {
    const col = {
      dataField: dataField,
      caption: caption,
      dataType: dataType,
      format: format,
      alignment: alignment,
      visible
    };

    if (dataType === 'date') {
      col['sortingMethod'] = this.dateSort;
    }

    if (dataType === 'string') {
      col['cellTemplate'] = 'commentCellTemplate';
    }


    if (dataField.length >= 5 && dataField.substring(0, 5) === 'field') {
      col['allowEditing'] = true;
    } else if (dataField.length >= 7 && dataField.substring(0, 7) === 'taskDue') {
      col['allowEditing'] = true;
    } else if (dataField.length >= 11 && dataField.substring(0, 11) === 'taskComment') {
      col['allowEditing'] = true;
    } else {
      col['allowEditing'] = false;
    }

    if (dataField === 'jobNumber') {
      col['fixed'] = true;
    }

    if (inGrid) {
      this.reportGridService.gridColumns.push(col);
    } else {
      this.reportGridService.extraColumns.push(col);
    }
  }

  setUpDataSource() {
    this.loading = true;

    // refresh the grid
    const currentState = JSON.parse(localStorage.getItem('allTaskGrid'));
    this.setState(currentState);

    setTimeout(() => {
      this.loading = false;
    }, 300); // grid wait to force refresh

    this.loadingState = false;

    this.dataSource = new CustomStore({
      key: 'jobId',
      load: async () => {
        return new Promise((resolve, reject) =>
          of(this.reportGridData).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values: object) => {
        const fieldKeys = Object.keys(values);

        return new Promise((resolve, reject) => {
          fieldKeys.forEach(fieldKey => {
            if (fieldKey.substring(0, 7) === 'taskDue') {
              const fieldNumber = fieldKey.substring(7);

              const jobTask = this.taskService.jobTasks?.find(i => i.jobId === key && i.taskMasterId === +fieldNumber);
              if (jobTask) {
                const recordToUpdate: any = {};
                recordToUpdate.dueDate = values[fieldKey];
                this.taskService.updateJobTask(jobTask.id.toString(), recordToUpdate).subscribe({
                  next: (res) => {
                    const reportGridData = this.reportGridData.find(i => i.jobId === key);
                    reportGridData['taskDue' + fieldNumber] = values[fieldKey];
                    // for recalculations
                    const jobExists = this.jobsToUpdate.find(i => i === key);
                    if (!jobExists) {
                      this.jobsToUpdate.push(key);
                    }
                    return resolve(res);
                  }, error: (err) => {
                    return reject(this.globalService.returnError(err));
                  }
                });
              } else {
                const taskMaster = this.maintenanceService.taskMasters.find(i => i.id === +fieldNumber);
                return reject((taskMaster?.taskTitle ?? '') + ' Task does not exist. Create from Tasks tab if required');
              }
            } else if (fieldKey.substring(0, 11) === 'taskComment') {
              const fieldNumber = fieldKey.substring(11);

              const jobTask = this.taskService.jobTasks?.find(i => i.jobId === key && i.taskMasterId === +fieldNumber);
              if (jobTask) {
                this.taskService.updateJobTask(jobTask.id.toString(), { officeComment: values[fieldKey] }).subscribe({
                  next: (res) => {
                    const reportGridData = this.reportGridData.find(i => i.jobId === key);
                    reportGridData['taskComment' + fieldNumber] = values[fieldKey];
                    return resolve(res);
                  }, error: (err) => {
                    return reject(this.globalService.returnError(err));
                  }
                });
              } else {
                const taskMaster = this.maintenanceService.taskMasters.find(i => i.id === +fieldNumber);
                return reject((taskMaster?.taskTitle ?? '') + ' Task does not exist. Create from Tasks tab if required');
              }
            } else if (fieldKey.substring(0, 5) === 'field') {
              const fieldNumber = fieldKey.substring(5);

              this.trackingFieldsService.updateJobField(key, fieldNumber, { textValue: values[fieldKey] }).subscribe({
                next: (res) => {
                  const reportGridData = this.reportGridData.find(i => i.jobId === key);
                  reportGridData['field' + fieldNumber] = values[fieldKey];
                  return resolve(res);
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
            }
          });
        });
      }
    });
  }

  onToolbarPreparing(e, templateName: string, toolbarTemplate2) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName
    });

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        template: toolbarTemplate2
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'columnchooser',
          hint: 'Choose Columns',
          onClick: this.columnChooser.bind(this)
        }
      });
  }

  refresh() {
    this.getData(false);
  }

  saveState() {
    this.currentState = JSON.parse(localStorage.getItem('allTaskGrid'));
    if (this.filterValue) {
      this.currentState.filterValue = this.filterValue;
    }

    const modalRef = this.modalService.open(StateStoreComponent, { windowClass: 'modal-stateStore', scrollable: true });
    modalRef.componentInstance.stateStoreTypeId = StateStoreTypeEnum.AllTaskGrid;
    modalRef.componentInstance.stateString = JSON.stringify(this.currentState);

    modalRef.result.then((state: StateStore) => {
      this.loadingState = true;
      this.loading = true;

      if (state) {
        this.reportName = state.description;
        this.currentState = JSON.parse(state.stateString) as DxGridState;

        try {
          localStorage.setItem('allTaskGrid', JSON.stringify(this.currentState));
        } catch (err) {
          this.notiService.showError('Error loading layout. Please try again');
          console.error(err);
          this.currentState = null;
          this.reportName = '';
          localStorage.removeItem('allTaskGrid');
        }
      } else {
        // load default
        this.currentState = null;
        this.reportName = '';
        localStorage.removeItem('allTaskGrid');
      }

      this.getData(true);
    });
  }

  onRowPrepared(e) {
    if (e.rowType === 'data') {
      // On Hold rows = pink
      const jobExtra = this.jobService.jobExtras.find(i => i.jobId === e.data.jobId);
      if (jobExtra && (jobExtra.isOnHold || jobExtra.cancellationDate)) {
        if (jobExtra.cancellationDate) {
          e.rowElement.style.backgroundColor = 'rgb(250, 140, 160)';
        } else {
          e.rowElement.style.backgroundColor = 'pink';
        }
      } else {
        if (jobExtra && jobExtra.trackingColour && jobExtra.trackingColour !== '0') {
          e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
          if (jobExtra.trackingColour === TrackingColourEnum.Orange.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(253, 209, 127, 0.6)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Yellow.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(253, 253, 139, 0.6)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Teal.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(132, 247, 237, 0.6)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Blue.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(147, 198, 247, 0.4)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Magenta.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(208, 157, 250, 0.6)';
          } else if (jobExtra.trackingColour === TrackingColourEnum.Grey.toString()) {
            e.rowElement.style.backgroundColor = 'rgb(0, 0, 0, 0.2)';
          }
        }
        // Overdue date cells = red
        let key = '';
        for (key in e.data) {
          if (e.data.hasOwnProperty(key)) {
            if (!e.data['taskOut' + key.substring(7)] // && e.data['taskDue' + key.substring(7)]
              && (key.substring(0, 7) === 'taskOut' || key.substring(0, 7) === 'taskDue'
                || key.substring(0, 14) === 'taskForecastIn' || key.substring(0, 15) === 'taskForecastOut')) {

              if (e.data['taskDue' + key.substring(7)] && (key.substring(0, 7) === 'taskOut' || key.substring(0, 7) === 'taskDue')) {
                const dueString = e.data['taskDue' + key.substring(7)].toString();
                const cellsToChange = e.cells.filter(i => i.column.name === key);

                if (dueString < this.todayString) {
                  cellsToChange.forEach(cell => {
                    if (cell.column.name.substring(0, 7) === 'taskOut') {
                      cell.cellElement?.classList?.add('redBackground');
                    } else {
                      cell.cellElement?.classList?.add('red-bold');
                    }
                  });
                } else {
                  cellsToChange.forEach(cell => {
                    if (cell.column.name.substring(0, 7) === 'taskOut') {
                      // e.data['taskOut' + key.substring(7)] = 'Started';
                      cell.cellElement?.classList?.add('lightGreenBackground');
                    }
                  });
                }
              }


              // colour the forecast if in the past
              // ----------------------------------
              if (!e.data['taskIn' + key.substring(14)] && key.substring(0, 14) === 'taskForecastIn') {
                const forecastString = e.data['taskForecastIn' + key.substring(14)];

                if (forecastString && forecastString.toString() < this.todayString) {
                  const cellsToChange = e.cells.filter(i => i.column.name === key);
                  cellsToChange.forEach(cell => {
                    if (cell.column.name.substring(0, 14) === 'taskForecastIn') {
                      cell.cellElement?.classList?.add('red-bold');
                    }
                  });
                }
              }

              if (!e.data['taskOut' + key.substring(15)] && key.substring(0, 15) === 'taskForecastOut') {
                const forecastString = e.data['taskForecastOut' + key.substring(15)];

                if (forecastString && forecastString.toString() < this.todayString) {
                  const cellsToChange = e.cells.filter(i => i.column.name === key);
                  cellsToChange.forEach(cell => {
                    if (cell.column.name.substring(0, 15) === 'taskForecastOut') {
                      cell.cellElement?.classList?.add('red-bold');
                    }
                  });
                }
              }
            }

            if (e.data['taskStatus' + key.substring(7)] === 'Not Applicable' || e.data['taskStatus' + key.substring(6)] === 'Not Applicable') {
              const cellsToChange = e.cells.filter(i => i.column.name === key);
              cellsToChange.forEach(cell => {
                if (cell.column.name.substring(0, 7) === 'taskOut' || cell.column.name.substring(0, 6) === 'taskIn') {
                  cell.cellElement?.classList?.add('greyBackground');
                }
              });
            }


            // check the contractual completion date
            if (e.data.currentContractualCompletionDate && e.data.forecastCompletionDate) {
              const notOverdue = this.utilService.convertDateToString(e.data.currentContractualCompletionDate) >= this.utilService.convertDateToString(e.data.forecastCompletionDate);

              const cellsToChange = e.cells.filter(i => i.column.name === key);
              cellsToChange.forEach(cell => {
                if (cell.column.name === 'forecastCompletionDate') {
                  if (notOverdue) {
                    cell.cellElement?.classList?.add('green');
                  } else {
                    cell.cellElement?.classList?.add('red-bold');
                  }
                }
              });
            }


            // check the calculated date fields
            if (e.data['field' + key.substring(5)]) {
              const calcField = this.trackingFieldsService.trackingFields.find(i => i.id === +key.substring(5))
              if (calcField?.trackingCalculationFieldId) {
                const dateString = e.data['field' + key.substring(5)];

                if (this.utilService.convertDateToString(dateString) < this.todayString) {
                  const cellsToChange = e.cells.filter(i => i.column.name === key);
                  cellsToChange.forEach(cell => {
                    cell.cellElement?.classList?.add('red-bold');
                  });
                }
              }
            }
          }
        }
      }
    }
  }

  dateSort = (one, two): number => {
    if (!one && !two) { return 0; }
    if (!one && two) {
      return this.lastColSortDirectionAscending ? 1 : -1;
    }
    if (one && !two) {
      return this.lastColSortDirectionAscending ? -1 : 1;
    }
    if (one < two) { return -1; }
    if (one > two) { return 1; }
    return 0;
  }

  gridOptionChanged(e) {
    if (e.value === 'asc') {
      this.lastColSortDirectionAscending = true;
    } else if (e.value === 'desc') {
      this.lastColSortDirectionAscending = false;
    }
  }

  onExporting(e) {
    this.gridService.onExporting(e, this.reportName && this.reportName !== '' ? this.reportName : 'report-grid');
  }

  columnChooser() {
    const currentState = JSON.parse(localStorage.getItem('allTaskGrid'));

    const modalRef = this.modalService.open(ColumnChooserComponent, { windowClass: 'modal-1400' });
    modalRef.result.then(() => {
      this.loading = true;
      this.setState(currentState);

      // refresh the grid
      setTimeout(() => {
        this.loading = false;
      }, 300); // grid wait to force refresh
    });
  }

  setState(currentState: any) {
    // check that all columns exist in the users view - remember that some may be admin tracking fields
    localStorage.removeItem('allTaskGrid');
    if (currentState) {
      const newColumns = [];
      let filterValue = currentState.filterValue;
      currentState.columns.forEach(element => {
        const gridColumn = this.reportGridService.gridColumns.find(i => i.dataField === element.dataField);
        if (gridColumn !== undefined) {
          if (gridColumn?.visible) {
            element.visible = true;
          } else {
            element.visible = false;
          }
          newColumns.push(element);
        } else {
          // we may need to take out of the filter
          if (JSON.stringify(filterValue).includes(element.dataField)) {
            filterValue = null;
          }
        }
      });

      currentState.columns = newColumns;
      currentState.filterValue = filterValue;
      localStorage.setItem('allTaskGrid', JSON.stringify(currentState));
    }
  }

  onContentReady() {
    // console.log('Grid ready ' + new Date().toLocaleTimeString());
  }

  goToJobData(e) {
    if (e.row.data.jobId) {
      this.jobService.setCurrentJob(this.jobs.find(i => i.id === e.row.data.jobId)?.jobNumber);
      localStorage.setItem('jobANNX-costcentreId', e.row.data.costCentreId);
      this.globalService.setAreaSelected('job-data');
      this.router.navigateByUrl('/job-data');
    }
  }

  onEditorPreparing(e: any) {
    if (e.parentType !== 'dataRow') {
      return;
    } else {
      this.dataFieldForEdit = e;

      if (e.dataField.length >= 7 && e.dataField.substring(0, 7) === 'taskDue') {
        // can edit only if task exists
        const jobTask = this.taskService.jobTasks?.find(i => i.jobId === e.row.data.jobId && i.taskMasterId === +e.dataField.substring(7));
        if (jobTask) {
          e.editorName = 'dxDateBox';
          e.editorOptions = {
            type: 'date',
            showClearButton: true,
            displayFormat: 'd-MMM-yy',
            onValueChanged: this.onDateValueChanged.bind(this),
            value: this.adhocSelection
          };
        } else {
          this.notiService.showInfo('Task does not exist. Create from Tasks tab if required');
          e.editorOptions.disabled = true;
        }
      } else if (e.dataField.length >= 11 && e.dataField.substring(0, 11) === 'taskComment') {
        // can edit only if task exists
        const jobTask = this.taskService.jobTasks?.find(i => i.jobId === e.row.data.jobId && i.taskMasterId === +e.dataField.substring(11));
        if (jobTask) {
          e.editorName = 'dxTextArea';
          e.editorOptions.autoResizeEnabled = true;
          let prevHeight = null;
          e.editorOptions.onInput = (args) => {
            const td = args.element.closest('td');
            if (td && prevHeight !== td.offsetHeight) {
              const overlay = e.element.querySelector('.dx-datagrid-focus-overlay');
              if (overlay != null) {
                overlay.style.height = (td.offsetHeight + 1) + 'px';
              }
              prevHeight = td.offsetHeight;
            }
          };
        } else {
          this.notiService.showInfo('Task does not exist. Create from Tasks tab if required');
          e.editorOptions.disabled = true;
        }
      } else if (e.dataField.length >= 5 && e.dataField.substring(0, 5) === 'field') {
        const fieldNumber = e.dataField.substring(5);
        const trackingField = this.trackingFieldsService.trackingFields.find(i => i.id === +fieldNumber);

        if (trackingField.trackingFieldTypeId === TrackingFieldTypeEnum.Text) {
          e.editorName = 'dxTextArea';
          e.editorOptions.autoResizeEnabled = true;
          let prevHeight = null;
          e.editorOptions.onInput = (args) => {
            const td = args.element.closest('td');
            if (td && prevHeight !== td.offsetHeight) {
              const overlay = e.element.querySelector('.dx-datagrid-focus-overlay');
              if (overlay != null) {
                overlay.style.height = (td.offsetHeight + 1) + 'px';
              }
              prevHeight = td.offsetHeight;
            }
          };
        } else if (trackingField.trackingFieldTypeId === TrackingFieldTypeEnum.Number) {
          e.editorName = 'dxNumberBox';
          e.editorOptions.showSpinButtons = true;
          e.editorOptions.showClearButton = true;
        } else if (trackingField.trackingFieldTypeId === TrackingFieldTypeEnum.Date) {
          e.editorName = 'dxDateBox';
          e.editorOptions = {
            type: 'date',
            showClearButton: true,
            displayFormat: 'd-MMM-yy',
            onValueChanged: this.onDateValueChanged.bind(this),
            value: this.adhocSelection
          };
        } else if (trackingField.trackingFieldTypeId === TrackingFieldTypeEnum.Lookup
          || trackingField.trackingFieldTypeId === TrackingFieldTypeEnum.Boolean) {

          if (trackingField.trackingFieldTypeId === TrackingFieldTypeEnum.Lookup) {
            this.lookupList = this.trackingFieldsService.allTrackingFieldLookups.filter(i => i.trackingFieldId === +fieldNumber);

            if (e.value && e.value.length) {
              const foundSelection = this.lookupList.find(i => i.description === e.value);
              if (!foundSelection) {
                // asdd it to the list
                this.addToList(e.value, trackingField.trackingFieldTypeId);
              }
            }
          } else if (trackingField.trackingFieldTypeId === TrackingFieldTypeEnum.Boolean) {
            this.lookupList = [];

            this.adhocItem = new TrackingFieldLookup(1, 'Yes', 1, 0);
            this.lookupList.push(this.adhocItem);
            this.adhocItem = new TrackingFieldLookup(2, 'No', 2, 0);
            this.lookupList.push(this.adhocItem);
            this.adhocItem = new TrackingFieldLookup(3, 'N/A', 3, 0);
            this.lookupList.push(this.adhocItem);

            if (e.value && e.value.length) {
              const foundSelection = this.lookupList.find(i => i.description === e.value);
              if (!foundSelection) {
                // asdd it to the list
                this.adhocItem = new TrackingFieldLookup(4, e.value, 4, 0);
                this.lookupList.push(this.adhocItem);
              }
            }
          }

          e.editorName = 'dxSelectBox';
          e.editorOptions = {
            dataSource: this.lookupList,
            searchEnabled: true,
            acceptCustomValue: true,
            valueExpr: 'description',
            displayExpr: 'description',
            showClearButton: true,
            onValueChanged: this.onLookupValueChanged.bind(this),
            onCustomItemCreating: this.addCustomItem.bind(this),
            value: this.adhocSelection
          };
        } else if (trackingField.trackingFieldTypeId === TrackingFieldTypeEnum.Calculated) {
          e.editorOptions.disabled = true;
        }
      }
    }
  }

  onDateValueChanged(e) {
    if (e.value instanceof Date) {
      this.adhocSelection = this.utilService.convertDateToString(e.value);
    } else {
      this.adhocSelection = e.value;
    }
    this.dataFieldForEdit.setValue(this.adhocSelection);
  }

  onLookupValueChanged(e) {
    this.adhocSelection = e.value;
    this.dataFieldForEdit.setValue(this.adhocSelection);
  }

  addCustomItem(data, trackingFieldId: number) {
    if (!data.text) {
      data.customItem = null;
      return;
    }

    this.addToList(data.text, trackingFieldId);
    this.adhocSelection = data.text;
    this.dataFieldForEdit.setValue(this.adhocSelection);
    data.customItem = this.adhocItem;
  }

  addToList(adhocText: string, trackingFieldId: number) {
    const productIds = this.lookupList.map(function (item) {
      return item.id;
    });
    const incrementedId = Math.max.apply(null, productIds) + 1;

    this.adhocItem = new TrackingFieldLookup(incrementedId, adhocText, incrementedId, trackingFieldId);
    this.lookupList.push(this.adhocItem);
  }

  onEditingStart(e) {
    if (e.column.dataField.length >= 5 && e.column.dataField.substring(0, 5) === 'field') {
      this.adhocSelection = e.data.textValue; // set
    } else if (e.column.dataField.length >= 7 && e.column.dataField.substring(0, 7) === 'taskDue') {
      this.adhocSelection = e.data[e.column.dataField] && Date.parse(e.data[e.column.dataField]) ? e.data[e.column.dataField] : null
    } else if (e.column.dataField.length >= 11 && e.column.dataField.substring(0, 11) === 'taskComment') {
      this.adhocSelection = e.data.textValue;
    }
  }

  changEditMode() {
    if (this.dataGrid.instance.hasEditData() && this.inEditMode) {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      this.inEditMode = !this.inEditMode;

      if (this.inEditMode) {
        this.notiService.showInfo('Edit mode now on. Edit start and completion dates via tasks tab. Edit task comments, due dates and tracking field data');
      }
    }
  }

  onSaved() {
    // recalc the current activity and forecast
    if (this.jobsToUpdate.length) {
      this.jobsToUpdate.forEach(jobId => {
        this.subscriptions.push(
          this.taskService.calcCurrentActivity(jobId)
            .subscribe({
              next: () => {
              },
              error: (err) => {
                this.notiService.notify(err);
              }
            })
        );
      });
      this.jobsToUpdate = [];
    }
  }
}
